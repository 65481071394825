export default class ObjectUtil {
  ObjectUtil () {
  }

  static copyFieldValue (src, target) {
    const targetKeys = Object.keys(target)
    targetKeys.forEach(key => {
      // eslint-disable-next-line no-prototype-builtins
      if (src.hasOwnProperty(key)) {
        if (target[key] instanceof Object) {
          this.copyFieldValue(src[key], target[key])
        } else {
          target[key] = src[key]
        }
      }
    })
    return target
  }

  static hashCode (input) {
    switch (typeof input) {
      case 'boolean':
        return input ? 1231 : 1237
      case 'number':
      case 'bigint':
        return input
      case 'string':
        var hash = 0
        for (let i = 0; i < input.length; i++) {
          const char = input.charCodeAt(i)
          hash = ((hash << 5) - hash) + char
          hash |= 0 // Convert to 32-bit integer
        }
        return hash

      case 'object':
        if (input === null) {
          return 0
        } else if (Array.isArray(input)) {
          return input.reduce((acc, item) => acc ^ this.hashCode(item), 0)
        } else {
          const keys = Object.keys(input).sort()
          return keys.reduce((acc, key) => acc ^ this.hashCode(input[key]), 0)
        }
      case 'symbol':
        return input.toString().split('').reduce((acc, char) => {
          const code = char.charCodeAt(0)
          return ((acc << 5) - acc) + code
        }, 0)
      default:
        throw new Error('Unsupported input type for hashCode(): ' + typeof input)
    }
  }
}
