import resultHttpTool from '@/http/ResultHttpTool'

const API_PREFIX = '/api/usr/vjbBill'

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export default {
  findByPage
}
