<template>
  <el-container>
    <el-header height="180px">
      Header
    </el-header>
    <el-main>
      <div class="calculator-main">
        <div class="extra-key">
        </div>
        <div class="main-key">
          <el-row>
            <el-button size="medium">!=</el-button>
            <el-button size="medium">></el-button>
            <el-button size="medium">>=</el-button>
            <el-button size="medium"><span v-html="'<='"/></el-button>
            <el-button size="medium"><span v-html="'<'"/></el-button>
            <el-button type="info" size="medium">!</el-button>
          </el-row>
          <el-row>
            <el-button size="medium">x/y</el-button>
            <el-button size="medium">x^y</el-button>
            <el-button size="medium">x^3</el-button>
            <el-button size="medium">x^2</el-button>
            <el-button size="medium">sqrt</el-button>
            <el-button type="info" size="medium">/</el-button>
          </el-row>
          <el-row>
            <el-button size="medium">a</el-button>
            <el-button size="medium">x</el-button>
            <el-button size="medium">7</el-button>
            <el-button size="medium">8</el-button>
            <el-button size="medium">9</el-button>
            <el-button type="info" size="medium">*</el-button>
          </el-row>
          <el-row>
            <el-button size="medium">b</el-button>
            <el-button size="medium">y</el-button>
            <el-button size="medium">4</el-button>
            <el-button size="medium">5</el-button>
            <el-button size="medium">6</el-button>
            <el-button type="info" size="medium">-</el-button>
          </el-row>
          <el-row>
            <el-button size="medium">c</el-button>
            <el-button size="medium">z</el-button>
            <el-button size="medium">1</el-button>
            <el-button size="medium">2</el-button>
            <el-button size="medium">3</el-button>
            <el-button type="info" size="medium">+</el-button>
          </el-row>
          <el-row>
            <el-button size="medium">d</el-button>
            <el-button size="medium">(</el-button>
            <el-button size="medium">)</el-button>
            <el-button size="medium">0</el-button>
            <el-button size="medium">.</el-button>
            <el-button size="medium" type="primary">=</el-button>
          </el-row>
        </div>
      </div>

    </el-main>
    <el-footer height="150px">Footer</el-footer>
  </el-container>
</template>

<script>
export default {
  name: 'Calculator'
}
</script>

<style scoped>

.el-container {
  height: 100%;
}

.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 40px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

.el-row {
  margin: 10px;
}

</style>
