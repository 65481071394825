import generalHttpTool from '@/http/GeneralHttpTool'

export function post (uri, ip) {
  return generalHttpTool.post(uri,
    ip, getConfig())
    .then(downloadThen())
    .catch(error => {
      console.error('[Download failed]', error)
    })
}

export function get (uri) {
  return generalHttpTool.get(uri, getConfig())
    .then(downloadThen())
    .catch(error => {
      console.error('[Download failed]', error)
    })
}

function getFileName (contentDisposition) {
  return contentDisposition.split('filename=')[1]
}

function downloadThen () {
  return resp => {
    const blob = new Blob([resp.data])
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
    // 创建下载的链接
    downloadElement.href = href
    // 下载后文件名
    downloadElement.download = getFileName(resp.headers['content-disposition'])
    document.body.appendChild(downloadElement)
    // 点击下载
    downloadElement.click()
    // 下载完成移除元素
    document.body.removeChild(downloadElement)
    // 释放掉blob对象
    window.URL.revokeObjectURL(href)
  }
}

function getConfig () {
  return {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json; application/octet-stream'
    }
  }
}

export default {
  post,
  get
}
