import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'
// import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/usrIfr'

export function findOrAdd () {
  return resultHttpTool.get(API_PREFIX + '/findOrAdd')
}

export function findByInvitationCode (invitationCode) {
  return resultHttpTool.get(API_PREFIX + '/findByInvitationCode',
    { params: { invitationCode: invitationCode } })
}

export function update (ip) {
  return resultHttpTool.post(API_PREFIX + '/update', ip)
    .then(resultData => {
      if (resultData) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return resultData
    })
}

export default {
  findByInvitationCode,
  findOrAdd,
  update
}
