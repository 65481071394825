export default class EnDecodeUtil {
  EnDecodeUtil () {
  }

  static hexEncode (str) {
    return Buffer.from(str, 'utf8').toString('hex')
  }

  static hexDecode (str) {
    return Buffer.from(str, 'hex').toString('utf8')
  }

  static encode (str, alg) {
    switch (alg) {
      case 'BASE64':
        return this.base64Encode(str)
      case 'URL':
        return this.uriEncode(str)
      case 'HEX':
        return this.hexEncode(str)
    }
  }

  static decode (str, alg) {
    switch (alg) {
      case 'BASE64':
        return this.base64Decode(str)
      case 'URL':
        return this.uriDecode(str)
      case 'HEX':
        return this.hexDecode(str)
    }
  }

  static uriEncode (unEncodedUri) {
    return encodeURI(unEncodedUri)
  }

  static uriDecode (encodedUri) {
    return decodeURI(encodedUri)
  }

  static uriFullyEncode (unEncodedUri) {
    return encodeURIComponent(unEncodedUri)
  }

  static uriFullyDecode (encodedUri) {
    return decodeURIComponent(encodedUri)
  }

  static base64Encode (unEncodedStr) {
    return Buffer.from(unEncodedStr, 'utf-8').toString('base64')
  }

  static base64Decode (encodedStr) {
    return Buffer.from(encodedStr, 'base64').toString('utf8')
  }
}
