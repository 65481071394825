<template>
  <el-container>
    <el-aside width="150px">
      <div>
        <tool-shower tool-unid="TimingLendar"></tool-shower>
      </div>
      <el-menu :default-active="this.$route.path" router>
        <menu-tree :menu-list="this.menuList"></menu-tree>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import MenuTree from '@/components/MenuTree.vue'
import ToolShower from '@/components/ToolShower.vue'

export default {
  name: 'TimingLendar',
  components: {
    ToolShower,
    MenuTree
  },
  data () {
    return {
      menuList: [
        {
          index: '/tool/timingLendar/calendar',
          icon: 'iconfont vj-icon-rili',
          name: '日历',
          nameKey: 'timingLendar.calendar',
          children: []
        },
        {
          index: '/tool/timingLendar/comingTime',
          icon: 'iconfont vj-icon-qianshu',
          name: '署光',
          nameKey: 'timingLendar.comingTime',
          children: []
        },
        {
          index: '/tool/timingLendar/pastTime',
          icon: 'iconfont vj-icon-kangdeshiguang',
          name: '拾光',
          nameKey: 'timingLendar.pastTime',
          children: []
        }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="scss">
@import "@/assets/scss/TimingLendar.scss";

</style>
