/**
 * 天干地支之地支速查表
 * @Array Of Property
 * @trans['子','丑','寅','卯','辰','巳','午','未','申','酉','戌','亥']
 * @return Cn string
 */
export default [
  '\u5b50',
  '\u4e11',
  '\u5bc5',
  '\u536f',
  '\u8fb0',
  '\u5df3',
  '\u5348',
  '\u672a',
  '\u7533',
  '\u9149',
  '\u620c',
  '\u4ea5'
]
