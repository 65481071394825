<template>
  <div class="tool-shower-container">
    <div class="tool-name-div">
      <p class="text">{{ toolInfo.name }}</p>
    </div>
    <div class="tool-menu-div">
      <p class="text">{{ toolInfo.name }}</p>
    </div>
    <router-link v-if="toolInfo.isVjvTool===1" :to="{path:toolInfo.path}" target="_blank">
      <img :src="toolLogo(toolInfo)" class="tool-img" alt="LOGO">
    </router-link>
  </div>
</template>

<script>
import ToolApi from '@/api/vojivosvc/toolhall/ToolApi'
import { LOCAL_FILE_PREFIX } from '@/constant/CmnBizConst'

export default {
  name: 'ToolShower',
  props: {
    toolUnid: String
  },
  created () {
    ToolApi.findByUnid(this.toolUnid)
      .then(result => {
        if (result) {
          this.toolInfo = result
        }
      })
  },
  data () {
    return {
      toolInfo: {
        name: '',
        path: ''
      }
    }
  },
  methods: {
    toolLogo (tool) {
      if (tool.logoUrl === LOCAL_FILE_PREFIX) {
        return require('@/assets/img/toollogo/' + this.toolUnid + '.png')
      } else if (tool.logoUrl.startsWith(LOCAL_FILE_PREFIX)) {
        return require(tool.logoUrl.substring(LOCAL_FILE_PREFIX.length))
      } else {
        return tool.logoUrl
      }
    }
  }
}
</script>

<style scoped>
.tool-shower-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 80px;
  border: #1A1A1A;
  border-radius: 5%;
  background-color: #8c939d;
}

.tool-img {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 2%;
  left: 30%;
}

.tool-name-div, .tool-menu-div {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-wrap: break-word;
  padding: 5px;
}

.tool-name-div {
  top: 0;
  left: 5px;
}

.tool-menu-div {
  top: 0;
  right: 15px;
}

.text {
  text-align: center;
  line-height: 1.2;
  font-size: 10px;
}
</style>
