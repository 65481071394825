<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="from-header">
          <div class="header-item-div">
            <div class="lang-select-div">
              <el-select v-model="fromLangId">
                <el-option v-for="lang in langList"
                           :key="lang.langId"
                           :value="lang.langId"
                           :label="lang.langName"
                           :disabled="lang.langId === toLangId"
                >
                </el-option>
              </el-select>
            </div>

          </div>
          <div class="header-item-div">
            <el-input v-model="topicFindByPage.srchKw" class="srch-input" @change="doSrch"></el-input>
          </div>
          <div class="header-item-div">
            <el-button icon="el-icon-plus" circle @click="toAdd(null, fromLangId)"></el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="to-header">
          <div class="header-item-div">
            <el-button icon="el-icon-refresh" circle @click="exchangeLang"></el-button>
          </div>
          <div class="header-item-div">
            <el-radio-group v-model="toLangId">
              <el-radio-button
                v-for="(item,idx) in defaultToLangList"
                :key="idx"
                :label="item.langId"
                :disabled="item.langId === fromLangId"
              >
                {{ item.langName }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div class="header-item-div">
            <el-select v-model="toLangId">
              <el-option v-for="lang in langList"
                         :key="lang.langId"
                         :value="lang.langId"
                         :label="lang.langName"
                         :disabled="lang.langId === fromLangId"
              >
              </el-option>
            </el-select>
          </div>
        </div>

      </el-col>
    </el-row>

    <div v-infinite-scroll="loadMore"
         infinite-scroll-disabled="isLoading"
         style="height: 600px;overflow: auto"
    >
      <el-row :gutter="20" v-for="(langCorner) in topicIdLangCornerMap.values()" :key="langCorner.id">
        <el-col :span="8">
          <el-card class="card-box" @click.native="toEdit(langCorner)">
            <div class="card-item-div">
              <el-tooltip effect="dark" :content="langCorner.topic" placement="top">
                <div class="topic-div">
                  {{ langCorner.topic | truncate }}
                </div>
              </el-tooltip>
            </div>
            <div class="card-item-div">
              <el-input
                :value="langCorner.content"
                disabled
                type="textarea"
                rows="6"
                @click="toView(langCorner)"
              ></el-input>
            </div>
            <div class="tag-div">
              <div>
                <el-tag
                  size="mini"
                  type="info"
                  style="margin-right: 5px"
                  v-for="(tag,idx) in langCorner.tags"
                  :key="idx">
                  {{ tag }}
                </el-tag>
              </div>
              <div>
                <el-tag v-if="createdByCurAcct(langCorner)" type="danger" size="mini">{{ langCorner.acctUnid }}</el-tag>
                <el-tag v-else size="mini">{{ langCorner.acctUnid }}</el-tag>
              </div>
            </div>
          </el-card>
        </el-col>
        <div v-if="langCorner.toLangMaster">
          <el-col :span="8">
            <el-card class="card-box" @click.native="toEdit(langCorner.toLangMaster)">
              <div class="card-item-div">
                <el-tooltip effect="dark" :content="langCorner.toLangMaster.topic" placement="top">
                  <div class="topic-div">
                    {{ langCorner.toLangMaster.topic | truncate }}
                  </div>
                </el-tooltip>
              </div>
              <div class="card-item-div">
                <el-input
                  :value="langCorner.toLangMaster.content"
                  disabled
                  type="textarea"
                  rows="6"
                  @click="toView(langCorner.toLangMaster)"
                ></el-input>
              </div>
              <div class="tag-div">
                <div>
                  <el-tag
                    size="mini"
                    type="info"
                    style="margin-right: 5px"
                    v-for="(tag,idx) in langCorner.toLangMaster.tags"
                    :key="idx">
                    {{ tag }}
                  </el-tag>
                </div>
                <div>
                  <el-tag v-if="createdByCurAcct(langCorner.toLangMaster)" type="danger" size="mini">
                    {{ langCorner.toLangMaster.acctUnid }}
                  </el-tag>
                  <el-tag v-else size="mini">{{ langCorner.toLangMaster.acctUnid }}</el-tag>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="5">
              <el-col :span="20">
                <el-carousel height="230px" v-if="langCorner.toLangOthers">
                  <el-carousel-item v-for="(item,idx) in langCorner.toLangOthers" :key="idx">
                    <el-card class="card-box" @click.native="toEdit(item)">
                      <div class="card-item-div">
                        <el-tooltip effect="dark" :content="item.topic" placement="top">
                          <div class="topic-div">
                            {{ item.topic | truncate }}
                          </div>
                        </el-tooltip>
                      </div>
                      <div class="card-item-div">
                        <el-input
                          :value="item.content"
                          disabled
                          type="textarea"
                          rows="6"
                          @click="toView(item)"
                        ></el-input>
                      </div>
                      <div class="tag-div">
                        <div>
                          <el-tag
                            size="mini"
                            type="info"
                            style="margin-right: 5px"
                            v-for="(tag,idx) in item.tags"
                            :key="idx">
                            {{ tag }}
                          </el-tag>
                        </div>
                        <div>
                          <el-tag v-if="createdByCurAcct(item)" type="danger" size="mini">{{ item.acctUnid }}</el-tag>
                          <el-tag v-else size="mini">{{ item.acctUnid }}</el-tag>
                        </div>
                      </div>
                    </el-card>
                  </el-carousel-item>
                </el-carousel>
                <el-carousel height="230px" v-else>
                  <el-carousel-item>
                    <el-card @click.native="toAdd(langCorner.topicId, toLangId)" class="card-box">
                      暂无该语言对应的其他内容，快去添加吧
                    </el-card>

                  </el-carousel-item>
                </el-carousel>
              </el-col>
              <el-col :span="4">
                <div>
                  <el-button icon="el-icon-right"
                             type="info"
                             circle
                             :disabled="langCorner.toLangOthers===null"
                             @click="othersNextPage(langCorner.topicId, toLangId)"></el-button>
                </div>
                <div>
                  <el-button icon="el-icon-back"
                             type="info"
                             circle
                             :disabled="langCorner.toLangOthers===null"
                             @click="othersPrevPage(langCorner.topicId, toLangId)"></el-button>
                </div>
                <div>
                  <el-button icon="el-icon-plus"
                             type="primary"
                             circle
                             @click="toAdd(langCorner.topicId, toLangId)"></el-button>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </div>
        <div v-else>
          <el-col :span="16">
            <el-card @click.native="toAdd(langCorner.topicId, toLangId)" class="card-box">
              暂无该语言对应的内容，第一个添加的将会被做为主译文
              <el-button type="text" @click="toAdd(langCorner.topicId, toLangId)">快去添加</el-button>
            </el-card>
          </el-col>
        </div>
      </el-row>
    </div>
    <div>
      <!--Add Dialog Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableAddForm"
        title="添加"
        append-to-body
        width="800px"
      >
        <el-form
          ref="addFormRef"
          :inline="true"
          :model="addForm"
          :rules="addFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item v-if="addForm.topicId" label="话题ID" prop="topicId">
            <el-input v-model="addForm.topicId" disabled style="width: 600px;"/>
          </el-form-item>
          <el-form-item label="话题" prop="topic">
            <el-input v-model="addForm.topic" style="width: 600px;"/>
          </el-form-item>
          <el-form-item label="语言" prop="langId">
            <el-select v-model="addForm.langId">
              <el-option v-for="lang in langList"
                         :key="lang.langId"
                         :value="lang.langId"
                         :label="lang.langName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地区" prop="regionId">
            <el-select v-model="addForm.regionId">
              <el-option v-for="region in regionList"
                         :key="region.regionId"
                         :value="region.regionId"
                         :label="region.regionName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <el-input v-model="addForm.content"
                      type="textarea"
                      rows="6"
                      style="width: 500px;"/>
          </el-form-item>
          <el-form-item label="标签" prop="tags">
            <el-select
              v-model="addForm.tags"
              multiple
              filterable
              allow-create
              placeholder="请选择或创建标签"
              style="width: 600px"
            >
              <el-option
                v-for="(tag,idx) in defaultTags"
                :key="idx"
                :label="tag"
                :value="tag">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableAddForm=false">取消</el-button>
          <el-button type="primary" @click="doAdd">确认</el-button>
        </div>
      </el-dialog>

      <!--Edit Dialog Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableEditForm"
        title="编辑话题"
        append-to-body
        width="800px"
      >
        <el-form
          ref="editFormRef"
          :inline="true"
          :model="editForm"
          :rules="editFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item label="话题ID" prop="main.topicId">
            <el-input disabled v-model="editForm.main.topicId" style="width: 600px;"/>
          </el-form-item>
          <el-form-item label="话题" prop="main.topic">
            <el-input v-model="editForm.main.topic" style="width: 600px;"/>
          </el-form-item>
          <el-form-item label="语言" prop="main.langId">
            <el-select disabled v-model="editForm.main.langId">
              <el-option v-for="lang in langList"
                         :key="lang.langId"
                         :value="lang.langId"
                         :label="lang.langName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地区" prop="main.regionId">
            <el-select v-model="editForm.main.regionId">
              <el-option v-for="region in regionList"
                         :key="region.regionId"
                         :value="region.regionId"
                         :label="region.regionName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="内容" prop="main.content">
            <el-input v-model="editForm.main.content"
                      type="textarea"
                      rows="6"
                      style="width: 500px;"/>
          </el-form-item>
          <el-form-item label="标签" prop="main.tags">
            <el-select
              v-model="editForm.main.tags"
              multiple
              filterable
              allow-create
              placeholder="请选择或创建标签"
              style="width: 600px"
            >
              <el-option
                v-for="(tag,idx) in defaultTags"
                :key="idx"
                :label="tag"
                :value="tag">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableEditForm=false">取消</el-button>
          <el-button type="primary" @click="doEdit">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import LangCornerApi from '@/api/vojivosvc/toolsubject/LangCornerApi'
import ProtRule from '@/constant/ProtRule'
import LoginManager from '@/sys/LoginManager'

const defaultAddForm = {
  topicId: null,
  langId: null,
  regionId: null,
  topic: null,
  content: null,
  tags: null
}
const defaultEditForm = {
  id: null,
  main: {
    regionId: null,
    topic: null,
    content: null,
    tags: null
  },
  extra: null
}
export default {
  name: 'LangCorner',
  watch: {
    fromLangId: {
      handler: function (newVal, oldVal) {
        if (newVal !== this.toLangId) {
          this.doSrch()
        }
      }
    },
    toLangId: {
      handler: function (newVal, oldVal) {
        if (newVal !== this.fromLangId) {
          this.doSrch()
        }
      }
    }
  },
  filters: {
    truncate (value) {
      if (value.length > 30) {
        return value.substring(0, 30) + '...'
      } else {
        return value
      }
    }
  },
  data () {
    return {
      fromLangId: 'zh',
      toLangId: 'en',
      defaultToLangList: [
        {
          langId: 'zh',
          langName: '汉语'
        },
        {
          langId: 'en',
          langName: '英语'
        },
        {
          langId: 'fr',
          langName: '法语'
        },
        // {
        //   langId: 'en',
        //   langName: '西班牙语'
        // },
        {
          langId: 'rs',
          langName: '俄语'
        },
        // {
        //   langId: 'en',
        //   langName: '阿拉伯语'
        // },
        {
          langId: 'ge',
          langName: '德语'
        },
        {
          langId: 'jp',
          langName: '日语'
        }
        // {
        //   langId: 'en',
        //   langName: '葡萄牙语'
        // }
      ],
      langList: [
        {
          langId: 'zh',
          langName: '汉语'
        },
        {
          langId: 'en',
          langName: '英语'
        }
      ],
      regionList: [
        {
          regionId: 'cn',
          regionName: '中国'
        },
        {
          regionId: 'us',
          regionName: '美国'
        }
      ],
      defaultTags: ['日常对话', '朋友聊天', '趣味话题', '家长里短', '工作高频', '商务洽谈'],
      topicIdLangCornerMap: new Map(),
      isNewTopic: true,
      enableAddForm: false,
      addForm: JSON.parse(JSON.stringify(defaultAddForm)),
      addFormRules: {},
      editForm: JSON.parse(JSON.stringify(defaultEditForm)),
      enableEditForm: false,
      editFormRules: {},
      topicFindByPage: {
        srchKw: null,
        current: 1,
        size: 6,
        total: 0
      },
      topicIdFindByPageMap: new Map(),
      isLoading: true
    }
  },
  created () {
    this.doSrch()
    this.initFormRules()
  },
  methods: {
    initFormRules () {
      this.addFormRules = {
        langId: [
          ProtRule.cmnRule().required
        ],
        regionId: [],
        topic: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().length_32
        ],
        content: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().notWhite
        ],
        tags: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().mainTagsSize
        ]
      }
      this.editFormRules = {
        main: {
          langId: [
            ProtRule.cmnRule().required
          ],
          regionId: [],
          topic: [
            ProtRule.cmnRule().required,
            ProtRule.cmnRule().notWhite,
            ProtRule.cmnRule().length_32
          ],
          content: [
            ProtRule.cmnRule().required,
            ProtRule.cmnRule().notWhite
          ],
          tags: [
            ProtRule.cmnRule().required,
            ProtRule.cmnRule().mainTagsSize
          ]
        }
      }
    },
    doSrch () {
      const ip = {
        pageIp: {
          current: 1,
          size: 6
        },
        srchIp: {
          srchKey: null,
          srchKw: this.topicFindByPage.srchKw !== null && this.topicFindByPage.srchKw.trim() === '' ? null : this.topicFindByPage.srchKw
        },
        orderBys: [
          {
            key: 'update_msec',
            asc: false
          }
        ],
        langIds: [this.fromLangId],
        isMaster: 1
      }
      LangCornerApi.findByPage(ip).then(rd => {
        this.isLoading = false
        if (rd) {
          this.topicFindByPage.total = rd.total
          this.topicIdLangCornerMap.clear()
          rd.data.forEach(each => this.topicIdLangCornerMap.set(each.topicId, each))
          this.topicIdLangCornerMap = new Map(this.topicIdLangCornerMap)
          const topicIds = rd.data.map(each => each.topicId)
          this.findAndSetToLangMaster(topicIds)
          this.findAndSetToLangOthers(topicIds)
        }
      })
    },
    findAndSetToLangMaster (topicIds) {
      if (topicIds.length < 1) {
        return
      }
      const ip = {
        pageIp: {
          current: 1,
          size: topicIds.length
        },
        topicIds: topicIds,
        langIds: [this.toLangId],
        isMaster: 1
      }
      LangCornerApi.findByPage(ip).then(rd => {
        if (rd && rd.data) {
          rd.data.forEach(each => {
            this.topicIdLangCornerMap.get(each.topicId).toLangMaster = each
          })
        }
        this.topicIdLangCornerMap = new Map(this.topicIdLangCornerMap)
      })
    },
    findAndSetToLangOthers (topicIds) {
      topicIds.forEach(topicId => {
        let page = this.topicIdFindByPageMap.get(topicId)
        if (page === null || page === undefined) {
          page = {
            current: 1,
            size: 6,
            total: 0
          }
          this.topicIdFindByPageMap.set(topicId, page)
        }
        const ip = {
          pageIp: {
            ...page
          },
          topicIds: [topicId],
          langIds: [this.toLangId],
          isMaster: 0
        }
        LangCornerApi.findByPage(ip).then(rd => {
          if (rd) {
            this.topicIdFindByPageMap.get(topicId).total = rd.total
            rd.data.forEach(each => {
              this.topicIdLangCornerMap.get(each.topicId).toLangOthers = rd.data
            })
          }
          this.topicIdLangCornerMap = new Map(this.topicIdLangCornerMap)
        })
      })
    },
    othersNextPage (topicId) {
      const page = this.topicIdFindByPageMap.get(topicId)
      if (page.current * page.size >= page.total) {
        return
      }
      page.current += 1
      this.findAndSetToLangOthers([topicId])
    },
    othersPrevPage (topicId) {
      const page = this.topicIdFindByPageMap.get(topicId)
      if (page.current <= 1) {
        return
      }
      page.current -= 1
      this.findAndSetToLangOthers([topicId])
    },
    loadMore () {
      this.isLoading = true
      if (this.topicFindByPage.current >= this.topicFindByPage.total / this.topicFindByPage.size) {
        return
      }
      this.topicFindByPage.current += 1
      const ip = {
        pageIp: {
          current: this.topicFindByPage.current,
          size: this.topicFindByPage.size
        },
        srchIp: {
          srchKey: null,
          srchKw: this.topicFindByPage.srchKw !== null && this.topicFindByPage.srchKw.trim() === '' ? null : this.topicFindByPage.srchKw
        },
        orderBys: [
          {
            key: 'update_msec',
            asc: false
          }
        ],
        langIds: [this.fromLangId],
        isMaster: 1
      }
      LangCornerApi.findByPage(ip).then(rd => {
        this.isLoading = false
        if (rd) {
          this.topicFindByPage.total = rd.total
          rd.data.forEach(each => this.topicIdLangCornerMap.set(each.topicId, each))
          this.topicIdLangCornerMap = new Map(this.topicIdLangCornerMap)
          const topicIds = rd.data.map(each => each.topicId)
          this.findAndSetToLangMaster(topicIds)
          this.findAndSetToLangOthers(topicIds)
        }
      })
    },
    /** ********** Add Lang Corner ********** **/
    toAdd (topicId, langId) {
      this.enableAddForm = true
      this.addForm = JSON.parse(JSON.stringify(defaultAddForm))
      this.addForm.topicId = topicId
      this.addForm.langId = langId
    },
    doAdd () {
      this.$refs.addFormRef.validate(async isValid => {
        if (!isValid) {
          return
        }
        const ip = { ...this.addForm }
        LangCornerApi.add(ip).then(rd => {
          if (rd) {
            this.doSrch()
            this.enableAddForm = false
          }
        })
      })
    },

    /** ********** Edit Lang Corner ********** **/
    toEdit (langCorner) {
      if (langCorner.acctSys === 'ADM') {
        return
      }
      LoginManager.isCurUsr(langCorner.acctId).then(op => {
        if (op) {
          this.enableEditForm = true
          this.editForm.id = langCorner.id
          this.editForm.main = { ...langCorner }
          this.editForm.extra = { ...defaultEditForm.extra }
        }
      })
    },
    doEdit () {
      this.$refs.editFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          findField: {
            id: this.editForm.id
          },
          updateField: { ...this.editForm.main },
          extraField: null
        }
        LangCornerApi.update(ip).then(rd => {
          if (rd) {
            this.doSrch()
            this.enableEditForm = false
          }
        })
      })
    },

    /** ********** View Lang Corner ********** **/
    toView (langCorner) {
    },
    exchangeLang () {
      const temp = this.fromLangId
      this.fromLangId = this.toLangId
      this.toLangId = temp
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/LangCorner.scss";
</style>
