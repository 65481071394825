import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    curUsr: {
      acctId: null,
      acctUnid: null,
      showName: null,
      showGender: null,
      avatarUrl: null,
      perId: null,
      perName: null
    }
  },
  mutations: {
    SET_CUR_USR (state, curUsr) {
      state.curUsr = curUsr
    }
  },
  actions: {
    setCurUsr ({ commit }, curUsr) {
      commit('SET_CUR_USR', curUsr)
    }
  },
  getters: {
    getCurUsr: state => state.curUsr
  }
})
export default store
