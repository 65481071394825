<template>
  <div class="activation-container">
    <i18n-btn class="i18n-btn"></i18n-btn>
    <el-card class="activation-card">
      <!--      <div class="header-btn">-->
      <!--        <i18n-btn></i18n-btn>-->
      <!--      </div>-->
      <div slot="header" class="clearfix">
        <h3 class="title-h3">{{ $t('activate.activateAcct') }}</h3>
      </div>
      <div v-if="activationStatus === 'PENDING'" class="activation-status">
        <el-skeleton animated :rows="1" :columns="3"/>
        {{ $t('activate.activating') }}
      </div>
      <div v-if="activationStatus === 'SUCCESS'" class="activation-success">
        <el-result icon="success" :title="$t('activate.activateSuccess')"
                   :sub-title="$t('activate.activateSuccessTips')">
          <template #extra>
            <el-button type="primary" @click="toLogin">{{ $t('activate.toLogin') }}</el-button>
          </template>
        </el-result>
      </div>
      <div v-if="activationStatus === 'FAILED'" class="activation-failed">
        <el-result icon="warning" :title="$t('activate.activateFailed')"
                   :sub-title="$t('activate.activateFailedTips')">
          <template #extra>
            <el-button type="primary" @click="retryActivate">{{ $t('activate.retry') }}</el-button>
          </template>
        </el-result>
      </div>
    </el-card>
  </div>
</template>

<script>
import UsrAcctApi from '@/api/usercenter/UsrAcctApi'
import I18nBtn from '@/components/I18nBtn.vue'

export default {
  name: 'Activate',
  components: { I18nBtn },
  data () {
    return {
      usrAcctId: null,
      activationCode: null,
      activationStatus: 'PENDING'
    }
  },
  created () {
    this.activate()
  },
  methods: {
    initActivationCode () {
      this.usrAcctId = this.$route.query.usrAcctId
      this.activationCode = this.$route.query.activationCode
    },
    doActivate () {
      UsrAcctApi.activate(this.usrAcctId, this.activationCode).then(op => {
        if (op) {
          this.activationStatus = 'SUCCESS'
          setTimeout(() => {
            this.$router.push('/login')
          }, 3000)
        } else {
          this.activationStatus = 'FAILED'
        }
      }).catch(() => {
        this.activationStatus = 'FAILED'
      })
    },
    activate () {
      setTimeout(() => {
        this.initActivationCode()
        this.doActivate()
      }, 1000)
    },
    retryActivate () {
      this.activationStatus = 'PENDING'
      this.activate()
    },
    toLogin () {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.activation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.activation-card {
  width: 400px;
}

.activation-status {
  text-align: center;
  margin-top: 20px;
}

.title-h3 {
  text-align: center;
}

/*.header-btn {*/
/*  position: relative;*/
/*  left: 330px;*/
/*  top: -110px;*/
/*}*/

.i18n-btn {
  position: absolute;
  right: 10%;
  top: 10%;
}
</style>
