import resultHttpTool from '@/http/ResultHttpTool'

const API_PREFIX = '/api/usr/vjbWallet'

export function findOrInit () {
  return resultHttpTool.get(API_PREFIX + '/findOrInit')
    .catch(() => {
      return {
        walletVjb: 0
      }
    })
}

export default {
  findOrInit
}
