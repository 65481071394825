<template>
  <el-container>
    <el-aside width="150px">
      <div>
        <h2>{{ $t('cmn.tags') }}</h2>
        <div>
          <el-tag v-for="tag in resPkgTags" :key="tag.id">
            {{ tag.name }}
          </el-tag>
        </div>
        <h2>{{ $t('cmn.allTags') }}</h2>
        <el-tree
          ref="tagTreeRef"
          node-key="id"
          :data="tagTrees"
          :default-checked-keys="checkedResPkgTagIds"
          :load="loadTagTrees"
          :props="{label: 'name', children: 'children', hasChildren: 'name', disabled:(data,node)=>true}"
          :expand-on-click-node="false"
          show-checkbox
          check-strictly
          lazy
        />
      </div>

      <div v-if="isLoggedIn">
        <h2>{{ $t('cmn.groups') }}</h2>
        <div>
          <el-tag v-for="group in resPkgGroups" :key="group.id">
            {{ group.name }}
          </el-tag>
        </div>
        <h2>{{ $t('cmn.allGroups') }}</h2>
        <el-tree
          ref="groupTreeRef"
          node-key="id"
          :data="groupTrees"
          :default-checked-keys="checkedResPkgGroupIds"
          :load="loadGroupTrees"
          :props="{label: 'name', children: 'children', hasChildren: 'name', disabled:(data,node)=>true}"
          :expand-on-click-node="false"
          show-checkbox
          check-strictly
          lazy
        />
      </div>
    </el-aside>
    <el-main>
      <div class="overview-div">
        <el-card>
          <el-row :gutter="60">
            <el-col :span="12">
              <el-row :gutter="30">
                <el-col :span="12">
                  <img :src="resPkg.firstImgUrl" class="left-img" alt="FirstImg">
                </el-col>
                <el-col :span="12">
                  <div>
                    <img :src="resPkg.secondImgUrl" class="right-img">
                  </div>
                  <div>
                    <img :src="resPkg.thirdImgUrl" class="right-img">
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <div class="name-area">
                <h2>{{ resPkg.name }}</h2>
              </div>
              <div class="profile-area">
                {{ resPkg.profile }}
              </div>
              <div class="tag-area">
                <div class="base-tag">
                  <el-tag
                    v-for="growthStage in resPkg.growthStages"
                    :key="growthStage"
                    effect="dark"
                    size="mini"
                  >
                    {{ $t('studyLab.' + growthStage) }}
                  </el-tag>
                  <el-badge
                    v-for="carrierInfo in resPkg.carrierInfos"
                    :key="carrierInfo.carrierType"
                    :value="carrierInfo.count"
                    class="item"
                  >
                    <el-tag
                      type="info"
                      effect="dark"
                      size="mini"
                    >
                      {{ $t('studyLab.' + carrierInfo.carrierType) }}
                    </el-tag>
                  </el-badge>
                </div>
                <div class="categ-tag">
                  <el-tag
                    v-for="mainCategTag in resPkg.mainCategTags"
                    :key="mainCategTag.id"
                    type="warning"
                    effect="dark"
                    size="mini"
                  >
                    {{ mainCategTag.name }}
                  </el-tag>
                  <el-tag
                    v-for="mainHltTag in resPkg.mainHltTags"
                    :key="mainHltTag.id"
                    type="danger"
                    effect="dark"
                    size="mini"
                  >
                    {{ mainHltTag.name }}
                  </el-tag>
                </div>
              </div>
              <div class="price-opn-area">
                <el-row :gutter="20">
                  <el-col :span="10">
                    <div v-if="resPkg.bePur">
                      <el-tag type="info" size="mini">{{ $t('trade.bePur') }}</el-tag>
                    </div>
                    <div v-else class="price-area">
                      <div v-if="resPkg.vojistarCardPricing">
                        <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                        <span class="final-price-span">{{ resPkg.finalPrice }}</span>
                        <div>
                          <el-tag
                            type="info" size="mini">
                            {{ $t('trade.' + resPkg.vojistarCardPricing.unid) }}
                          </el-tag>
                        </div>
                        <div>
                          <span class="final-cur-price-span">{{ resPkg.curPrice }}</span>
                          <span class="orig-price-span">{{ resPkg.origPrice }}</span>
                        </div>
                      </div>
                      <div v-else>
                        <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                        <span class="cur-price-span">{{ resPkg.curPrice }}</span>
                        <span class="orig-price-span">{{ resPkg.origPrice }}</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="14">
                    <div class="opn-area">
                      <el-button v-if="isLoggedIn" icon="el-icon-edit" circle size="small"
                                 @click="toEditUsrzFavAndPurResPkg(resPkg)"/>
                      <el-button v-if="!resPkg.bePur" icon="el-icon-download" circle size="small"
                                 @click="downloadOrPur(resPkg)"/>
                      <el-popover
                        v-if="resPkg.beFav"
                        :ref="'CancelFavPop.' + resPkg.id"
                        placement="top"
                        width="200"
                      >
                        <p>{{ $t('cmn.cancelFavConfirm') }}</p>
                        <div style="text-align: right;">
                          <el-button size="mini" type="text"
                                     @click="$refs['CancelFavPop.' + resPkg.id].doClose()">
                            {{ $t('cmn.cancel') }}
                          </el-button>
                          <el-button type="primary" size="mini" @click="doCancelFav(resPkg)">
                            {{ $t('cmn.confirm') }}
                          </el-button>
                        </div>
                        <el-button v-if="resPkg.beFav" slot="reference" icon="el-icon-star-on" circle
                                   size="small"/>
                      </el-popover>
                      <el-button v-if="!resPkg.beFav" icon="el-icon-star-off" circle size="small"
                                 @click="toFav(resPkg)"/>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
      <el-divider></el-divider>
      <div class="content-div">
        <div v-for="res in resPkgResList" :key="res.id">
          <el-card class="res-div">
            <el-row :gutter="60">
              <el-col :span="6">
                <div v-if="res.carrierType==='BOOK'">
                  <img :src="res.firstImgUrl" class="book-img">
                </div>
                <div v-else>
                  <div>
                    <img :src="res.firstImgUrl" class="doc-code-img">
                  </div>
                  <div>
                    <img :src="res.secondImgUrl" class="doc-code-img">
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="name-area">
                  <h2>{{ res.name }}</h2>
                </div>
                <div class="tag-area">
                  <div class="base-tag">
                    <el-tag
                      v-for="growthStage in res.growthStages"
                      :key="growthStage"
                      effect="dark"
                      size="mini"
                    >
                      {{ $t('studyLab.' + growthStage) }}
                    </el-tag>
                    <el-tag
                      type="info"
                      effect="dark"
                      size="mini"
                    >
                      {{ res.carrierType }}
                    </el-tag>
                  </div>
                  <div class="categ-tag">
                    <el-tag
                      v-for="mainCategTag in res.mainCategTags"
                      :key="mainCategTag.id"
                      type="warning"
                      effect="dark"
                      size="mini"
                    >
                      {{ mainCategTag.name }}
                    </el-tag>
                    <el-tag
                      v-for="mainHltTag in res.mainHltTags"
                      :key="mainHltTag.id"
                      type="danger"
                      effect="dark"
                      size="mini"
                    >
                      {{ mainHltTag.name }}
                    </el-tag>
                  </div>
                </div>
                <div class="opn-area">
                  <el-button type="text" @click="downloadResOrPur(resPkg, res)">{{ $t('cmn.download') }}
                  </el-button>
                  <el-button type="text" @click="toShowResCtlg(res)">{{ $t('studyLab.viewCtlg') }}</el-button>
                </div>
                <div class="profile-area">
                  {{ res.profile }}
                </div>
              </el-col>
              <el-col :span="6">
                <div class="detail-download-btn-div">
                  <el-button icon="el-icon-download" type="danger" @click="downloadResOrPur(resPkg, res)">{{$t('cmn.download')}}</el-button>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </div>
    </el-main>
    <div>
      <!-- Ctlg Dialog-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableResCtlgDialog"
        :title="$t('studyLab.ctlg')"
        append-to-body
        width="800px"
      >
        <div v-if="curResCtlg.ctlg" v-html="curResCtlg.ctlg.replaceAll('\n', '<br/>')">
        </div>
        <div v-if="curResCtlg.ctlgImgUriUrls && curResCtlg.ctlgImgUriUrls.length > 0" class="res-ctlg-img-container">
          <img v-for="imgUriUrl in this.curResCtlg.ctlgImgUriUrls"
               :src="imgUriUrl.url"
               :key="curResCtlg.id + imgUriUrl.uri"
               class="res-ctlg-img"
          >
        </div>
      </el-dialog>

      <!-- Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableFavForm"
        :title="$t('cmn.fav')"
        append-to-body
        width="600px"
      >
        <el-form
          ref="favFormRef"
          :inline="true"
          :model="favForm"
          :rules="favFormRules"
          size="small"
          label-width="120px"
        >
          <el-form-item :label="$t('studyLab.resPkgName')" prop="resPkgName">
            <el-input v-model="favForm.resPkgName" disabled style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cmn.customName')" prop="customName">
            <el-input v-model="favForm.customName" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.customProfile')" prop="customProfile">
            <el-input v-model="favForm.customProfile" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.group')" prop="resPkgGroupIds">
            <el-tree
              v-model="favForm.resPkgGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              show-checkbox
              lazy
              @check="handleFavGroupTreeNodeCheck"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Edit Dialog-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableEditDialog"
        :title="$t('cmn.edit')"
        append-to-body
        width="600px"
      >
        <el-form
          ref="editFormRef"
          :inline="true"
          :model="editForm"
          :rules="editFormRules"
          size="small"
          label-width="120px"
        >
          <el-form-item :label="$t('studyLab.resPkgName')" prop="resPkgName">
            <el-input v-model="editForm.resPkgName" disabled style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cmn.customName')" prop="customName">
            <el-input v-model="editForm.customName" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.customProfile')" prop="customProfile">
            <el-input v-model="editForm.customProfile" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.group')" prop="resPkgGroupIds">
            <el-tree
              v-model="editForm.resPkgGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              show-checkbox
              check-strictly
              lazy
              @check="handleEditGroupTreeNodeCheck"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableEditDialog=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doEditUsrzFavAndPurResPkg">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Pur Order Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchPurForm"
        :title="$t('trade.batchPur')"
        append-to-body
        width="600px"
      >
        <el-form
          ref="batchPurFormRef"
          :model="batchPurForm"
          :rules="batchPurFormRules"
          size="small"
          label-width="50px"
        >
          <el-form-item prop="resPkgs">
            <el-card v-for="resPkg in batchPurForm.resPkgs" :key="resPkg.id" style="width: 400px">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="pur-res-pkg-img-area">
                    <el-row :gutter="5">
                      <el-col :span="12">
                        <img :src="resPkg.firstImgUrl" class="left-img">
                      </el-col>
                      <el-col :span="12">
                        <div>
                          <img :src="resPkg.secondImgUrl" class="right-img">
                        </div>
                        <div>
                          <img :src="resPkg.thirdImgUrl" class="right-img">
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <h2>{{ resPkg.name }}</h2>
                </el-col>
                <el-col :span="16">
                  <div class="pur-res-pkg-price-area">
                    <div v-if="resPkg.vojistarCardPricing">
                      <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                      <span class="final-price-span">{{ resPkg.finalPrice }}</span>
                      <el-tag
                        type="info" size="mini">
                        {{ $t('trade.' + resPkg.vojistarCardPricing.unid) }}
                      </el-tag>
                      <div>
                        <span class="final-cur-price-span">{{ resPkg.curPrice }}</span>
                        <span class="orig-price-span">{{ resPkg.origPrice }}</span>
                      </div>
                    </div>
                    <div v-else>
                      <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                      <span class="cur-price-span">{{ resPkg.curPrice }}</span>
                      <span class="orig-price-span">{{ resPkg.origPrice }}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-form-item>
          <!--          <el-form-item :label="$t('cmn.group')" prop="resPkgGroupIds">-->
          <!--            <el-tree-->
          <!--              v-model="batchPurForm.resPkgGroupIds"-->
          <!--              :data="groupTrees"-->
          <!--              node-key="id"-->
          <!--              :load="loadGroupTrees"-->
          <!--              :props="{label: 'name', children: 'children', hasChildren: 'name'}"-->
          <!--              :expand-on-click-node="false"-->
          <!--              :check-strictly="true"-->
          <!--              show-checkbox-->
          <!--              lazy-->
          <!--              @check="handleGroupTreeNodeCheck"-->
          <!--            />-->
          <!--          </el-form-item>-->
          <!--          <el-form-item :label="$t('trade.orderPrice')" prop="price">{{-->
          <!--              batchPurForm.price-->
          <!--            }}-->
          <!--          </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <div v-if="batchPurForm.price > this.walletVjb">
            <span>{{ $t('trade.walletVjb') }}</span>{{ this.walletVjb }}
          </div>
          <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
          <span class="vjb-amt-span">{{ batchPurForm.price }}</span>
          <el-button v-if="batchPurForm.price > this.walletVjb" type="primary" @click="toTopUp">
            {{ $t('trade.toTopUp') }}
          </el-button>
          <el-button v-else type="danger" @click="doBatchPurOrder">{{ $t('trade.orderPay') }}</el-button>
        </div>
      </el-dialog>
    </div>
  </el-container>

</template>

<script>
import ResPkgApi from '@/api/vojivosvc/studylab/ResPkgApi'
import ResCtlgApi from '@/api/vojivosvc/studylab/ResCtlgApi'
import ResTagApi from '@/api/vojivosvc/studylab/ResTagApi'
import UsrzFavResPkgApi from '@/api/vojivosvc/studylab/UsrzFavResPkgApi'
import UsrzPurResPkgApi from '@/api/vojivosvc/studylab/UsrzPurResPkgApi'
import ResApi from '@/api/vojivosvc/studylab/ResApi'
import LoginManager from '@/sys/LoginManager'
import UsrzResPkgGroupApi from '@/api/vojivosvc/studylab/UsrzResPkgGroupApi'
import ProtRule from '@/constant/ProtRule'
import FileServiceApi from '@/api/fileservice/FileServiceApi'
import VjbWalletApi from '@/api/tradecenter/VjbWalletApi'

export default {
  name: 'ResPkgDetail',
  // computed: {
  //   studyLabResPkg () {
  //     return studyLabResPkg
  //   }
  // },
  data () {
    return {
      isLoggedIn: false,
      resPkgId: null,
      resPkg: {
        id: null,
        name: null,
        nameI18nRes: null,
        beFav: null,
        bePur: null,
        carrierInfos: [],
        createMsec: null,
        creatorAcctId: null,
        creatorAcctSys: null,
        creatorAcctUnid: null,
        curPrice: null,
        firstImgUri: null,
        firstImgUrl: null,
        secondImgUri: null,
        secondImgUrl: null,
        thirdImgUri: null,
        thirdImgUrl: null,
        growthStages: [],
        mainCategTags: [],
        mainHltTags: [],
        origPrice: null,
        profile: null,
        profileI18nRes: null,
        rankValue: null,
        remark: null,
        res: [],
        tags: [],
        updateMsec: null,
        updaterAcctId: null,
        updaterAcctSys: null,
        updaterAcctUnid: null,
        vjvPlc: null
      },
      resPkgResList: [],
      resPkgTags: [],
      resPkgGroups: [],
      resIdCtlgMap: new Map(),
      curResCtlg: {
        ctlg: null,
        ctlgImgUris: [],
        ctlgImgUriUrls: []
      },
      enableResCtlgDialog: false,
      tagTrees: [],
      checkedResPkgTagIds: [],
      groupTrees: [],
      checkedResPkgGroupIds: [],
      enableFavForm: false,
      favForm: {
        resPkgName: null,
        resPkgId: null,
        resPkgGroupIds: [],
        customName: null,
        customProfile: null
      },
      favFormRules: {},
      enableEditDialog: false,
      editForm: {
        resPkgId: null,
        resPkgName: null,
        customName: null,
        customProfile: null,
        resPkgGroupIds: []
      },
      editFormRules: {},
      enableBatchPurForm: false,
      batchPurForm: {
        resPkgs: [],
        price: 0,
        resPkgGroupIds: []
      },
      batchPurFormRules: {},
      walletVjb: 0
    }
  },
  created () {
    this.resPkgId = this.$route.query.resPkgId
    this.initFormRules()
    this.init()
  },
  methods: {
    initFormRules () {
      this.favFormRules = {
        resPkgName: [
          ProtRule.cmnRule().required
        ],
        resPkgGroupIds: [
          ProtRule.cmnRule().cmnBatchSize
        ],
        customName: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().length_64
        ],
        customProfile: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().cmnProfileLength
        ]
      }
      this.editFormRules = {
        customName: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().length_64
        ],
        customProfile: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().cmnProfileLength
        ],
        resPkgGroupIds: [
          ProtRule.cmnRule().cmnBatchSize
        ]
      }
      this.batchPurFormRules = {
        resPkgs: [
          ProtRule.cmnRule().mBatchSize
        ],
        resPkgGroupIds: [
          ProtRule.cmnRule().mBatchSize
        ]
      }
    },
    init () {
      LoginManager.isLoggedIn().then(rd => {
        if (rd) {
          this.isLoggedIn = true
          this.findResPkgGroups(this.resPkgId)
        } else {
          this.isLoggedIn = false
        }
        this.findResPkg(this.resPkgId)
        this.findResPkgTags(this.resPkgId)
      })
    },
    findResPkg (resPkgId) {
      ResPkgApi.findById(resPkgId)
        .then(rd => {
          this.resPkg = rd
          this.findResPkgResList(rd.resIds)
        })
    },
    findResPkgResList (resIds) {
      const ip = {
        ids: resIds
      }
      ResApi.findList(ip).then(rd => {
        if (rd) {
          this.resPkgResList = rd
        }
      })
    },
    findResPkgTags (resPkgId) {
      ResPkgApi.findTags(resPkgId).then(rd => {
        if (rd) {
          this.resPkgTags = rd
          this.checkedResPkgTagIds = this.resPkgTags.map(tag => tag.id)
        }
      })
    },
    findResPkgGroups (resPkgId) {
      if (this.isLoggedIn) {
        UsrzResPkgGroupApi.findResPkgBelongingGroups(resPkgId)
          .then(rd => {
            this.resPkgGroups = rd
            this.checkedResPkgGroupIds = this.resPkgGroups.map(group => group.id)
            this.$refs.groupTreeRef.setCheckedKeys(this.checkedResPkgGroupIds)
          })
      }
    },
    toShowResCtlg (res) {
      const resCtlg = this.resIdCtlgMap.get(res.id)
      if (resCtlg) {
        this.curResCtlg = resCtlg
        this.enableResCtlgDialog = true
      } else {
        ResCtlgApi.findDetail(res.id)
          .then(resCtlgOp => {
            this.curResCtlg = resCtlgOp
            this.resIdCtlgMap.set(res.id, resCtlgOp)
            this.enableResCtlgDialog = true
          })
          .catch(() => {
            this.enableResCtlgDialog = false
            this.$message.error('NO CATALOG')
          })
      }
    },
    loadTagTrees (node, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        ResTagApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.tagTrees = resultData
            }
          })
      }, 100)
    },
    toFav (resPkg) {
      this.enableFavForm = true
      this.favForm.resPkgId = resPkg.id
      this.favForm.resPkgName = resPkg.name
      this.favForm.resPkgGroupIds = []
    },
    doFav () {
      this.$refs.favFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          ...this.favForm
        }
        UsrzFavResPkgApi.fav(ip).then(rd => {
          if (rd) {
            this.enableFavForm = false
            this.resPkg.beFav = true
          }
        })
      })
    },
    doCancelFav (resPkg) {
      const ip = {
        resPkgIds: []
      }
      ip.resPkgIds.push(resPkg.id)
      UsrzFavResPkgApi.batchCancelFav(ip)
        .then(resultData => {
          if (resultData) {
            resPkg.beFav = false
            this.$refs['CancelFavPop.' + resPkg.id].doClose()
          }
        })
    },
    /** ****************** Edit *********************/
    toEditUsrzFavAndPurResPkg (resPkg) {
      this.editForm.resPkgName = resPkg.name
      this.editForm.resPkgId = resPkg.id
      this.editForm.customName = resPkg.customName
      this.editForm.customProfile = resPkg.customProfile
      this.enableEditDialog = true
    },
    handleEditGroupTreeNodeCheck (data, checkedData) {
      this.editForm.resPkgGroupIds = []
      this.editForm.resPkgGroupIds.push(...checkedData.checkedKeys)
    },
    doEditUsrzFavAndPurResPkg () {
      // const updateIp = {
      //   findField: {
      //     resPkgId: this.editForm.resPkgId
      //   },
      //   updateField: {
      //     customName: this.editForm.customName,
      //     customProfile: this.editForm.customProfile,
      //     resPkgGroupIds: this.editForm.resPkgGroupIds
      //   }
      // }
      // UsrzPurResPkgApi.updateBasicInfo(updateIp)
      const favIp = {
        resPkgId: this.editForm.resPkgId,
        customName: this.editForm.customName,
        customProfile: this.editForm.customProfile,
        resPkgGroupIds: this.editForm.resPkgGroupIds
      }
      UsrzFavResPkgApi.fav(favIp).then(op => {
        if (op) {
          this.enableEditDialog = false
          setTimeout(() => this.findResPkgGroups(favIp.resPkgId), 500)
        }
      })
    },
    /** ****************** Download or Pur *********************/

    downloadOrPur (studyLabResPkg) {
      if (!studyLabResPkg.bePur) {
        this.toPurOrder(studyLabResPkg)
      } else {
        this.downloadResPkg(studyLabResPkg)
      }
    },
    downloadResPkg (resPkg) {
      for (const res of resPkg.res) {
        this.downloadRes(res)
      }
    },

    downloadResOrPur (studyLabResPkg, res) {
      if (!studyLabResPkg.bePur) {
        this.toPurOrder(studyLabResPkg)
      } else {
        this.downloadRes(studyLabResPkg, res)
      }
    },
    downloadRes (resPkg, res) {
      const ip = {
        resPkgId: resPkg.id,
        resId: res.id
      }
      UsrzPurResPkgApi.acquireResDownloadUrl(ip)
        .then(rd => {
          if (rd) {
            FileServiceApi.download(rd.resDownloadUrl, res.name + res.fileExt)
          }
        })
    },
    toPurOrder (resPkg) {
      this.enableBatchPurForm = true
      this.batchPurForm.resPkgs = []
      this.batchPurForm.price = 0
      this.batchPurForm.resPkgs.push({
        id: resPkg.id,
        name: resPkg.name,
        firstImgUrl: resPkg.firstImgUrl,
        secondImgUrl: resPkg.secondImgUrl,
        thirdImgUrl: resPkg.thirdImgUrl,
        origPrice: resPkg.origPrice,
        curPrice: resPkg.curPrice,
        finalPrice: resPkg.finalPrice,
        vojistarCardPricing: resPkg.vojistarCardPricing
      })
      this.batchPurForm.price += resPkg.finalPrice
      VjbWalletApi.findOrInit().then(op => {
        if (op) {
          this.walletVjb = op.walletVjb
        }
      })
    },

    doBatchPurOrder () {
      const ip = {
        ...this.batchPurForm
      }
      UsrzPurResPkgApi.batchPurOrder(ip)
        .then(purOrderOp => {
          this.purPay(purOrderOp.resPkgOrderOp)
        })
    },

    purPay (resPkgOrder) {
      const ip = {
        orderId: resPkgOrder.id,
        orderPrice: resPkgOrder.price,
        txnId: resPkgOrder.txnId
      }
      UsrzPurResPkgApi.purPay(ip)
        .then(op => {
          if (op) {
            this.enableBatchPurForm = false
            this.resPkg.bePur = true
          }
        })
    },

    toTopUp () {
      this.$router.push('/perCenter/vjbJar')
    },

    loadGroupTrees (node, resolve) {
      if (this.isLoggedIn) {
        const findTreesIp = {
          srchIp: null,
          timeIp: null,
          orderBys: [
            {
              key: 'rankValue',
              asc: true
            }
          ],
          parentIds: [],
          treeHeight: 1
        }
        if (node && node.data && node.data.id) {
          findTreesIp.parentIds = [node.data.id]
        }
        setTimeout(() => {
          UsrzResPkgGroupApi.findTrees(findTreesIp)
            .then(resultData => {
              if (resolve) {
                resolve(resultData)
              } else {
                this.groupTrees = resultData
              }
            })
        }, 100)
      }
    },
    handleFavGroupTreeNodeCheck (data, checkedData) {
      this.favForm.resPkgGroupIds = []
      this.favForm.resPkgGroupIds.push(...checkedData.checkedKeys)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/ResPkgDetail.scss";

</style>
