import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/vjbReward'

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export function receive (ip) {
  return resultHttpTool.post(API_PREFIX + '/receive', ip)
    .then(rd => {
      if (rd) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
    })
}

export default {
  receive,
  findByPage
}
