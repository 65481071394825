import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import uuid from '../util/UuidUtil'
import i18n from '../i18n/i18n'
import { MSG_LEVEL_TYPE } from '@/constant/CmnConst'
import { CODE_MSG_EN, CODE_MSG_ZH } from '@/constant/CmnBizMsg'
import Router from '@/router'
import TimeUtil from '@/util/TimeUtil'
import SysConfig from '@/SysConfig'
import LoginManager from '@/sys/LoginManager'

let LOG_IN_MSG_TRIGGERED = false

// create an axios instance
const resultHttpTool = axios.create({
  baseURL: '/vojivoUsrApi',
  timeout: SysConfig.reqTimeoutMsec
})
resultHttpTool.defaults.baseURL = '/vojivoUsrApi'

resultHttpTool.interceptors.request.use(config => {
  config.headers.token = LoginManager.token()
  const cmnParam = {
    traceId: uuid(),
    fromAppId: 'vojivo-usr-web',
    langId: i18n.locale,
    regionId: 'CHINA',
    timeZoneId: TimeUtil.guessCurrentTimezoneId()
  }
  config.headers['Cmn-Param'] = JSON.stringify(cmnParam)
    .replace('{', 'CmnParam(')
    .replace('}', ')CmnParam')
  config.url = encodeURI(config.url)
  return config
}, error => {
  console.log('[ReqFailed]', error)
  return Promise.reject(error)
})

function handleUnAuth () {
  if (!LOG_IN_MSG_TRIGGERED) {
    LOG_IN_MSG_TRIGGERED = true
    MessageBox.confirm(i18n.t('sys.unAuth'), '', {
      confirmButtonText: i18n.t('cmn.confirm'),
      cancelButtonText: i18n.t('cmn.cancel'),
      showCancelButton: true,
      type: 'warning'
    }).then(() => {
      LOG_IN_MSG_TRIGGERED = false
      // 用户点击确认按钮后跳转到登录页面
      Router.push('/login')
    }).catch(() => {
      // 用户点击取消按钮后
      LOG_IN_MSG_TRIGGERED = false
    })
  }
}

// response interceptor
resultHttpTool.interceptors.response.use(
  response => {
    const result = response.data
    console.log('[RespSuccess]', result)
    if (result.ret < 0) {
      if (result.error.code === 401) {
        handleUnAuth()
      } else {
        Message({
          message: handleError(result.error),
          type: MSG_LEVEL_TYPE.ERROR,
          duration: SysConfig.msgDurationMsec
        })
      }
      return Promise.reject(result.error)
    } else {
      return result.data
    }
  },
  error => {
    console.log('[RespFailed]', error)
    console.log(`[RespFailed][error.message: ${error.message}] [error.code: ${error.code}] [error.response]`, error.response)
    if (error.message && error.message.includes('timeout')) {
      Message({
        message: i18n.t('sys.timeoutRetry'),
        type: MSG_LEVEL_TYPE.ERROR,
        duration: SysConfig.msgDurationMsec
      })
    } else if (!error.response) {
      Message({
        message: error.message,
        type: MSG_LEVEL_TYPE.ERROR,
        duration: SysConfig.msgDurationMsec
      })
    } else {
      if (error.response.status === 401) {
        handleUnAuth()
      } else {
        Message({
          message: code2Msg(error.response.status),
          type: MSG_LEVEL_TYPE.ERROR,
          duration: SysConfig.msgDurationMsec
        })
      }
    }
    return Promise.reject(error)
  }
)

function handleError (error) {
  let codeMsg = null
  if (i18n.locale === 'zh') {
    codeMsg = CODE_MSG_ZH.get(error.code)
  } else {
    codeMsg = CODE_MSG_EN.get(error.code)
  }
  let detailMsg = ''
  for (const detail of error.details) {
    const msg = 'Field: ' + detail.field + '\n' +
      'Message: ' + detail.msg
    detailMsg = detailMsg.concat(msg).concat('\n')
    return codeMsg + '\n' + detailMsg
  }
}

function code2Msg (code) {
  if (i18n.locale === 'zh') {
    return CODE_MSG_ZH.get(code)
  } else {
    return CODE_MSG_EN.get(code)
  }
}

export default resultHttpTool
