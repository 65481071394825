export const DEFAULT_TIME_PO = {
  timeKey: null,
  beginMsec: null,
  endMsec: null,
  includeLower: true,
  includeUpper: false
}

export const DEFAULT_SRCH_PO = {
  srchKey: null,
  srchKw: null
}

export const DEFAULT_PAGE_PO = {
  size: 10,
  current: 1
}

export const DEFAULT_ORDER_BY = {
  key: 'updateMsec',
  asc: false
}

export const DEFAULT_ORDER_BY_RANK_VALUE = {
  key: 'rankValue',
  asc: true
}

export const DEFAULT_ORDER_BYS = [
  DEFAULT_ORDER_BY
]

export const DEFAULT_ORDER_BYS_RANK_VALUE = [
  DEFAULT_ORDER_BY_RANK_VALUE
]

export const DEFAULT_FIND_BY_PAGE_PO = {
  srchIp: DEFAULT_SRCH_PO,
  timeIp: DEFAULT_TIME_PO,
  pageIp: DEFAULT_PAGE_PO,
  orderBys: DEFAULT_ORDER_BYS
}
export const DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE = {
  srchIp: DEFAULT_SRCH_PO,
  timeIp: DEFAULT_TIME_PO,
  pageIp: DEFAULT_PAGE_PO,
  orderBys: DEFAULT_ORDER_BYS_RANK_VALUE
}
export const TABLE_COLUMN_MIN_WIDTH_OBJ = {
  longId: 100,
  unid: 120
}

export const TABLE_COLUMN_WIDTH_OBJ = {
  strId: 147,
  dateTime: 155
}

export const PAGE_MIN_NUM = 1
export const PAGE_MAX_NUM = 10000

export const PAGE_MIN_SIZE = 1
export const PAGE_MAX_SIZE = 100

export const SRCH_KW_MIN_LENGTH = 1
export const SRCH_KW_MAX_LENGTH = 1024

export const ORDER_BYS_MAX_SIZE = 3

export const REMARK_MIN_LENGTH = 1
export const REMARK_MAX_LENGTH = 1024

export const RANK_VALUE_MIN_LENGTH = 1
export const RANK_VALUE_MAX_LENGTH = 64

export const STR_ID_LENGTH = 32

export const STR_ID_MIN_LENGTH = 8

export const STR_ID_MAX_LENGTH = 64

export const BATCH_MAX_SIZE = 100

export const LOCAL_FILE_PREFIX = 'vojivo-local@'
export const FILE_INFRA_FILE_PREFIX = 'vojivo-file-infra@'
