<template>
  <div>
    <el-form ref="usrAcctFormRef" :model="usrAcctForm"
             label-width="80px"
             label-position='left'>
      <!-- unid-->
      <el-form-item :label="$t('userCenter.usrAcct.unid')" prop="unid">
        <div class="form-item-box">
          <el-input :disabled=true v-model="usrAcctForm.unid"></el-input>
          <el-button @click="toChangeUnid">{{ $t('userCenter.usrAcct.changeUnid') }}</el-button>
          <el-dialog :title="$t('userCenter.usrAcct.changeUnid')" :visible.sync="unidDialogVisible" append-to-body>
            <el-form :model="unidForm" :rules="unidFormRules" ref="unidFormRef">
              <el-form-item :label="$t('userCenter.usrAcct.oldUnid')" label-width="100px" prop="oldUnid">
                <el-input v-model="unidForm.oldUnid" disabled autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item :label="$t('userCenter.usrAcct.newUnid')" label-width="100px" prop="newUnid">
                <el-input v-model="unidForm.newUnid" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item :label="$t('userCenter.usrAcct.pwd')" label-width="100px" prop="pwd">
                <el-input v-model="unidForm.pwd" type="password" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="unidDialogVisible=false">{{ $t('cmn.cancel') }}</el-button>
              <el-button type="primary" @click="confirmChangeUnid">{{ $t('cmn.confirm') }}
              </el-button>
            </div>
          </el-dialog>
        </div>
      </el-form-item>

      <!-- pwd -->
      <el-form-item :label="$t('userCenter.usrAcct.pwd')" prop="pwd">
        <div class="form-item-box">
          <el-input :disabled=true v-model="fakedPwd" type="password"></el-input>
          <el-button @click="toChangePwd">{{ $t('userCenter.usrAcct.changePwd') }}</el-button>
          <el-dialog :title="$t('userCenter.usrAcct.changePwd')" :visible.sync="pwdDialogVisible" append-to-body>
            <el-form :model="pwdForm" :rules="pwdFormRules">
              <el-form-item :label="$t('userCenter.usrAcct.unid')" label-width="100px" prop="unid">
                <el-input v-model="pwdForm.unid" disabled autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item :label="$t('userCenter.usrAcct.oldPwd')" label-width="100px" prop="oldPwd">
                <el-input v-model="pwdForm.oldPwd" autocomplete="off" type="password"></el-input>
              </el-form-item>
              <el-form-item :label="$t('userCenter.usrAcct.newPwd')" label-width="100px" prop="newPwd">
                <el-input v-model="pwdForm.newPwd" type="password"></el-input>
              </el-form-item>
              <el-form-item :label="$t('userCenter.usrAcct.confirmNewPwd')" label-width="100px" prop="confirmNewPwd">
                <el-input v-model="pwdForm.confirmNewPwd" type="password"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="cancelChangePwd">{{ $t('cmn.cancel') }}</el-button>
              <el-button type="primary" @click="confirmChangePwd">{{ $t('cmn.confirm') }}
              </el-button>
            </div>
          </el-dialog>
        </div>
      </el-form-item>

      <!-- email -->
      <el-form-item :label="$t('userCenter.usrAcct.email')" prop="email">
        <div class="form-item-box">
          <el-input :disabled=true v-model="usrAcctForm.email"></el-input>
          <el-button @click="openChangeEmailDialog">{{ $t('userCenter.usrAcct.changeEmail') }}</el-button>
          <el-dialog :title="$t('userCenter.usrAcct.changeEmail')" :visible.sync="emailDialogVisible" append-to-body>
            <el-form :model="emailForm" :rules="emailFormRules" ref="emailFormRef">
              <el-form-item :label="$t('userCenter.usrAcct.oldEmail')" label-width="100px" prop="oldEmail">
                <el-input v-model="emailForm.oldEmail" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item :label="$t('userCenter.usrAcct.newEmail')" label-width="100px" prop="newEmail">
                <el-input v-model="emailForm.newEmail" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item :label="$t('userCenter.usrAcct.pwd')" label-width="100px" prop="pwd">
                <el-input v-model="emailForm.pwd" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="cancelChangeEmail">{{ $t('cmn.cancel') }}</el-button>
              <el-button type="primary" @click="confirmChangeEmail">{{ $t('cmn.confirm') }}
              </el-button>
            </div>
          </el-dialog>
        </div>
      </el-form-item>
    </el-form>

    <el-divider></el-divider>

    <el-row :gutter="30">
      <el-col :span="14">
        <div>
          <el-form ref="basicInfoForm" :model="basicInfoForm" :rules="basicInfoFormRules"
                   label-width="80px"
                   label-position='left'>
            <el-form-item :label="$t('userCenter.usrAcct.showName')" prop="showName">
              <el-input v-model="basicInfoForm.showName" style="width: 300px"></el-input>
            </el-form-item>

            <el-form-item :label="$t('userCenter.usrAcct.showGender')" prop="showGender">
              <el-radio-group v-model="basicInfoForm.showGender">
                <el-radio label="MALE">{{ $t('userCenter.male') }}</el-radio>
                <el-radio label="FEMALE">{{ $t('userCenter.female') }}</el-radio>
                <el-radio label="HIDE">{{ $t('userCenter.unknown') }}</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item :label="$t('userCenter.usrAcct.showProfile')" prop="showProfile">
              <el-input v-model="basicInfoForm.showProfile" style="width: 300px"></el-input>
            </el-form-item>
          </el-form>
          <el-popover
            ref="updateUsrAcctPopover"
            placement="top"
            width="200"
          >
            <p>{{ $t('cmn.confirmSave') }}</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs['updateUsrAcctPopover'].doClose()">
                {{ $t('cmn.cancel') }}
              </el-button>
              <el-button type="primary" size="mini" @click="updateUsrAcctBasicInfo">{{ $t('cmn.confirm') }}</el-button>
            </div>
            <el-button slot="reference" type="primary">{{ $t('cmn.save') }}</el-button>
          </el-popover>
        </div>
      </el-col>
      <el-col :span="10">
        <div>
          <el-avatar :src="curUsrAcct.avatarUrl" shape="circle" :size="150" @click.native="toSetAvatar">
          </el-avatar>
        </div>
      </el-col>

      <div>
        <!--        avatar -->
        <el-dialog :title="$t('userCenter.usrAcct.setAvatar')" :visible.sync="avatarDialogVisible" width="500px">
          <el-form :model="avatarForm" :rules="avatarFormRules" ref="avatarFormRef">
            <el-form-item label-width="130px">
              <el-upload
                class="avatar-uploader"
                action=""
                :limit="1"
                :auto-upload="true"
                :http-request="uploadAvatarImg"
                :on-remove="handleRmAvatarImg"
                :on-success="handleUploadAvatarSuccess"
                :show-file-list="false">
                <img v-if="avatarImgFileUrl" :src="avatarImgFileUrl" class="avatar" alt="Avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <!--            <el-form-item>-->
            <!--              <el-input v-model="avatarForm.avatarUri" :disabled="!enableInputAvatarUri" @blur="showAvatar"></el-input>-->
            <!--            </el-form-item>-->
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="avatarDialogVisible=false">{{ $t('cmn.cancel') }}</el-button>
            <el-button type="warning" @click="resetAvatar">{{ $t('cmn.reset') }}</el-button>
            <el-button type="primary" @click="doSetAvatar">{{ $t('cmn.save') }}
            </el-button>
          </div>
        </el-dialog>
      </div>
    </el-row>
  </div>
</template>

<script>
import UsrAcctApi from '@/api/usercenter/UsrAcctApi'
import HashUtil from '@/util/HashUtil'
import FileServiceApi from '@/api/fileservice/FileServiceApi'
import ProtRule from '@/constant/ProtRule'
import StringUtil from '@/util/StringUtil'
import LoginManager from '@/sys/LoginManager'
import { FILE_INFRA_FILE_PREFIX } from '@/constant/CmnBizConst'

export default {
  name: 'UsrAcctInfo',
  created () {
    this.initForm()
    this.initFormRules()
  },
  data () {
    return {
      curUsrAcct: {},
      usrAcctForm: {
        usrPerId: null,
        mainAcctId: null,

        unid: null,
        email: null,
        isVip: null,

        showName: null,
        showGender: null,
        showProfile: null
      },
      unidDialogVisible: false,
      unidForm: {
        oldUnid: null,
        newUnid: null,
        pwd: null
      },
      unidFormRules: {},
      // pwd
      fakedPwd: '*********',
      pwdDialogVisible: false,
      pwdForm: {
        unid: '',
        oldPwd: '',
        newPwd: '',
        confirmNewPwd: ''
      },
      pwdFormRules: {},

      // email
      emailDialogVisible: false,
      emailForm: {
        oldEmail: null,
        newEmail: null,
        pwd: null
      },
      emailFormRules: {},

      basicInfoForm: {
        showName: null,
        showGender: null,
        showProfile: null
      },

      basicInfoFormRules: {},

      enableInputAvatarUri: true,
      avatarDialogVisible: false,
      avatarForm: {
        avatarUri: '',
        igLessAvatarUri: false
      },
      avatarFormRules: {},
      avatarImgFileUrl: null

    }
  },
  methods: {
    initFormRules () {
      this.unidFormRules = {
        oldUnid: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().unidPtn
        ],
        newUnid: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().unidPtn
        ],
        pwd: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().cmnPwdPlainPtn
        ]
      }
      this.pwdFormRules = {
        unid: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().unidPtn
        ],
        oldPwd: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().cmnPwdPlainPtn
        ],
        newPwd: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().cmnPwdPlainPtn
        ],
        confirmNewPwd: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().cmnPwdPlainPtn,
          {
            validator: (rule, value, callback) => {
              if (value !== this.pwdForm.newPwd) {
                callback(new Error(this.$t('protMsg.notSamePwd')))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
      this.emailFormRules = {
        oldEmail: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().emailPtn
        ],
        newEmail: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().emailPtn
        ],
        pwd: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().cmnPwdPlainPtn
        ]
      }
      this.basicInfoFormRules = {
        showName: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().cmnNameLength
        ],
        showProfile: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().cmnProfileLength
        ]
      }
      this.avatarFormRules = {
        avatarUri: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().cmnUriLength
        ]
      }
    },
    initForm () {
      UsrAcctApi.findCurrent()
        .then(usrAcctOp => {
          this.curUsrAcct = usrAcctOp
          this.usrAcctForm = usrAcctOp
          this.basicInfoForm = {
            showName: usrAcctOp.showName,
            showGender: usrAcctOp.showGender,
            showProfile: usrAcctOp.showProfile
          }
        })
    },
    logout () {
      LoginManager.logout()
      this.$router.push('/login')
    },

    toChangeUnid () {
      this.unidDialogVisible = true
      this.unidForm.oldUnid = this.curUsrAcct.unid
    },
    confirmChangeUnid () {
      if (this.unidForm.oldUnid !== this.unidForm.newUnid) {
        const ip = {
          oldUnid: this.unidForm.oldUnid,
          newUnid: this.unidForm.newUnid,
          pwd: HashUtil.doubleMd5(this.unidForm.pwd)
        }
        UsrAcctApi.changeUnid(ip)
          .then(resultData => {
            this.logout()
          })
      }
    },
    // pwd
    toChangePwd () {
      this.pwdDialogVisible = true
      this.pwdForm.unid = this.curUsrAcct.unid
    },
    cancelChangePwd () {
      this.pwdForm.oldPwd = ''
      this.pwdForm.newPwd = ''
      this.pwdForm.confirmNewPwd = ''
      this.pwdDialogVisible = false
    },
    confirmChangePwd () {
      const ip = {
        unid: this.pwdForm.unid,
        oldPwd: HashUtil.doubleMd5(this.pwdForm.oldPwd),
        newPwd: HashUtil.doubleMd5(this.pwdForm.newPwd)
      }
      UsrAcctApi.changePwd(ip)
        .then(rd => {
          this.pwdDialogVisible = false
          this.logout()
        })
    },

    // email
    openChangeEmailDialog () {
      this.emailDialogVisible = true
      this.emailForm.oldEmail = this.usrAcctForm.email
    },
    cancelChangeEmail () {
      this.emailDialogVisible = false
    },
    confirmChangeEmail () {
      if (this.emailForm.newEmail !== this.usrAcctForm.email) {
        const ip = {
          oldEmail: this.emailForm.oldEmail,
          newEmail: this.emailForm.newEmail,
          pwd: HashUtil.doubleMd5(this.emailForm.pwd)
        }
        UsrAcctApi.changeEmail(ip)
          .then(rd => {
            this.emailDialogVisible = false
            this.logout()
          })
      }
    },
    updateUsrAcctBasicInfo () {
      const ip = {
        findField: {},
        updateField: {
          ...this.basicInfoForm
        },
        extraField: {}
      }
      UsrAcctApi.updateBasicInfo(ip)
        .then(op => {
          if (op) {
            LoginManager.syncCurUsr()
            this.$refs.updateUsrAcctPopover.doClose()
          }
        })
    },

    showAvatar () {
      if (this.enableInputAvatarUri) {
        if (StringUtil.isMeaningless(this.avatarForm.avatarUri)) {
          this.avatarImgFileUrl = ''
        }
      }
      if (this.avatarForm.avatarUri.startsWith('http') || this.avatarForm.avatarUri.startsWith('https')) {
        this.avatarImgFileUrl = this.avatarForm.avatarUri
      }
    },
    handleRmAvatarImg () {
      this.enableInputAvatarUri = true
      this.avatarForm.avatarUri = this.curUsrAcct.avatarUri
    },
    uploadAvatarImg (event) {
      FileServiceApi.uploadImg(event.file, 'IMG', result => {
        // img/20230707/***/img.png
        this.avatarForm.avatarUri = FILE_INFRA_FILE_PREFIX + result.key
        event.onSuccess(result)
        this.enableInputAvatarUri = false
      })
    },
    handleUploadAvatarSuccess (response, file, fileList) {
      this.avatarImgFileUrl = URL.createObjectURL(file.raw)
    },
    toSetAvatar () {
      this.avatarDialogVisible = true
      this.enableInputAvatarUri = true
      this.avatarForm.avatarUri = this.curUsrAcct.avatarUri
      this.avatarImgFileUrl = this.curUsrAcct.avatarUrl
    },
    resetAvatar () {
      this.avatarForm.avatarUri = ''
      this.avatarImgFileUrl = ''
    },
    doSetAvatar () {
      const ip = {
        avatarUri: this.avatarForm.avatarUri,
        igLessAvatarUri: this.avatarForm.igLessAvatarUri
      }
      UsrAcctApi.setAvatar(ip)
        .then(result => {
          if (result) {
            this.curUsrAcct.avatarUri = this.avatarForm.avatarUri
            this.curUsrAcct.avatarUrl = this.avatarImgFileUrl
            this.avatarDialogVisible = false
            LoginManager.syncCurUsr()
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.form-item-box {
  display: flex;
  align-items: center;

  .el-button {
    margin-left: 20px;
  }
}

.el-input {
  min-width: 200px;
  max-width: 500px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 100%;
}
</style>
