import i18n from '@/i18n/i18n'
import ProtRegex from '@/constant/ProtRegex'

export function sizeValidator (rule, value, callback) {
  if (value === undefined || value === null) {
    return callback()
  }
  const length = Array.isArray(value) ? value.length : value.toString().length
  if (length < rule.min || length > rule.max) {
    return callback(new Error(i18n.t('protMsg.sizeRange') + '[' + rule.min + ',' + rule.max + ']'))
  }
  return callback()
}

export function intNumRangeValidator (rule, value, callback) {
  if (value === undefined || value === null || value.toString().trim() === '') {
    return callback(i18n.t('protMsg.required'))
  }
  const num = Number(value)
  if (Number.isNaN(num) || !Number.isInteger(num)) {
    return callback(new Error(i18n.t('protMsg.onlyInteger')))
  }
  if (num < rule.min || num > rule.max) {
    return callback(new Error(i18n.t('protMsg.valueRange') + '[' + rule.min + ',' + rule.max + ']'))
  }
  return callback()
}

export function cmnRule () {
  return {
    notWhite: {
      validator (rule, value, callback) {
        if (value === null || value === undefined || value === '') {
          return callback()
        }
        if (value.trim() !== '') {
          return callback()
        }
        return callback(new Error())
      },
      message: i18n.t('protMsg.notWhite'),
      trigger: 'blur'
    },
    notEmpty: {
      validator (rule, value, callback) {
        if (value === undefined || value === null || value.length === 0) {
          return callback(new Error())
        }
        return callback()
      },
      message: i18n.t('protMsg.notEmpty'),
      trigger: 'blur'
    },
    required: {
      required: true,
      message: i18n.t('protMsg.required'),
      trigger: 'blur'
    },
    cmnNameLength: {
      max: 32,
      message: i18n.t('protMsg.lengthMustLte') + '32',
      trigger: 'blur'
    },
    fileExtLength: {
      max: 32,
      message: i18n.t('protMsg.lengthMustLte') + '32',
      trigger: 'blur'
    },
    cmnProfileLength: {
      max: 256,
      message: i18n.t('protMsg.lengthMustLte') + '256',
      trigger: 'blur'
    },
    cmnUriLength: {
      max: 1024,
      message: i18n.t('protMsg.lengthMustLte') + '1024',
      trigger: 'blur'
    },
    cmnStrLength: {},
    length_32: {
      max: 32,
      message: i18n.t('protMsg.lengthMustLte') + '32',
      trigger: 'blur'
    },
    length_64: {
      max: 64,
      message: i18n.t('protMsg.lengthMustLte') + '64',
      trigger: 'blur'
    },
    length_256: {
      max: 256,
      message: i18n.t('protMsg.lengthMustLte') + '256',
      trigger: 'blur'
    },
    length_1024: {
      max: 1024,
      message: i18n.t('protMsg.lengthMustLte') + '1024',
      trigger: 'blur'
    },
    cmnTextLength: {
      max: 4096,
      message: i18n.t('protMsg.lengthMustLte') + '4096',
      trigger: 'blur'
    },
    progNaming: {
      pattern: ProtRegex.PROG_NAMING,
      message: i18n.t('protMsg.patternNotMatch') + '【' + i18n.t('protMsg.progNaming') + '】',
      trigger: 'blur'
    },
    unidPtn: {
      pattern: ProtRegex.UNID,
      message: i18n.t('protMsg.patternNotMatch') + '【' + i18n.t('protMsg.unidRegex') + '】',
      trigger: 'blur'
    },
    emailPtn: {
      pattern: ProtRegex.EMAIL,
      message: i18n.t('protMsg.patternNotMatch') + '【' + i18n.t('protMsg.emailRegex') + '】',
      trigger: 'blur'
    },
    cmnPwdPlainPtn: {
      pattern: ProtRegex.PWD_PLAIN,
      message: i18n.t('protMsg.patternNotMatch') + '【' + i18n.t('protMsg.pwdPlainRegex') + '】',
      trigger: 'blur'
    },
    i18nResSize: {
      validator: sizeValidator,
      min: 1,
      max: 8,
      message: i18n.t('protMsg.sizeRange') + '[1,8]',
      trigger: 'blur'
    },
    priceRule: {
      validator: intNumRangeValidator,
      min: 0,
      max: 10000000000,
      required: true,
      // message: i18n.t('protMsg.valueRange') + '[0,10000000000]',
      trigger: 'blur'
    },
    mainTagsSize: {
      validator: sizeValidator,
      min: 1,
      max: 3,
      message: i18n.t('protMsg.sizeRange') + '[1,3]',
      trigger: 'blur'
    },
    cmnTagsSize: {
      validator: sizeValidator,
      min: 1,
      max: 32,
      message: i18n.t('protMsg.sizeRange') + '32',
      trigger: 'blur'
    },
    cmnBatchSize: {
      validator: sizeValidator,
      min: 1,
      max: 100,
      message: i18n.t('protMsg.sizeRange') + '[1,100]',
      trigger: 'blur'
    },
    sBatchSize: {
      validator: sizeValidator,
      min: 1,
      max: 8,
      message: i18n.t('protMsg.sizeRange') + '[1,8]',
      trigger: 'blur'
    },
    mBatchSize: {
      validator: sizeValidator,
      min: 1,
      max: 32,
      message: i18n.t('protMsg.sizeRange') + '32',
      trigger: 'blur'
    },
    lBatchSize: {
      validator: sizeValidator,
      min: 1,
      max: 64,
      message: i18n.t('protMsg.sizeRange') + '64',
      trigger: 'blur'
    },
    cmnImgsSize: {
      validator: sizeValidator,
      min: 1,
      max: 8,
      message: i18n.t('protMsg.sizeRange') + '[1,8]',
      trigger: 'blur'
    }
  }
}

export function rankValueRemark () {
  return {
    rankValue: [
      {
        validator (rule, value, callback) {
          if (value === null || value === undefined || value === '') {
            return callback()
          }
          if (value.toString().trim() === '') {
            return callback(new Error())
          }
          if (!Number.isNaN(parseFloat(value))) {
            const parts = value.toString().split('.')
            if (parts[0] != null && parts[0].length > 5) {
              return callback(new Error())
            }
            if (parts.length > 1 && parts[1] != null && parts[1].length > 5) {
              return callback(new Error())
            }
            return callback()
          } else {
            return callback(new Error())
          }
        },
        message: i18n.t('protMsg.rankValue'),
        trigger: 'blur'
      }
    ],
    remark: [
      cmnRule().notWhite,
      cmnRule().length_1024
    ]
  }
}

export function cmnBaseName () {
  return {
    name: [
      cmnRule().required,
      cmnRule().notWhite,
      cmnRule().cmnNameLength
    ]
  }
}

export function cmnProgName () {
  return {
    name: [
      cmnRule().required,
      cmnRule().notWhite,
      cmnRule().cmnNameLength,
      cmnRule().progNaming
    ]
  }
}

export default {
  sizeValidator,
  cmnRule,
  rankValueRemark,
  cmnBaseName,
  cmnProgName
}
