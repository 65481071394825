export default class LonLatUtil {
  LonLatUtil () {
  }

  static isValidLon (lon) {
    return lon >= -180 && lon <= 180
  }

  static isValidLat (lat) {
    return lat >= -90 && lat <= 90
  }

  static identifyLonLatPoint (text) {
    if (text.indexOf('lon') !== -1) {
      return this.identifyLonLatPointWithKeyword(text)
    }
    return this.identifyLonLatPointOnlyNumber(text)
  }

  static identifyLonLatPointWithKeyword (text) {
    const lonArr = this.identifyLonWithKeyword(text)
    const latArr = this.identifyLatWithKeyword(text)
    const num = lonArr.length < latArr.length ? lonArr.length : latArr.length
    const lonLatPointArr = []
    for (let i = 0; i < num; i++) {
      lonLatPointArr.push({
        lon: lonArr[i],
        lat: latArr[i]
      })
    }
    console.log('[LonLatPointArr][with keyword]', lonLatPointArr)
    return lonLatPointArr
  }

  static identifyLonWithKeyword (text) {
    const lonPtn1 = '(lon|lng|longitude)(\\D{0,}?)(-{0,1}\\d{1,}\\.\\d{1,})'
    const re1 = new RegExp(lonPtn1, 'g')
    const ma = text.matchAll(re1)
    const lonArr = []
    let maNext = ma.next()
    while (!maNext.done) {
      const lon = Number.parseFloat(maNext.value[3])
      if (this.isValidLon(lon)) {
        lonArr.push(lon)
      }
      maNext = ma.next()
    }
    console.log('lonArr', lonArr)
    return lonArr
  }

  static identifyLatWithKeyword (text) {
    const latPtn = '(lat|latitude)(\\D{0,}?)(-{0,1}\\d{1,}\\.\\d{1,})'
    const regExp = new RegExp(latPtn, 'g')
    const ma = text.matchAll(regExp)
    const latArr = []
    let maNext = ma.next()
    while (!maNext.done) {
      const lat = Number.parseFloat(maNext.value[3])
      if (this.isValidLat(lat)) {
        latArr.push(lat)
      }
      maNext = ma.next()
    }
    console.log('latArr', latArr)
    return latArr
  }

  static identifyLonLatPointOnlyNumber (text) {
    const lonLatNumPtn = '(\\D{0,}?)(-{0,1}\\d{1,}\\.\\d{1,})'
    const regExp = new RegExp(lonLatNumPtn, 'g')
    const ma = text.matchAll(regExp)
    const lonLatArr = []
    let maNext = ma.next()
    while (!maNext.done) {
      lonLatArr.push(maNext.value[2])
      maNext = ma.next()
    }
    console.log('lonLatArr', lonLatArr)
    const lonLatPointArr = []
    if (lonLatArr.length <= 1) {
      return lonLatPointArr
    }
    for (let i = 0; i < lonLatArr.length - 1;) {
      if (this.isValidLon(lonLatArr[i]) && this.isValidLat(lonLatArr[i + 1])) {
        lonLatPointArr.push({
          lon: lonLatArr[i],
          lat: lonLatArr[i + 1]
        })
        i = i + 2
      } else if (this.isValidLon(lonLatArr[i + 1]) && this.isValidLat(lonLatArr[i])) {
        lonLatPointArr.push({
          lon: lonLatArr[i + 1],
          lat: lonLatArr[i]
        })
        i = i + 2
      } else {
        i = i + 1
      }
    }
    console.log('[LonLatPointArr][only number]', lonLatPointArr)
    return lonLatPointArr
  }

  static isLonFirst (lonLatArr) {
    for (let i = 0; i < lonLatArr.length; i++) {
      if (i % 2 === 1) {
        if (lonLatArr[i] > 90 || lonLatArr[i] < -90) {
          return false
        }
      }
    }
    return true
  }
}
