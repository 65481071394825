<template>
  <div>
    <div class="ts-cur-container">
      <div class="ts-cur-title">{{ $t('timestamp.curTs') }}</div>
      <el-card>
        <div class="ts-cur-value">{{ nowTsSec }}</div>
      </el-card>
      <div class="ts-cur-btn-container">
        <el-button v-if="tsRunning" type="success" circle size="medium" icon="el-icon-video-pause"
                   @click="stopTs"></el-button>
        <el-button v-else type="danger" circle size="medium" icon="el-icon-video-play" @click="startTs"></el-button>
        <el-button type="primary" circle size="medium" icon="el-icon-timer" @click="watchNow"></el-button>
        <el-button type="warning" circle size="medium" icon="el-icon-refresh" @click="refreshTs"></el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="ts-convert-container">
      <div class="ts-convert-title">{{ $t('timestamp.tsConvert') }}</div>
      <div>
        <el-row :gutter="30">
          <el-col :span="10">
            <div class="ts-convert-unit-tz-select">
              <el-button type="info" plain disabled>{{ $t('timestamp.tsUnit') }}</el-button>
              <el-select v-model="tsUnitModel" filterable default-first-option
                         @change="onTsUnitModelSelect">
                <el-option
                  v-for="tsUnitModel in tsUnitModelList"
                  :key="tsUnitModel.key"
                  :label="tsUnitModel.label"
                  :value="tsUnitModel.key">
                </el-option>
              </el-select>
              <el-button class="iconfont vj-icon-clear" @click="clearInputArea"></el-button>
            </div>
            <el-input type="textarea"
                      v-model="tsArea"
                      :disabled="executeMode==='TIME_TO_TS'"
                      rows="10"
                      @input="delayConvert">
            </el-input>
          </el-col>
          <el-col :span="4">
            <div class="execute-mode-div">
              <div>
                <el-radio v-model="executeMode" label="TS_TO_TIME" @input="onChangeExecuteMode">
                  {{ this.$t('timestamp.tsToTime') }}
                </el-radio>
              </div>
              <div>
                <el-radio v-model="executeMode" label="TIME_TO_TS" @input="onChangeExecuteMode">
                  {{ this.$t('timestamp.timeToTs') }}
                </el-radio>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="ts-convert-unit-tz-select">
              <el-button type="info" disabled plain>{{ this.$t('timestamp.timeZone') }}
              </el-button>
              <el-select v-model="timeZoneId" filterable default-first-option
                         @change="onSelectTimeZone">
                <Timezone></Timezone>
              </el-select>
              <el-button class="iconfont vj-icon-clear" @click="clearInputArea"></el-button>
            </div>
            <el-input type="textarea"
                      v-model="dateTimeArea"
                      :disabled="executeMode==='TS_TO_TIME'"
                      rows="10"
                      @input="delayConvert">
            </el-input>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Timezone from '../../../components/Timezone'
import TimeUtil from '../../../util/TimeUtil'
import CheckUtil from '@/util/CheckUtil'

export default {
  name: 'TsConvert',
  components: { Timezone },
  data () {
    return {
      tsRunning: true,
      nowTsSec: Math.round(new Date() / 1000),
      nowDateTimeStr: TimeUtil.convertMsecToDateTimeStr(new Date().getTime()),
      timer: 0,

      delayConvertTimeoutId: null,
      executeMode: 'TS_TO_TIME',
      timeZoneId: TimeUtil.guessCurrentTimezoneId(),
      tsArea: '0\n' + '60',
      dateTimeArea: '',
      tsUnitModel: 's',
      tsUnitModelList: [
        // {
        //   key: 'auto',
        //   label: '毫秒(ms)/秒(s)'
        // },
        {
          key: 's',
          label: '秒(s)'
        },
        {
          key: 'ms',
          label: '毫秒(ms)'
        }
      ]
    }
  },
  mounted () {
    this.startTs()
    this.convert()
  },
  beforeRouteLeave (to, from, next) {
    this.stopTs()
    next()
  },
  methods: {
    refreshTs () {
      setTimeout(() => {
        this.nowTsSec = Math.round(new Date().getTime() / 1000)
      }, 0)
    },
    startTs () {
      this.tsRunning = true
      if (this.timer) {
        return
      }
      this.timer = setInterval(() => {
        this.refreshTs()
      }, 1000)
    },
    stopTs () {
      this.nowTsSec = Math.round(new Date().getTime() / 1000)
      clearInterval(this.timer)
      this.timer = null
      this.tsRunning = false
    },
    watchNow () {
      let ts
      if (this.tsUnitModel === 's') {
        ts = this.nowTsSec
      } else {
        ts = this.nowTsSec * 1000
      }
      if (this.tsArea) {
        this.tsArea = this.tsArea + '\n' + ts
      } else {
        this.tsArea = '' + ts
      }
      this.executeMode = 'TS_TO_TIME'
      this.convert()
    },
    delayConvert () {
      this.clearDelayConvertTimeout()
      this.delayConvertTimeoutId = setTimeout(() => {
        this.convert()
      }, 1000)
    },
    clearDelayConvertTimeout () {
      if (this.delayConvertTimeoutId) {
        clearTimeout(this.delayConvertTimeoutId)
      }
    },
    convert () {
      switch (this.executeMode) {
        case 'TS_TO_TIME':
          this.convertTsToTime()
          break
        case 'TIME_TO_TS':
          this.convertTimeToTs()
      }
    },
    convertTsToTime () {
      const tsStrArr = this.tsArea.split(/[\n,;]/)
      const dateTimeArr = []
      tsStrArr.forEach((value, idx) => {
        const trimValue = value.trim()
        if (CheckUtil.isMeaningless(trimValue)) {
          dateTimeArr[idx] = ''
          return
        }
        switch (this.tsUnitModel) {
          case 'auto':
            if (trimValue.length > 10) {
              dateTimeArr[idx] = TimeUtil.convertTsMillisToDateTimeStr(trimValue, this.timeZoneId)
            } else {
              dateTimeArr[idx] = TimeUtil.convertTsMillisToDateTimeStr(trimValue * 1000, this.timeZoneId)
            }
            break
          case 's':
            dateTimeArr[idx] = TimeUtil.convertTsMillisToDateTimeStr(trimValue * 1000, this.timeZoneId)
            break
          case 'ms':
            dateTimeArr[idx] = TimeUtil.convertTsMillisToDateTimeStr(trimValue, this.timeZoneId)
            break
        }
      })
      this.dateTimeArea = ''
      dateTimeArr.forEach((value, idx) => {
        if (idx === dateTimeArr.length - 1) {
          this.dateTimeArea += value
        } else {
          this.dateTimeArea += value + '\n'
        }
      })
    },
    convertTimeToTs () {
      const dateTimeArr = this.dateTimeArea.split(/[\n,;]/)
      const tsArr = []
      dateTimeArr.forEach((value, idx) => {
        const trimValue = value.trim()
        if (CheckUtil.isMeaningless(trimValue)) {
          tsArr[idx] = ''
          return
        }
        switch (this.tsUnitModel) {
          case 'ms':
            tsArr[idx] = TimeUtil.convertDateTimeStrToTsSec(trimValue, this.timeZoneId) * 1000
            break
          default:
            tsArr[idx] = TimeUtil.convertDateTimeStrToTsSec(trimValue, this.timeZoneId)
            break
        }
      })
      console.log(tsArr)
      this.tsArea = ''
      tsArr.forEach((value, idx) => {
        if (idx === tsArr.length - 1) {
          this.tsArea += value
        } else {
          this.tsArea += value + '\n'
        }
      })
    },
    onChangeExecuteMode (selectedValue) {
      switch (selectedValue) {
        case 'TS_TO_TIME':
          this.convertTsToTime()
          break
        case 'TIME_TO_TS':
          this.convertTimeToTs()
      }
    },

    onTsUnitModelSelect (tsUnitModel) {
      this.tsUnitModel = tsUnitModel
      this.convert()
    },
    onSelectTimeZone (timeZoneId) {
      this.timeZoneId = timeZoneId
      this.convert()
    },
    clearInputArea () {
      this.tsArea = ''
      this.dateTimeArea = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/Timestamp.scss";
</style>
