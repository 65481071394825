<template>
  <hash-cmpt hashAlg="SHA512"></hash-cmpt>
</template>

<script>
import HashCmpt from './HashCmpt.vue'

export default {
  name: 'SHA512',
  components: { HashCmpt }
}
</script>
