<template>
  <el-container>
    <el-aside width="150px">
      <p>Aside</p>
      <el-tree
        ref="treeRef"
        :data="treeData"
        node-key="id"
        show-checkbox
        check-strictly
        @check="handleTreeNodeChecked">
      </el-tree>

    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'TestMain',
  data () {
    return {
      treeData: [
        {
          id: 1,
          label: 'Node1'
        },
        {
          id: 2,
          label: 'Node2'
        }
      ]
    }
  },
  methods: {
    handleTreeNodeChecked () {
      this.$router.push({
        path: '/testRouter',
        query: {
          data: this.$refs.treeRef.getCheckedKeys()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
