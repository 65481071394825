<template>
  <div>
    <p>Test</p>
    <div class="sprite"></div>
    <el-form>
      <el-form-item prop="username">
        <span class="svg-container">
            <SvgIcon fileName="user_account"></SvgIcon>
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="Username"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>
    </el-form>

    <div id="jsonTreeEditor" style="width: 300px; height: 200px"></div>

  </div>
</template>

<script>
import '../assets/svg/jsoneditor-icons.svg'
import SvgIcon from '../components/SvgIcon'

import JsonTreeEditor from '../jsoneditorlab/jsoneditor.js'
import '../jsoneditorlab/jsoneditor.css'

export default {
  name: 'Test',
  components: { SvgIcon },
  mounted () {
    this.initJsonTreeEditor()
  },
  methods: {
    initJsonTreeEditor () {
      const container = document.getElementById('jsonTreeEditor')
      this.jsonTreeEditor = new JsonTreeEditor.JSONEditor(container)
      this.jsonTreeEditor.set(this.defaultJsonData)
    }
  },
  data () {
    return {
      loginForm: {
        username: 'admin',
        password: '111111'
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur'
        }],
        password: [{
          required: true,
          trigger: 'blur'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      jsonTreeEditor: null,
      defaultJsonData: {
        Array: [1, 2, 3],
        Boolean: true,
        Null: null,
        Number: 123,
        Object: {
          a: 'b',
          c: 'd'
        },
        String: 'Hello World'
      }
    }
  }

}
</script>

<style lang="less" scoped>

.login-form {
  position: relative;
  width: 520px;
  max-width: 100%;
  padding: 160px 35px 0;
  margin: 0 auto;
  overflow: hidden;
}

.el-input {
  //display: inline-block;
  height: 47px;
  width: 85%;

  input {
    background: transparent;
    border: 0px;
    -webkit-appearance: none;
    border-radius: 0px;
    padding: 12px 5px 12px 15px;
    height: 47px;
  }
}

.el-form-item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #454545;
}

.svg-container {
  padding: 6px 5px 6px 15px;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
}

.sprite {
  content: '';
  display: block;
  width: 26px;
  height: 26px;
  background-size: 100px auto;
  background: gray url(../../src/assets/img/jsoneditor-icons.svg) no-repeat -72px -120px;

  /*background: gray url("../assets/img/jsoneditor-icons.png") no-repeat -72px -120px;*/
}
</style>
