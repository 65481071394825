import SysConfig from '@/SysConfig'
import Cookies from 'js-cookie'
import ObjectUtil from '@/util/ObjectUtil'

export function setToken (token) {
  Cookies.set(SysConfig.tokenKey, token)
}

export function getToken () {
  return Cookies.get(SysConfig.tokenKey)
}

export function removeToken () {
  Cookies.remove(SysConfig.tokenKey)
}

export function setCurUsr (curUsr) {
  const curUsrObj = {
    id: null,
    unid: null,
    showName: null,
    showGender: null,
    avatarUrl: null
  }
  ObjectUtil.copyFieldValue(curUsr, curUsrObj)
  Cookies.set(SysConfig.curUsrKey, JSON.stringify(curUsrObj))
}

export function getCurUsr () {
  return JSON.parse(Cookies.get(SysConfig.curUsrKey))
}

export function removeCurUsr () {
  Cookies.remove(SysConfig.curUsrKey)
}

export function rememberMe (remMeData) {
  Cookies.set(SysConfig.rememberMeKey, JSON.stringify(remMeData), { expires: SysConfig.rememberMeExpireDay })
}

export function remMeData () {
  return JSON.parse(Cookies.get(SysConfig.rememberMeKey))
}

export function forgetMe () {
  Cookies.remove(SysConfig.rememberMeKey)
}

export default {
  setToken,
  getToken,
  removeToken,
  setCurUsr,
  getCurUsr,
  removeCurUsr,

  rememberMe,
  remMeData,
  forgetMe
}
