<template>
  <div class="login_container">
    <I18nBtn class="i18n-btn"></I18nBtn>
    <div class="re-email-div">
      <!--      <el-tooltip content="没有收到激活邮件？点击重发" placement="top" effect="light">-->
      <!--        <el-button class="iconfont vj-icon-email-solid" circle @click="toResendEmail"></el-button>-->
      <!--      </el-tooltip>-->
      <el-button class="iconfont vj-icon-email-solid" circle @click="toResendEmail"></el-button>
    </div>
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="../../assets/img/SmileSnowOwl.jpeg">
      </div>
      <!-- 登录表单区域 -->
      <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
        <!-- 用户名 -->
        <el-form-item prop="principal">
          <el-input v-model="loginForm.principal" prefix-icon="iconfont vj-icon-user-solid"
                    @input="change2NormalLogin"
                    :placeholder="$t('login.inputUnameOrEmail')"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="pwd">
          <el-input v-model="loginForm.pwd" prefix-icon="iconfont vj-icon-pwd" type="password"
                    @input="change2NormalLogin"
                    :placeholder="$t('login.inputPwd')"></el-input>
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item prop="captchaCode" v-if="!rememberMeLogin">
          <el-input
            v-model="loginForm.captchaCode"
            :placeholder="$t('login.inputCaptcha')"
            auto-complete="off"
            @keyup.enter.native="submit"
            style="width: 63%"
            prefix-icon="iconfont vj-icon-captcha"
          >
            <!--            <svg-icon slot="prefix" file-name="validCode" class="el-input__icon input-icon"/>-->
          </el-input>
          <div class="login-captcha">
            <img :src="captchaUri" @click="genCaptcha" alt="Captcha">
          </div>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-checkbox v-model="loginForm.rememberMe" @change="change2NormalLogin">{{
            $t('login.rememberMe')
          }}
        </el-checkbox>
        <el-form-item class="login-btn-item">
          <el-button @click="submit">{{ $t('login.login') }}</el-button>
        </el-form-item>
        <div class="login-link">
          <router-link to="/register" tag="a">{{ $t('login.register') }}</router-link>
        </div>
      </el-form>
    </div>

    <div>
      <el-dialog
        :close-on-click-modal="false"
        append-to-body
        :visible.sync="enableResendEmailDialog"
        :title="$t('login.resendEmail')"
        width="500px"
      >
        <el-form
          ref="resendEmailFormRef"
          :model="resendEmailForm"
          :rules="resendEmailFormRules"
          label-width="0"
        >
          <el-form-item prop="email">
            <el-input v-model="resendEmailForm.email" :placeholder="$t('register.inputEmail')"
                      prefix-icon="iconfont vj-icon-email-solid"></el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input v-model="resendEmailForm.pwd" :placeholder="$t('register.inputPwd')" type="password"
                      prefix-icon="iconfont vj-icon-pwd"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableResendEmailDialog=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doResendEmail">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import I18nBtn from '../../components/I18nBtn'
import UsrAuthApi from '@/api/UsrAuthApi'
import HashUtil from '@/util/HashUtil'
import LoginManager from '@/sys/LoginManager'
import ProtRule from '@/constant/ProtRule'
import ProtRegex from '@/constant/ProtRegex'
import UsrApi from '@/api/usercenter/UsrApi'

export default {
  components: { I18nBtn },
  watch: {
    '$i18n.locale': {
      handler: function (newVal, oldVal) {
        this.initFormRules()
      }
    },
    immediate: true
  },
  data () {
    return {
      rememberMeLogin: false,
      captchaUri: '',
      captchaUuid: '',
      // 这是登录表单的数据绑定对象
      loginForm: {
        principal: '',
        pwd: '',
        credential: '',
        captchaCode: '',
        rememberMe: false
      },
      loginFormRules: {},

      enableResendEmailDialog: false,
      resendEmailForm: {
        email: '',
        pwd: ''
      },
      resendEmailFormRules: {}
    }
  },
  created () {
    this.initFormRules()
    this.init()
  },
  methods: {
    initFormRules () {
      this.loginFormRules = {
        principal: [
          ProtRule.cmnRule().required,
          {
            validator: (rule, value, callback) => {
              if (new RegExp(ProtRegex.EMAIL).test(value) || new RegExp(ProtRegex.UNID).test(value)) {
                callback()
              } else {
                callback(new Error(this.$t('protMsg.invalidPrincipal')))
              }
            },
            trigger: 'blur'
          }
        ],
        pwd: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().cmnPwdPlainPtn
        ],
        captchaCode: [
          ProtRule.cmnRule().required
        ]
      }
      this.resendEmailFormRules = {
        email: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().emailPtn
        ],
        pwd: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().cmnPwdPlainPtn
        ]
      }
    },
    init () {
      LoginManager.isLoggedIn().then(rd => {
        if (rd) {
          this.route2Home()
        } else {
          this.genCaptcha()
        }
      })
      const remMeData = LoginManager.remMeData()
      if (remMeData) {
        this.rememberMeLogin = true
        this.loginForm.principal = remMeData.principal
        this.loginForm.credential = remMeData.credential
        this.loginForm.pwd = '999999999'
        this.loginForm.rememberMe = true
      }
    },
    async genCaptcha () {
      this.loginForm.captchaCode = ''
      const captchaOp = await UsrAuthApi.genCaptcha()
      this.captchaUri = captchaOp.imgBase64
      this.captchaUuid = captchaOp.uuid
    },
    change2NormalLogin () {
      this.rememberMeLogin = false
    },
    submit () {
      this.$refs.loginFormRef.validate(
        isValid => {
          if (!isValid) {
            return
          }
          if (this.rememberMeLogin) {
            this.doLogin()
          } else {
            this.verifyThenLogin()
          }
        }
      )
    },
    verifyThenLogin () {
      const ip = {
        uuid: this.captchaUuid,
        code: this.loginForm.captchaCode
      }
      UsrAuthApi.verifyCaptcha(ip).then(op => {
        if (op) {
          this.doLogin()
        } else {
          this.$message.error(this.$t('login.wrongCaptcha'))
          this.genCaptcha()
        }
      })
    },
    doLogin () {
      const loginData = {
        principal: this.loginForm.principal,
        credential: this.rememberMeLogin
          ? this.loginForm.credential
          : HashUtil.md5(HashUtil.md5(this.loginForm.pwd))
      }
      try {
        UsrAuthApi.login(loginData.principal, loginData.credential).then(rd => {
          if (rd) {
            LoginManager.login(rd)
            if (this.loginForm.rememberMe) {
              const remMeData = { ...loginData }
              LoginManager.rememberMe(remMeData)
            } else {
              LoginManager.forgetMe()
            }
            this.route2Home()
          } else {
            this.genCaptcha()
          }
        })
          .catch(() => {
            this.genCaptcha()
          })
      } catch (e) {
        this.genCaptcha()
        return this.$message.error(this.$t('login.loginFailed'))
      }
    },
    route2Home () {
      this.$router.push('/home')
    },
    toResendEmail () {
      this.enableResendEmailDialog = true
      if (this.loginForm.principal.includes('@')) {
        this.resendEmailForm.email = this.loginForm.principal
      }
    },
    doResendEmail () {
      this.$refs.resendEmailFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          principal: this.resendEmailForm.email,
          pwd: HashUtil.md5(HashUtil.md5(this.resendEmailForm.pwd))
        }
        UsrApi.sendActivationUrl(ip).then(op => {
          if (op) {
            this.$message.success(this.$t('login.resendEmailSuccess'))
            this.enableResendEmailDialog = false
          } else {
            this.$message.error(this.$t('login.resendEmailFailed'))
          }
        }).catch(() => {
          this.$message.error(this.$t('login.resendEmailFailed'))
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login_container {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../../assets/img/LoginBgImg.jpeg") center;
}

.i18n-btn {
  position: absolute;
  right: 10%;
  top: 10%;
}

.re-email-div {
  position: absolute;
  right: 6%;
  top: 10%;
}

.login_box {
  width: 500px;
  height: 400px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.login-btn-item {
  margin: 30px 25px 0;

  .el-button {
    display: block;
    width: 100%;
    font-size: 15px;
    background-color: #D97158;
    color: #fff;
  }
}

.login-link {
  padding-right: 24px;
  margin-top: 10px;

  a {
    float: right;
  }
}

.login-captcha {
  width: 33%;
  display: inline-block;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle
  }
}
</style>
