export default {
  'principal.credential.wrong': '用户名或密码错误',
  'email.exists': 'Email已存在',
  'unid.exists': 'VOJI UNID 已存在',
  'unid.pwd.wrong': 'VOJI UNID 或密码错误',
  'unid.invalid': 'VOJI UNID无效',
  'unid.not.found': 'VOJI UNID不存在',
  'email.invalid': '邮箱无效',
  'acct.not.found': '账号不存在',
  'acct.disabled': '账号被禁用',
  'acct.unactivated': '账号未激活，请前往邮箱点击链接激活',
  'invalid.active.code': '无效的激活码',
  'invalid.principle': '账号无效',
  'can.not.delete.default.group': '不能删除默认分组'
}
