import resultHttpTool from '@/http/ResultHttpTool'

const API_PREFIX = '/api/usr/vjbTopUp'

export function findTopUpPkgs () {
  return resultHttpTool.get(API_PREFIX + '/findTopUpPkgs')
    .then(respResultData => {
      console.info('[RespResultData]', respResultData)
      if (respResultData === null || respResultData.length < 1) {
        return defaultTopUpPkgs()
      }
      return respResultData
    }, () => {
      return defaultTopUpPkgs()
    })
}

export function findOrSyncStatus (ip) {
  return resultHttpTool.get(API_PREFIX + '/findOrSyncStatus', {
    params: {
      id: ip.id,
      txnId: ip.txnId,
      payWay: ip.payWay
    }
  })
}

export function syncStatus () {
  return resultHttpTool.get(API_PREFIX + '/syncStatus')
}

export function init (ip) {
  return resultHttpTool.post(API_PREFIX + '/init', ip)
}

export function complete (ip) {
  return resultHttpTool.post(API_PREFIX + '/complete', ip)
}

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export default {
  findTopUpPkgs,
  findOrSyncStatus,
  syncStatus,
  init,
  complete,
  findByPage
}

function defaultTopUpPkgs () {
  return [
    {
      id: 11,
      name: '一心一意',
      i18nName: '一心一意',
      nameI18nRes: [
        {
          langId: 'zh',
          value: '一心一意'
        },
        {
          langId: 'en',
          value: 'Heart and Soul'
        }
      ],
      origCcyAmt: 330,
      curCcyAmt: 220,
      showOrigCcyAmt: '3.3',
      showCurCcyAmt: '2.2',
      showCcySymbol: '￥',
      txnVjb: 11,
      remark: '两情相悦，一心一意',
      i18nRemark: '两情相悦，一心一意',
      remarkI18nRes: [
        {
          langId: 'zh',
          value: '两情相悦，一心一意'
        },
        {
          langId: 'en',
          value: 'Two of a kind, heart and soul'
        }
      ],
      isSp: 0
    },
    {
      id: 99,
      name: '海誓山盟',
      i18nName: '海誓山盟',
      nameI18nRes: [
        {
          langId: 'zh',
          value: '海誓山盟'
        },
        {
          langId: 'en',
          value: 'Vows of eternal love'
        }
      ],
      origCcyAmt: 1990,
      curCcyAmt: 990,
      showOrigCcyAmt: '19.9',
      showCurCcyAmt: '9.9',
      showCcySymbol: '￥',
      txnVjb: 99,
      remark: '海誓山盟，愿天长地久',
      i18nRemark: '海誓山盟，愿天长地久',
      remarkI18nRes: [
        {
          langId: 'zh',
          value: '海誓山盟，愿天长地久'
        },
        {
          langId: 'en',
          value: 'Vows of eternal love, wishing for everlasting'
        }
      ],
      isSp: 0
    },
    {
      id: 520,
      name: '心有灵犀',
      i18nName: '心有灵犀',
      nameI18nRes: [
        {
          langId: 'zh',
          value: '心有灵犀'
        },
        {
          langId: 'en',
          value: 'Mind Acts Upon Mind'
        }
      ],
      origCcyAmt: 5200,
      curCcyAmt: 5000,
      showOrigCcyAmt: '52.0',
      showCurCcyAmt: '50.0',
      showCcySymbol: '￥',
      txnVjb: 520,
      remark: '身无彩凤双飞翼，心有灵犀一点通',
      i18nRemark: '身无彩凤双飞翼，心有灵犀一点通',
      remarkI18nRes: [
        {
          langId: 'zh',
          value: '身无彩凤双飞翼，心有灵犀一点通'
        },
        {
          langId: 'en',
          value: 'No phoenix wings, but mind acts upon mind.'
        }
      ],
      isSp: 0
    },
    {
      id: 1314,
      name: '一生一世',
      i18nName: '一生一世',
      nameI18nRes: [
        {
          langId: 'zh',
          value: '一生一世'
        },
        {
          langId: 'en',
          value: 'Lifetime Commitment'
        }
      ],
      origCcyAmt: 13140,
      curCcyAmt: 9900,
      showOrigCcyAmt: '131.4',
      showCurCcyAmt: '99.9',
      showCcySymbol: '￥',
      txnVjb: 1314,
      remark: '相濡以沫，独一无二，共度一生一世',
      i18nRemark: '相濡以沫，独一无二，共度一生一世',
      remarkI18nRes: [
        {
          langId: 'zh',
          value: '相濡以沫，独一无二，共度一生一世'
        },
        {
          langId: 'en',
          value: 'Mutual support, unique and irreplaceable, sharing a lifetime commitment\''
        }
      ],
      isSp: 0
    },
    {
      id: 5201314999,
      name: '致...！',
      i18nName: '致...！',
      nameI18nRes: [
        {
          langId: 'zh',
          value: '致...！'
        },
        {
          langId: 'en',
          value: 'For...'
        }
      ],
      origCcyAmt: 13145200,
      curCcyAmt: 5209900,
      showOrigCcyAmt: '131452',
      showCurCcyAmt: '52099',
      showCcySymbol: '￥',
      txnVjb: 5201314999,
      remark: '也许在过去，也许在未来，也许就在现在。他的，她的，你的，也许是属于我的，致...！',
      i18nRemark: '也许在过去，也许在未来，也许就在现在。他的，她的，你的，也许是属于我的，致...！',
      remarkI18nRes: [
        {
          langId: 'zh',
          value: '也许在过去，也许在未来，也许就在现在。他的，她的，你的，也许是属于我的，致...！'
        },
        {
          langId: 'en',
          value: 'Perhaps in the past, perhaps in the future, perhaps right now. His, hers, yours, perhaps mine, to...!'
        }
      ],
      isSp: 1
    }
  ]
}
