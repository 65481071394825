import resultHttpTool from '@/http/ResultHttpTool'
import HttpDownloadTool from '@/http/HttpDownloadTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/jsonOne'

export function convertToExcel (ip) {
  return HttpDownloadTool.post(API_PREFIX + '/convertToExcel', ip)
}

export function convertToCsv (ip) {
  return HttpDownloadTool.post(API_PREFIX + '/convertToCsv', ip)
}

export function convertTo (ip) {
  return resultHttpTool.post(API_PREFIX + '/convertTo', ip)
    .then(resultData => {
      if (resultData) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return resultData
    })
}

export function parseFromExcel (formData) {
  return resultHttpTool.post(API_PREFIX + '/parseFromExcel', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(resultData => {
    if (resultData) {
      ApiMsgBox.success()
    } else {
      ApiMsgBox.failed()
    }
    return resultData
  })
}

export function parseFromCsv (formData) {
  return resultHttpTool.post(API_PREFIX + '/parseFromCsv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(resultData => {
    if (resultData) {
      ApiMsgBox.success()
    } else {
      ApiMsgBox.failed()
    }
    return resultData
  })
}

export function parseFrom (ip) {
  return resultHttpTool.post(API_PREFIX + '/parseFrom', ip)
    .then(resultData => {
      if (resultData) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return resultData
    })
}

export function extractFromText (ip) {
  return resultHttpTool.post(API_PREFIX + '/extractFromText', ip)
    .then(resultData => {
      if (resultData) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return resultData
    })
}

export function extractFromImg (formData) {
  return resultHttpTool.post(API_PREFIX + '/extractFromImg', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(resultData => {
    if (resultData) {
      ApiMsgBox.success()
    } else {
      ApiMsgBox.failed()
    }
    return resultData
  })
}

export default {
  convertToExcel,
  convertToCsv,
  convertTo,
  parseFromExcel,
  parseFromCsv,
  parseFrom,
  extractFromText,
  extractFromImg
}
