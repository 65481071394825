import resultHttpTool from '@/http/ResultHttpTool'
import generalHttpTool from '@/http/GeneralHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/coCoder'

export function genCode (genCodeIp) {
  return resultHttpTool.post(API_PREFIX + '/genCode', formatIp(genCodeIp))
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function download (codeGenTree, projectName) {
  return generalHttpTool.post(API_PREFIX + '/download?projectName=' + projectName,
    codeGenTree, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; application/octet-stream'
      }
    })
    .then(resp => {
      const blob = new Blob([resp.data])
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      // 创建下载的链接
      downloadElement.href = href
      // 下载后文件名
      downloadElement.download = getFileName(resp.headers['content-disposition'])
      document.body.appendChild(downloadElement)
      // 点击下载
      downloadElement.click()
      // 下载完成移除元素
      document.body.removeChild(downloadElement)
      // 释放掉blob对象
      window.URL.revokeObjectURL(href)
    }, () => {
      console.error('[Download failed]')
    })
}

function getFileName (contentDisposition) {
  return contentDisposition.split('filename=')[1]
}

function formatIp (genCodeIp) {
  if (genCodeIp === null) {
    genCodeIp = {
      userId: null,
      accountId: null,
      databaseType: 'MYSQL',
      entityJsonModel: '',
      entitySqlModel: '',
      entityModelName: '',
      entityModelRemark: '',
      codeGenFrameworks: ['SEAKEER_FRAMEWORK', 'SWAGGER', 'SRPING_CLOUD_FEIGN'],
      projectName: '',
      authorName: '',
      moduleParentPkgPath: '',
      moduleName: '',
      enableSaveProject: false,
      enableEntityInnerClass: false
    }
  }
  return genCodeIp
}

export default {
  genCode,
  download
}
