<template>
  <div>
    <!--  条件项目容器    -->
    <div class="cond-container">
      <el-input
        v-model="srchIp.srchKw"
        clearable
        :placeholder="$t('cmn.plsInputSrchKw')"
        class="kw-input-box"
        style="width: 200px;"
        @input="srch"
      />
      <el-date-picker
        v-model="timeIp.beginMsec"
        type="datetime"
        value-format="timestamp"
        :placeholder="$t('cmn.plsChooseBeginDateTime')"
        align="right"
        size="small"
        :picker-options="beginMsecPickerOptions"
      />
      <el-date-picker
        v-model="timeIp.endMsec"
        type="datetime"
        value-format="timestamp"
        :placeholder="$t('cmn.plsChooseEndDateTime')"
        align="right"
        size="small"
        :picker-options="endMsecPickerOptions"
      />
    </div>
    <el-divider></el-divider>

    <el-timeline>
      <el-timeline-item
        v-for="(item, idx) in pastTimeList"
        :key="idx"
        placement="top"
        :timestamp="formatMsec(item.dayMsec)">
        <el-card>
          <h4>{{ item.dayWords }}</h4>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import TimeUtil from '@/util/TimeUtil'
import UsrzDayRecordApi from '@/api/vojivosvc/toolsubject/UsrzDayRecordApi'

export default {
  name: 'PastTime',
  data () {
    return {
      timeIp: {
        timeKey: null,
        beginMsec: new Date().getTime() - 2 * 24 * 60 * 60 * 1000,
        endMsec: new Date().getTime(),
        includeLower: true,
        includeUpper: false
      },
      srchIp: {
        srchKey: null,
        srchKw: null
      },
      pastTimeList: [],
      beginMsecPickerOptions: {
        disabledDate: (time) => {
          if (this.timeIp.endMsec) {
            const maxBeginDate = new Date(this.timeIp.endMsec)
            maxBeginDate.setDate(maxBeginDate.getDate() - 7)
            // 大于endMsec或者小于最大的beginMsec不可选
            return time.getTime() >= this.timeIp.endMsec || time.getTime() < maxBeginDate.getTime()
          }
        }
      },
      endMsecPickerOptions: {
        disabledDate: (date) => {
          if (this.timeIp.beginMsec) {
            const maxEndDate = new Date(this.timeIp.beginMsec)
            maxEndDate.setDate(maxEndDate.getDate() + 7)
            // 小于beginMsec或者大于最大的endMsec不可选
            return date.getTime() <= this.timeIp.beginMsec || date.getTime() > maxEndDate.getTime()
          }
        }
      }
    }
  },
  created () {
    this.srch()
  },
  methods: {
    srch () {
      const ip = {
        timeIp: this.timeIp,
        srchIp: this.srchIp,
        pageIp: {
          current: 1,
          size: 7
        }
      }
      UsrzDayRecordApi.findByPage(ip)
        .then(result => {
          if (result) {
            this.pastTimeList = result.data
          }
        })
    },
    formatMsec (msec) {
      return TimeUtil.convertMsecToDateTimeWeekStr(msec)
    }
  }
}
</script>

<style scoped>

</style>
