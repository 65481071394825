import resultHttpTool from '@/http/ResultHttpTool'

const API_PREFIX = '/api/usr/emotto'

export function findByPage (findByPageIp) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', formatIp(findByPageIp))
}

function formatIp (findByPageIp) {
  if (findByPageIp === null) {
    findByPageIp = {
      srchIp: null,
      pageIp: {
        current: 1,
        size: 7
      },
      orderBys: [
        {
          key: 'update_msec',
          asc: false
        }
      ],
      timeIp: null
    }
  }
  return findByPageIp
}

export default { findByPage }
