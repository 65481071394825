export default class CheckUtil {
  CheckUtil () {
  }

  static isMeaningless (param) {
    if (param === undefined || param === null || param === '') {
      return true
    }
    if (typeof param === 'string' && param.trim() === '') {
      return true
    }
    return false
  }

  static isMeaningful (param) {
    return !this.isMeaningless(param)
  }
}
