<template>
  <el-container class="home-container">
    <el-header class="home-header">
      <el-row :gutter="10">
        <el-col :span="3">
          <div class="vjv-logo-area">
            <img class="vjv-logo-img" src="../../assets/img/SmileSnowOwl.jpeg">
          </div>
        </el-col>
        <el-col :span="17">
          <el-menu :default-active="$route.path" class="header-left-menu"
                   mode="horizontal"
                   :router="true">
            <el-menu-item index="/myRoom">{{ $t('home.myRoom') }}</el-menu-item>
            <el-menu-item index="/toolHall">{{ $t('home.toolHall') }}</el-menu-item>
            <el-menu-item index="/studyLab">{{ $t('home.studyLab') }}</el-menu-item>
            <el-menu-item index="/ieSpace">{{ $t('home.ieSpace') }}</el-menu-item>
            <el-menu-item index="/magicCorner">{{ $t('home.magicCorner') }}</el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="4">
          <div class="header-right-box">
            <I18nBtn class="i18n-btn"></I18nBtn>
            <el-button circle icon="el-icon-share" @click="toShare"></el-button>
            <el-menu :default-active="$route.path" class="user-menu" mode="horizontal"
                     @select="handleUserMenuSelect" @close="handleUserMenuClose">
              <el-submenu index="/userSub">
                <template slot="title">
                  <div class="user-icon-img-div">
                    <i v-if="!isLogin || !getCurUsr.avatarUrl" class="iconfont vj-icon-user1"></i>
                    <img v-else class="avatar-img" :src="getCurUsr.avatarUrl" alt="Avatar"/>
                  </div>
                </template>
                <el-menu-item index="/perCenter" v-if="isLogin">{{ this.$t('home.perCenter') }}</el-menu-item>
                <el-menu-item index="/logout" v-if="isLogin">{{ this.$t('home.logout') }}</el-menu-item>
                <!--                <el-menu-item index="/deregister" v-if="isLogin">{{ this.$t('home.deregister') }}</el-menu-item>-->
                <el-menu-item index="/login" v-if="!isLogin">{{ this.$t('home.login') }}</el-menu-item>
                <el-menu-item index="/register" v-if="!isLogin">{{ this.$t('home.register') }}</el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </el-col>
      </el-row>
    </el-header>
    <el-container>
      <el-main class="home-main">
        <router-view></router-view>
      </el-main>
      <el-aside width="150px" class="home-aside">
        <div class="vjv-mascot-div">
          <img class="vjv-mascot-img" src="../../assets/img/MascotOwl.jpeg" alt="Mascot">
        </div>
        <div class="vjv-calendar-div">
          <a href="http://www.vojivo.xyz/tool/timingLendar" target="_blank">
            <img class="vjv-calendar-img" src="../../assets/img/VjvCalendar.jpeg" alt="TimingLendar">
          </a>
          <!--          <img class="vjv-calendar-img" src="../../assets/img/VjvCalendar.jpeg" alt="TimingLendar">-->
        </div>
        <div class="vjv-star-prd-div">
          <el-row :gutter="10" v-if="starTools.length>0">
            <el-col :span="12">
              <div class="star-prd-img-name">
                <a class="prd-a" :href="starToolUri(starTools.at(0))" target="_blank">
                  <img class="prd-img" :src="starToolLogo(starTools.at(0))" alt="LOGO">
                </a>
                <span class="prd-text">
                  {{ starTools.at(0).name }}
                </span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="star-prd-img-name">
                <a class="prd-a" :href="starToolUri(starTools.at(1))" target="_blank">
                  <img class="prd-img" :src="starToolLogo(starTools.at(1))" alt="LOGO">
                </a>
                <span class="prd-text">
                  {{ starTools.at(1).name }}
                </span>
              </div>
            </el-col>
          </el-row>
          <div></div>
          <el-row :gutter="10" v-if="starTools.length>0">
            <el-col :span="12">
              <div class="star-prd-img-name">
                <a class="prd-a" :href="starToolUri(starTools.at(2))" target="_blank">
                  <img class="prd-img" :src="starToolLogo(starTools.at(2))" alt="LOGO">
                </a>
                <span class="prd-text">
                  {{ starTools.at(2).name }}
                </span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="star-prd-img-name">
                <a class="prd-a" :href="starToolUri(starTools.at(3))" target="_blank">
                  <img class="prd-img" :src="starToolLogo(starTools.at(3))" alt="LOGO">
                </a>
                <span class="prd-text">
                  {{ starTools.at(3).name }}
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="vjv-star-res-pkg-div" v-if="starResPkg">
          <!--          <el-card class="vjv-star-res-pkg-card">-->
          <!--            <img class="star-res-pkg-img" :src="starResPkg.imgUrl" alt="RES">-->
          <!--            <span>{{ starResPkg.name }}</span>-->
          <!--          </el-card>-->
          <img class="star-res-pkg-img" :src="starResPkg.imgUrl" alt="RES" @click="goToResPkgDetail(starResPkg.id)">
          <span>{{ starResPkg.name }}</span>
        </div>
        <div class="vjv-rcmd-ad-div">
          <div>
            <el-carousel indicator-position="none" height="100px">
              <el-carousel-item v-for="rcmdAd in upperRcmdAds" :key="rcmdAd.id">
                <a :href="rcmdAd.link" target="_blank">
                  <img class="rcmd-ad-img" :src="rcmdAd.imgUrl" alt="IMG"/>
                </a>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div>
            <el-carousel indicator-position="none" height="100px">
              <el-carousel-item v-for="rcmdAd in lowerRcmdAds" :key="rcmdAd.id">
                <a :href="rcmdAd.link" target="_blank">
                  <el-image class="rcmd-ad-img" :src="rcmdAd.imgUrl"></el-image>
                </a>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </el-aside>
    </el-container>
    <div>
      <el-dialog
        :visible.sync="enableShareDialog"
        :title="$t('share.ifrComm')"
        width="660px"
      >
        <div>
          <div class="title-div">{{ $t('share.invitation') }}</div>
          <div class="tip-div">
            {{ $t('share.invitationTip') }}
          </div>
          <div class="invitation-div">
            <el-form
              ref="ifrFormRef"
              :inline="true"
              :model="ifrForm"
              :rules="ifrFormRules"
              size="small"
            >
              <el-form-item :label="$t('share.invitationLink')" prop="invitationLink">
                <el-input v-model="invitation.invitationLink" disabled style="width: 400px;"></el-input>
                <el-button style="margin-left: 5px" @click="copyInvitationLink">{{ $t('cmn.copy') }}</el-button>
              </el-form-item>
              <el-form-item :label="$t('share.invitationWords')" prop="invitationWords">
                <el-input v-model="ifrForm.invitationWords" type="textarea" style="width: 400px;"
                          :autosize="{ minRows: 1, maxRows: 6 }"></el-input>
                <el-button style="margin-left: 5px" @click="saveIfr">{{ $t('cmn.save') }}</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="title-div">{{ $t('share.commGroup') }}</div>
          <div class="tip-div">
            {{ $t('share.commTip') }}
          </div>
          <div class="comm-div">
            <div class="qq-group-div">
              <img class="comm-group-img" :src="comm.qqGroupImgBase64">
              <div style="margin-left: 30px">{{ $t('share.qqGroup') }}：{{ comm.qqGroupNumber }}</div>
            </div>
            <div class="wechat-group-div">
              <img class="comm-group-img" :src="comm.wechatImgBase64">
              <div style="margin-left: 60px">{{ $t('share.wechatGroup') }}</div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </el-container>
</template>

<script>
import I18nBtn from '../../components/I18nBtn'
import DashboardApi from '@/api/DashboardApi'
import LoginManager from '@/sys/LoginManager'
import UsrIfrApi from '@/api/usercenter/UsrIfrApi'
import UsrAuthApi from '@/api/UsrAuthApi'
import { mapGetters } from 'vuex'
import { LOCAL_FILE_PREFIX } from '@/constant/CmnBizConst'

export default {
  components: { I18nBtn },
  data () {
    return {
      activeIndex: '',
      isLogin: false,
      starTools: [],
      starResPkg: null,
      upperRcmdAds: [],
      lowerRcmdAds: [],
      enableShareDialog: false,
      ifrForm: {
        id: null,
        invitationWords: null
      },
      ifrFormRules: {},
      comm: {
        qqGroupImgBase64: null,
        qqGroupNumber: null,
        wechatImgBase64: null
      },
      invitation: {
        invitationLink: null,
        invitationCode: null,
        invitationWords: null
      }
    }
  },
  computed: {
    ...mapGetters(['getCurUsr'])
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.initFormRules()
      this.initStarProducts()
      this.initRcmdAds()
      LoginManager.curUsr().then(op => {
        if (op) {
          this.isLogin = true
        }
      })
    },
    initFormRules () {
    },
    starToolUri (starTool) {
      if (starTool) {
        return starTool.uri
      }
      return ''
    },
    starToolLogo (starTool) {
      if (!starTool) {
        return ''
      }
      if (starTool.logoUrl === LOCAL_FILE_PREFIX) {
        return require('@/assets/img/toollogo/' + starTool.unid + '.png')
      } else if (starTool.logoUrl.startsWith(LOCAL_FILE_PREFIX)) {
        return require(starTool.logoUrl.substring(LOCAL_FILE_PREFIX.length))
      } else {
        return starTool.logoUrl
      }
    },
    initStarProducts () {
      DashboardApi.findStarTools().then(rd => {
        if (rd) {
          this.starTools = rd
        }
      })
      DashboardApi.findStarResPkgs().then(rd => {
        if (rd) {
          this.starResPkg = rd[0]
        }
      })
    },
    initRcmdAds () {
      DashboardApi.findRcmdAds('HOME_RIGHT_UPPER')
        .then(rd => {
          this.upperRcmdAds = rd
        })
      DashboardApi.findRcmdAds('HOME_RIGHT_LOWER')
        .then(rd => {
          this.lowerRcmdAds = rd
        })
    },
    goToResPkgDetail (resPkgId) {
      const routeData = this.$router.resolve({
        path: '/resPkgDetail',
        query: {
          resPkgId: resPkgId
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleUserMenuSelect (activeIndex) {
      switch (activeIndex) {
        case '/perCenter':
          this.toUserCenter()
          break
        case '/logout':
          this.toLogout()
          break
        case '/login':
          this.toLogin()
          break
        case '/register':
          this.toRegister()
          break
      }
    },
    handleUserMenuClose (key, keyPath) {
      console.log('user menu clos')
      console.log(key, keyPath)
    },
    toLogin () {
      LoginManager.logout()
      this.$router.push('/login')
    },
    toLogout () {
      UsrAuthApi.logout().then(op => {
        if (op) {
          LoginManager.logout()
          this.$router.push('/login')
        }
      })
    },
    toRegister () {
      this.$router.push('/register')
    },
    toUserCenter () {
      this.$router.push('/perCenter')
        .catch(err => err)
    },
    toShare () {
      this.enableShareDialog = true
      UsrIfrApi.findOrAdd().then(rd => {
        if (rd) {
          this.invitation.invitationCode = rd.invitationCode
          this.invitation.invitationLink = 'https://vojivo.xyz/register?invitationCode=' + rd.invitationCode
          this.invitation.invitationWords = rd.invitationWords
          this.ifrForm.id = rd.id
          this.ifrForm.invitationWords = rd.invitationWords
        }
      })
      DashboardApi.findCommInfo().then(rd => {
        if (rd) {
          this.comm.qqGroupImgBase64 = rd.qqGroupImgBase64
          this.comm.qqGroupNumber = rd.qqGroupNumber
          this.comm.wechatImgBase64 = rd.wechatImgBase64
        }
      })
    },
    copyInvitationLink () {
      navigator.clipboard.writeText(this.invitation.invitationLink)
        .then(() => this.$message.success(this.$t('cmnBizMsg.copySuccess')))
        .catch(() => this.$message.error(this.$t('cmnBizMsg.copyFail')))
    },
    saveIfr () {
      this.$refs.ifrFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          findField: { id: this.ifrForm.id },
          updateField: { invitationWords: this.ifrForm.invitationWords }
        }
        UsrIfrApi.update(ip)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/Home.scss";
</style>
