<template>
  <el-container>
    <el-aside width="150px">
      <div>
        <tool-shower tool-unid="Timestamp"></tool-shower>
      </div>
      <el-menu :default-active="$route.path" class="el-menu-vertical-demo" router>
        <el-menu-item index="/tool/timestamp/tsConvert">
          <i class="iconfont vj-icon-shijianzhuanhuan"></i>
          <span slot="title">{{ $t('timestamp.tsConvert') }}</span>
        </el-menu-item>
        <el-menu-item index="/tool/timestamp/tsKnowledge">
          <i class="iconfont vj-icon-kepu-"></i>
          <span slot="title">{{ $t('timestamp.tsKnowledge') }}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>

</template>

<script>
import ToolShower from '@/components/ToolShower.vue'

export default {
  name: 'Timestamp',
  components: { ToolShower }
}
</script>

<style lang="less" scoped>
.el-card {
  width: 240px;
  height: 148.32px;
  margin-top: 18px;
}
</style>
