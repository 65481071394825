import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/resPkgOrder'

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export function batchDelete (ip) {
  return resultHttpTool.post(API_PREFIX + '/batchDelete', ip)
    .then(rd => {
      if (rd) {
        ApiMsgBox.delSuccess()
      } else {
        ApiMsgBox.delFailed()
      }
      return rd
    })
}

export default {
  findByPage,
  batchDelete
}
