import { render, staticRenderFns } from "./UNICODE.vue?vue&type=template&id=a882fdc6&scoped=true"
import script from "./UNICODE.vue?vue&type=script&lang=js"
export * from "./UNICODE.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a882fdc6",
  null
  
)

export default component.exports