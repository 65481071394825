<template>
  <el-container>
    <el-aside width="220px">
      <div class="my-room-radio-div">
        <el-radio v-model="myRoomItem" label="FAV_TOOL" @input="onChangeMyRoomItem">{{ this.$t('myRoom.favTool') }}
        </el-radio>
        <el-radio v-model="myRoomItem" label="FAV_RES_PKG" @input="onChangeMyRoomItem">{{ this.$t('myRoom.favRes') }}
        </el-radio>
        <el-radio v-model="myRoomItem" label="PUR_RES_PKG" @input="onChangeMyRoomItem">{{ this.$t('myRoom.purRes') }}
        </el-radio>
      </div>

      <el-divider></el-divider>

      <div v-if="myRoomItem==='FAV_TOOL'">
        <div>
          <span>{{ this.$t('myRoom.toolGroup') }}</span>
          <el-button size="mini"
                     :disabled="checkedToolGroupIds===null || checkedToolGroupIds.length<1"
                     @click="toBatchDelToolGroup" style="float:right;"
                     icon="el-icon-delete" circle></el-button>
          <el-button size="mini" @click="toAddToolGroup" style="float:right;"
                     icon="el-icon-plus" circle></el-button>
        </div>

        <el-input
          v-model="toolGroupSrchKw"
          clearable
          size="small"
          prefix-icon="el-icon-search"
          class="filter-item"
          @input="srchToolGroup"
        />

        <el-divider></el-divider>

        <el-tree
          ref="toolGroupTreeRef"
          :data="toolGroupTrees"
          :props="toolGroupProps"
          node-key="id"
          show-checkbox
          check-strictly
          lazy
          :load="loadUsrzToolGroupTrees"
          @check="handleUsrzToolGroupTreeNodeCheck"
          @node-drop="handleToolTreeNodeDrop"
          draggable>
        <span slot-scope="{ node, data }" style="width:100%;" @mouseenter="mouseenter(data)"
              @mouseleave="mouseleave(data)">
          <span v-if="node.data.isDefault" style="color: #4e6ef2">{{ node.label }}</span>
          <span v-else>{{ node.label }}</span>
          <el-popover
            :ref="'DelPop.' + node.data.id"
            placement="top"
            width="200"
          >
            <p>{{ $t('cmn.confirmDel') }}</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text"
                         @click="$refs['DelPop.' + node.data.id].doClose()">{{ $t('cmn.cancel') }}</el-button>
              <el-button type="primary" size="mini"
                         @click="doDelToolGroup(node.data.id)">{{ $t('cmn.confirm') }}</el-button>
            </div>
             <el-button
               v-show="data.del"
               slot="reference"
               icon="el-icon-delete"
               size="mini"
               style="float:right;"
               circle>
             </el-button>
        </el-popover>
          <el-button v-show="data.del" size="mini" @click="toEditToolGroup(node.data)"
                     style="margin-right: 2px;float:right;" icon="el-icon-edit" circle></el-button>
          <el-button v-show="data.del" size="mini" @click="toAddToolGroup(node.data)"
                     style="margin-right: 2px;float:right;"
                     icon="el-icon-plus" circle></el-button>
        </span>
        </el-tree>
      </div>

      <div v-else>
        <div>
          <span>{{ this.$t('myRoom.resGroup') }}</span>
          <el-button size="mini"
                     :disabled="checkedResPkgGroupIds===null || checkedResPkgGroupIds.length<1"
                     @click="toBatchDelResPkgGroup"
                     style="float:right;"
                     icon="el-icon-delete" circle></el-button>
          <el-button size="mini" @click="toAddResPkgGroup" style="float:right;"
                     icon="el-icon-plus" circle></el-button>
        </div>
        <el-input
          v-model="resPkgGroupSrchKw"
          clearable
          size="small"
          prefix-icon="el-icon-search"
          class="filter-item"
          @input="srchResPkgGroup"
        />

        <el-divider></el-divider>

        <el-tree
          ref="resPkgGroupTreeRef"
          class="group-tree"
          :data="resPkgGroupTrees"
          :props="resPkgGroupProps"
          node-key="id"
          show-checkbox
          lazy
          :load="loadUsrzResPkgGroupTrees"
          @check="handleUsrzResPkgGroupTreeNodeCheck"
          @node-drop="handleResPkgGroupTreeNodeDrop"
          draggable>
        <span class="custom-tree-node" slot-scope="{ node, data }" style="width:100%;" @mouseenter="mouseenter(data)"
              @mouseleave="mouseleave(data)">
          <span v-if="node.data.isDefault" style="color: #4e6ef2">{{ node.label }}</span>
          <span v-else>{{ node.label }}</span>
            <el-popover
              :ref="'DelPop.' + node.data.id"
              placement="top"
              width="200"
            >
            <p>{{ $t('cmn.confirmDel') }}</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text"
                         @click="$refs['DelPop.' + node.data.id].doClose()">{{ $t('cmn.cancel') }}</el-button>
              <el-button type="primary" size="mini"
                         @click="doDelResPkgGroup(node.data.id)">{{ $t('cmn.confirm') }}</el-button>
            </div>
             <el-button
               v-show="data.del"
               slot="reference"
               icon="el-icon-delete"
               size="mini"
               style="float:right;"
               circle>
             </el-button>
        </el-popover>
          <el-button v-show="data.del" size="mini" @click="toEditResPkgGroup(node.data)"
                     style="margin-right: 2px;float:right;" icon="el-icon-edit" circle>
          </el-button>
          <el-button v-show="data.del" size="mini" @click="toAddResPkgGroup(node.data)"
                     style="margin-right: 2px;float:right;"
                     icon="el-icon-plus" circle>
          </el-button>
    </span>
        </el-tree>
      </div>

      <!--      tool group -->
      <div>
        <!--Add Tool Group Form-->
        <el-dialog
          :close-on-click-modal="false"
          :visible.sync="enableAddToolGroupForm"
          :title="$t('cmn.add') + $t('myRoom.toolGroup')"
          append-to-body
          width="500px"
        >
          <el-form
            ref="addToolGroupFormRef"
            :model="addToolGroupForm"
            :rules="addToolGroupFormRules"
            size="small"
            label-width="80px"
          >
            <el-form-item :label="$t('cmn.name')" prop="name">
              <el-input v-model="addToolGroupForm.name"/>
            </el-form-item>
            <el-form-item :label="$t('myRoom.parentGroup')" prop="parentId">
              <treeselect
                v-model="addToolGroupForm.parentId"
                :options="selectToolGroupTrees"
                :normalizer="groupTreeNodeNormalizer"
                :load-options="loadToolGroupTrees"
              />
            </el-form-item>

            <el-form-item :label="$t('cmn.rankValue')" prop="rankValue">
              <el-input v-model="addToolGroupForm.rankValue"/>
            </el-form-item>
            <el-form-item :label="$t('cmn.remark')" prop="remark">
              <el-input v-model="addToolGroupForm.remark"/>
            </el-form-item>
            <div v-if="addToolGroupForm.groupCountOverLimit">
              <span>{{
                  $t('trade.maxCountTip')
                }}: {{ addToolGroupForm.groupMaxCount }}，{{ $t('trade.vojistarCardNoLimitTip') }}</span>
              <el-button type="text" @click="goToPurVojistarCard">
                {{ $t('trade.click2VojistarCard') }}
              </el-button>
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="enableAddToolGroupForm=false">{{ $t('cmn.cancel') }}</el-button>
            <el-button type="primary" :disabled="addToolGroupForm.groupCountOverLimit" @click="doAddToolGroup">
              {{ $t('cmn.confirm') }}
            </el-button>
          </div>
        </el-dialog>
        <!--Edit Tool Group Form-->
        <el-dialog
          :close-on-click-modal="false"
          :v-if="enableEditToolGroupForm"
          :visible.sync="enableEditToolGroupForm"
          :title="$t('cmn.edit') + $t('myRoom.toolGroup')"
          append-to-body
          width="800px"
        >
          <el-form
            ref="editToolGroupFormRef"
            :inline="true"
            :model="editToolGroupForm"
            :rules="editToolGroupFormRules"
            size="small"
            label-width="100px"
          >
            <el-form-item :label="$t('cmn.name')" prop="main.name">
              <el-input v-model="editToolGroupForm.main.name" style="width: 600px;"/>
            </el-form-item>
            <el-form-item :label="$t('myRoom.parentGroup')" prop="main.parentId">
              <treeselect
                v-model="editToolGroupForm.main.parentId"
                :options="selectToolGroupTrees"
                :normalizer="groupTreeNodeNormalizer"
                :load-options="loadToolGroupTrees"
                style="width: 500px;"
              />
            </el-form-item>
            <el-form-item :label="$t('cmn.rankValue')" prop="main.rankValue">
              <el-input v-model="editToolGroupForm.main.rankValue" style="width: 600px;"/>
              <el-checkbox
                v-model="editToolGroupForm.extra.igLessRankValue"
                border
                size="small"
                style="border-color: #E4E7ED"
              />
            </el-form-item>

            <el-form-item :label="$t('cmn.remark')" prop="main.remark">
              <el-input v-model="editToolGroupForm.main.remark" style="width: 600px;"/>
              <el-checkbox
                v-model="editToolGroupForm.extra.igLessRemark"
                border
                size="small"
                style="border-color: #E4E7ED"
              />
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="enableEditToolGroupForm=false">{{ $t('cmn.cancel') }}</el-button>
            <el-button type="primary" @click="doEditToolGroup">{{ $t('cmn.confirm') }}</el-button>
          </div>
        </el-dialog>

        <!-- Batch Del Tool Group Dialog-->
        <el-dialog
          :close-on-click-modal="false"
          :v-if="enableBatchDelToolGroupDialog"
          :visible.sync="enableBatchDelToolGroupDialog"
          :title="$t('cmn.del') + $t('myRoom.toolGroup')"
          append-to-body
          width="400px"
        >
          <span>{{ $t('myRoom.delGroupConfirm') }}</span>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="enableBatchDelToolGroupDialog=false">{{ $t('cmn.cancel') }}</el-button>
            <el-button type="primary" @click="doBatchDelToolGroup">{{ $t('cmn.confirm') }}</el-button>
          </div>
        </el-dialog>
      </div>

      <!--      res pkg group -->
      <div>
        <!--Add Res Pkg Group Form-->
        <el-dialog
          :close-on-click-modal="false"
          :visible.sync="enableAddResPkgGroupForm"
          :title="$t('cmn.add') + $t('myRoom.resGroup')"
          append-to-body
          width="500px"
        >
          <el-form
            ref="addResPkgGroupFormRef"
            :model="addResPkgGroupForm"
            :rules="addResPkgGroupFormRules"
            size="small"
            label-width="80px"
          >
            <el-form-item :label="$t('cmn.name')" prop="name">
              <el-input v-model="addResPkgGroupForm.name"/>
            </el-form-item>
            <el-form-item :label="$t('myRoom.parentGroup')" prop="parentId">
              <treeselect
                v-model="addResPkgGroupForm.parentId"
                :options="selectResPkgGroupTrees"
                :normalizer="groupTreeNodeNormalizer"
                :load-options="loadResPkgGroupTrees"
              />
            </el-form-item>

            <el-form-item :label="$t('cmn.rankValue')" prop="rankValue">
              <el-input v-model="addResPkgGroupForm.rankValue"/>
            </el-form-item>
            <el-form-item :label="$t('cmn.remark')" prop="remark">
              <el-input v-model="addResPkgGroupForm.remark"/>
            </el-form-item>

            <div v-if="addResPkgGroupForm.groupCountOverLimit">
              <span>{{
                  $t('trade.maxCountTip')
                }}: {{ addResPkgGroupForm.groupMaxCount }}，{{ $t('trade.vojistarCardNoLimitTip') }}</span>
              <el-button type="text" @click="goToPurVojistarCard">
                {{ $t('trade.click2VojistarCard') }}
              </el-button>
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="enableAddResPkgGroupForm=false">{{ $t('cmn.cancel') }}</el-button>
            <el-button type="primary" @click="doAddResPkgGroup">{{ $t('cmn.confirm') }}</el-button>
          </div>
        </el-dialog>
        <!--Edit Res Pkg Group Form-->
        <el-dialog
          :close-on-click-modal="false"
          :v-if="enableEditResPkgGroupForm"
          :visible.sync="enableEditResPkgGroupForm"
          :title="$t('cmn.edit') + $t('myRoom.resGroup')"
          append-to-body
          width="800px"
        >
          <el-form
            ref="editResPkgGroupFormRef"
            :inline="true"
            :model="editResPkgGroupForm"
            :rules="editResPkgGroupFormRules"
            size="small"
            label-width="100px"
          >
            <el-form-item :label="$t('cmn.name')" prop="main.name">
              <el-input v-model="editResPkgGroupForm.main.name" style="width: 600px;"/>
            </el-form-item>
            <el-form-item label="父级" prop="main.parentId">
              <treeselect
                v-model="editResPkgGroupForm.parentId"
                :options="selectResPkgGroupTrees"
                :normalizer="groupTreeNodeNormalizer"
                :load-options="loadResPkgGroupTrees"
                style="width: 500px;"
              />
            </el-form-item>

            <el-form-item :label="$t('cmn.rankValue')" prop="main.rankValue">
              <el-input v-model="editResPkgGroupForm.main.rankValue" style="width: 600px;"/>
              <el-checkbox
                v-model="editResPkgGroupForm.extra.igLessRankValue"
                border
                size="small"
                style="border-color: #E4E7ED"
              />
            </el-form-item>
            <el-form-item :label="$t('cmn.remark')" prop="main.remark">
              <el-input v-model="editResPkgGroupForm.main.remark" style="width: 600px;"/>
              <el-checkbox
                v-model="editResPkgGroupForm.extra.igLessRemark"
                border
                size="small"
                style="border-color: #E4E7ED"
              />
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="enableEditResPkgGroupForm=false">{{ $t('cmn.cancel') }}</el-button>
            <el-button type="primary" @click="doEditResPkgGroup">{{ $t('cmn.confirm') }}</el-button>
          </div>
        </el-dialog>

        <!-- Batch Del Res Pkg Group Dialog-->
        <el-dialog
          :close-on-click-modal="false"
          :v-if="enableBatchDelResPkgGroupDialog"
          :visible.sync="enableBatchDelResPkgGroupDialog"
          :title="$t('cmn.del') + $t('myRoom.resGroup')"
          append-to-body
          width="400px"
        >
          <span>{{ $t('myRoom.delGroupConfirm') }}</span>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="enableBatchDelResPkgGroupDialog=false">{{ $t('cmn.cancel') }}</el-button>
            <el-button type="primary" @click="doBatchDelResPkgGroup">{{ $t('cmn.confirm') }}</el-button>
          </div>
        </el-dialog>
      </div>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import UsrzResPkgGroupApi from '@/api/vojivosvc/studylab/UsrzResPkgGroupApi'
import Treeselect, { LOAD_CHILDREN_OPTIONS, LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import UsrzToolGroupApi from '@/api/vojivosvc/toolhall/UsrzToolGroupApi'
import RankValueUtil from '@/util/RankValue'
import ProtRule from '@/constant/ProtRule'
import UsrzBizConfigApi from '@/api/vojivosvc/UsrzBizConfigApi'

export default {
  name: 'MyRoom',
  watch: {
    '$i18n.locale': {
      handler: function (newVal, oldVal) {
        this.initFormRules()
      }
    },
    immediate: true
  },
  components: { Treeselect },
  created () {
    this.initFormRules()
  },
  data () {
    return {
      myRoomItem: 'FAV_TOOL',
      myRoomItems: [
        {
          label: '收藏的工具',
          value: 'FAV_TOOL'
        },
        {
          label: '收藏的资源',
          value: 'FAV_RES_PKG'
        },
        {
          label: '购买的资源',
          value: 'PUR_RES_PKG'
        }
      ],
      toolGroupSrchKw: null,
      resPkgGroupSrchKw: null,
      currentGroupType: 'ToolGroup',
      usrzResPkgRelationType: 'FAV',

      resPkgGroupTrees: [],
      resPkgGroupProps: {
        label: 'name',
        children: 'children',
        hasChildren: true
      },
      selectResPkgGroupTrees: null,
      enableAddResPkgGroupForm: false,
      addResPkgGroupForm: {
        name: null,
        parentId: null,
        rankValue: null,
        remark: null,
        groupCount: 0,
        groupMaxCount: 0,
        groupCountOverLimit: false
      },
      addResPkgGroupFormRules: {},
      enableEditResPkgGroupForm: false,
      editResPkgGroupForm: {
        id: null,
        main: {
          name: null,
          parentId: null,
          rankValue: null,
          remark: null
        },
        extra: {
          igLessRankValue: true,
          igLessRemark: true
        }
      },
      editResPkgGroupFormRules: {},

      enableBatchDelToolGroupDialog: false,
      enableBatchDelResPkgGroupDialog: false,
      toolGroupProps: {
        label: 'name',
        children: 'children',
        hasChildren: true
      },
      toolGroupTrees: [],
      selectToolGroupTrees: null,
      checkedToolGroupIds: [],
      checkedResPkgGroupIds: [],
      enableAddToolGroupForm: false,
      addToolGroupForm: {
        name: null,
        parentId: null,
        rankValue: null,
        remark: null,
        groupCount: 0,
        groupMaxCount: 0,
        groupCountOverLimit: false
      },
      addToolGroupFormRules: {},
      enableEditToolGroupForm: false,
      editToolGroupForm: {
        id: null,
        main: {
          name: null,
          parentId: null,
          rankValue: null,
          remark: null
        },
        extra: {
          igLessParentId: false,
          igLessRankValue: true,
          igLessRemark: true
        }
      },
      editToolGroupFormRules: {}
    }
  },
  methods: {
    initFormRules () {
      const cmnRule = {
        ...ProtRule.cmnBaseName(),
        ...ProtRule.rankValueRemark()
      }
      this.addToolGroupFormRules = {
        ...cmnRule
      }
      this.editToolGroupFormRules = {
        main: {
          ...cmnRule
        }
      }
      this.addResPkgGroupFormRules = {
        ...cmnRule
      }
      this.editResPkgGroupFormRules = {
        main: {
          ...cmnRule
        }
      }
    },
    onChangeMyRoomItem () {
      switch (this.myRoomItem) {
        case 'FAV_TOOL':
          if (this.toolGroupTrees === null || this.toolGroupTrees.length === 0) {
            this.loadUsrzToolGroupTrees(null, null)
          }
          if (this.$refs.toolGroupTreeRef) {
            this.$refs.toolGroupTreeRef.setCheckedKeys(this.checkedToolGroupIds)
          }
          this.$router.push({
            path: '/myRoom/myFavTool',
            query: {
              groupTreeRootIds: this.checkedToolGroupIds
            }
          })
          break
        case 'FAV_RES_PKG':
          if (this.resPkgGroupTrees === null || this.resPkgGroupTrees.length === 0) {
            this.loadUsrzResPkgGroupTrees(null, null)
          }
          if (this.$refs.resPkgGroupTreeRef) {
            this.$refs.resPkgGroupTreeRef.setCheckedKeys(this.checkedResPkgGroupIds)
          }
          this.$router.push({
            path: '/myRoom/myFavResPkg',
            query: {
              groupTreeRootIds: this.checkedResPkgGroupIds
            }
          })
          break
        case 'PUR_RES_PKG':
          if (this.resPkgGroupTrees === null || this.resPkgGroupTrees.length === 0) {
            this.loadUsrzResPkgGroupTrees(null, null)
          }
          if (this.$refs.resPkgGroupTreeRef) {
            this.$refs.resPkgGroupTreeRef.setCheckedKeys(this.checkedResPkgGroupIds)
          }
          this.$router.push({
            path: '/myRoom/myPurResPkg',
            query: {
              groupTreeRootIds: this.checkedResPkgGroupIds
            }
          })
      }
    },

    mouseenter (data) {
      this.$set(data, 'del', true)
    },
    mouseleave (data) {
      this.$set(data, 'del', false)
    },

    goToPurVojistarCard () {
      this.$router.push('/perCenter/vojistarCard')
    },

    // tool group
    handleUsrzToolGroupTreeNodeCheck (data, checkedData) {
      this.checkedToolGroupIds = []
      this.checkedToolGroupIds.push(...checkedData.checkedKeys)
      this.onChangeMyRoomItem()
    },
    toAddToolGroup (treeNodeData) {
      this.enableAddToolGroupForm = true
      if (treeNodeData) {
        this.addToolGroupForm.parentId = treeNodeData.id
      }
      UsrzBizConfigApi.findUsrzBizConfig().then(configOp => {
        if (configOp) {
          this.addToolGroupForm.groupMaxCount = configOp.toolGroupMaxCount
          UsrzToolGroupApi.countTotal().then(op => {
            if (op) {
              this.addToolGroupForm.groupCount = op
              if (this.addToolGroupForm.groupCount >= this.addToolGroupForm.groupMaxCount) {
                this.addToolGroupForm.groupCountOverLimit = true
              }
            }
          })
        }
      })
    },
    async doAddToolGroup () {
      const addRetData = await UsrzToolGroupApi.add({ ...this.addToolGroupForm })
      if (addRetData) {
        this.enableAddToolGroupForm = false
      }
    },
    toEditToolGroup (treeNodeData) {
      this.enableEditToolGroupForm = true
      this.editToolGroupForm.id = treeNodeData.id
      this.editToolGroupForm.main = { ...treeNodeData }
    },
    doEditToolGroup () {
      const ip = {
        findField: {
          id: this.editToolGroupForm.id
        },
        updateField: { ...this.editToolGroupForm.main },
        extraField: { ...this.editToolGroupForm.extra }
      }
      UsrzToolGroupApi.update(ip)
        .then(() => {
          this.enableEditToolGroupForm = false
          this.loadUsrzToolGroupTrees()
        })
    },
    toBatchDelToolGroup (treeNodeData) {
      this.enableBatchDelToolGroupDialog = true
    },
    async doBatchDelToolGroup () {
      const ip = {
        treeRootIds: []
      }
      this.checkedToolGroupIds.forEach(each => ip.treeRootIds.push(each))
      const delRetData = await UsrzToolGroupApi.batchDeleteTree(ip)
      if (delRetData) {
        await this.srchToolGroup()
        this.enableBatchDelToolGroupDialog = false
      }
    },
    async doDelToolGroup (id) {
      const ip = {
        treeRootIds: []
      }
      ip.treeRootIds.push(id)
      const delRetData = await UsrzToolGroupApi.batchDeleteTree(ip)
      if (delRetData) {
        this.$refs['DelPop.' + id].doClose()
        await this.doSrch()
      }
    },
    loadUsrzToolGroupTrees (treeNode, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (treeNode && treeNode.data && treeNode.data.id) {
        findTreesIp.parentIds = [treeNode.data.id]
      }
      setTimeout(() => {
        UsrzToolGroupApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.toolGroupTrees = resultData
            }
          })
      }, 100)
    },
    loadToolGroupTrees ({
      action,
      parentNode,
      callback
    }) {
      if (action === LOAD_ROOT_OPTIONS) {
        const findTreesIp = {
          treeHeight: 1,
          timeIp: null,
          srchIp: null,
          orderBys: [
            {
              key: 'rankValue',
              asc: true
            }
          ]
        }
        UsrzToolGroupApi.findTrees(findTreesIp).then(data => {
          this.selectToolGroupTrees = data
          for (const group of this.selectToolGroupTrees) {
            group.children = null
          }
          setTimeout(() => {
            callback()
          }, 100)
        })
      }
      if (action === LOAD_CHILDREN_OPTIONS) {
        const findTreesIp = {
          parentIds: [],
          treeHeight: 1,
          timeIp: null,
          srchIp: null,
          orderBys: [
            {
              key: 'rankValue',
              asc: true
            }
          ]
        }
        if (parentNode.id != null) {
          findTreesIp.parentIds.push(parentNode.id)
        }
        UsrzToolGroupApi.findTrees(findTreesIp).then(data => {
          parentNode.children = []
          for (const child of data) {
            child.children = null
            parentNode.children.push({
              ...child
            })
          }
          setTimeout(() => {
            callback()
          }, 100)
        })
      }
    },
    groupTreeNodeNormalizer (group) {
      return {
        id: group.id,
        label: group.name,
        children: group.children,
        hasChildren: true
      }
    },
    async srchToolGroup () {
      if (this.toolGroupSrchKw === null || this.toolGroupSrchKw.trim() === '') {
        const findTreesIp = {
          srchIp: null,
          timeIp: null,
          orderBys: [
            {
              key: 'rankValue',
              asc: true
            }
          ],
          parentIds: [],
          treeHeight: 1
        }
        this.toolGroupTrees = (await UsrzToolGroupApi.findTrees(findTreesIp))
        return
      }
      const ip = {
        srchIp: {
          srchKey: null,
          srchKw: this.toolGroupSrchKw
        },
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        pageIp: {
          current: 1,
          size: 100
        }
      }
      this.toolGroupTrees = (await UsrzToolGroupApi.findByPage(ip)).data
    },
    handleToolTreeNodeDrop (beDragNode, enterNode, position, event) {
      switch (position) {
        case 'inner':
          this.changeToolGroupParent(beDragNode.data.id, enterNode.data.id)
          break
        case 'before':
          this.toChangeToolGroupRank(beDragNode, enterNode, false)
          break
        case 'after':
          this.toChangeToolGroupRank(beDragNode, enterNode, true)
          break
      }
    },
    toChangeToolGroupRank (beDragNode, enterNode, isLastOne) {
      if (isLastOne) {
        this.doChangeToolGroupRank(beDragNode.data.id, RankValueUtil.genNext(enterNode.data.rankValue))
      } else {
        const prevRankValue = this.getEnterNodePrevNodeRankValue(enterNode)
        if (prevRankValue === null || prevRankValue === undefined) {
          this.doChangeToolGroupRank(beDragNode.data.id, RankValueUtil.genPre(enterNode.data.rankValue))
        } else {
          const nextRankValue = enterNode.data.rankValue
          this.doChangeToolGroupRank(beDragNode.data.id, RankValueUtil.genMiddle(prevRankValue, nextRankValue))
          if (prevRankValue === nextRankValue) {
            this.toolGroupReRank(enterNode.data.parentId)
          }
        }
      }
      if (beDragNode.data.parentId !== enterNode.data.parentId) {
        this.changeToolGroupParent(beDragNode.data.id, enterNode.data.parentId)
      }
    },
    toolGroupReRank (parentId) {
      const ip = {
        parentId: parentId
      }
      UsrzToolGroupApi.reRank(ip)
    },
    doChangeToolGroupRank (id, newRankValue) {
      const ip = {
        id: id,
        rankValue: newRankValue
      }
      UsrzToolGroupApi.changeRankValue(ip)
    },
    getEnterNodePrevNodeRankValue (enterNode) {
      if (enterNode.parent === null || enterNode.parent.data.name === undefined) {
        for (let i = 0; i < this.toolGroupTrees.length; i++) {
          if (this.toolGroupTrees.at(i).name === enterNode.data.name) {
            if (i === 0) {
              return null
            } else {
              return this.toolGroupTrees.at(i - 2).rankValue
            }
          }
        }
        return null
      } else {
        const children = enterNode.parent.childNodes
        for (let i = 0; i < children.length; i++) {
          if (children.at(i).data.name === enterNode.data.name) {
            if (i === 0) {
              return null
            } else {
              return children.at(i - 2).data.rankValue
            }
          }
        }
        return null
      }
    },
    changeToolGroupParent (id, parentId) {
      const ip = {
        id: id,
        parentId: parentId
      }
      UsrzToolGroupApi.changeParent(ip)
    },

    // res pkg group
    handleUsrzResPkgGroupTreeNodeCheck (data, checkedData) {
      this.checkedResPkgGroupIds = []
      this.checkedResPkgGroupIds.push(...checkedData.checkedKeys)
      this.onChangeMyRoomItem()
    },
    toAddResPkgGroup (treeNodeData) {
      this.enableAddResPkgGroupForm = true
      this.addResPkgGroupForm.parentId = treeNodeData.id

      UsrzBizConfigApi.findUsrzBizConfig().then(configOp => {
        if (configOp) {
          this.addResPkgGroupForm.groupMaxCount = configOp.resPkgGroupMaxCount
          UsrzResPkgGroupApi.countTotal().then(op => {
            if (op) {
              this.addResPkgGroupForm.groupCount = op
              if (this.addResPkgGroupForm.groupCount >= this.addResPkgGroupForm.groupMaxCount) {
                this.addResPkgGroupForm.groupCountOverLimit = true
              }
            }
          })
        }
      })
    },
    doAddResPkgGroup () {
      UsrzResPkgGroupApi.add({ ...this.addResPkgGroupForm })
        .then(() => {
          this.enableAddResPkgGroupForm = false
        })
    },
    toEditResPkgGroup (treeNodeData) {
      this.enableEditResPkgGroupForm = true
      this.editResPkgGroupForm.id = treeNodeData.id
      this.editResPkgGroupForm.main = { ...treeNodeData }
    },
    doEditResPkgGroup () {
      UsrzResPkgGroupApi.update({ ...this.editResPkgGroupForm })
        .then(() => {
          this.enableEditResPkgGroupForm = false
        })
    },
    toBatchDelResPkgGroup (treeNodeData) {
      this.enableBatchDelResPkgGroupDialog = true
    },
    async doBatchDelResPkgGroup () {
      const ip = {
        treeRootIds: []
      }
      this.checkedResPkgGroupIds.forEach(each => ip.treeRootIds.push(each))
      const delRetData = await UsrzResPkgGroupApi.batchDeleteTree(ip)
      if (delRetData) {
        await this.srchResPkgGroup()
        this.enableBatchDelResPkgGroupDialog = false
      }
    },

    doDelResPkgGroup (id) {
      const ip = {
        treeRootIds: []
      }
      ip.treeRootIds.push(id)
      UsrzResPkgGroupApi.batchDeleteTree(ip).then(op => {
        if (op) {
          this.$refs['DelPop.' + id].doClose()
          this.srchResPkgGroup()
        }
      })
    },
    loadUsrzResPkgGroupTrees (treeNode, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (treeNode && treeNode.data && treeNode.data.id) {
        findTreesIp.parentIds = [treeNode.data.id]
      }
      setTimeout(() => {
        UsrzResPkgGroupApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.resPkgGroupTrees = resultData
            }
          })
      }, 100)
    },
    loadResPkgGroupTrees ({
      action,
      parentNode,
      callback
    }) {
      if (action === LOAD_ROOT_OPTIONS) {
        const findTreesIp = {
          parentIds: [],
          treeHeight: 1,
          timeIp: null,
          srchIp: null,
          orderBys: [
            {
              key: 'rankValue',
              asc: true
            }
          ]
        }
        UsrzResPkgGroupApi.findTrees(findTreesIp).then(data => {
          this.selectResPkgGroupTrees = data
          for (const group of this.selectResPkgGroupTrees) {
            group.children = null
          }
          setTimeout(() => {
            callback()
          }, 100)
        })
      }
      if (action === LOAD_CHILDREN_OPTIONS) {
        const findTreesIp = {
          parentIds: [],
          treeHeight: 1,
          timeIp: null,
          srchIp: null,
          orderBys: [
            {
              key: 'rankValue',
              asc: true
            }
          ]
        }
        if (parentNode.id != null) {
          findTreesIp.parentIds.push(parentNode.id)
        }
        UsrzResPkgGroupApi.findTrees(findTreesIp).then(data => {
          parentNode.children = []
          for (const child of data) {
            child.children = null
            parentNode.children.push({
              ...child
            })
          }
          setTimeout(() => {
            callback()
          }, 100)
        })
      }
    },
    async srchResPkgGroup () {
      if (this.resPkgGroupSrchKw === null || this.resPkgGroupSrchKw.trim() === '') {
        const findTreesIp = {
          srchIp: null,
          timeIp: null,
          orderBys: [
            {
              key: 'rankValue',
              asc: true
            }
          ],
          parentIds: [],
          treeHeight: 1
        }
        this.resPkgGroupTrees = (await UsrzResPkgGroupApi.findTrees(findTreesIp))
        return
      }
      const ip = {
        srchIp: {
          srchKey: null,
          srchKw: this.resPkgGroupSrchKw
        },
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        pageIp: {
          current: 1,
          size: 100
        }
      }
      this.resPkgGroupTrees = (await UsrzResPkgGroupApi.findByPage(ip)).data
    },
    handleResPkgGroupTreeNodeDrop (beDragNode, enterNode, position, event) {
      switch (position) {
        case 'inner':
          this.changeResPkgGroupParent(beDragNode.data.id, enterNode.data.parentId)
          break
        case 'before':
          this.toChangeResPkgGroupRank(beDragNode, enterNode, false)
          break
        case 'after':
          this.toChangeResPkgGroupRank(beDragNode, enterNode, true)
          break
      }
    },
    toChangeResPkgGroupRank (beDragNode, enterNode, isLastOne) {
      if (isLastOne) {
        this.doChangeResPkgGroupRank(beDragNode.data.id, RankValueUtil.genNext(enterNode.data.rankValue))
      } else {
        const prevRankValue = this.getEnterResPkgGroupTreeNodePrevNodeRankValue(enterNode)
        if (prevRankValue === null || prevRankValue === undefined) {
          this.doChangeResPkgGroupRank(beDragNode.data.id, RankValueUtil.genPre(enterNode.data.rankValue))
        } else {
          const nextRankValue = enterNode.data.rankValue
          this.doChangeResPkgGroupRank(beDragNode.data.id, RankValueUtil.genMiddle(prevRankValue, nextRankValue))
          if (nextRankValue === prevRankValue) {
            this.resPkgGroupReRank(enterNode.data.parentId)
          }
        }
      }
      if (beDragNode.data.parentId !== enterNode.data.parentId) {
        this.changeResPkgGroupParent(beDragNode.data.id, enterNode.data.parentId)
      }
    },

    resPkgGroupReRank (parentId) {
      const ip = {
        parentId: parentId
      }
      UsrzResPkgGroupApi.reRank(ip)
    },

    doChangeResPkgGroupRank (id, newRankValue) {
      const ip = {
        id: id,
        rankValue: newRankValue
      }
      UsrzResPkgGroupApi.changeRankValue(ip)
    },
    getEnterResPkgGroupTreeNodePrevNodeRankValue (enterNode) {
      if (enterNode.parent === null || enterNode.parent.data.name === undefined) {
        for (let i = 0; i < this.resPkgGroupTrees.length; i++) {
          if (this.resPkgGroupTrees.at(i).name === enterNode.data.name) {
            if (i < 2) {
              return null
            } else {
              return this.resPkgGroupTrees.at(i - 2).rankValue
            }
          }
        }
        return null
      } else {
        const children = enterNode.parent.childNodes
        for (let i = 0; i < children.length; i++) {
          if (children.at(i).data.name === enterNode.data.name) {
            if (i < 2) {
              return null
            } else {
              return children.at(i - 2).data.rankValue
            }
          }
        }
        return null
      }
    },
    changeResPkgGroupParent (id, parentId) {
      const ip = {
        id: id,
        parentId: parentId
      }
      UsrzResPkgGroupApi.changeParent(ip)
    }
  }

}
</script>
