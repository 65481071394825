<template>
  <el-container>
    <el-header>
      <div class="geo-btn-div">
        <div class="geo-btn-item">
          <el-button @click="locDialogVisible=true"
                     class="iconfont vj-icon-saomiaoquerenbiaoji">
            {{ this.$t('geoLonLat.recognize') }}
          </el-button>
        </div>
        <div class="geo-btn-item">
          <el-button @click="calDistance"
                     class="iconfont vj-icon-juliliangce"
          >
            {{ this.$t('geoLonLat.cal') }}
          </el-button>
        </div>
        <div class="geo-btn-item">
          <el-button @click="clearTableData"
                     class="iconfont vj-icon-qingkong"
          >{{ this.$t('geoLonLat.clear') }}
          </el-button>
        </div>
        <div class="geo-btn-item">
          <el-button @click="toSave(true)"
                     class="iconfont vj-icon-baocunzuobiaodian"
          >{{ this.$t('geoLonLat.saveAsNew') }}
          </el-button>
        </div>
        <div class="geo-btn-item">
          <el-button @click="toSave(false)"
                     :disabled="geoRecordId===null"
                     class="iconfont vj-icon-baocunzuobiaodian"
          >{{ this.$t('geoLonLat.update') }}
          </el-button>
        </div>
        <div class="geo-btn-item">
          <el-button @click="convert2Json"
                     class="iconfont vj-icon-jsonjiexi"
          >{{ this.$t('geoLonLat.toJson') }}
          </el-button>
        </div>
      </div>
    </el-header>
    <el-main>
      <el-table :data="tableData" border stripe style="width: 100%;" max-height="500px" @header-click="onHeaderClick">
        <el-table-column
          :label="$t('geoLonLat.number')"
          type="index"
          width="100">
        </el-table-column>
        <el-table-column prop="locA" :label="$t('geoLonLat.locA')" width="300">
          <template slot-scope="scope">
            <el-row :gutter="2">
              <el-col :span="22">
                <el-input v-model="scope.row.locA.lon" @input="validateLon(scope.row.locA)">
                  <template slot="prepend">经度(lon)</template>
                  {{ scope.row.locA.lon }}
                </el-input>
                <el-input v-model="scope.row.locA.lat" @input="validateLat(scope.row.locA)">
                  <template slot="prepend">纬度 (lat)</template>
                  {{ scope.row.locA.lat }}
                </el-input>
              </el-col>
              <el-col :span="2">
                <div>
                  <el-button type="text" size="medium" @click="exchangeLonLat(scope.row.locA)">
                    <i class="el-icon-sort" aria-hidden="true"></i>
                  </el-button>
                </div>
                <div>
                  <el-button type="text" size="medium" @click="reIdentify(scope.row.locA)">
                    <i class="iconfont vj-icon-saomiaoquerenbiaoji" aria-hidden="true"></i>
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="locB" :label="$t('geoLonLat.locB')" width="300">
          <template slot-scope="scope">
            <el-row :gutter="2">
              <el-col :span="22">
                <el-input v-model="scope.row.locB.lon" @input="validateLon(scope.row.locB)">
                  <template slot="prepend">经度(lon)</template>
                  {{ scope.row.locB.lon }}
                </el-input>
                <el-input v-model="scope.row.locB.lat" @input="validateLat(scope.row.locB)">
                  <template slot="prepend">纬度 (lat)</template>
                  {{ scope.row.locB.lat }}
                </el-input>
              </el-col>
              <el-col :span="2">
                <div>
                  <el-button type="text" size="medium" @click="exchangeLonLat(scope.row.locB)">
                    <i class="el-icon-sort" aria-hidden="true"></i>
                  </el-button>
                </div>
                <div>
                  <el-button type="text" size="medium" @click="reIdentify(scope.row.locB)">
                    <i class="iconfont vj-icon-saomiaoquerenbiaoji" aria-hidden="true"></i>
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="disMeter" :label="$t('geoLonLat.distance') + '(' + $t('geoLonLat.unit')+': m)'"
                         width="150">
        </el-table-column>
        <el-table-column :label="$t('cmn.opn')">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click="calData(scope.row)">
              <i class="iconfont vj-icon-juliliangce" aria-hidden="true"></i>
            </el-button>
            <el-button type="text" size="medium" @click="deleteData(scope.row,scope.$index)">
              <i class="el-icon-delete" aria-hidden="true"></i>
            </el-button>
            <el-button type="text" size="medium" @click="addData(scope.row,scope.$index)">
              <i class="el-icon-circle-plus-outline" aria-hidden="true"></i>
            </el-button>
            <el-button type="text" size="medium" @click="copyData(scope.row,scope.$index)">
              <i class="el-icon-bottom" aria-hidden="true"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div>
        <el-dialog :title="$t('geoLonLat.recognizeLonLat')" :visible.sync="locDialogVisible">
          <el-row :gutter="24">
            <el-col :span="12">
              <h3>{{ $t('geoLonLat.locA') }}</h3>
              <el-input type="textarea" v-model="inputLocATextArea" rows="10">
              </el-input>
            </el-col>
            <el-col :span="12">
              <h3>{{ $t('geoLonLat.locB') }}</h3>
              <el-input type="textarea" v-model="inputLocBTextArea" rows="10">
              </el-input>
            </el-col>
          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button @click="clearTextArea">{{ $t('geoLonLat.clear') }}</el-button>
            <el-button @click="cancelAutoIdentifyPoint">{{ $t('cmn.cancel') }}</el-button>
            <el-button type="primary" @click="confirmAutoIdentifyPoint">{{ $t('cmn.confirm') }}
            </el-button>
          </div>
        </el-dialog>
        <el-dialog :title="$t('geoLonLat.lonLatText')" :visible.sync="locTextDialogVisible">
          <el-row :gutter="24">
            <el-col :span="12">
              <h3>{{ $t('geoLonLat.locA') }}</h3>
              <el-input type="textarea" v-model="locATextArea" rows="10">
              </el-input>
            </el-col>
            <el-col :span="12">
              <h3>{{ $t('geoLonLat.locB') }}</h3>
              <el-input type="textarea" v-model="locBTextArea" rows="10">
              </el-input>
            </el-col>
          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="toLonLatJson">LonLatJson</el-button>
            <el-button type="primary" @click="toCoordinatesJson">CoordinatesJson</el-button>
            <el-button type="primary" @click="toLonLatText">LonLatText</el-button>
            <el-button @click="locTextDialogVisible=false">{{ $t('cmn.cancel') }}</el-button>
          </div>
        </el-dialog>
        <!-- Save Form-->
        <el-dialog
          :close-on-click-modal="false"
          :visible.sync="enableSaveForm"
          :title="$t('cmn.save')"
        >
          <el-form
            ref="saveFormRef"
            :inline="true"
            :model="saveForm"
            :rules="saveFormRules"
            label-width="150px"
          >
            <el-form-item :label="$t('cmn.name')" prop="recordName">
              <div class="geo-save-input-div">
                <el-input v-model="saveForm.recordName"/>
              </div>
            </el-form-item>
            <el-form-item :label="$t('geoLonLat.inputTextA')" prop="inputTextA">
              <div class="geo-save-input-div">
                <el-input type="textarea" v-model="saveForm.inputTextA" rows="3" disabled/>
              </div>
            </el-form-item>

            <el-form-item :label="$t('geoLonLat.inputTextB')" prop="inputTextB">
              <div class="geo-save-input-div">
                <el-input type="textarea" v-model="saveForm.inputTextB" rows="3" disabled/>
              </div>
            </el-form-item>

            <el-form-item :label="$t('geoLonLat.geoResultsJson')" prop="geoResultsJson">
              <div class="geo-save-input-div">
                <el-input type="textarea" v-model="saveForm.geoResultsJson" rows="5" disabled/>
              </div>
            </el-form-item>

            <div v-if="saveForm.geoRecordCountOverLimit">
              <span>{{
                  $t('trade.maxCountTip')
                }}: {{ saveForm.geoRecordMaxCount }}，{{ $t('trade.vojistarCardNoLimitTip') }}</span>
              <el-button type="text" @click="goToPurVojistarCard">
                {{ $t('trade.click2VojistarCard') }}
              </el-button>
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="enableSaveForm=false">{{ $t('cmn.cancel') }}</el-button>
            <el-button type="primary" :disabled="saveForm.geoRecordCountOverLimit"
                       @click="doSave">
              {{
                $t('cmn.confirm')
              }}
            </el-button>
          </div>
        </el-dialog>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import LonLatUtil from '../../../util/LonLatUtil'
import GeoRecordApi from '@/api/vojivosvc/toolsubject/GeoRecordApi'
import ProtRule from '@/constant/ProtRule'
import StringUtil from '@/util/StringUtil'
import UsrzBizConfigApi from '@/api/vojivosvc/UsrzBizConfigApi'

export default {
  name: 'GeoCal',
  created () {
    this.init()
  },
  watch: {
    tableData: {
      handler: function (newVal, oldVal) {
        this.tableData = newVal
        this.calDistance()
      },
      deep: true
    }
  },
  data () {
    return {
      geoRecordId: null,
      geoRecordName: null,
      tableData: [
        {
          locA: {
            lon: '-67.814676091075',
            lat: '-9.9809890539501'
          },
          locB: {
            lon: '-67.822078',
            lat: '-9.976536'
          },
          disMeter: '950.9'
        }
      ],
      locDialogVisible: false,
      currentLoc: '',
      inputLocATextArea: '',
      inputLocBTextArea: '',

      // 格式转换显示
      locTextDialogVisible: false,
      locATextArea: '',
      locBTextArea: '',
      enableSaveForm: false,
      saveForm: {
        asNew: false,
        id: null,
        recordName: null,
        inputTextA: null,
        inputTextB: null,
        geoResults: [
          {
            locA: {
              lon: null,
              lat: null
            },
            locB: {
              lon: null,
              lat: null
            },
            disMeter: null
          }
        ],
        geoResultsJson: null,

        geoRecordCount: 0,
        geoRecordMaxCount: 0,
        geoRecordCountOverLimit: false
      },
      saveFormRules: {
        recordName: [
          {
            min: 1,
            max: 32,
            message: this.$t('protMsg.lengthRange') + '[1,32]',
            trigger: 'blur'
          },
          ProtRule.cmnRule().notWhite
        ],
        inputTextA: [
          {
            min: 1,
            max: 4096,
            message: this.$t('protMsg.lengthRange') + '[1,4096]',
            trigger: 'blur'
          },
          ProtRule.cmnRule().notWhite
        ],
        inputTextB: [
          {
            min: 1,
            max: 4096,
            message: this.$t('protMsg.lengthRange') + '[1,4096]',
            trigger: 'blur'
          },
          ProtRule.cmnRule().notWhite
        ]
      }
    }
  },
  methods: {
    init () {
      if (this.$route.query) {
        const recordId = this.$route.query.geoRecordId
        if (recordId !== undefined && recordId !== null && recordId !== '') {
          this.geoRecordId = recordId
        }
      }
      if (this.geoRecordId) {
        GeoRecordApi.findDetail(this.geoRecordId)
          .then(rt => {
            this.tableData = rt.geoResults
            this.geoRecordName = rt.recordName
            this.inputLocATextArea = rt.inputTextA
            this.inputLocBTextArea = rt.inputTextB
          })
      }
    },
    validateLon (loc) {
      if (loc.lon === '-' || loc.lon.endsWith('.')) {
        return
      }
      const value = parseFloat(loc.lon)
      if (!StringUtil.isNumber(loc.lon)) {
        loc.lon = ''
      } else if (value < -180) {
        loc.lon = '-180'
      } else if (value > 180) {
        loc.lon = '180'
      }
    },

    validateLat (loc) {
      if (loc.lat === '-' || loc.lat.endsWith('.')) {
        return
      }
      const value = parseFloat(loc.lat)
      if (!StringUtil.isNumber(loc.lat)) {
        loc.lat = ''
      } else if (value < -90) {
        loc.lat = '-90'
      } else if (value > 90) {
        loc.lat = '90'
      }
    },
    // 添加
    addData (row, index) {
      if (this.tableData.length >= 20) {
        return
      }
      this.tableData.splice(index + 1, 0, {
        locA: {
          lon: '',
          lat: ''
        },
        locB: {
          lon: '',
          lat: ''
        },
        disMeter: ''
      })
    },
    copyData (row, index) {
      this.tableData.splice(index + 1, 0, {
        locA: {
          lon: this.tableData[index].locA.lon,
          lat: this.tableData[index].locA.lat
        },
        locB: {
          lon: this.tableData[index].locB.lon,
          lat: this.tableData[index].locB.lat
        },
        disMeter: this.tableData[index].disMeter
      })
    },
    calData (row) {
      if (row.locA && row.locB && row.locA.lon && row.locA.lat && row.locB.lon && row.locB.lat) {
        row.disMeter = this.calDisMeter(row.locA.lon, row.locA.lat, row.locB.lon, row.locB.lat)
      } else {
        row.disMeter = null
      }
    },
    deleteData (row, index) {
      if (this.tableData.length > 1) {
        this.tableData.splice(index, 1)
      } else {
        this.clearTableData()
      }
    },
    onHeaderClick (column, event) {
      console.log('column', column)
      console.log('event', event)
      this.currentLoc = column.property
      this.locDialogVisible = true
      // switch (column.property) {
      //   case 'locA':
      //     this.onlocAHeaderClick()
      //     break
      //   case 'locB':
      //     break
      //   default:
      //     break
    },
    clearTextArea () {
      this.inputLocATextArea = ''
      this.inputLocBTextArea = ''
    },
    cancelAutoIdentifyPoint () {
      this.currentLoc = ''
      this.locDialogVisible = false
    },
    confirmAutoIdentifyPoint () {
      this.tableData = this.tableData.concat(this.autoIdentifyPoint())
      this.locDialogVisible = false
    },
    autoIdentifyPoint () {
      const locAArr = LonLatUtil.identifyLonLatPoint(this.inputLocATextArea)
      const locBArr = LonLatUtil.identifyLonLatPoint(this.inputLocBTextArea)
      const retArr = []
      if (locAArr.length >= locBArr.length) {
        for (let i = 0; i < locAArr.length; i++) {
          retArr.push({
            locA: this.getPoint(locAArr, i),
            locB: this.getPoint(locBArr, i),
            disMeter: ''
          })
        }
      } else {
        for (let i = 0; i < locBArr.length; i++) {
          retArr.push({
            locA: this.getPoint(locAArr, i),
            locB: this.getPoint(locBArr, i),
            disMeter: ''
          })
        }
      }
      console.log('retArr', retArr)
      return retArr
    },
    getPoint (locArr, idx) {
      if (locArr.length - 1 >= idx) {
        return locArr[idx]
      }
      return {
        lon: '',
        lat: ''
      }
    },
    calDistance () {
      this.tableData.forEach((value, idx) => {
        this.calData(value)
      })
    },
    calDisMeter (lon1, lat1, lon2, lat2) {
      const radLat1 = lat1 * Math.PI / 180.0
      const radLat2 = lat2 * Math.PI / 180.0
      const a = radLat1 - radLat2
      const b = lon1 * Math.PI / 180.0 - lon2 * Math.PI / 180.0
      let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)))
      s = s * 6378.137
      s = Math.round(s * 10000) / 10
      console.log(s)
      return s
    },
    toLonLatText () {
      this.locATextArea = ''
      this.locBTextArea = ''
      this.tableData.forEach((value, idx) => {
        this.locATextArea += (value.locA.lon + ',' + value.locA.lat + '\n')
        this.locBTextArea += (value.locB.lon + ',' + value.locB.lat + '\n')
      })
    },
    convert2Json () {
      this.locTextDialogVisible = true
      this.toLonLatJson()
    },
    toLonLatJson () {
      const locAArr = []
      const locBArr = []
      this.tableData.forEach((value, idx) => {
        locAArr.push({
          lon: value.locA.lon,
          lat: value.locA.lat
        })
        locBArr.push({
          lon: value.locB.lon,
          lat: value.locB.lat
        })
      })
      this.locATextArea = JSON.stringify(locAArr, null, 2)
      this.locBTextArea = JSON.stringify(locBArr, null, 2)
    },
    toCoordinatesJson () {
      const locAArr = []
      const locBArr = []
      this.tableData.forEach((value, idx) => {
        locAArr.push({
          loc: {
            coordinates: [
              value.locA.lon,
              value.locA.lat
            ]
          }
        })
        locBArr.push({
          loc: {
            coordinates: [
              value.locB.lon,
              value.locB.lat
            ]
          }
        })
      })
      this.locATextArea = JSON.stringify(locAArr, null, 2)
      this.locBTextArea = JSON.stringify(locBArr, null, 2)
    },
    exchangeLonLat (point) {
      if (LonLatUtil.isValidLon(point.lat) && LonLatUtil.isValidLat(point.lon)) {
        const temp = point.lon
        point.lon = point.lat
        point.lat = temp
      }
    },
    async reIdentify (point) {
      const data = await this.$prompt('', this.$t('geoLonLat.recognizeLonLat'), {
        confirmButtonText: this.$t('cmn.confirm'),
        cancelButtonText: this.$t('cmn.cancel')
      }).then(({
        value
      }) => {
        const identifiedLonLatArr = LonLatUtil.identifyLonLatPoint(value)
        if (identifiedLonLatArr.length < 1) {
          return point
        }
        return identifiedLonLatArr[0]
      }).catch(() => {
        return point
      })
      console.log('data', data)
      point.lon = data.lon
      point.lat = data.lat
    },
    clearTableData () {
      this.tableData = [
        {
          locA: {
            lon: '',
            lat: ''
          },
          locB: {
            lon: '',
            lat: ''
          },
          disMeter: ''
        }
      ]
    },
    toSave (asNew) {
      this.saveForm.asNew = asNew
      this.enableSaveForm = true
      this.saveForm.recordName = this.geoRecordName
      this.saveForm.inputTextB = this.inputLocBTextArea
      this.saveForm.geoResults = []
      this.tableData.forEach(each => {
        this.saveForm.geoResults.push({
          locA: {
            ...each.locA
          },
          locB: {
            ...each.locB
          },
          disMeter: each.disMeter
        })
      })
      this.saveForm.geoResultsJson = JSON.stringify(this.saveForm.geoResults)
      this.saveForm.id = this.geoRecordId
      this.saveForm.recordName = this.geoRecordName

      if (!this.saveForm.asNew) {
        return
      }

      UsrzBizConfigApi.findUsrzBizConfig().then(configOp => {
        if (configOp) {
          this.saveForm.geoRecordMaxCount = configOp.geoRecordMaxCount
          GeoRecordApi.countTotal().then(op => {
            if (op) {
              this.saveForm.geoRecordCount = op
              if (this.saveForm.geoRecordCount >= this.saveForm.geoRecordMaxCount) {
                this.saveForm.geoRecordCountOverLimit = true
              }
            }
          })
        }
      })
    },
    doSave () {
      if (this.saveForm.asNew) {
        this.addGeoRecord()
      } else {
        this.updateGeoRecord()
      }
    },

    goToPurVojistarCard () {
      this.$router.push('/perCenter/vojistarCard')
    },
    addGeoRecord () {
      const ip = {
        recordName: null,
        inputTextA: null,
        inputTextB: null,
        geoResults: [
          {
            locA: {
              lon: null,
              lat: null
            },
            locB: {
              lon: null,
              lat: null
            },
            disMeter: null
          }
        ]
      }
      ip.recordName = this.saveForm.recordName
      ip.inputTextA = this.saveForm.inputTextA
      ip.inputTextB = this.saveForm.inputTextB
      ip.geoResults = JSON.parse(this.saveForm.geoResultsJson)
      GeoRecordApi.add(ip)
        .then(rt => {
          this.geoRecordId = rt.id
          this.enableSaveForm = false
        })
    },
    updateGeoRecord () {
      const ip = {
        findField: {
          id: this.geoRecordId
        },
        updateField: {
          recordName: this.saveForm.recordName,
          inputTextA: this.saveForm.inputTextA,
          inputTextB: this.saveForm.inputTextB,
          geoResults: JSON.parse(this.saveForm.geoResultsJson)
        }
      }
      GeoRecordApi.update(ip)
        .then(() => {
          this.enableSaveForm = false
        })
    },
    test (scope) {
      console.log(scope)
      console.log(scope.column.label)
    }
  }
}
</script>

<style scoped>

</style>
