<template>
  <div>
    <el-radio-group v-model="storeSeq" size="mini" @input="onStoreReqChange">
      <el-radio-button label="BE">{{ $t('enDecoder.be') }}</el-radio-button>
      <el-radio-button label="LE">{{ $t('enDecoder.le') }}</el-radio-button>
    </el-radio-group>
    <en-decoder-cmpt ref="enDecoderCmpt" :encode-alg="encodeAlg"></en-decoder-cmpt>
  </div>
</template>

<script>
import EnDecoderCmpt from '@/views/tool/a02_endecoder/EnDecoderCmpt.vue'

export default {
  name: 'UTF16',
  components: { EnDecoderCmpt },
  data () {
    return {
      storeSeq: 'BE',
      encodeAlg: 'UTF-16BE'
    }
  },
  methods: {
    onStoreReqChange (selectedValue) {
      if (selectedValue === 'LE') {
        this.encodeAlg = 'UTF-16LE'
      } else {
        this.encodeAlg = 'UTF-16BE'
      }
      this.$refs.enDecoderCmpt.delayExecute()
    }
  }
}
</script>

<style scoped>

</style>
