import CryptoJS from 'crypto-js'
import CRC from 'crc'

export default class HashUtil {
  HashUtil () {
  }

  static hash (str, hashAlg) {
    switch (hashAlg) {
      case 'MD5':
        return this.md5(str)
      case 'SHA1':
        return this.sha1(str)
      case 'SHA256':
        return this.sha256(str)
      case 'SHA512':
        return this.sha512(str)
      case 'CRC32':
        return this.crc32(str)
      default:
        return str
    }
  }

  static md5 (str) {
    return CryptoJS.MD5(str).toString()
  }

  static sha256 (str) {
    return CryptoJS.SHA256(str).toString()
  }

  static sha1 (str) {
    return CryptoJS.SHA1(str).toString()
  }

  static sha512 (str) {
    return CryptoJS.SHA512(str).toString()
  }

  static crc32 (str) {
    return CRC.crc32(str).toString(16)
  }

  static doubleMd5 (str) {
    return this.md5(this.md5(str))
  }
}
