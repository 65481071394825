import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/usrzFavTool'

export function findByPage (findByPageIp) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', formatIp(findByPageIp))
}

export function fav (ip) {
  return resultHttpTool.post(API_PREFIX + '/fav', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function batchFav (ip) {
  return resultHttpTool.post(API_PREFIX + '/batchFav', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function batchCancelFav (ip) {
  return resultHttpTool.post(API_PREFIX + '/batchCancelFav', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

function formatIp (findByPageIp) {
  if (findByPageIp === null) {
    findByPageIp = {
      toolGroupId: null,
      srchIp: null,
      pageIp: {
        current: 1,
        size: 2,
        oopers: []
      }
    }
  }
  return findByPageIp
}

export default {
  fav,
  batchFav,
  batchCancelFav,
  findByPage
}
