<template>
  <div>
    <!--表格渲染-->
    <el-table
      ref="table"
      :data="tableData"
      style="width: 100%;"
      :border="true"
    >
      <el-table-column prop="number" :label="$t('trade.orderNo')" resizable min-width="100px"/>
      <el-table-column
        prop="createMsec"
        :label="$t('trade.placeTime')"
        :formatter="msecFormatter"
        sortable="custom"
        resizable
        width="155px"
      />

      <el-table-column prop="status" :label="$t('trade.orderStatus')" resizable min-width="80px"
                       :formatter="orderStatusFormatter"/>
      <el-table-column prop="price" :label="$t('trade.vjb')" resizable min-width="100px"/>
      <el-table-column
        :label="$t('studyLab.resPkg')"
        width="180">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <div v-for="resPkgKeyInfo in scope.row.resPkgKeyInfos" :key="resPkgKeyInfo.id">
              <p>{{ resPkgKeyInfo.i18nName }}</p>
              <p>{{ resPkgKeyInfo.curPrice }}</p>
            </div>
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.resPkgKeyInfos.length}}</el-tag>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="payWay" :label="$t('trade.payWay')" resizable min-width="100px"
                       :formatter="payWayFormatter"/>
      <el-table-column prop="txnId" :label="$t('trade.txnId')" resizable min-width="100px"/>
      <el-table-column prop="remark" :label="$t('cmn.remark')" resizable min-width="100px"/>
      <el-table-column
        :label="$t('cmn.opn')"
        width="100px"
        align="center"
        fixed="right"
      >
        <template v-slot="scope">
          <!--          <el-button-->
          <!--            size="mini"-->
          <!--            style="margin-right: 3px;"-->
          <!--            type="primary"-->
          <!--            icon="el-icon-edit"-->
          <!--            @click="toEdit(scope.row)"-->
          <!--          />-->
          <el-popover
            :ref="'DelPop.' + scope.row.id"
            placement="top"
            width="200"
          >
            <p>{{ $t('cmn.confirmDel') }}</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs['DelPop.' + scope.row.id].doClose()">
                {{ $t('cmn.cancel') }}
              </el-button>
              <el-button type="primary" size="mini" @click="doDelete(scope.row.id)">{{ $t('cmn.confirm') }}
              </el-button>
            </div>
            <el-button
              slot="reference"
              v-if="canDelOrder(scope.row)"
              type="danger"
              icon="el-icon-delete"
              size="mini"
            />
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <!--分页组件-->
    <el-pagination
      :total="page.total"
      :page-sizes="[5, 10, 20]"
      :page-size.sync="page.size"
      :current-page.sync="page.current"
      layout="total, sizes, prev, pager, next, jumper"
      style="margin-top: 8px;"
      @size-change="handlePageSizeChange"
      @current-change="handleCurrentPageChange"
    />

  </div>
</template>

<script>

import TimeUtil from '@/util/TimeUtil'
import ResPkgOrderApi from '@/api/tradecenter/ResPkgOrderApi'

export default {
  name: 'MyOrder',
  data () {
    return {
      page: {
        total: 0,
        current: 1,
        size: 5
      },
      orderBys: [
        {
          key: 'createMsec',
          asc: false
        }
      ],
      tableData: []
    }
  },
  created () {
    this.doSrch()
  },
  methods: {
    async doSrch () {
      const ip = {
        orderBys: this.orderBys,
        pageIp: {
          current: this.page.current,
          size: this.page.size
        }
      }
      const pageOp = await ResPkgOrderApi.findByPage(ip)
      this.page.total = pageOp.total
      this.tableData = pageOp.data
    },

    handleSortChange (data) {
      const orderBy = {}
      orderBy.key = data.prop
      orderBy.asc = data.order === 'ascending'
      this.orderBys = [
        { ...orderBy }
      ]
      this.doSrch()
    },

    handlePageSizeChange (pageSize) {
      this.page.size = pageSize
      this.doSrch()
    },
    handleCurrentPageChange (currentPage) {
      this.page.current = currentPage
      this.doSrch()
    },

    doDelete (orderId) {
      const ip = {
        ids: [orderId]
      }
      ResPkgOrderApi.batchDelete(ip).then(rd => {
        if (rd) {
          this.$refs['DelPop.' + orderId].doClose()
          this.doSrch()
        }
      })
    },
    canDelOrder (order) {
      return order.status !== 'PAID' && order.status !== 'COMPLETED'
    },
    msecFormatter (row, column, cellValue, index) {
      if (cellValue) {
        return TimeUtil.convertMsecToDateTimeStr(cellValue)
      }
      return null
    },
    orderStatusFormatter (row, column, cellValue, index) {
      return this.$t('trade.' + cellValue)
    },
    payWayFormatter (row, column, cellValue, index) {
      return this.$t('trade.' + cellValue)
    }
  }
}
</script>

<style scoped>

</style>
