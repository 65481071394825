export const CODE_MSG_EN = new Map([
  [400, 'BAD_REQUEST(400)'],
  [401, 'UNAUTHORIZED(401)'],
  [403, 'FORBIDDEN(403)'],
  [404, 'NOT_FOUND(404)'],
  [405, 'METHOD_NOT_ALLOWED(405)'],
  [406, 'NOT_ACCEPTABLE(406)'],
  [409, 'CONFLICT(409)'],
  [412, 'PRECONDITION_FAILED(412)'],
  [415, 'UNSUPPORTED_MEDIA_TYPE(415)'],
  [444, 'PROTOCOL_NOT_MATCH(444)'],
  [500, 'INTERNAL_ERROR(500)'],
  [502, 'GATEWAY_ERROR(502)'],
  [503, 'SERVICE_UNAVAILABLE(503)'],
  [504, 'GATEWAY_TIMEOUT(504)']
])

export const CODE_MSG_ZH = new Map([
  [400, '请求参数错误(400)'],
  [401, '未认证或已失效(401)'],
  [403, '无权限或超限被禁止(403)'],
  [404, '资源未找到(404)'],
  [405, '请求方法不允许(405)'],
  [406, '不接受(406)'],
  [409, '资源冲突(409)'],
  [412, '前置条件不满足(412)'],
  [415, '不支持的媒体类型(415)'],
  [444, '协议不匹配(444)'],
  [500, '服务内部错误(500)'],
  [502, '网关错误(502)'],
  [503, '服务不可用(503)'],
  [504, '网关超时(504)']
])
