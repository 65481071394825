import JsonTool from '../views/tool/a00_json/JsonTool'
import MyVueJsonEditor from '@/views/tool/a00_json/MyVueJsonEditor.vue'
import Timestamp from '../views/tool/a01_timestamp/Timestamp'
import TsConvert from '../views/tool/a01_timestamp/TsConvert.vue'
import TsKnowledge from '../views/tool/a01_timestamp/TsKnowledge'
import EnDecoder from '../views/tool/a02_endecoder/EnDecoder'
import Hash from '../views/tool/a03_hash/Hash'
import GeoLonLat from '../views/tool/a04_geo_lon_lat/GeoLonLat'
import Calendar from '../views/tool/b00_calendar/Calendar'
import Calculator from '../views/tool/b01_calculator/Calculator'
import GenCode from '../views/tool/a05_cocoder/GenCode'
import Emotto from '@/views/tool/b03_emotto/Emotto'
import CoCoder from '@/views/tool/a05_cocoder/CoCoder.vue'
import GeoRecord from '@/views/tool/a04_geo_lon_lat/GeoRecord.vue'
import JsonRecord from '@/views/tool/a00_json/JsonRecord.vue'
import CoCoderProject from '@/views/tool/a05_cocoder/CoCoderProject.vue'
import CoCoderView from '@/views/tool/a05_cocoder/CoCoderView.vue'
import UnitConverter from '@/views/tool/b01_unit_converter/UnitConverter.vue'
import LengthUnit from '@/views/tool/b01_unit_converter/LengthUnit.vue'
import AreaUnit from '@/views/tool/b01_unit_converter/AreaUnit.vue'
import MassUnit from '@/views/tool/b01_unit_converter/MassUnit.vue'
import VolumeUnit from '@/views/tool/b01_unit_converter/VolumeUnit.vue'
import TimeUnit from '@/views/tool/b01_unit_converter/TimeUnit.vue'
import StorageUnit from '@/views/tool/b01_unit_converter/StorageUnit.vue'
import TempUnit from '@/views/tool/b01_unit_converter/TempUnit.vue'
import SpeedUnit from '@/views/tool/b01_unit_converter/SpeedUnit.vue'
import AngularUnit from '@/views/tool/b01_unit_converter/AngularUnit.vue'
import TimingLendar from '@/views/tool/b00_calendar/TimingLendar.vue'
import ComingTime from '@/views/tool/b00_calendar/ComingTime.vue'
import PastTime from '@/views/tool/b00_calendar/PastTime.vue'
import MD5 from '@/views/tool/a03_hash/MD5.vue'
import SHA256 from '@/views/tool/a03_hash/SHA256.vue'
import SHA1 from '@/views/tool/a03_hash/SHA1.vue'
import SHA512 from '@/views/tool/a03_hash/SHA512.vue'
import CRC32 from '@/views/tool/a03_hash/CRC32.vue'
import HashIntro from '@/views/tool/a03_hash/HashIntro.vue'
import BASE64 from '@/views/tool/a02_endecoder/BASE64.vue'
import URL from '@/views/tool/a02_endecoder/URL.vue'
import UTF8 from '@/views/tool/a02_endecoder/UTF8.vue'
import ASCII from '@/views/tool/a02_endecoder/ASCII.vue'
import Chinese from '@/views/tool/a02_endecoder/Chinese.vue'
import Char from '@/views/tool/a02_endecoder/Char.vue'
import UNICODE from '@/views/tool/a02_endecoder/UNICODE.vue'
import GB2312 from '@/views/tool/a02_endecoder/GB2312.vue'
import GBK from '@/views/tool/a02_endecoder/GBK.vue'
import GB18030 from '@/views/tool/a02_endecoder/GB18030.vue'
import UTF16 from '@/views/tool/a02_endecoder/UTF16.vue'
import UTF32 from '@/views/tool/a02_endecoder/UTF32.vue'
import HEX from '@/views/tool/a02_endecoder/HEX.vue'
import LangCorner from '@/views/tool/b02_lang_corner/LangCorner.vue'
import EnDecodeOverview from '@/views/tool/a02_endecoder/EnDecodeOverview.vue'
import UnicodeCharset from '@/views/tool/a02_endecoder/UnicodeCharset.vue'
import JsonOne from '@/views/tool/a00_json/JsonOne.vue'
import GeoCal from '@/views/tool/a04_geo_lon_lat/GeoCal.vue'
import CoCoderExample from '@/views/tool/a05_cocoder/CoCoderExample.vue'

const PREFIX = '/tool'
const toolRoutes = [
  {
    path: PREFIX + '/jsonTool',
    name: 'JsonTool',
    component: JsonTool
  },
  {
    path: PREFIX + '/myVueJsonEditor',
    name: 'MyVueJsonEditor',
    component: MyVueJsonEditor
  },
  {
    path: PREFIX + '/jsonOne',
    name: 'JsonOne',
    component: JsonOne
  },
  {
    path: PREFIX + '/jsonRecord',
    name: 'JsonRecord',
    component: JsonRecord
  },
  {
    path: PREFIX + '/timestamp',
    name: 'Timestamp',
    component: Timestamp,
    redirect: PREFIX + '/timestamp/tsConvert',
    children: [
      {
        path: PREFIX + '/timestamp/tsConvert',
        name: 'SingleTs',
        component: TsConvert
      },
      {
        path: PREFIX + '/timestamp/tsKnowledge',
        name: 'TsKnowledge',
        component: TsKnowledge
      }
    ]
  },
  {
    path: '/tool/enDecoder',
    name: 'EnDecoder',
    component: EnDecoder,
    redirect: '/tool/enDecoder/enDecodeOverview',
    children: [
      {
        path: 'enDecodeOverview',
        name: 'EnDecodeOverview',
        component: EnDecodeOverview
      },
      {
        path: 'base64',
        name: 'BASE64',
        component: BASE64
      },
      {
        path: 'url',
        name: 'URL',
        component: URL
      },
      {
        path: 'hex',
        name: 'HEX',
        component: HEX
      },
      {
        path: 'char',
        name: 'Char',
        component: Char,
        redirect: 'char/ascii',
        children: [
          {
            path: 'ascii',
            name: 'ASCII',
            component: ASCII,
            children: []
          },
          {
            path: 'chinese',
            name: 'Chinese',
            component: Chinese,
            redirect: 'chinese/gb2312',
            children: [
              {
                path: 'gb2312',
                name: 'GB2312',
                component: GB2312,
                children: []
              },
              {
                path: 'gbk',
                name: 'GBK',
                component: GBK,
                children: []
              },
              {
                path: 'gb18030',
                name: 'GB18030',
                component: GB18030,
                children: []
              }
            ]
          },
          {
            path: 'unicode',
            name: 'UNICODE',
            component: UNICODE,
            redirect: 'unicode/utf8',
            children: [
              {
                path: 'utf8',
                name: 'UTF8',
                component: UTF8,
                children: []
              },
              {
                path: 'utf16',
                name: 'UTF16',
                component: UTF16,
                children: []
              },
              {
                path: 'utf32',
                name: 'UTF32',
                component: UTF32,
                children: []
              },
              {
                path: 'unicodeCharset',
                name: 'UnicodeCharset',
                component: UnicodeCharset,
                children: []
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: PREFIX + '/hash',
    name: 'Hash',
    component: Hash,
    redirect: '/tool/hash/hashIntro',
    children: [
      {
        path: 'hashIntro',
        name: 'HashIntro',
        component: HashIntro
      },
      {
        path: 'md5',
        name: 'MD5',
        component: MD5
      },
      {
        path: 'sha256',
        name: 'SHA256',
        component: SHA256
      },
      {
        path: 'sha1',
        name: 'SHA1',
        component: SHA1
      },
      {
        path: 'sha512',
        name: 'SHA512',
        component: SHA512
      },
      {
        path: 'crc32',
        name: 'CRC32',
        component: CRC32
      }
    ]
  },
  {
    path: PREFIX + '/geoLonLat',
    name: 'GeoLonLat',
    component: GeoLonLat,
    redirect: PREFIX + '/geoLonLat/geoCal',
    children: [
      {
        path: PREFIX + '/geoLonLat/geoCal',
        name: 'GeoCal',
        component: GeoCal
      },
      {
        path: PREFIX + '/geoLonLat/geoRecord',
        name: 'GeoRecord',
        component: GeoRecord
      }
    ]
  },
  {
    path: PREFIX + '/coCoder',
    name: 'CoCoder',
    component: CoCoder,
    redirect: PREFIX + '/coCoder/genCode',
    children: [
      {
        path: PREFIX + '/coCoder/genCode',
        name: 'GenCode',
        component: GenCode
      },
      {
        path: PREFIX + '/coCoder/coCoderProject',
        name: 'CoCoderProject',
        component: CoCoderProject
      },
      {
        path: PREFIX + '/coCoder/coCoderView',
        name: 'CoCoderView',
        component: CoCoderView
      },
      {
        path: PREFIX + '/coCoder/coCoderExample',
        name: 'CoCoderExample',
        component: CoCoderExample
      }
    ]
  },
  {
    path: PREFIX + '/timingLendar',
    name: 'TimingLendar',
    component: TimingLendar,
    redirect: PREFIX + '/timingLendar/calendar',
    children: [
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'comingTime',
        name: 'ComingTime',
        component: ComingTime
      },
      {
        path: 'pastTime',
        name: 'PastTime',
        component: PastTime
      }
    ]
  },
  {
    path: PREFIX + '/calculator',
    name: 'Calculator',
    component: Calculator
  },
  {
    path: PREFIX + '/unitConverter',
    name: 'UnitConverter',
    component: UnitConverter,
    redirect: PREFIX + '/unitConverter/timeUnit',
    children: [
      {
        path: PREFIX + '/unitConverter/timeUnit',
        name: 'TimeUnit',
        component: TimeUnit
      },
      {
        path: PREFIX + '/unitConverter/lengthUnit',
        name: 'LengthUnit',
        component: LengthUnit
      },
      {
        path: PREFIX + '/unitConverter/areaUnit',
        name: 'AreaUnit',
        component: AreaUnit
      },
      {
        path: PREFIX + '/unitConverter/volumeUnit',
        name: 'VolumeUnit',
        component: VolumeUnit
      },
      {
        path: PREFIX + '/unitConverter/massUnit',
        name: 'MassUnit',
        component: MassUnit
      },
      {
        path: PREFIX + '/unitConverter/storageUnit',
        name: 'StorageUnit',
        component: StorageUnit
      },
      {
        path: PREFIX + '/unitConverter/tempUnit',
        name: 'TempUnit',
        component: TempUnit
      },
      {
        path: PREFIX + '/unitConverter/speedUnit',
        name: 'SpeedUnit',
        component: SpeedUnit
      },
      {
        path: PREFIX + '/unitConverter/angularUnit',
        name: 'AngularUnit',
        component: AngularUnit
      }
    ]
  },
  {
    path: PREFIX + '/langCorner',
    name: 'LangCorner',
    component: LangCorner
  },
  {
    path: PREFIX + '/emotto',
    name: 'Emotto',
    component: Emotto
  }
]

export default toolRoutes
