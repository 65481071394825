import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/studyLabRes'

export function add (ip) {
  return resultHttpTool.post(API_PREFIX + '/add', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.addSuccess()
      } else {
        ApiMsgBox.addFailed()
      }
      return op
    })
}

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export function findList (ip) {
  return resultHttpTool.post(API_PREFIX + '/findList', ip)
}

export function updateBasicInfo (ip) {
  return resultHttpTool.post(API_PREFIX + '/updateBasicInfo', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.editSuccess()
      } else {
        ApiMsgBox.editFailed()
      }
      return op
    })
}

export function replaceTags (ip) {
  return resultHttpTool.post(API_PREFIX + '/replaceTags', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function batchDelete (idsIp) {
  return resultHttpTool.post(API_PREFIX + '/batchDelete', idsIp)
    .then(op => {
      if (op) {
        ApiMsgBox.delSuccess()
      } else {
        ApiMsgBox.delFailed()
      }
      return op
    })
}

export default {
  add,
  findByPage,
  findList,
  updateBasicInfo,
  replaceTags,
  batchDelete
}
