import resultHttpTool from '@/http/ResultHttpTool'

const API_PREFIX = '/api/usr/enDecoder'

export function charEncode (str, coding) {
  return resultHttpTool({
    method: 'get',
    url: API_PREFIX + '/charEncode',
    params: {
      str: str,
      coding: coding
    }
  })
}

export function charDecode (code, coding) {
  return resultHttpTool.get(API_PREFIX + '/charDecode', {
    params: {
      code: code,
      coding: coding
    }
  })
}

export function toUnicode (str) {
  return resultHttpTool.get(API_PREFIX + '/toUnicode?str=' + str)
}

export function fromUnicode (unicode) {
  return resultHttpTool.get(API_PREFIX + '/fromUnicode?unicode=' + unicode)
}

export default {
  charEncode,
  charDecode,
  toUnicode,
  fromUnicode
}
