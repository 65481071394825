export default {
  cmn: {
    name: '名称',
    rankValue: '排序',
    remark: '备注',
    confirm: '确定',
    cancel: '取消',
    save: '保存',
    saveType: '保存类型',
    new: '新增',
    update: '更新',
    copy: '复制',
    back: '返回',
    add: '添加',
    edit: '编辑',
    del: '删除',
    download: '下载',
    opn: '操作',
    deal: '处理',
    view: '查看',
    recordName: '记录名',
    selectAll: '全选',
    srch: '搜索',
    refresh: '刷新',
    reset: '重置',
    field: '字段',
    srchField: '搜索字段',
    timeType: '时间类型',
    plsInputSrchKw: '请输入搜索关键字',
    plsChooseBeginDateTime: '请选择起始时间',
    plsChooseEndDateTime: '请选择截止时间',
    createTime: '创建时间',
    updateTime: '更新时间',
    beginTime: '起始时间',
    endTime: '截止时间',
    includeUpper: '包含上限',
    includeLower: '包含下限',
    batchFav: '批量收藏',
    fav: '收藏',
    cancelFav: '取消收藏',
    cancelFavConfirm: '确定取消收藏吗？',
    uploadDrag: '拖到此处，或',
    uploadClick: '点击上传',
    fileName: '文件名',
    confirmDel: '确定删除吗？',
    confirmSave: '确定保存吗？',
    checkCurPage: '全选此页',
    cancelCheckAll: '取消全选',
    customName: '自定义名称',
    customProfile: '自定义简介',
    profile: '简介',
    group: '分组',
    groups: '分组列表',
    allGroups: '所有分组',
    belongingGroups: '所属分组',
    tag: '标签',
    tags: '标签列表',
    allTags: '所有标签',
    ctlg: '类目',
    ctlgs: '类目列表',
    allCtlgs: '所有类目',
    plsWaitingFor: '正在快马加鞭开发中 敬请期待~',
    plsJoinUs: '【vojivo@yeah.net】盛情邀您加入我们 共建乌及屋'
  },
  cmnBizMsg: {
    success: '恭喜您成功啦~',
    failed: '非常抱歉 出问题失败了',
    addSuccess: '添加成功',
    addFailed: '添加失败',
    editSuccess: '编辑成功',
    editFailed: '编辑失败',
    delSuccess: '删除成功',
    delFailed: '删除失败',
    opSuccess: '操作成功',
    opFailed: '操作失败',
    copySuccess: '复制成功',
    copyFailed: '复制失败'
  },
  timezone: {
    utcMinus12: {
      displayName: 'UTC-12',
      cityIns: '美国-贝克岛、美国-豪兰岛'
    },
    utcMinus11: {
      displayName: 'UTC-11',
      cityIns: '萨摩亚-阿皮亚'
    },
    utcMinus10: {
      displayName: 'UTC-10',
      cityIns: '美国-夏威夷、库克群岛-阿瓦鲁阿'
    },
    utcMinus9: {
      displayName: 'UTC-9',
      cityIns: '美国-阿拉斯加'
    },
    utcMinus8: {
      displayName: 'UTC-8',
      cityIns: '美国-洛杉矶、美国-旧金山、加拿大-温哥华'
    },
    utcMinus7: {
      displayName: 'UTC-7',
      cityIns: '美国-丹佛、加拿大-卡尔加里'
    },
    utcMinus6: {
      displayName: 'UTC-6',
      cityIns: '美国-芝加哥、加拿大-温尼伯、墨西哥-墨西哥城'
    },
    utcMinus5: {
      displayName: 'UTC-5',
      cityIns: '美国-华盛顿、美国-纽约、加拿大-多伦多'
    },
    utcMinus4: {
      displayName: 'UTC-4',
      cityIns: '加拿大-圣约翰斯、委内瑞拉-加拉加斯'
    },
    utcMinus3: {
      displayName: 'UTC-3',
      cityIns: '巴西-圣保罗、阿根廷-布宜诺斯'
    },
    utcMinus2: {
      displayName: 'UTC-2',
      cityIns: '南乔治亚岛、南桑威奇群岛'
    },
    utcMinus1: {
      displayName: 'UTC-1',
      cityIns: '佛得角-普拉亚、葡萄牙-亚速尔群岛'
    },
    utc0: {
      displayName: 'UTC+0',
      cityIns: '英国-伦敦、爱尔兰-都柏林、葡萄牙-里斯本'
    },
    utc1: {
      displayName: 'UTC+1',
      cityIns: '德国-柏林、法国-巴黎、意大利-罗马'
    },
    utc2: {
      displayName: 'UTC+2',
      cityIns: '希腊-雅典、土耳其-伊斯坦布尔、埃及-开罗'
    },
    utc3: {
      displayName: 'UTC+3',
      cityIns: '俄罗斯-莫斯科、伊拉克-巴格达、沙特阿拉伯-利雅得'
    },
    utc4: {
      displayName: 'UTC+4',
      cityIns: '阿联酋-阿布扎比、阿曼-马斯喀特、乌兹别克斯坦-塔什干'
    },
    utc5: {
      displayName: 'UTC+5',
      cityIns: '巴基斯坦-伊斯兰堡、孟加拉国-达卡、乌兹别克斯坦-塔什干'
    },
    utc6: {
      displayName: 'UTC+6',
      cityIns: '哈萨克斯坦-阿斯塔纳、塔吉克斯坦-杜尚别'
    },
    utc7: {
      displayName: 'UTC+7',
      cityIns: '泰国-曼谷、越南-河内、印尼-雅加达'
    },
    utc8: {
      displayName: 'UTC+8',
      cityIns: '中国-北京、中国-香港、马来西亚-吉隆坡、新加坡-新加坡市'
    },
    utc9: {
      displayName: 'UTC+9',
      cityIns: '日本-东京、韩国-首尔'
    },
    utc10: {
      displayName: 'UTC+10',
      cityIns: '澳大利亚-悉尼、澳大利亚-墨尔本'
    },
    utc11: {
      displayName: 'UTC+11',
      cityIns: '瓦努阿图-维拉港'
    },
    utc12: {
      displayName: 'UTC+12',
      cityIns: '新西兰-奥克兰、新西兰-惠灵顿、斐济-苏瓦'
    },
    utc13: {
      displayName: 'UTC+13',
      cityIns: '汤加-努库阿洛法'
    },
    utc14: {
      displayName: 'UTC+14',
      cityIns: '基里巴斯-塔拉瓦'
    }
  },
  sys: {
    unAuth: '您还未登录或登录已失效，请登录系统',
    activateSuccess: '恭喜您激活成功！欢迎加入乌及屋，快去登录领取奖励吧~',
    timeoutRetry: '非常抱歉！系统超时了，请再重试一次吧'
  },
  deregister: {},
  register: {
    vojivo: '乌及屋',
    login: '登录',
    register: '注册',
    inputVojiUnid: '请输入乌及名',
    inputEmail: '请输入邮箱',
    inputPwd: '请输入密码',
    inputConfirmPwd: '请确认密码',
    inputCaptcha: '请输入验证码',
    registerFailed: '非常抱歉 注册失败了',
    registerSuccess: '恭喜注册成功啦~ 登录邮箱激活账号即可正常使用'
  },
  activate: {
    activateAcct: '激活账号',
    activating: '正在激活中...',
    activateSuccess: '激活成功',
    activateSuccessTips: '快去登录领取奖励吧~~ 点击登录或3秒后自动跳转登录',
    toLogin: '去登录',
    activateFailed: '激活失败',
    activateFailedTips: '非常抱歉 请重试或联系【vojivo@yeah.net】',
    retry: '重试'
  },
  login: {
    inputUnameOrEmail: '请输入乌及名或邮箱',
    inputPwd: '请输入密码',
    inputCaptcha: '请输入验证码',
    login: '登录',
    register: '注册',
    loginFailed: '登录失败',
    loginSuccess: '登录成功',
    wrongCaptcha: '验证码错误',
    rememberMe: '记住我',
    resendEmail: '重发激活账号邮件',
    resendEmailSuccess: '邮件发送成功',
    resendEmailFailed: '邮件发送失败'
  },
  share: {
    ifrComm: '分享与交流',
    invite: ' 邀您加入乌及屋',
    invitation: '邀请函',
    invitationTip: '复制链接分享给朋友，朋友注册成功后您和朋友都将随机获得[99,88,77,66,55,33,22,11]颗乌及豆',
    invitationLink: '链接',
    invitationWords: '致辞',
    commGroup: '交流群',
    commTip: '加入群聊，分享交流，诸多福利等你来~',
    qqGroup: 'QQ群',
    wechatGroup: '微信群',
    rewardAfterActive: '激活后您将随机获得[99,88,77,66,55,33,22,11]颗乌及豆'
  },
  home: {
    myRoom: '我的小窝',
    toolHall: '工具大厅',
    studyLab: '乌及书房',
    magicCorner: '神秘角落',
    ieSpace: '互娱空间',
    perCenter: '个人中心',
    logout: '退出登录',
    login: '登录系统',
    register: '注册账号',
    deregister: '注销账号'
  },
  myRoom: {
    myGroup: '我的分组',
    addGroup: '新增分组',
    editGroup: '编辑分组',
    favTool: '收藏的工具',
    favRes: '收藏的资料',
    purRes: '购买的资料',
    toolGroup: '工具分组',
    resGroup: '资料分组',
    parentGroup: '父级分组',
    setGroup: '设置分组',
    delGroupConfirm: '删除父级分组将会一起删除子级分组，确定删除吗？',
    batchPur: '批量购买'
  },
  toolCmn: {
    inputTextArea: '输入区',
    resultTextArea: '结果',
    toolName: '工具名',
    onlineTime: '上线时间'
  },
  toolHall: {
    toolCategory: '工具目录',
    platformTool: '平台工具',
    thirdPartTool: '三方工具',
    allTool: '所有工具',
    toolList: '工具列表'
  },
  studyLab: {
    resPkgList: '资源包列表',
    resPkgName: '资源包名称',
    resPkg: '资源包',
    viewCtlg: '查看目录',
    ctlg: '目录',
    VJV_SEED: '乌及籽',
    VJV_BUD: '乌及芽',
    VJV_FLOWER: '乌及花',
    VJV_FRUIT: '乌及果',
    IMG: '图片',
    DOC: '文档',
    BOOK: '书籍',
    AUDIO: '音频',
    VIDEO: '视频',
    CODE: '代码',
    SOFTWARE: '软件'
  },
  trade: {
    bePur: '已购',
    purOrder: '下单',
    purPay: '支付',
    orderPay: '下单支付',
    confirmPay: '确认支付',
    pay: '支付',
    order: '订单',
    orderNo: '订单号',
    placeTime: '下单时间',
    orderStatus: '订单状态',
    COMPLETED: '已完成',
    UNPAID: '未支付',
    PAID: '已支付',
    CANCELED: '已取消',
    EXPIRED: '已失效',
    payWay: '支付方式',
    plsUse: '请使用',
    scanToPay: '扫描支付，支付成功后将自动更新页面',
    payQrCode: '支付二维码',
    VJB_WALLET: '乌及豆罐',
    ALI_PAY: '支付宝',
    WECHAT_PAY: '微信支付',
    CASH: '现金',
    BANK: '银行卡',
    CREDIT: '信用卡',
    origPrice: '原价',
    curPrice: '现价',
    orderPrice: '订单金额',
    batchPur: '批量购买',
    allCheckedBePur: '选中的都已经购买，可直接下载~',
    vjbJar: '乌及豆罐',
    walletVjb: '豆罐余额',
    clickReceiveReward: '点击可领取奖励噢~',
    vjbRewardItem: '奖项',
    receive: '领取',
    customTopUp: '自定义充值',
    topUpPkg: '充值套餐',
    topUp: '充值',
    toTopUp: '去充值',
    topUpSuccess: '恭喜，充值成功',
    myTopUp: '我的充值记录',
    topUpTime: '充值时间',
    ccyAmt: '金额',
    txnCcyAmt: '交易金额',
    topUpStatus: '充值状态',
    txnWay: '交易方式',
    SELF: '自助',
    CS: '客服',
    vojistarCard: '乌及星卡',
    myVojistarCard: '我的乌及星卡',
    noVojistarCard: '您还没有乌及星卡，乌及星卡将给您带来更多便利的体验，快去选一张吧~',
    allVojistarCard: '所有乌及星卡',
    purVojistarCard: '进入乌及星',
    upgradeVojistarCard: '升级乌及星卡',
    vojistarCardLevel: '乌及星卡种类',
    maxCountTip: '当前已达最大的数量限制',
    vojistarCardNoLimitTip: '持有乌及星卡后所有数据都不限量！',
    click2VojistarCard: '点击查看乌及星卡~',

    myOrder: '我的订单',
    myBill: '我的账单',
    txnId: '交易ID',
    vjb: '乌及豆',
    txnBiz: '交易业务',
    TOP_UP: '乌及豆充值',
    PUR_RES_PKG: '购买资源',
    PUR_VOJISTAR_CARD: '乌及星卡',
    REG_INVITER: '邀请注册奖励',
    REG_INVITEE: '受邀注册奖励',
    REWARD_REG_INVITER: '邀请注册奖励',
    REWARD_REG_INVITEE: '受邀注册奖励',
    ORB_STAR: '星球卡',
    NEBU_STAR: '星宇卡',
    CLU_STAR: '星团卡',
    GALA_STAR: '星系卡',
    UNI_STAR: '星宇卡'
  },
  magicCorner: {
    blueBox: '蓝宝盒',
    bigWin: '大字橱窗',
    vojiRoom: '乌及故事屋'
  },
  ieSpace: {
    winkBalcony: '闻可台',
    paperPlane: '纸飞机',
    inbox: '私信箱',
    commentWall: '评论墙',
    prdWindow: '需求窗',
    gameRoom: '游戏室'
  },
  userCenter: {
    myInfo: '我的信息',
    usrPerInfo: '用户信息',
    usrAcctInfo: '账号信息',
    male: '男',
    female: '女',
    unknown: '保密',
    deregister: '注销账号',
    usrPer: {
      name: '名字',
      gender: '性别',
      remark: '备注'
    },
    usrAcct: {
      unid: '乌及号',
      pwd: '密码',
      oldUnid: '原乌及号',
      newUnid: '新乌及号',
      showName: '显示名',
      showGender: '显示性别',
      showProfile: '显示简介',
      oldEmail: '原邮箱',
      newEmail: '新邮箱',
      email: '邮箱',
      phone: '电话',
      wechat: '微信',
      level: '账号等级',
      isVip: '会员',
      status: '状态',
      changeUnid: '修改乌及号',
      upgradeAcctLevel: '账号升级',
      changePwd: '修改密码',
      changeEmail: '更换邮箱',
      oldPwd: '当前密码',
      newPwd: '新密码',
      confirmNewPwd: '确认新密码',
      avatarUri: '头像URI',
      uploadAvatar: '点击上传',
      setAvatar: '设置头像',
      usrPerId: '',
      mainAcctId: ''
    }
  },

  jsonOne: {
    editor: '编辑器',
    javaEditor: 'Java编辑器',
    plsInputJmesPath2Srch: 'JMESPath表达式',
    jmesPathSrchResult: 'JMESPath 搜索结果',
    invalidInput: '输入无效',

    save: '保存记录',
    view: '查看记录',
    convertTo: '转换为',
    parseFrom: '解析自',
    extractFrom: '提取自',

    toExcel: '转换为EXCEL',
    toCsv: '转换为CSV',
    toSql: '转换为SQL',
    toYaml: '转换为YAML',
    toProperties: '转换为PROPERTIES',

    toJava: '转换为JAVA',
    toGo: '转换为GO',
    toCpp: '转换为C++',
    toPhp5: '转换为PHP5',
    toPhp7: '转换为PHP7',

    fromExcel: '解析自EXCEL',
    fromCsv: '解析自CSV',
    fromSql: '解析自SQL',
    fromYaml: '解析自YAML',
    fromProperties: '解析自PROPERTIES',
    fromJava: '解析自JAVA',
    fromGo: '解析自GO',
    fromCpp: '解析自C++',
    fromPhp: '解析自PHP',

    fromText: '提取自文本',
    fromImg: '提取自图片',

    className: '类名',
    enableGenKey: '启用生成KEY模式',
    enableGenKeyDes: '若启用生成KEY模式，则在识别到有多个JSON时，将返回一个完整的JSON，自动生成JSON_1, JSON_2等KEY，其值为识别到的多个JSON。' +
      '若不启用生成KEY模式，则在识别到多个JSON时，直接返回多个JSON，因为JSON校验器会校验是否完整的JSON结构，所以返回多个JSON时JSON校验器可能会错误提示。',
    jsonArrayMode: '数组解析模式',
    jsonArrayEleMode: '数组元素解析模式',
    jsonObjectMode: '对象解析模式',
    multiCol: '多列',
    oneCol: '单列',
    multiColTip: '如arr:[1,2,3], 解析为3列，列名为arr[0]、arr[1]、arr[3]',
    oneColTip: '如arr:[1,2,3], 解析为1列，列名为arr',
    subHeader: '表头KEY',
    subHeaderTip: '属性名Key作为表头的模式',
    kv: 'KV对',
    kvTip: 'key=value模式',
    jsonString: 'JSON串',
    jsonStringTip: 'JSON字符串模式',
    headerCount: '表头行数',
    multiType: '多类型',
    multiTypeTip: '开启将自动识别数据类型，否则统一为字符串',
    multiLayer: '多层级',
    multiLayerTip: '开启将自动识别对象并解析为多层级的JSON',
    enableArray: '数组模式',
    enableArrayTip: '开启将自动识别数组'
  },

  coCoder: {
    genCode: '生成代码',
    myProject: '我的项目',
    newProject: '新项目',
    projectView: '项目大屏',
    exampleProject: '示例项目',

    databaseType: '数据库类型',
    databaseSelect: '数据库选择',
    entityModeConfig: '实体模型配置',
    frameworkSelect: '框架选择',

    mainFramework: '主体框架',
    extraFramework: '额外框架',
    commonBase: '基础SpringBootWeb',
    commonBaseTip: '基础的Spring Boot Web框架',
    vojivoSeakeerTip: '基于Spring Boot开发的框架，封装了诸多便捷功能，代码更加简洁',
    swaggerTip: 'Swagger V3框架，引入将在生成的接口上添加@Tag @Operation等注解，用于生成API文档',
    springCloudFeignTip: 'Spring Cloud Feign框架，引入将生成Feign客户端调用服务端接口的代码',
    viewExample: '查看示例',
    exampleCode: '示例代码',

    prevStep: '上一步',
    nextStep: '下一步',
    restart: '重新开始',

    entityName: '实体名',
    entityRemark: '实体备注',
    entityModelType: '实体模型类型',
    entityModelContent: '实体模型内容',
    entityModelContentPlaceholder: '请输入JSON或SQL语句(建表语句或者INSERT语句), 将会被解析为实体类的字段',
    tableDocName: '表或文档名',
    projectName: '项目名称',
    authorName: '作者名称',
    moduleParentPkgPath: '父级包路径',
    moduleName: '模块名称',
    moduleNamePlaceholder: '生成代码的完整路径为{父级包路径}.{模块名称}',
    entityJsonModel: '实体JSON模型',
    entitySqlModel: '实体SQL模型'
  },

  timingLendar: {
    calendar: '日历',
    comingTime: '署光',
    pastTime: '拾光'
  },

  timestamp: {
    curTs: '当前时间戳',
    tsConvert: '时间戳转换',
    tsToTime: '转为时间',
    timeToTs: '转时间戳',
    tsUnit: '时间戳',
    timeZone: '日期时间',
    tsKnowledge: '时间戳科普',
    define: '时间戳是指从GMT/UTC 1970-01-01 00:00:00 开始所经过的秒数，不考虑闰秒。\n' +
      'GMT: 格林威治时间(Greenwich Mean Time) \n' +
      'UTC: 协调世界时间(Coordinated Universal Time),又称世界统一时间、世界标准时间、国际协调时间'
  },
  geoLonLat: {
    geoCal: '距离计算',
    geoRecord: '历史记录',
    recognize: '识别',
    recognizeLonLat: '识别经纬度',
    cal: '计算',
    clear: '清空',
    saveAsNew: '存新',
    update: '更新',
    toJson: '转JSON',
    record: '查看记录',
    number: '编号',
    distance: '距离',
    unit: '单位',
    locA: '位置A',
    locB: '位置B',
    lonLatText: '经纬度文本',
    inputTextA: '位置A输入文本',
    inputTextB: '位置B输入文本',
    geoResultsJson: '结果JSON',
    recordName: '记录名'

  },
  enDecoder: {
    encode: '编码',
    decode: '解码',
    enDecodeOverview: '编解码简介',
    enDecodeDefine: '编解码指在数据的表示、存储和传输时对数据进行格式转换的一种技术。\n' +
      '编码是将输入的原始数据转换为特定格式的过程，而解码则是将这种特定格式的数据转换回原始输入数据的过程。\n' +
      '编解码的目的是为了确保数据在不同的系统或设备之间能够正确地传输和解析。',
    beLe: '大端BE(Big-Endian)和小端LE(Little-Endian): 计算机在存储多字节数据时，会存在字节序的概念，即数据的高字节（指代表数值最高有效位的字节）和低字节存储在低地址还是高地址，由此产生了BE和LE两种存储方式。\n' +
      '\n' +
      '大端BE：数据的高字节，存放在低地址中。大端存储较为直观，读取数据时从低地址开始读取，数值最高有效位先出现，符合人类的阅读习惯。且符号位比较容易判断，但大端模式在进行数值运算效率较低且内存对齐优化会受限。大端模式通常应用于网络传输（网络字节序）。\n' +
      '\n' +
      '小端LE：数据的低字节，存放在低地址中。读取数据时从高地址开始读取。小端存储在进行位操作时比较方便。另外小端存储可以更自然地利用低地址部分存放最常访问或最重要的数据，利于某些特定的内存对齐优化。但小端存储不直观，不符合人类的阅读习惯，且符号位判断复杂。\n' +
      '\n' +
      'UTF-8的编码是以1个字节为单位进行处理的，所以不涉及大小端存储，而UTF-16和UTF-32则分别存在大端编码和小端编码的方式：UTF-16BE、UTF-16LE和UTF-32BE、UTF-32LE.',
    charset: '字符集',
    charCoding: '字符编码',
    intro: '简介',
    coding: '编码方式',
    define: '概念说明',
    principle: '基本原理',
    usageScenario: '应用场景',
    base64: 'BASE64编解码',
    url: 'URL编解码',
    hex: 'HEX编解码',
    char: '字符编解码',
    // ascii: 'ASCII字符集编解码',
    // chinese: '汉字字符集编解码',
    // gb2312: 'GB2312编解码',
    // gbk: 'GBK编解码',
    // gb18030: 'GB18030编解码',
    // unicode: 'UNICODE字符集编解码',
    // utf8: 'UTF-8编解码',
    // utf16: 'UTF-16编解码',
    // utf32: 'UTF-32编解码'
    ascii: 'ASCII',
    chinese: '汉字',
    gb2312: 'GB2312',
    gbk: 'GBK',
    gb18030: 'GB18030',
    unicode: 'UNICODE',
    utf8: 'UTF-8',
    utf16: 'UTF-16',
    utf32: 'UTF-32',
    be: '大端BE',
    le: '小端LE',
    unicodeCharset: 'UNICODE字符集',
    toUnicode: '转为UNICODE',
    fromUnicode: '转自UNICODE',
    inputUnicode: '请输入UNICODE, 格式如\\u4f60\\u597d'
  },
  hash: {
    hashIntro: '哈希介绍',
    spaceHash: '哈&nbsp;&nbsp;&nbsp;&nbsp;希',
    crackHash: '逆哈希',
    spaceConfirm: '确&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;定',
    crack: '逆',
    hashAlg: '哈希算法',
    outputResult: '输出结果',
    performance: '计算性能',
    collisionProbability: '碰撞概率',
    usageScenario: '应用场景',
    principle: '基本原理',
    hashDefine: 'Hash(哈希/散列)是一种将任意长度的输入(预映射pre-image)映射为固定长度的输出(Hash值)的算法。\n' +
      '这种映射是一种压缩映射，即散列值的空间通常远小于输入的空间，不同的输入可能会映射成相同的输出(即产生碰撞)。\n' +
      'Hash具有正向简单，逆向困难的特点。'
  },

  unitConverter: {
    unit: '单位',
    categ: '分类',
    siu: '国际标准单位',
    regularUnit: '常用单位',
    metricUnit: '公制单位',
    municipalUnit: '市制单位',
    imperialUnit: '英制单位',
    otherRelated: '其他相关',
    lengthUnit: '长度单位',
    areaUnit: '面积单位',
    volumeUnit: '体积单位',
    massUnit: '质量单位',
    tempUnit: '温度单位',
    storageUnit: '存储单位',
    timeUnit: '时间单位',
    speedUnit: '速度单位',
    angularUnit: '角度单位'
  },
  timeUnit: {
    fs: '飞秒(fs)',
    ps: '皮秒(ps)',
    ns: '纳秒(ns)',
    us: '微秒(μs)',
    ms: '毫秒(ms)',
    s: '秒(s)',
    min: '分钟(min)',
    ke: '刻',
    h: '小时(h)',
    d: '日(d)',
    w: '周(w)',
    m: '月(m)',
    quarter: '季(q)',
    y: '年',
    c: '世纪',
    remark: {
      fs: '',
      ps: '',
      ns: '',
      us: '',
      ms: '',
      s: '一秒等于铯-133原子在基态下的两个超精细能级之间跃迁所对应的辐射的9192631770个周期的时间。',
      min: '',
      ke: '',
      h: '1 h = 60 min',
      d: '1 d = 24 h',
      w: '1 w = 7 d',
      m: '小月 = 30 天 或者 大月 = 31天; 大月 --- 1,3,5,7,8,10,12; 小月 --- 2,4,6,9,11',
      quarter: '',
      y: '',
      c: '1c = 100 y'
    }
  },
  lengthUnit: {
    nm: '纳米(nm)',
    um: '微米(μm)',
    mm: '毫米(mm)',
    cm: '厘米(cm)',
    dm: '分米(dm)',
    m: '米(m)',
    km: '千米(km)',

    Hao: '毫',
    Li: '厘',
    Fen: '分',
    Cun: '寸',
    Chi: '尺',
    Zhang: '丈',
    Lii: '里',

    milliInch: '密耳(mil)',
    inch: '英寸(in)',
    foot: '英尺(ft)',
    yard: '码(yd)',
    fathom: '英寻(fm)',
    rd: '竿(rd)',
    furlong: '弗隆(fur)',
    mile: '英里(mi)',
    nauticalMile: '海里(nmi)',

    lightYear: '光年(ly)',
    au: '天文单位(A.U.)',

    remark: {
      nm: '',
      um: '',
      mm: '',
      cm: '',
      dm: '',
      m: '光在真空中传播1/299792458秒所经过的距离定义为1m。',
      km: '',

      Hao: '',
      Li: '',
      Fen: '',
      Cun: '',
      Chi: '',
      Zhang: '',
      Lii: '',

      milliInch: '',
      inch: '',
      foot: '',
      yard: '',
      fathom: '',
      rd: '',
      furlong: '',
      mile: '英里，又称为迈',
      nauticalMile: '海里(nmi)，常用语航海领域',

      lightYear: '光年(ly)是指光在真空中沿直线传播一儒略年(天文学时间单位，数值定义为365.25天)所经过的距离，1 ly = 299792458 km/s * 365.25 d * 86400 s = 9460730472580800 m = 9.4607304725808e+15 ~ 9.46e+15。光年一般用于衡量天体之间的距离，如距离太阳最近的恒星是位于半人马座的比邻星，距离太阳4.22光年。',
      au: '天文单位(A.U.)是天文学中计量天体之间距离的一种单位，其数值取地球和太阳之间的平均距离1.495978707e+11米。月球距离地球0.0026 A.U.'
    }
  },
  areaUnit: {
    squareMm: '平方毫米(m㎡)',
    squareCm: '平方厘米(c㎡)',
    squareDm: '平方分米(d㎡)',
    squareM: '平方米(㎡)',
    are: '公亩(a)',
    hectare: '公顷(ha)',
    squareKm: '平方千米(k㎡)',

    PingFangCun: '平方寸',
    PingFangChi: '平方尺',
    Fen: '分',
    Mu: '亩',
    Qing: '倾',

    squareInch: '平方英寸(in²)',
    squareFoot: '平方英尺(ft²)',
    squareYard: '平方码(yd²)',
    squareRod: '平方竿(rd²)',
    acre: '英亩(ac)',
    squareMile: '平方英里(mi²)',

    remark: {
      squareMm: '',
      squareCm: '',
      squareDm: '',
      squareM: '',
      are: '',
      hectare: '',
      squareKm: '',

      PingFangCun: '',
      PingFangChi: '',
      Fen: '',
      Mu: '亩，常用来衡量土地面积',
      Qing: '',

      squareInch: '',
      squareFoot: '',
      squareYard: '',
      squareRod: '',
      acre: '1 ac = 160 rd²',
      squareMile: '平方英里(mi²)'
    }
  },
  volumeUnit: {
    cubicM: '立方米(m³)',
    cubicMm: '立方毫米(mm³)',
    cubicCm: '立方厘米(cm³)',
    cubicDm: '立方分米(dm³)',
    cubicKm: '立方千米(km³)',

    ul: '微升(μl)',
    ml: '毫升(ml)',
    cl: '厘升(cl)',
    dl: '分升(dl)',
    l: '升(l)',
    hl: '公石(hl)',

    LiFangCun: '立方寸',
    LiFangChi: '立方尺',
    ShiSheng: '市升',
    Dou: '斗',

    cubicInch: '立方英寸(in³)',
    cubicFoot: '立方英尺(ft³)',
    cubicYard: '立方码(yd³)',

    ukOz: '盎司(oz)-英',
    usOz: '盎司(oz)-美',
    ukGal: '加仑(gal)-英',
    usGal: '加仑(gal)-美',

    remark: {
      cubicM: '',
      cubicMm: '',
      cubicCm: '',
      cubicDm: '',
      cubicKm: '',

      ul: '',
      ml: '',
      cl: '',
      dl: '',
      l: '',
      hl: '公石dàn',

      LiFangCun: '',
      LiFangChi: '',
      ShiSheng: '',
      Dou: '',

      cubicInch: '',
      cubicFoot: '',
      cubicYard: '',

      ukOz: '1 oz = 28.41 ml',
      usOz: '1 oz = 29.57 ml',
      ukGal: '1 gal = 4.54609 l',
      usGal: '1 gal = 231 in³'
    },
    extra: {
      ul: '容积',
      ml: '容积',
      cl: '容积',
      dl: '容积',
      l: '容积',
      hl: '容积',
      ShiSheng: '容积',
      Dou: '容积',
      ukOz: '容积-液体',
      usOz: '容积-液体',
      ukGal: '容积',
      usGal: '容积'
    }
  },
  massUnit: {
    ug: '微克(μg)',
    mg: '毫克(mg)',
    carat: '克拉(ct)',
    g: '克(g)',
    quintal: '公担(q)',
    kg: '千克(kg)',
    tonne: '吨(t)',

    Qian: '钱',
    Liang: '两',
    Jin: '斤',

    grain: '格令(gr)',
    dram: '打兰(dr)',
    ounce: '盎司(oz)',
    pound: '磅(lb)',
    stone: '英石(st)',
    usHundredweight: '短担-美担(cwt)',
    ukHundredweight: '长担-英担(cwt)',
    shortTon: '短吨-美吨(ton)',
    longTon: '长吨-英吨(ton)',
    remark: {
      ug: '',
      mg: '',
      carat: '一般用于钻石质量',
      g: '',
      quintal: '',
      kg: '',
      tonne: '',

      Qian: '',
      Liang: '',
      Jin: '',

      grain: '1 gr = 0.06479891 g',
      dram: '1 dr = 1/16 oz',
      ounce: '1 oz = 437.5 gr = 16 dr',
      pound: '1 lb = 16 oz',
      stone: '1 st = 14 lb',
      ukHundredweight: '英担，又名长担，1 英担 = 112 lb = 8 st',
      usHundredweight: '美担，又名长担，1 美担 = 100 lb',
      shortTon: '1 美吨 = 2000 lb',
      longTon: '1 英吨 = 2240 lb'
    },
    extra: {}
  },
  tempUnit: {
    r: '兰氏度(°R)',
    k: '开氏度(K)',
    f: '华氏度(℉)',
    c: '摄氏度(℃)',
    re: '列氏度(°Re)',
    remark: {
      r: '',
      k: '',
      f: '',
      c: '',
      re: ''
    }
  },
  storageUnit: {
    bit: '比特(bit)',
    byte: '字节(B)',
    kb: '千字节(KB)',
    mb: '兆字节(MB)',
    gb: '千兆字节(GB)',
    tb: '太字节(TB)',
    pb: '拍字节(PB)',
    eb: '艾字节(EB)',
    remark: {
      bit: '',
      byte: '1 B = 8 bit',
      kb: '',
      mb: '',
      gb: '',
      tb: '',
      pb: '',
      eb: ''
    }
  },
  speedUnit: {
    mPerSec: '米/秒(m/s)',
    kmPerHour: '千米/时(km/h)',
    kmPerSec: '千米/秒(km/s)',
    inchPerSec: '英寸/秒(in/s)',
    milePerHour: '英里/时(mile/h)',
    mach: '马赫(mach)',
    remark: {
      mPerSec: '',
      kmPerHour: '同公里/时',
      kmPerSec: '',
      inchPerSec: '',
      milePerHour: '',
      mach: '马赫(mach)，又称马赫数(Mach Number)，是速度与音速的比值，是一个无量纲数。一般用于航空航天领域。' +
        '马赫不是一个单位，但一般使用时会用1马赫，10马赫来表示速度，对应1倍音速和、10倍音速。' +
        '因为音速在不同环境中不一样，一般可按15摄氏度1个标准大气压下的音速，即340.3 m/s'
    }
  },
  angularUnit: {
    angleSystem: '角度制',
    radianSystem: '弧度制',

    degree: '度(°)',
    minute: '分(\')',
    second: '秒(")',
    rad: '弧度(rad)',
    mrad: '豪弧(mrad)',
    remark: {
      degree: '圆周的1/360定义为1°',
      minute: '角度分',
      second: '角度秒',
      rad: '弧度(rad) = 弧长 / 半径; 圆周的弧度 = 2πr/r = 2π',
      mrad: ''
    }
  },

  protMsg: {
    notWhite: '不能为空格',
    notEmpty: '不能为空',
    paramInvalid: '参数不合法，请检查',
    meaningful: '不能为无意义的字符串',
    lengthRange: '长度范围',
    sizeRange: '数量范围',
    lengthMustLte: '长度须 <= ',
    required: '该参数必须',
    patternNotMatch: '格式不匹配',
    progNaming: '请输入字母、数字或下划线，数字不可开头',
    pwdPlainRegex: '须为3-32位数字或英文字母',
    unidRegex: '须为2-32位英文字母或数字',
    emailRegex: '请输入有效的邮箱',
    notSamePwd: '两次输入的密码不一致',
    unidExists: '乌及号已存在',
    emailExists: '邮箱已存在'
  }
}
