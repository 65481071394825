<template>
  <el-container class="json-one-el-container">
    <el-aside :width="this.leftAsideWith">
      <div>
        <el-menu :collapse="this.leftAsideIsCollapsed" unique-opened @select="this.onMenuSelected">
          <el-menu-item index="save">
            <i class="iconfont vj-icon-save"></i>
            <span slot="title">{{ this.$t('jsonOne.save') }}</span>
          </el-menu-item>
          <el-menu-item index="view">
            <i class="iconfont vj-icon-chakanjilu"></i>
            <span slot="title">{{ this.$t('jsonOne.view') }}</span>
          </el-menu-item>
          <el-submenu index="convertTo">
            <template slot="title">
              <i class="iconfont vj-icon-share"></i>
              <span>{{ this.$t('jsonOne.convertTo') }}</span>
            </template>
            <el-menu-item index="toExcel">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.toExcel') }}</span>
            </el-menu-item>
            <el-menu-item index="toCsv">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.toCsv') }}</span>
            </el-menu-item>
            <el-menu-item index="toSql">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.toSql') }}</span>
            </el-menu-item>
            <el-menu-item index="toYaml">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.toYaml') }}</span>
            </el-menu-item>
            <el-menu-item index="toProperties">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.toProperties') }}</span>
            </el-menu-item>
            <el-menu-item index="toJava">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.toJava') }}</span>
            </el-menu-item>
            <el-menu-item index="toGo">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.toGo') }}</span>
            </el-menu-item>
            <el-menu-item index="toCpp">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.toCpp') }}</span>
            </el-menu-item>
            <el-menu-item index="toPhp5">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.toPhp5') }}</span>
            </el-menu-item>
            <el-menu-item index="toPhp7">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.toPhp7') }}</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="parseFrom">
            <template slot="title">
              <i class="iconfont vj-icon-share-to-me"></i>
              <span>{{ this.$t('jsonOne.parseFrom') }}</span>
            </template>
            <el-menu-item index="fromExcel">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.fromExcel') }}</span>
            </el-menu-item>
            <el-menu-item index="fromCsv">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.fromCsv') }}</span>
            </el-menu-item>
            <el-menu-item index="fromSql">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.fromSql') }}</span>
            </el-menu-item>
            <el-menu-item index="fromYaml">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.fromYaml') }}</span>
            </el-menu-item>
            <el-menu-item index="fromProperties">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.fromProperties') }}</span>
            </el-menu-item>
            <el-menu-item index="fromJava">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.fromJava') }}</span>
            </el-menu-item>
            <el-menu-item index="fromGo">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.fromGo') }}</span>
            </el-menu-item>
            <el-menu-item index="fromCpp">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.fromCpp') }}</span>
            </el-menu-item>
            <el-menu-item index="fromPhp">
              <!--  <i class="el-icon-menu"></i>  -->
              <span slot="title">{{ this.$t('jsonOne.fromPhp') }}</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="extractFrom">
            <template slot="title">
              <i class="iconfont vj-icon-saomiaoshibie"></i>
              <span>{{ this.$t('jsonOne.extractFrom') }}</span>
            </template>
            <el-menu-item index="fromText">
              <!--    <i class="iconfont vj-icon-tubiaoji_wenbenshibie"></i>-->
              <span slot="title">{{ this.$t('jsonOne.fromText') }}</span>
            </el-menu-item>
            <el-menu-item index="fromImg">
              <!--  <i class="iconfont vj-icon-tubiaoji_tupianshibie"></i>-->
              <span slot="title">{{ this.$t('jsonOne.fromImg') }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-aside>
    <el-container class="json-one-el-container">
      <el-header height="50px" class="json-one-el-header">
        <div>
          <div v-show="jsonMainEditorMode==='JSON_CODE'|| jsonMainEditorMode==='JSON_DIFF'"
               class="json-code-editor-header">
            <el-button v-if="leftAsideIsCollapsed" @click="leftAsideIsCollapsed=false"
                       icon="el-icon-s-unfold">
            </el-button>
            <el-button v-else @click="leftAsideIsCollapsed=true" icon="el-icon-s-fold">
            </el-button>
            <div class="json-one-editor-mode-select-div">
              <el-select v-model="jsonMainEditorMode" @change="onChangeJsonMainEditorMode">
                <el-option value="JSON_CODE" label="CODE"></el-option>
                <el-option value="JSON_TREE" label="TREE"></el-option>
                <el-option value="JSON_DIFF" label="DIFF"></el-option>
              </el-select>
            </div>
            <el-button type="info" size="mini" circle @click="onExtend" :disabled="isPretty===true">
              <i class="json-icon-extend"></i>
            </el-button>
            <el-button type="info" size="mini" circle @click="onIntend" :disabled="isPretty===false">
              <i class="json-icon-intend"></i>
            </el-button>
            <el-button type="info" size="mini" circle @click="onRepair">
              <i class="json-icon-repair"></i>
            </el-button>
            <el-button type="info" size="mini" circle @click="sortKeys(true)" :disabled="isDesc===true">
              <i class="json-icon-sort-desc"></i>
            </el-button>
            <el-button type="info" size="mini" circle @click="sortKeys(false)" :disabled="isDesc===false">
              <i class="json-icon-sort-asc"></i>
            </el-button>
            <el-button type="info" size="mini" circle @click="undo">
              <i class="json-icon-undo"></i>
            </el-button>
            <el-button type="info" size="mini" circle @click="redo">
              <i class="json-icon-redo"></i>
            </el-button>
            <div v-if="jsonMainEditorMode==='JSON_CODE'" class="json-one-jmes-input-div">
              <el-input
                v-model="jmesPathSrchInput"
                :placeholder="$t('jsonOne.plsInputJmesPath2Srch')"
                @input="doJmesPathSrch">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>
            <div class="json-one-space-num-div">
              <el-input-number v-model="jsonSpaceNum"
                               :min="1"
                               :max="10"
                               @change="onJsonSpaceNumChange">
              </el-input-number>
            </div>
          </div>
          <div v-show="jsonMainEditorMode==='JSON_TREE'" class="json-tree-editor-header">
            <el-button v-if="leftAsideIsCollapsed" @click="leftAsideIsCollapsed=false"
                       icon="el-icon-s-unfold">
            </el-button>
            <el-button v-else @click="leftAsideIsCollapsed=true" icon="el-icon-s-fold">
            </el-button>
            <div class="json-one-editor-mode-select-div">
              <el-select v-model="jsonMainEditorMode" @change="onChangeJsonMainEditorMode">
                <el-option value="JSON_CODE" label="CODE"></el-option>
                <el-option value="JSON_TREE" label="TREE"></el-option>
                <el-option value="JSON_DIFF" label="DIFF"></el-option>
              </el-select>
            </div>
            <el-button type="info" size="mini" circle @click="expandJsonTree"
                       :disabled="jsonTreeEditorData.isCollapsed===true">
              <i class="json-icon-expand"></i>
            </el-button>
            <el-button type="info" size="mini" circle @click="collapseJsonTree"
                       :disabled="jsonTreeEditorData.isCollapsed===false">
              <i class="json-icon-collapse"></i>
            </el-button>
            <el-button type="info" size="mini" circle @click="undoJsonTree">
              <i class="json-icon-undo"></i>
            </el-button>
            <el-button type="info" size="mini" circle @click="redoJsonTree">
              <i class="json-icon-redo"></i>
            </el-button>
            <div class="json-one-tree-srch">
              <el-input
                v-model="jsonTreeEditorData.srchInput"
                @input="doJsonTreeSrch">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <div slot="suffix" class="json-one-tree-srch-suffix">
                  <i class="el-icon-caret-top" @click="prevJsonTreeSearchResult"></i>
                  <i class="el-icon-caret-bottom" @click="nextJsonTreeSearchResult"></i>
                </div>
              </el-input>
            </div>
            <div class="json-one-tree-srch-active">
              <el-input v-model="jsonTreeEditorData.activeResultIdx" disabled>
                <template slot="append">{{ jsonTreeEditorData.srchResult.length }}</template>
              </el-input>
            </div>
          </div>
        </div>
      </el-header>
      <el-main class="json-one-el-main">
        <!--        <div v-if="jsonDiffEnabled()" class="json-editor-diff-main">-->
        <!--          <div class="json-editor" ref="jsonEditorRef"></div>-->
        <!--          <div class="json-editor" ref="jsonEditorDiffRef"></div>-->
        <!--        </div>-->
        <div class="json-one-editor-container">
          <div v-show="jsonMainEditorMode === 'JSON_DIFF'" class="json-one-editor-diff-left"
               ref="jsonEditorDiffLeftRef"></div>
          <div v-show="jsonMainEditorMode === 'JSON_DIFF'" class="json-one-editor-diff-mid">
            <el-statistic
              :value="jsonDiffItems.length"
              title="DIFF"
              @click.native="diffJson"
            >
              <template slot="prefix">
                <i v-if="isDiffing" class="el-icon-loading" style="color: red"></i>
                <i v-else class="el-icon-s-flag" style="color: red"></i>
              </template>
            </el-statistic>
          </div>
          <div v-show="jsonMainEditorMode === 'JSON_DIFF'" class="json-one-editor-diff-right"
               ref="jsonEditorDiffRightRef"></div>
          <div v-show="jsonMainEditorMode==='JSON_CODE'" class="json-one-editor"
               ref="jsonEditorRef"></div>
          <div v-show="jsonMainEditorMode === 'JSON_TREE'" class="json-one-editor">
            <div id="jsonTreeEditor" style="height: 100%"></div>
          </div>
          <div style="position: absolute">
            <vue-draggable-resizable
              v-if="enableJmesPathSrchViewDiv"
              :w="400"
              :h="520"
              :x="630"
              :y="-550"
              :z="999"
              :parent="false"
              :resizable="true"
            >
              <div style="width: 100%; height: 100%; background-color: #B3C0D1">
                <h3 style="margin-top: 0; padding-top: 10px">{{ $t('jsonOne.jmesPathSrchResult') }}</h3>
                <el-input
                  v-model="jmesPathSrchResult"
                  type="textarea"
                  disabled
                  :rows="18">
                </el-input>
                <div style="margin-top: 10px; display: flex; justify-content: center">
                  <el-button type="primary" @click="confirmJmesPathSrchResult">{{ $t('cmn.confirm') }}</el-button>
                  <el-button type="primary" @click="cancelJmesPathSrchResult">{{ $t('cmn.cancel') }}</el-button>
                </div>
              </div>
            </vue-draggable-resizable>
          </div>
        </div>
      </el-main>
    </el-container>

    <div>
      <!-- Save Dialog-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableSaveForm"
        :title="this.$t('jsonOne.save')"
        width="600px"
      >
        <el-form
          ref="saveFormRef"
          label-width="100px"
          :model="saveForm"
          :rules="saveFormRules"
        >
          <el-form-item v-if="saveForm.id" :label="$t('cmn.saveType')" prop="saveNew">
            <el-radio v-model="saveForm.saveNew" :label="false">{{ $t('cmn.update') }}</el-radio>
            <el-radio v-model="saveForm.saveNew" :label="true">{{ $t('cmn.new') }}</el-radio>
          </el-form-item>
          <el-form-item :label="$t('cmn.recordName')" prop="recordName">
            <el-input v-model="saveForm.recordName"/>
          </el-form-item>
          <el-form-item label="Json Data" prop="jsonData">
            <el-input type="textarea" v-model="saveForm.jsonData" rows="6" disabled/>
          </el-form-item>
          <div v-if="saveForm.jsonRecordOverLimit">
            <span>{{ $t('trade.maxCountTip') }}: {{ saveForm.jsonRecordMaxCount }}，{{
                $t('trade.vojistarCardNoLimitTip')
              }}</span>
            <el-button type="text" @click="goToPurVojistarCard">
              {{ $t('trade.click2VojistarCard') }}
            </el-button>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableSaveForm=false">{{ this.$t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doSave">{{ this.$t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- To Excel Dialog-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableToExcelDialog"
        :title="this.$t('jsonOne.toExcel')"
        append-to-body
      >
        <el-form
          ref="toExcelFormRef"
          :model="toExcelForm"
          :rules="toExcelFormRules"
          label-width="200px"
        >
          <el-form-item :label="$t('cmn.fileName')" prop="fileTitle">
            <el-input placeholder="excel" v-model="toExcelForm.fileTitle">
              <template v-slot:append>.xlsx</template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('jsonOne.jsonArrayMode')" prop="jsonArrayMode">
            <el-radio-group v-model="toExcelForm.jsonArrayMode" @input="onToExcelJsonArrayModeChange">
              <el-radio label="MULTI_COL" ref="multiColRadio">{{ $t('jsonOne.multiCol') }}
                <el-tooltip effect="light" placement="top">
                  <span slot="content">{{ $t('jsonOne.multiColTip') }}</span>
                  <i class="el-icon-question" style="margin-left: 2px;"></i>
                </el-tooltip>
              </el-radio>
              <el-radio label="ONE_COL">{{ $t('jsonOne.oneCol') }}
                <el-tooltip effect="light" placement="top">
                  <span slot="content">{{ $t('jsonOne.oneColTip') }}</span>
                  <i class="el-icon-question" style="margin-left: 2px;"></i>
                </el-tooltip>
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item :label="$t('jsonOne.jsonArrayEleMode')" prop="jsonArrayEleMode">
            <el-select v-model="toExcelForm.jsonArrayEleMode">
              <el-option v-if="toExcelForm.jsonArrayMode==='MULTI_COL'" value="SUB_HEADER"
                         :label="$t('jsonOne.subHeader')">
                <span style="float: left">{{ $t('jsonOne.subHeader') }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 30px">
                  {{ $t('jsonOne.subHeaderTip') }}
                </span>
              </el-option>
              <el-option value="K_V" :label="$t('jsonOne.kv')">
                <span style="float: left">{{ $t('jsonOne.kv') }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 30px">
                  {{ $t('jsonOne.kvTip') }}
                </span>
              </el-option>
              <el-option value="JSON_STRING" :label="$t('jsonOne.jsonString')">
                <span style="float: left">{{ $t('jsonOne.jsonString') }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 30px">
                  {{ $t('jsonOne.jsonStringTip') }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('jsonOne.jsonObjectMode')" prop="jsonObjectMode">
            <el-select v-model="toExcelForm.jsonObjectMode">
              <el-option v-for="(item, index) in objectParseModeOptions" :key="index" :value="item.value"
                         :label="item.label">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 30px">{{ item.tip }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableToExcelDialog=false">{{ this.$t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doToExcel">{{ this.$t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- From Excel Dialog-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableFromExcelDialog"
        :title="this.$t('jsonOne.fromExcel')"
        append-to-body
      >
        <el-form
          ref="fromExcelFormRef"
          :model="fromExcelForm"
          :rules="fromExcelFormRules"
        >

          <el-form-item :label="$t('jsonOne.headerCount')">
            <el-input-number v-model="fromExcelForm.headerCount"></el-input-number>
          </el-form-item>

          <el-form-item :label="$t('jsonOne.multiType')">
            <el-switch v-model="fromExcelForm.enableMultiType"></el-switch>
            <el-tooltip effect="light" placement="top">
              <span slot="content">{{ $t('jsonOne.multiTypeTip') }}</span>
              <i class="el-icon-question" style="margin-left: 10px;"></i>
            </el-tooltip>
          </el-form-item>

          <el-form-item :label="$t('jsonOne.jsonArrayMode')" prop="jsonArrayMode">
            <el-radio-group v-model="fromExcelForm.jsonArrayMode" @input="onFromExcelJsonArrayModeChange">
              <el-radio label="MULTI_COL">{{ $t('jsonOne.multiCol') }}
                <el-tooltip effect="light" placement="top">
                  <span slot="content">{{ $t('jsonOne.multiColTip') }}</span>
                  <i class="el-icon-question" style="margin-left: 2px;"></i>
                </el-tooltip>
              </el-radio>
              <el-radio label="ONE_COL">{{ $t('jsonOne.oneCol') }}
                <el-tooltip effect="light" placement="top">
                  <span slot="content">{{ $t('jsonOne.oneColTip') }}</span>
                  <i class="el-icon-question" style="margin-left: 2px;"></i>
                </el-tooltip>
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item :label="$t('jsonOne.jsonArrayEleMode')" prop="jsonArrayMode">
            <el-select v-model="fromExcelForm.jsonArrayEleMode">
              <el-option v-if="fromExcelForm.jsonArrayMode==='MULTI_COL'"
                         value="SUB_HEADER"
                         :label="$t('jsonOne.subHeader')">
                <span style="float: left">{{ $t('jsonOne.subHeader') }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 30px">
                  {{ $t('jsonOne.subHeaderTip') }}
                </span>
              </el-option>
              <el-option value="K_V" :label="$t('jsonOne.kv')">
                <span style="float: left">{{ $t('jsonOne.kv') }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 30px">
                  {{ $t('jsonOne.kvTip') }}
                </span>
              </el-option>
              <el-option value="JSON_STRING" :label="$t('jsonOne.jsonString')">
                <span style="float: left">{{ $t('jsonOne.jsonString') }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 30px">
                  {{ $t('jsonOne.jsonStringTip') }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('jsonOne.jsonObjectMode')" prop="jsonObjectMode">
            <el-select v-model="fromExcelForm.jsonObjectMode">
              <el-option v-for="(item, index) in objectParseModeOptions" :key="index" :value="item.value"
                         :label="item.label">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 30px">{{ item.tip }}</span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-upload
              ref="excelUploadRef"
              action=""
              drag
              :http-request="parseJsonFromExcel"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">{{ this.$t('cmn.uploadDrag') }}<em>{{ this.$t('cmn.uploadClick') }}</em>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableFromExcelDialog=false">{{ this.$t('cmn.back') }}</el-button>
        </div>
      </el-dialog>

      <!-- To CSV Dialog-->
      <el-dialog
        width="30%"
        :close-on-click-modal="false"
        :visible.sync="enableToCsvDialog"
        :title="this.$t('jsonOne.toCsv')"
        append-to-body
      >
        <el-form
          ref="toCsvFormRef"
          :model="toCsvForm"
          :rules="toCsvFormRules"
        >
          <el-form-item :label="$t('cmn.fileName')" prop="fileTitle">
            <el-input v-model="toCsvForm.fileTitle">
              <template v-slot:append>.csv</template>
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableToCsvDialog=false">{{ this.$t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doToCsv">{{ this.$t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- From CSV Dialog-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableFromCsvDialog"
        :title="this.$t('jsonOne.fromCsv')"
        append-to-body
      >

        <el-form
          ref="fromExcelFormRef"
          :model="fromCsvForm"
          :rules="fromCsvFormRules"
        >

          <el-form-item :label="$t('jsonOne.multiType')">
            <el-switch v-model="fromCsvForm.enableMultiType"></el-switch>
            <el-tooltip effect="light" placement="top">
              <span slot="content">{{ $t('jsonOne.multiTypeTip') }}</span>
              <i class="el-icon-question" style="margin-left: 10px;"></i>
            </el-tooltip>
          </el-form-item>

          <el-form-item :label="$t('jsonOne.multiLayer')">
            <el-switch v-model="fromCsvForm.enableMultiLayer"></el-switch>
            <el-tooltip effect="light" placement="top">
              <span slot="content">{{ $t('jsonOne.multiLayerTip') }}</span>
              <i class="el-icon-question" style="margin-left: 10px;"></i>
            </el-tooltip>
          </el-form-item>

          <el-form-item :label="$t('jsonOne.enableArray')">
            <el-switch v-model="fromCsvForm.enableArray"></el-switch>
            <el-tooltip effect="light" placement="top">
              <span slot="content">{{ $t('jsonOne.enableArrayTip') }}</span>
              <i class="el-icon-question" style="margin-left: 10px;"></i>
            </el-tooltip>
          </el-form-item>

          <el-form-item>
            <el-upload
              ref="csvUploadRef"
              action=""
              drag
              :http-request="parseJsonFromCsv"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">{{ this.$t('cmn.uploadDrag') }}<em>{{ this.$t('cmn.uploadClick') }}</em>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableFromCsvDialog=false">{{ this.$t('cmn.back') }}</el-button>
        </div>
      </el-dialog>

      <!-- To Java Dialog-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableToJavaDialog"
        :title="this.$t('jsonOne.toJava')"
        append-to-body
      >
        <el-form
          ref="toJavaFormRef"
          :inline="true"
          :model="toJavaForm"
          :rules="toJavaFormRules"
        >
          <el-form-item :label="this.$t('jsonOne.className')" prop="className">
            <el-input v-model="toJavaForm.className"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableToJavaDialog=false">{{ this.$t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doToJava">{{ this.$t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Java Editor Dialog -->
      <el-dialog
        width="70%"
        :close-on-click-modal="false"
        :visible.sync="enableJavaEditorDialog"
        :title="this.$t('jsonOne.javaEditor')"
        append-to-body>
        <div class="java-editor" ref="javaEditorRef"></div>
        <div v-if="isFromModel" slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableJavaEditorDialog=false">{{ this.$t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doFromJava">{{ this.$t('cmn.confirm') }}</el-button>
        </div>
        <div v-else slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableJavaEditorDialog=false">{{ this.$t('cmn.back') }}</el-button>
        </div>
      </el-dialog>

      <!-- Multi Editor Dialog -->
      <el-dialog
        width="70%"
        :close-on-click-modal="false"
        :visible.sync="enableMultiEditorDialog"
        :title="multiEditorDialogTitle"
        append-to-body>
        <div class="enable-gen-key-div">
          <el-popover
            v-if="isFromModel && curContentType==='TEXT'"
            placement="top-start"
            width="600"
            trigger="hover"
            :content="this.$t('jsonOne.enableGenKeyDes')">
            <el-checkbox slot="reference"
                         size="small"
                         v-model="enableGenKey"
                         :label="this.$t('jsonOne.enableGenKey')"
                         border>
            </el-checkbox>
          </el-popover>
        </div>
        <div class="multi-editor" ref="multiEditorRef"></div>
        <div v-if="isFromModel" slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableMultiEditorDialog=false">{{ this.$t('cmn.cancel') }}</el-button>
          <el-button v-if="curContentType==='TEXT'"
                     type="primary"
                     @click="doFromText">
            {{ this.$t('cmn.confirm') }}
          </el-button>
          <el-button v-else type="primary" @click="doFrom">{{ this.$t('cmn.confirm') }}</el-button>
        </div>
        <div v-else slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableMultiEditorDialog=false">{{ this.$t('cmn.back') }}</el-button>
        </div>
      </el-dialog>

      <!-- From Img Dialog-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableFromImgDialog"
        :title="this.$t('jsonOne.fromImg')"
        append-to-body
      >
        <div class="enable-gen-key-div">
          <el-popover
            v-if="isFromModel && curContentType==='TEXT'"
            width="600"
            placement="top-start"
            trigger="hover"
            :content="this.$t('jsonOne.enableGenKeyDes')">
            <el-checkbox slot="reference"
                         v-model="enableGenKey"
                         :label="this.$t('jsonOne.enableGenKey')"
                         size="small"
                         border>
            </el-checkbox>
          </el-popover>
        </div>
        <el-upload
          ref="imgUploadRef"
          action=""
          drag
          :http-request="extractJsonFromImg"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">{{ this.$t('cmn.uploadDrag') }}<em>{{ this.$t('cmn.uploadClick') }}</em></div>
        </el-upload>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableFromImgDialog=false">{{ this.$t('cmn.back') }}</el-button>
        </div>
      </el-dialog>
    </div>
  </el-container>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

import jsonrepair from 'jsonrepair'
import jmespath from 'jmespath'
import UsrzJsonRecordApi from '@/api/vojivosvc/toolsubject/UsrzJsonRecordApi'
import JsonOneApi from '@/api/vojivosvc/toolsubject/JsonOneApi'
import StringUtil from '@/util/StringUtil'

import JsonTreeEditor from '../../../jsoneditorlab/jsoneditor.js'
import '../../../jsoneditorlab/jsoneditor.css'
import ProtRule from '@/constant/ProtRule'

import FastJsonPath from 'fast-json-patch'
import Ace from 'ace-builds'
import ObjectUtil from '@/util/ObjectUtil'
import UsrzBizConfigApi from '@/api/vojivosvc/UsrzBizConfigApi'
import '../../../scss/jsoneditor.scss'

export default {
  name: 'JsonOne',
  components: {
    VueDraggableResizable
  },
  created () {
    this.init()
  },
  mounted () {
    this.initJsonEditor()
    this.initJsonEditorDiff()
    this.initJsonTreeEditor()
  },
  computed: {
    // collapse () {
    //   return collapse
    // },
    leftAsideWith: {
      get () {
        return this.leftAsideIsCollapsed ? '100px' : '200px'
      },
      set (v) {
      }
    },
    multiEditorDialogTitle: {
      get () {
        return this.curContentType + this.$t('jsonOne.editor')
      },
      set (v) {
      }
    },

    objectParseModeOptions: {
      get () {
        return [
          {
            value: 'SUB_HEADER',
            label: this.$t('jsonOne.subHeader'),
            tip: this.$t('jsonOne.subHeaderTip')
          },
          {
            value: 'K_V',
            label: this.$t('jsonOne.kv'),
            tip: this.$t('jsonOne.kvTip')
          },
          {
            value: 'JSON_STRING',
            label: this.$t('jsonOne.jsonString'),
            tip: this.$t('jsonOne.jsonStringTip')
          }
        ]
      },
      set (v) {
      }
    }

  },
  data () {
    return {
      leftAsideIsCollapsed: true,
      jsonMainEditorMode: 'JSON_CODE',
      fromJsonMainEditorMode: 'JSON_CODE',
      editorThemeName: 'SQL Server', // monokai

      jsonRecordId: null,
      jsonRecordName: null,
      jsonEditor: null,
      jsonEditorDiff: null,
      delayDiffJsonTimeoutId: null,
      jsonDiffItems: [],
      isDiffing: false,
      jsonEditorMarkers: [],
      jsonEditorDiffMarkers: [],
      defaultJsonData: {
        Array: [1, 2, 3],
        Boolean: true,
        Null: null,
        Number: 123,
        Object: {
          a: 'b',
          c: 'd'
        },
        String: 'Hello World',
        keyDiff1: 'key diff',
        valueDiff: 'value diff 1',
        keyHas: 'key has',
        ArrayObject: [
          {
            ao1: 'a01',
            ao2: 'a02'
          },
          {
            ao3: 'a03',
            ao4: 'a04'
          }
        ],
        NestedObject: {
          x: 'b',
          y: 'd',
          z: {
            w: 'ww',
            v: 'vv'
          }
        },
        ComplexObject: {
          cox: 'b',
          coy: 'd',
          coz: {
            w: 'ww',
            v: 'vv'
          },
          coArray: [
            {
              ao1: 'a01',
              ao2: 'a02'
            },
            {
              ao3: 'a03',
              ao4: [1, 2, 3]
            }
          ]
        }
      },
      defaultDiffJsonData: {
        Array: [1, 2, 3],
        Boolean: true,
        Null: null,
        Number: 123,
        Object: {
          a: 'b',
          c: 'd'
        },
        String: 'Hello World',
        keyDiff2: 'key diff',
        valueDiff: 'value diff 2',
        ArrayObject: [
          {
            ao1: 'a01',
            ao2: 'a02'
          },
          {
            ao3: 'a03',
            ao4: 'a04'
          }
        ],
        NestedObject: {
          x: 'b',
          y: 'd',
          z: {
            w: 'ww',
            v: 'vv'
          }
        },
        ComplexObject: {
          cox: 'b',
          coy: 'd',
          coz: {
            w: 'ww',
            v: 'vv'
          },
          coArray: [
            {
              ao1: 'a01',
              ao2: 'a02'
            },
            {
              ao3: 'a03',
              ao4: [1, 2, 3]
            }
          ]
        }
      },
      isPretty: null,
      isDesc: null,
      jmesPathSrchInput: null,
      jmesPathSrchResult: null,
      enableJmesPathSrchViewDiv: false,
      jsonSpaceNum: 2,

      jsonTreeEditor: null,
      jsonTreeEditorData: {
        jsonTreeValue: null,
        isCollapsed: null,
        srchInput: null,
        srchResult: [],
        activeResult: null,
        activeResultIdx: null
      },
      enableSaveForm: false,
      saveForm: {
        id: null,
        recordName: null,
        saveNew: false,
        jsonData: null,
        jsonRecordCount: 0,
        jsonRecordMaxCount: 0,
        jsonRecordOverLimit: false
      },
      saveFormRules: {
        recordName: [
          {
            min: 1,
            max: 32,
            message: this.$t('protMsg.lengthRange') + '[1,32]',
            trigger: 'blur'
          },
          ProtRule.cmnRule().notWhite
        ],
        jsonData: [
          {
            required: true,
            message: this.$t('protMsg.required'),
            trigger: 'blur'
          },
          {
            min: 2,
            max: 4096,
            message: this.$t('protMsg.lengthRange') + '[2,4096]',
            trigger: 'blur'
          },
          ProtRule.cmnRule().notWhite
        ]
      },
      isFromModel: false,
      enableToExcelDialog: false,
      toExcelForm: {
        fileTitle: 'Json2Excel',
        jsonArrayMode: 'MULTI_COL',
        jsonArrayEleMode: 'SUB_HEADER',
        jsonObjectMode: 'SUB_HEADER'
      },
      toExcelFormRules: {
        fileTitle: [
          ProtRule.cmnRule().required,
          {
            min: 1,
            max: 25,
            message: this.$t('protMsg.lengthRange') + '[1,25]',
            trigger: 'blur'
          },
          ProtRule.cmnRule().notWhite
        ]
      },
      enableFromExcelDialog: false,
      fromExcelForm: {
        headerCount: null,
        enableMultiType: true,
        jsonArrayMode: 'MULTI_COL',
        jsonArrayEleMode: 'SUB_HEADER',
        jsonObjectMode: 'SUB_HEADER'
      },
      fromExcelFormRules: {},

      enableToCsvDialog: false,
      toCsvForm: {
        fileTitle: 'Json2Csv'
      },
      toCsvFormRules: {},
      enableFromCsvDialog: false,
      fromCsvForm: {
        enableMultiType: true,
        enableMultiLayer: true,
        enableArray: true
      },
      fromCsvFormRules: {},
      enableJavaEditorDialog: false,
      enableToJavaDialog: false,
      toJavaForm: {},
      toJavaFormRules: {},
      javaEditor: null,

      enableFromImgDialog: false,

      enableGenKey: true,
      enableMultiEditorDialog: false,
      multiEditor: null,
      curContentType: null,
      contentTypeModeMap: new Map([
        ['SQL', 'sql'],
        ['YAML', 'yaml'],
        ['PROPERTIES', 'properties'],
        ['JAVA', 'java'],
        ['GO', 'go'],
        ['CPP', 'c_cpp'],
        ['PHP', 'php'],
        ['PHP_5', 'php'],
        ['PHP_7', 'php'],
        ['TEXT', 'text']
      ])
    }
  },
  methods: {
    init () {
      if (this.$route.query) {
        const recordId = this.$route.query.jsonRecordId
        if (recordId !== undefined && recordId !== null && recordId !== '') {
          this.jsonRecordId = recordId
        }
      }
      if (this.jsonRecordId) {
        UsrzJsonRecordApi.findDetail(this.jsonRecordId)
          .then(rt => {
            this.jsonRecordName = rt.recordName
            this.jsonEditor.getSession().setValue(JSON.stringify(JSON.parse(rt.jsonData), null, this.jsonSpaceNum))
          })
      }
    },
    onExtend () {
      this.jsonEditor.getSession().setValue(JSON.stringify(JSON.parse(this.jsonEditor.getValue()), null, this.jsonSpaceNum))
      this.isPretty = true
      if (this.jsonDiffEnabled() && this.jsonEditorDiff) {
        this.jsonEditorDiff.getSession().setValue(JSON.stringify(JSON.parse(this.jsonEditorDiff.getValue()), null, this.jsonSpaceNum))
      }
    },
    onIntend () {
      this.jsonEditor.getSession().setValue(JSON.stringify(JSON.parse(this.jsonEditor.getValue()), null))
      this.isPretty = false
      if (this.jsonDiffEnabled() && this.jsonEditorDiff) {
        this.jsonEditorDiff.getSession().setValue(JSON.stringify(JSON.parse(this.jsonEditorDiff.getValue()), null))
      }
    },
    onRepair () {
      const editorValue = this.jsonEditor.getValue()
      if (editorValue === null || editorValue === '') {
        return
      }
      const repairedJsonStr = jsonrepair(editorValue)
      this.jsonEditor.getSession().setValue(JSON.stringify(JSON.parse(repairedJsonStr), null, this.jsonSpaceNum))
      this.onRepairJsonEditorDiff()
    },
    onRepairJsonEditorDiff () {
      if (this.jsonDiffEnabled() && this.jsonEditorDiff) {
        const editorValue = this.jsonEditorDiff.getValue()
        if (editorValue === null || editorValue === '') {
          return
        }
        const repairedJsonStr = jsonrepair(editorValue)
        this.jsonEditorDiff.getSession().setValue(JSON.stringify(JSON.parse(repairedJsonStr), null, this.jsonSpaceNum))
      }
    },
    doJmesPathSrch () {
      try {
        if (StringUtil.isBlank(this.jmesPathSrchInput)) {
          this.enableJmesPathSrchViewDiv = false
          return
        }
        this.enableJmesPathSrchViewDiv = true
        const curJson = JSON.parse(this.jsonEditor.getValue())
        const result = jmespath.search(curJson, this.jmesPathSrchInput)
        this.jmesPathSrchResult = JSON.stringify(result, null, this.jsonSpaceNum)
      } catch (error) {
        this.jmesPathSrchResult = this.$t('jsonOne.invalidInput') + ': ' + this.jmesPathSrchInput
      }
    },
    confirmJmesPathSrchResult () {
      this.jsonEditor.getSession().setValue(this.jmesPathSrchResult)
      this.cancelJmesPathSrchResult()
    },
    cancelJmesPathSrchResult () {
      this.jmesPathSrchInput = null
      this.jmesPathSrchResult = null
      this.enableJmesPathSrchViewDiv = false
    },
    undo () {
      this.jsonEditor.undo()
      if (this.jsonDiffEnabled() && this.jsonEditorDiff) {
        this.jsonEditorDiff.undo()
      }
    },
    redo () {
      this.jsonEditor.redo()
      if (this.jsonDiffEnabled() && this.jsonEditorDiff) {
        this.jsonEditorDiff.redo()
      }
    },
    onChange (delta) {
      this.isPretty = null
      this.isDesc = null
      this.isDiffing = true
    },
    onBlur () {
      console.log('onblur', this.jsonDiffEnabled(), this.jsonEditor, this.jsonEditorDiff)
      if (this.jsonDiffEnabled() && this.jsonEditor && this.jsonEditorDiff) {
        this.sortJson(this.isDesc, this.jsonEditor)
        this.sortJson(this.isDesc, this.jsonEditorDiff)
        this.delayDiffJson()
      }
    },
    jsonDiffEnabled () {
      return this.jsonMainEditorMode === 'JSON_DIFF'
    },
    delayDiffJson () {
      this.clearDelayDiffJsonTimeout()
      this.delayDiffJsonTimeoutId = setTimeout(() => {
        this.diffJson()
      }, 1000)
    },
    clearDelayDiffJsonTimeout () {
      if (this.delayDiffJsonTimeoutId) {
        clearTimeout(this.delayDiffJsonTimeoutId)
      }
    },
    diffJson () {
      const json1 = JSON.parse(this.jsonEditor.getValue())
      const json2 = JSON.parse(this.jsonEditorDiff.getValue())
      this.jsonDiffItems = FastJsonPath.compare(json1, json2)
      console.log('jsonDiffItems', this.jsonDiffItems)
      this.isDiffing = false
      this.highlightJsonDiffItems(json1, json2)
    },
    highlightJsonDiffItems (json1, json2) {
      this.jsonEditorMarkers.forEach((markerId) => {
        this.jsonEditor.getSession().removeMarker(markerId)
      })
      this.jsonEditorDiffMarkers.forEach((markerId) => {
        this.jsonEditorDiff.getSession().removeMarker(markerId)
      })
      this.jsonEditorMarkers = []
      this.jsonEditorDiffMarkers = []

      const jsonEditorMarkerInfoList = []
      const jsonEditorDiffMarkerInfoList = []
      this.jsonDiffItems.forEach((opPathValue) => {
        this.parseMarkerInfo(opPathValue, jsonEditorMarkerInfoList, jsonEditorDiffMarkerInfoList, json1, json2)
      })
      jsonEditorMarkerInfoList.forEach((markerInfo) => {
        const markerId = this.jsonEditor.getSession().addMarker(
          markerInfo.range,
          markerInfo.className,
          'fullLine'
        )
        this.jsonEditorMarkers.push(markerId)
      })
      jsonEditorDiffMarkerInfoList.forEach((markerInfo) => {
        const markerId = this.jsonEditorDiff.getSession().addMarker(
          markerInfo.range,
          markerInfo.className,
          'fullLine'
        )
        this.jsonEditorDiffMarkers.push(markerId)
      })
    },

    parseRange (path, json, startRowIdx) {
      const keys = path.split('/').filter(Boolean)
      const curKey = keys.shift()
      let rowIdx = startRowIdx
      for (const [key, value] of Object.entries(json)) {
        if (key !== curKey) {
          rowIdx += this.parseObjectDeepth(value)
          continue
        }
        if (keys.length === 0) {
          if (typeof value === 'object') {
            // 认为整个对象都不同，返回整个对象的范围
            const endRowIdx = rowIdx + this.parseObjectDeepth(value)
            return new Ace.Range(rowIdx + 1, 0, endRowIdx, 10)
          } else {
            // 目标键值对在同一行，返回范围
            return new Ace.Range(rowIdx + 1, 0, rowIdx + 1, 1)
          }
        } else {
          // 继续递归查找剩余的 keyPath
          return this.parseRange(keys.join('/'), value, rowIdx + 1)
        }
      }

      // 未找到目标键值对，返回 null 或抛出异常
      return null
    },
    parseObjectDeepth (data) {
      if (data === null || typeof data === 'string') {
        return 1
      }
      const entries = Object.entries(data)
      if (entries.length === 0) {
        return 1
      }
      let deepth = 0
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of entries) {
        if (typeof value === 'object') {
          deepth += this.parseObjectDeepth(value)
        } else {
          deepth += 1
        }
      }
      return deepth + 2
    },
    parseMarkerInfo (opPathValue, jsonEditorMarkerInfoList, jsonEditorDiffMarkerInfoList, json1, json2) {
      switch (opPathValue.op) {
        case 'replace':
          var range1 = this.parseRange(opPathValue.path, json1, 0)
          var range2 = this.parseRange(opPathValue.path, json2, 0)
          if (range1) {
            jsonEditorMarkerInfoList.push({
              range: range1,
              className: 'json-one-diff-marker-replace'
            })
          }
          if (range2) {
            jsonEditorDiffMarkerInfoList.push({
              range: range2,
              className: 'json-one-diff-marker-replace'
            })
          }

          break
        case 'remove':
          var range3 = this.parseRange(opPathValue.path, json1, 0)
          if (range3) {
            jsonEditorMarkerInfoList.push({
              range: range3,
              className: 'json-one-diff-marker-remove'
            })
          }
          break
        case 'add':
          var range4 = this.parseRange(opPathValue.path, json2, 0)
          if (range4) {
            jsonEditorDiffMarkerInfoList.push({
              range: range4,
              className: 'json-one-diff-marker-add'
            })
          }
          break
        default:
          break
      }
    },
    sortJson (isDesc, editor) {
      const data = JSON.parse(editor.getValue())
      const sortedJsonData = this.getSortJson(data, isDesc)
      editor.getSession().setValue(JSON.stringify(sortedJsonData, null, this.jsonSpaceNum).replaceAll('_vo_ji_vo_', ''))
      this.isDesc = isDesc
    },
    sortKeys (isDesc) {
      this.sortJson(isDesc, this.jsonEditor)
      if (this.jsonDiffEnabled() && this.jsonEditorDiff) {
        this.sortJson(isDesc, this.jsonEditorDiff)
      }
    },
    getSortJson (json, isDesc) {
      if (Array.isArray(json)) {
        return this.sortArray(json, isDesc)
      }
      if (json === null || json === undefined) {
        return json
      }
      if (typeof json !== 'object') {
        return json
      }
      const sortedJsonData = {}
      const keys = Object.keys(json)
      keys.sort((a, b) => {
        if (a === b) {
          return 0
        }
        if (isDesc) {
          return a < b ? 1 : -1
        }
        return a > b ? 1 : -1
      }).forEach(key => {
        // 数字类的key会自动排序
        sortedJsonData['_vo_ji_vo_' + key] = json[key]
      })
      for (const key of keys) {
        sortedJsonData['_vo_ji_vo_' + key] = this.getSortJson(sortedJsonData['_vo_ji_vo_' + key], isDesc)
      }
      return sortedJsonData
    },

    sortArray (array, isDesc) {
      array.sort((a, b) => {
        const hashCodeA = ObjectUtil.hashCode(a)
        const hashCodeB = ObjectUtil.hashCode(b)
        if (hashCodeA === hashCodeB) {
          return 0
        }
        if (isDesc) {
          return hashCodeA < hashCodeB ? 1 : -1
        }
        return hashCodeA > hashCodeB ? 1 : -1
      })
      for (let i = 0; i < array.length; i++) {
        array[i] = this.getSortJson(array[i], isDesc)
      }
      return array
    },
    onJsonSpaceNumChange () {
      this.jsonEditor.getSession().setValue(JSON.stringify(JSON.parse(this.jsonEditor.getValue()), null, this.jsonSpaceNum))
      this.isPretty = true
      if (this.jsonDiffEnabled() && this.jsonEditorDiff) {
        this.jsonEditorDiff.getSession().setValue(JSON.stringify(JSON.parse(this.jsonEditorDiff.getValue()), null, this.jsonSpaceNum))
      }
    },
    onChangeJsonMainEditorMode (jsonMainEditorMode) {
      switch (jsonMainEditorMode) {
        case 'JSON_CODE':
          if (this.fromJsonMainEditorMode === 'JSON_TREE') {
            this.reJsonEditor(this.$refs.jsonEditorRef, JSON.stringify(this.jsonTreeEditor.get(), null, this.jsonSpaceNum))
          }
          if (this.fromJsonMainEditorMode === 'JSON_DIFF') {
            this.reJsonEditor(this.$refs.jsonEditorRef, JSON.stringify(JSON.parse(this.jsonEditor.getValue()), null, this.jsonSpaceNum))
          }
          break
        case 'JSON_TREE':
          this.jsonTreeEditor.set(JSON.parse(this.jsonEditor.getValue()))
          break
        case 'JSON_DIFF':
          if (this.fromJsonMainEditorMode === 'JSON_CODE') {
            this.reJsonEditor(this.$refs.jsonEditorDiffLeftRef, JSON.stringify(JSON.parse(this.jsonEditor.getValue()), null, this.jsonSpaceNum))
          }
          if (this.fromJsonMainEditorMode === 'JSON_TREE') {
            this.reJsonEditor(this.$refs.jsonEditorDiffLeftRef, JSON.stringify(this.jsonTreeEditor.get(), null, this.jsonSpaceNum))
          }
          if (this.jsonEditorDiff) {
            this.jsonEditorDiff.getSession().setValue(JSON.stringify(JSON.parse(this.jsonEditorDiff.getValue()), null, this.jsonSpaceNum))
          }
          break
      }
      console.log('mode', this.fromJsonMainEditorMode, jsonMainEditorMode)
      this.fromJsonMainEditorMode = jsonMainEditorMode
    },
    initJsonTreeEditor () {
      const container = document.getElementById('jsonTreeEditor')
      this.jsonTreeEditor = new JsonTreeEditor.JSONEditor(container)
      this.jsonTreeEditor.set(this.defaultJsonData)
    },
    expandJsonTree () {
      this.jsonTreeEditor.expandAll()
    },
    collapseJsonTree () {
      this.jsonTreeEditor.collapseAll()
    },
    undoJsonTree () {
      this.jsonTreeEditor._onUndo()
    },
    redoJsonTree () {
      this.jsonTreeEditor._onRedo()
    },
    doJsonTreeSrch () {
      this.jsonTreeEditorData.srchResult = this.jsonTreeEditor.search(this.jsonTreeEditorData.srchInput)
      this.setJsonTreeActiveSrchResult(undefined)
    },
    setJsonTreeActiveSrchResult (index, focus) {
      if (this.jsonTreeEditorData.activeResult) {
        const prevNode = this.jsonTreeEditorData.activeResult.node
        const prevElem = this.jsonTreeEditorData.activeResult.elem
        if (prevElem === 'field') {
          delete prevNode.searchFieldActive
        } else {
          delete prevNode.searchValueActive
        }
        prevNode.updateDom()
      }

      if (!this.jsonTreeEditorData.srchResult || !this.jsonTreeEditorData.srchResult[index]) {
        // out of range, set to undefined
        this.jsonTreeEditorData.activeResultIdx = null
        this.jsonTreeEditorData.activeResult = null
        return
      }

      this.jsonTreeEditorData.activeResultIdx = index

      // set new node active
      const node = this.jsonTreeEditorData.srchResult[index].node
      const elem = this.jsonTreeEditorData.srchResult[index].elem
      if (elem === 'field') {
        node.searchFieldActive = true
      } else {
        node.searchValueActive = true
      }
      this.jsonTreeEditorData.activeResult = this.jsonTreeEditorData.srchResult[index]
      node.updateDom()
      node.scrollTo(function () {
        if (focus) {
          node.focus(elem)
        }
      })
    },
    prevJsonTreeSearchResult () {
      if (!this.jsonTreeEditorData.srchResult) {
        return
      }
      const srchResultMaxIdx = this.jsonTreeEditorData.srchResult.length - 1
      if (this.jsonTreeEditorData.activeResultIdx !== null) {
        if (this.jsonTreeEditorData.activeResultIdx === 0) {
          this.setJsonTreeActiveSrchResult(srchResultMaxIdx, true)
        } else {
          this.setJsonTreeActiveSrchResult(this.jsonTreeEditorData.activeResultIdx - 1, true)
        }
      } else {
        this.setJsonTreeActiveSrchResult(srchResultMaxIdx, true)
      }
    },
    nextJsonTreeSearchResult () {
      if (!this.jsonTreeEditorData.srchResult) {
        return
      }
      const srchResultMaxIdx = this.jsonTreeEditorData.srchResult.length - 1
      if (this.jsonTreeEditorData.activeResultIdx !== null) {
        if (this.jsonTreeEditorData.activeResultIdx === srchResultMaxIdx) {
          this.setJsonTreeActiveSrchResult(0, true)
        } else {
          this.setJsonTreeActiveSrchResult(this.jsonTreeEditorData.activeResultIdx + 1, true)
        }
      } else {
        this.setJsonTreeActiveSrchResult(0, true)
      }
    },
    reJsonEditor (ref, jsonStr) {
      this.destroyCurEditor()
      require('ace-builds/webpack-resolver')
      require('ace-builds/src-noconflict/mode-json')
      require('ace-builds/src-noconflict/ext-language_tools')
      var editor = Ace.edit(ref)
      editor.setTheme('ace/theme/' + this.editorThemeName)
      editor.session.setMode('ace/mode/json')
      editor.getSession().on('change', this.onChange)
      editor.on('blur', this.onBlur)
      editor.getSession().setValue(jsonStr)
      editor.setOptions({
        showLineNumbers: true,
        wrap: true
      })
      this.jsonEditor = editor
    },
    destroyCurEditor () {
      this.jsonEditor.destroy()
      this.jsonEditor = null
    },
    initJsonEditor () {
      // var ace = require('ace-builds')
      require('ace-builds/webpack-resolver')
      require('ace-builds/src-noconflict/mode-json')
      require('ace-builds/src-noconflict/ext-language_tools')
      var editor = Ace.edit(this.$refs.jsonEditorRef)

      editor.setTheme('ace/theme/' + this.editorThemeName)
      editor.session.setMode('ace/mode/json')

      editor.getSession().on('change', this.onChange)
      editor.on('blur', this.onBlur)
      editor.getSession().setValue(JSON.stringify(this.defaultJsonData, null, this.jsonSpaceNum))
      editor.setOptions({
        // editor options
        // selectionStyle: 'line', // "line"|"text"
        // highlightActiveLine: true, // boolean
        // highlightSelectedWord: true, // boolean
        // readOnly: false, // boolean: true if read only
        // cursorStyle: 'ace', // "ace"|"slim"|"smooth"|"wide"
        // mergeUndoDeltas: true, // false|true|"always"
        // behavioursEnabled: true, // boolean: true if enable custom behaviours
        // wrapBehavioursEnabled: true, // boolean
        // autoScrollEditorIntoView: undefined, // boolean: this is needed if editor is inside scrollable page
        // keyboardHandler: null, // function: handle custom keyboard events
        //
        // // renderer options
        // animatedScroll: false, // boolean: true if scroll should be animated
        // displayIndentGuides: false, // boolean: true if the indent should be shown. See 'showInvisibles'
        // showInvisibles: false, // boolean -> displayIndentGuides: true if show the invisible tabs/spaces in indents
        // showPrintMargin: true, // boolean: true if show the vertical print margin
        // printMarginColumn: 80, // number: number of columns for vertical print margin
        // printMargin: undefined, // boolean | number: showPrintMargin | printMarginColumn
        // showGutter: true, // boolean: true if show line gutter
        // fadeFoldWidgets: false, // boolean: true if the fold lines should be faded
        // showFoldWidgets: true, // boolean: true if the fold lines should be shown ?
        showLineNumbers: true,
        // highlightGutterLine: false, // boolean: true if the gutter line should be highlighted
        // hScrollBarAlwaysVisible: false, // boolean: true if the horizontal scroll bar should be shown regardless
        // vScrollBarAlwaysVisible: false, // boolean: true if the vertical scroll bar should be shown regardless
        // fontSize: 12, // number | string: set the font size to this many pixels
        // fontFamily: undefined, // string: set the font-family css value
        // maxLines: 10, // number: set the maximum lines possible. This will make the editor height changes
        // minLines: 1 // number: set the minimum lines possible. This will make the editor height changes
        // maxPixelHeight: 0, // number -> maxLines: set the maximum height in pixel, when 'maxLines' is defined.
        // scrollPastEnd: 0, // number -> !maxLines: if positive, user can scroll pass the last line and go n * editorHeight more distance
        // fixedWidthGutter: false, // boolean: true if the gutter should be fixed width
        // theme: 'ace/theme/textmate', // theme string from ace/theme or custom?
        //
        // // mouseHandler options
        // scrollSpeed: 2, // number: the scroll speed index
        // dragDelay: 0, // number: the drag delay before drag starts. it's 150ms for mac by default
        // dragEnabled: true, // boolean: enable dragging
        // focusTimout: 0, // number: the focus delay before focus starts.
        // tooltipFollowsMouse: true, // boolean: true if the gutter tooltip should follow mouse
        //
        // // session options
        // firstLineNumber: 1, // number: the line number in first line
        // overwrite: false, // boolean
        // newLineMode: 'auto', // "auto" | "unix" | "windows"
        // useWorker: true, // boolean: true if use web worker for loading scripts
        // useSoftTabs: true, // boolean: true if we want to use spaces than tabs
        // tabSize: 4, // number
        wrap: true // boolean | string | number: true/'free' means wrap instead of horizontal scroll, false/'off' means horizontal scroll instead of wrap, and number means number of column before wrap. -1 means wrap at print margin
        // indentedSoftWrap: true, // boolean
        // foldStyle: 'markbegin' // enum: 'manual'/'markbegin'/'markbeginend'.
        // mode: 'ace/mode/html' // string: path to language mode
      })
      this.jsonEditor = editor
    },
    initJsonEditorDiff () {
      var ace = require('ace-builds')
      require('ace-builds/webpack-resolver')
      require('ace-builds/src-noconflict/mode-json')
      require('ace-builds/src-noconflict/ext-language_tools')
      var editor = ace.edit(this.$refs.jsonEditorDiffRightRef)
      editor.setTheme('ace/theme/' + this.editorThemeName)
      editor.session.setMode('ace/mode/json')
      editor.getSession().on('change', this.onChange)
      editor.on('blur', this.onBlur)
      editor.getSession().setValue(JSON.stringify(this.defaultDiffJsonData, null, this.jsonSpaceNum))
      editor.setOptions({
        showLineNumbers: true,
        wrap: true
      })
      this.jsonEditorDiff = editor
    },
    initJavaEditor () {
      var ace = require('ace-builds')
      require('ace-builds/webpack-resolver')
      require('ace-builds/src-noconflict/mode-java')
      require('ace-builds/src-noconflict/ext-language_tools')
      var editor = ace.edit(this.$refs.javaEditorRef)
      editor.setTheme('ace/theme/' + this.editorThemeName)
      editor.session.setMode('ace/mode/java')
      editor.getSession().on('change', this.onJavaChange)
      editor.getSession().setValue('public class HelloWorld {\n' +
        '\n' +
        '    public static void main(String[] args) {\n' +
        '\n' +
        '        System.out.println("Hello World")\n' +
        '    }\n' +
        '\n' +
        '}')
      editor.setOptions({
        showLineNumbers: true,
        wrap: true
      })
      this.javaEditor = editor
    },
    initMultiEditor (mode) {
      var ace = require('ace-builds')
      require('ace-builds/webpack-resolver')
      require('ace-builds/src-noconflict/mode-java')
      require('ace-builds/src-noconflict/ext-language_tools')
      var editor = ace.edit(this.$refs.multiEditorRef)
      editor.setTheme('ace/theme/' + this.editorThemeName)
      editor.session.setMode('ace/mode/' + mode)
      // editor.getSession().on('change', this.onMultiEditorChange())
      // editor.getSession().setValue('public class HelloWorld {\n' +
      //   '\n' +
      //   '    public static void main(String[] args) {\n' +
      //   '\n' +
      //   '        System.out.println("Hello World")\n' +
      //   '    }\n' +
      //   '\n' +
      //   '}')
      editor.getSession().setValue('')
      editor.setOptions({
        showLineNumbers: true,
        wrap: true
      })
      this.multiEditor = editor
    },
    onJavaChange (e) {
    },
    toSave () {
      this.enableSaveForm = true
      this.saveForm.jsonData = JSON.stringify(JSON.parse(this.jsonEditor.getValue()), null, this.jsonSpaceNum)
      this.saveForm.id = this.jsonRecordId
      this.saveForm.recordName = this.jsonRecordName
      if (this.jsonRecordId) {
        this.saveForm.saveNew = false
      } else {
        this.saveForm.saveNew = true
      }
      UsrzJsonRecordApi.countTotal().then(op => {
        if (op) {
          this.saveForm.jsonRecordCount = op
        }
      })
      UsrzBizConfigApi.findUsrzBizConfig().then(op => {
        if (op) {
          this.saveForm.jsonRecordMaxCount = op.jsonRecordMaxCount
        }
      })
    },
    doSave () {
      if (this.saveForm.saveNew) {
        if (this.saveForm.jsonRecordCount >= this.saveForm.jsonRecordMaxCount) {
          this.saveForm.jsonRecordOverLimit = true
        } else {
          this.addJsonRecord()
        }
      } else {
        this.updateJsonRecord()
      }
    },
    addJsonRecord () {
      const ip = {
        recordName: null,
        jsonData: null
      }
      ip.recordName = this.saveForm.recordName
      ip.jsonData = this.saveForm.jsonData
      UsrzJsonRecordApi.add(ip)
        .then(rt => {
          this.jsonRecordId = rt.id
          this.enableSaveForm = false
        })
    },
    updateJsonRecord () {
      const ip = {
        findField: {
          id: this.jsonRecordId
        },
        updateField: {
          recordName: this.saveForm.recordName,
          jsonData: this.saveForm.jsonData
        }
      }
      UsrzJsonRecordApi.update(ip)
        .then(() => {
          this.enableSaveForm = false
        })
    },
    goToPurVojistarCard () {
      this.$router.push('/perCenter/vojistarCard')
    },
    toViewHistory () {
      this.$router.push('/tool/jsonRecord')
    },
    onToExcelJsonArrayModeChange (newMode) {
      this.toExcelForm.jsonArrayEleMode = null
    },
    onFromExcelJsonArrayModeChange (newMode) {
      this.fromExcelForm.jsonArrayEleMode = null
    },
    toToExcel () {
      this.enableToExcelDialog = true
    },
    doToExcel () {
      const ip = {
        sourceJsonStr: this.jsonEditor.getValue(),
        targetFileName: this.toExcelForm.fileTitle + '.xlsx',
        toExcelConfig: {
          jsonArrayMode: 'MULTI_COL',
          jsonArrayEleMode: 'SUB_HEADER',
          jsonObjectMode: 'SUB_HEADER'
        }
      }
      JsonOneApi.convertToExcel(ip)
      this.enableToExcelDialog = false
    },
    toFromExcel () {
      this.enableFromExcelDialog = true
    },
    parseJsonFromExcel (event) {
      const formData = new FormData()
      formData.append('excelFile', event.file)
      formData.append('headerCount', this.fromExcelForm.headerCount)
      formData.append('enableMultiType', this.fromExcelForm.enableMultiType)
      formData.append('jsonArrayMode', this.fromExcelForm.jsonArrayMode)
      formData.append('jsonArrayEleMode', this.fromExcelForm.jsonArrayEleMode)
      formData.append('jsonObjectMode', this.fromExcelForm.jsonObjectMode)

      JsonOneApi.parseFromExcel(formData)
        .then(resultData => {
          if (resultData) {
            event.onSuccess()
            this.jsonEditor.getSession().setValue(JSON.stringify(JSON.parse(resultData), null, this.jsonSpaceNum))
            this.$refs.excelUploadRef.clearFiles()
            this.enableFromExcelDialog = false
          }
        })
        .catch(err => {
          event.onError(err)
        })
    },
    toToCsv () {
      this.enableToCsvDialog = true
    },
    doToCsv () {
      const ip = {
        sourceJsonStr: this.jsonEditor.getValue(),
        targetFileName: this.toCsvForm.fileTitle + '.csv'
      }
      JsonOneApi.convertToCsv(ip)
      this.enableToCsvDialog = false
    },
    toFromCsv () {
      this.enableFromCsvDialog = true
    },
    parseJsonFromCsv (event) {
      const formData = new FormData()
      formData.append('csvFile', event.file)
      formData.append('enableMultiType', this.fromCsvForm.enableMultiType)
      formData.append('enableMultiLayer', this.fromCsvForm.enableMultiLayer)
      formData.append('enableArray', this.fromCsvForm.enableArray)

      JsonOneApi.parseFromCsv(formData)
        .then(resultData => {
          if (resultData) {
            event.onSuccess()
            this.jsonEditor.getSession().setValue(JSON.stringify(JSON.parse(resultData), null, this.jsonSpaceNum))
            this.$refs.csvUploadRef.clearFiles()
            this.enableFromCsvDialog = false
          }
        })
        .catch(err => {
          event.onError(err)
        })
    },
    toFromText () {
      this.enableGenKey = true
      this.toFrom('TEXT')
    },
    doFromText () {
      const ip = {
        sourceText: this.multiEditor.getValue(),
        enableGenKey: this.enableGenKey
      }
      JsonOneApi.extractFromText(ip)
        .then(resultData => {
          if (resultData) {
            this.jsonEditor.getSession().setValue(resultData)
            this.enableMultiEditorDialog = false
          }
        })
    },
    toFromImg () {
      this.enableFromImgDialog = true
    },
    extractJsonFromImg (event) {
      const formData = new FormData()
      formData.append('imgFile', event.file)
      formData.append('enableGenKey', this.enableGenKey)

      JsonOneApi.extractFromImg(formData)
        .then(resultData => {
          if (resultData) {
            event.onSuccess()
            this.jsonEditor.getSession().setValue(resultData, null, this.jsonSpaceNum)
            this.$refs.imgUploadRef.clearFiles()
            this.enableFromImgDialog = false
          }
        })
        .catch(err => {
          event.onError(err)
        })
    },
    toTo (targetContentType) {
      this.isFromModel = false
      this.curContentType = targetContentType
      this.doTo()
    },
    doTo () {
      const ip = {
        sourceJsonStr: this.jsonEditor.getValue(),
        targetContentType: this.curContentType
      }
      JsonOneApi.convertTo(ip).then(resultData => {
        if (resultData) {
          this.enableMultiEditorDialog = true
          setTimeout(() => {
            this.initMultiEditor(this.contentTypeModeMap.get(this.curContentType))
            this.multiEditor.getSession().setValue(resultData)
          }, 500)
        }
      })
    },
    toFrom (sourceContentType) {
      this.isFromModel = true
      this.enableMultiEditorDialog = true
      this.curContentType = sourceContentType
      setTimeout(() => {
        this.initMultiEditor(this.contentTypeModeMap.get(sourceContentType))
      }, 500)
    },
    doFrom () {
      const ip = {
        sourceContent: this.multiEditor.getValue(),
        sourceContentType: this.curContentType
      }
      JsonOneApi.parseFrom(ip)
        .then(resultData => {
          if (resultData) {
            this.jsonEditor.getSession().setValue(resultData)
            this.enableMultiEditorDialog = false
          }
        })
    },
    toToJava () {
      this.isFromModel = false
      this.enableToJavaDialog = true
    },
    doToJava () {
      const ip = {
        sourceJsonStr: this.jsonEditor.getValue(),
        targetContentType: 'JAVA'
      }
      JsonOneApi.convertTo(ip).then(resultData => {
        if (resultData) {
          this.enableToJavaDialog = false
          this.enableJavaEditorDialog = true
          if (!this.javaEditor) {
            setTimeout(() => {
              this.initJavaEditor()
              this.javaEditor.getSession().setValue(resultData)
            }, 500)
          } else {
            this.javaEditor.getSession().setValue(resultData)
          }
        }
      })
    },
    toFromJava () {
      this.isFromModel = true
      this.enableJavaEditorDialog = true
      if (!this.javaEditor) {
        setTimeout(() => {
          this.initJavaEditor()
        }, 500)
      }
    },
    doFromJava () {
      const ip = {
        sourceContent: this.javaEditor.getValue(),
        sourceContentType: 'JAVA'
      }
      JsonOneApi.parseFrom(ip)
        .then(resultData => {
          if (resultData) {
            this.jsonEditor.getSession().setValue(resultData)
            this.enableJavaEditorDialog = false
          }
        })
    },
    onMenuSelected (menuIndex, menuPath) {
      switch (menuIndex) {
        case 'save':
          this.toSave()
          break
        case 'view':
          this.toViewHistory()
          break
        case 'toExcel':
          this.toToExcel()
          break
        case 'toCsv':
          this.toToCsv()
          break
        case 'toSql':
          this.toTo('SQL')
          break
        case 'toYaml':
          this.toTo('YAML')
          break
        case 'toProperties':
          this.toTo('PROPERTIES')
          break
        case 'toJava':
          this.toToJava()
          break
        case 'toGo':
          this.toTo('GO')
          break
        case 'toCpp':
          this.toTo('CPP')
          break
        case 'toPhp5':
          this.toTo('PHP_5')
          break
        case 'toPhp7':
          this.toTo('PHP_7')
          break
        case 'fromExcel':
          this.toFromExcel()
          break
        case 'fromCsv':
          this.toFromCsv()
          break
        case 'fromSql':
          this.toFrom('SQL')
          break
        case 'fromYaml':
          this.toFrom('YAML')
          break
        case 'fromProperties':
          this.toFrom('PROPERTIES')
          break
        case 'fromJava':
          this.toFromJava()
          break
        case 'fromGo':
          this.toFrom('GO')
          break
        case 'fromCpp':
          this.toFrom('CPP')
          break
        case 'fromPhp':
          this.toFrom('PHP')
          break
        case 'fromText':
          this.toFromText()
          break
        case 'fromImg':
          this.toFromImg()
          break
      }
    }
  }
}
</script>

<style lang="scss">
</style>
