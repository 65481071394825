<template>
  <div>
    <div class="unit-input-div">
      <el-input class="item unit-converter-input-number-area" v-model="input" @input="onInput"></el-input>
      <el-select class="item" v-model="unit1" filterable @change="onUnitChange" ref="unit1OptionRef">
        <el-option-group
          v-for="unitOption in unitOptions"
          :key="unitOption.categ"
          :label="unitOption.categ">
          <el-option
            v-for="(item, idx) in unitOption.units"
            :key="unitOption.categ + '.' + idx"
            :label="item.unitName"
            :value="item.unitKey">
          </el-option>
        </el-option-group>
      </el-select>
      <el-button class="item" icon="el-icon-refresh" circle @click="exchangeUnit"></el-button>
      <el-select class="item" v-model="unit2" filterable @change="onUnitChange" ref="unit2OptionRef">
        <el-option-group
          v-for="unitOption in unitOptions"
          :key="unitOption.categ"
          :label="unitOption.categ">
          <el-option
            v-for="(item, idx) in unitOption.units"
            :key="unitOption.categ + '.' + idx"
            :label="item.unitName"
            :value="item.unitKey">
          </el-option>
        </el-option-group>
      </el-select>
      <el-button class="item" icon="el-icon-more" circle @click="showAll"></el-button>
    </div>
    <div v-if="this.result">
      <el-divider></el-divider>
      <h3 v-if="this.result">{{ this.result }}</h3>
    </div>
    <el-divider></el-divider>
    <div>
      <el-table
        :data="tableData"
        height="500px"
        border
        :span-method="objectSpanMethod"
        style="width: 100%">
        <el-table-column
          prop="categ"
          :label="$t('unitConverter.categ')"
          width="150">
        </el-table-column>
        <el-table-column
          prop="unitName"
          :label="$t('unitConverter.unit')"
          width="180">
        </el-table-column>
        <el-table-column
          prop="fromSiuFormula"
          label="FROM M(kg)">
        </el-table-column>
        <el-table-column
          prop="toSiuFormula"
          label="TO M(kg)">
        </el-table-column>
        <el-table-column
          prop="remark"
          :label="$t('cmn.remark')">
        </el-table-column>
      </el-table>
    </div>

    <div>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableShowAllDialog"
        append-to-body
      >
        <div style="display: flex">
          <el-input class="unit-converter-input-number-area" v-model="input" @input="onInput"></el-input>
          <el-select v-model="unit1" @change="onUnitChange" ref="unit1OptionRef">
            <el-option-group
              v-for="unitOption in unitOptions"
              :key="unitOption.categ"
              :label="unitOption.categ">
              <el-option
                v-for="(item, idx) in unitOption.units"
                :key="unitOption.categ + '.' + idx"
                :label="item.unitName"
                :value="item.unitKey">
              </el-option>
            </el-option-group>
          </el-select>
        </div>
        <div>
          <div v-for="(item, idx) in allUnitResult" :key="idx">
            {{ item }}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js'

const math = require('mathjs')

export default {
  name: 'LengthUnit',
  computed: {
    result: {
      get () {
        return this.calUnitResult()
      },
      set (v) {
      }
    },
    allUnitResult: {
      get () {
        return this.calAllUnit()
      },
      set (v) {
      }
    },
    unitData: {
      get () {
        return {
          siu: {
            kg: {
              categ: this.$t('unitConverter.siu'),
              unitName: this.$t('massUnit.kg'),
              unitKey: 'kg',
              fromSiuFormula: 'M(kg) = M(kg)',
              toSiuFormula: 'M(kg) = M(kg)',
              fromSiuExp: 'x * 1',
              toSiuExp: 'y * 1',
              remark: this.$t('massUnit.remark.kg')
            }
          },
          metricUnit: {
            ug: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('massUnit.ug'),
              unitKey: 'ug',
              fromSiuFormula: 'M(μg) = M(kg) * 1e+9',
              toSiuFormula: 'M(kg) = M(μg) * 1e-9',
              fromSiuExp: 'x * 1e+9',
              toSiuExp: 'y * 1e-9',
              remark: this.$t('massUnit.remark.ug')
            },
            mg: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('massUnit.mg'),
              unitKey: 'mg',
              fromSiuFormula: 'M(mg) = M(kg) * 1e+6',
              toSiuFormula: 'M(kg) = M(mg) * 1e-6',
              fromSiuExp: 'x * 1e+6',
              toSiuExp: 'y * 1e-6',
              remark: this.$t('massUnit.remark.mg')
            },
            g: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('massUnit.g'),
              unitKey: 'g',
              fromSiuFormula: 'M(g) = M(kg) * 1e+3 = M(kg) * 1000',
              toSiuFormula: 'M(kg) = M(g) * 1e-3 = M(g) * 0.001',
              fromSiuExp: 'x * 1000',
              toSiuExp: 'y * 0.001',
              remark: this.$t('massUnit.remark.g')
            },
            carat: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('massUnit.carat'),
              unitKey: 'carat',
              fromSiuFormula: 'M(carat) = M(kg) / 0.0002',
              toSiuFormula: 'M(kg) = M(carat) * 0.0002',
              fromSiuExp: 'x / 0.0002',
              toSiuExp: 'y * 0.0002',
              remark: this.$t('massUnit.remark.carat')
            },
            quintal: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('massUnit.quintal'),
              unitKey: 'quintal',
              fromSiuFormula: 'M(q) = M(kg) * 100',
              toSiuFormula: 'M(kg) = M(q) * 0.01',
              fromSiuExp: 'x * 100',
              toSiuExp: 'y * 0.01',
              remark: this.$t('massUnit.remark.quintal')
            },
            kg: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('massUnit.kg'),
              unitKey: 'kg',
              fromSiuFormula: 'M(kg) = M(kg)',
              toSiuFormula: 'M(kg) = M(kg)',
              fromSiuExp: 'x * 1',
              toSiuExp: 'y * 1',
              remark: this.$t('massUnit.remark.kg')
            },
            tonne: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('massUnit.tonne'),
              unitKey: 't',
              fromSiuFormula: 'M(t) = M(kg) / 1000',
              toSiuFormula: 'M(kg) = M(t) * 1000',
              fromSiuExp: 'x / 1000',
              toSiuExp: 'y * 1000',
              remark: this.$t('massUnit.remark.tonne')
            }
          },
          municipalUnit: {
            Qian: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('massUnit.Qian'),
              unitKey: 'Qian',
              fromSiuFormula: 'M(钱) = M(kg) * 200',
              toSiuFormula: 'M(kg) = M(钱) * 0.005',
              fromSiuExp: 'x * 200',
              toSiuExp: 'y * 0.005',
              remark: this.$t('massUnit.remark.Qian')
            },
            Liang: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('massUnit.Liang'),
              unitKey: 'Liang',
              fromSiuFormula: 'M(两) = M(kg) * 2 * 10 = M(kg) * 20',
              toSiuFormula: 'M(kg) = M(两) * 0.05',
              fromSiuExp: 'x * 20',
              toSiuExp: 'y * 0.05',
              remark: this.$t('massUnit.remark.Liang')
            },
            Jin: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('massUnit.Jin'),
              unitKey: 'Jin',
              fromSiuFormula: 'M(斤) = M(kg) * 2',
              toSiuFormula: 'M(kg) = M(斤) * 0.5',
              fromSiuExp: 'x * 2',
              toSiuExp: 'y * 0.5',
              remark: this.$t('massUnit.remark.Jin')
            }
          },
          imperialUnit: {
            grain: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('massUnit.grain'),
              unitKey: 'grain',
              fromSiuFormula: 'M(gr) = M(kg) * 1e+5 / 6.479891',
              toSiuFormula: 'M(kg) = M(gr) * 6.479891e-5',
              fromSiuExp: 'x * 1e+5 / 6.479891',
              toSiuExp: 'y * 6.479891e-5',
              remark: this.$t('massUnit.remark.grain')
            },
            dram: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('massUnit.dram'),
              unitKey: 'dram',
              fromSiuFormula: 'M(dr) = M(kg) * 16 / 0.028349523125',
              toSiuFormula: 'M(kg) = ( M(dr) / 16 ) * 0.028349523125)',
              fromSiuExp: 'x * 16 / 0.028349523125',
              toSiuExp: '( y / 16 ) * 0.028349523125)',
              remark: this.$t('massUnit.remark.dram')
            },
            ounce: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('massUnit.ounce'),
              unitKey: 'ounce',
              fromSiuFormula: 'M(oz) = M(kg) / 0.028349523125',
              toSiuFormula: 'M(kg) = M(oz) * 0.028349523125',
              fromSiuExp: 'x / 0.028349523125',
              toSiuExp: 'y * 0.028349523125',
              remark: this.$t('massUnit.remark.ounce')
            },
            pound: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('massUnit.pound'),
              unitKey: 'pound',
              fromSiuFormula: 'M(lb) = M(kg) / 0.45359237',
              toSiuFormula: 'M(kg) = M(lb) * 0.45359237',
              fromSiuExp: 'x / 0.45359237',
              toSiuExp: 'y * 0.45359237',
              remark: this.$t('massUnit.remark.lb')
            },
            stone: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('massUnit.stone'),
              unitKey: 'stone',
              fromSiuFormula: 'M(st) = M(kg) / 6.35029318',
              toSiuFormula: 'M(kg) = M(st) * 6.35029318',
              fromSiuExp: 'x / 6.35029318',
              toSiuExp: 'y * 6.35029318',
              remark: this.$t('massUnit.remark.stone')
            },
            usHundredweight: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('massUnit.usHundredweight'),
              unitKey: 'usHundredweight',
              fromSiuFormula: 'M(cwt) = M(kg) / 45.359237',
              toSiuFormula: 'M(kg) = M(cwt) * 45.359237',
              fromSiuExp: 'x / 45.359237',
              toSiuExp: 'y * 45.359237',
              remark: this.$t('massUnit.remark.usHundredweight')
            },
            ukHundredweight: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('massUnit.ukHundredweight'),
              unitKey: 'ukHundredweight',
              fromSiuFormula: 'M(cwt) = M(kg) / 50.80234544',
              toSiuFormula: 'M(kg) = M(cwt) * 50.80234544',
              fromSiuExp: 'x / 50.80234544',
              toSiuExp: 'y * 50.80234544',
              remark: this.$t('massUnit.remark.ukHundredweight')
            },
            shortTon: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('massUnit.shortTon'),
              unitKey: 'shortTon',
              fromSiuFormula: 'M(ton) = M(kg) / 907.18474',
              toSiuFormula: 'M(kg) = M(ton) * 907.18474',
              fromSiuExp: 'x / 907.18474',
              toSiuExp: 'y * 907.18474',
              remark: this.$t('massUnit.remark.shortTon')
            },
            longTon: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('massUnit.longTon'),
              unitKey: 'longTon',
              fromSiuFormula: 'M(ton) = M(kg) / 1016.0469088',
              toSiuFormula: 'M(kg) = M(ton) * 1016.0469088',
              fromSiuExp: 'x / 1016.0469088',
              toSiuExp: 'y * 1016.0469088',
              remark: this.$t('massUnit.remark.longTon')
            }
          }
        }
      },
      set (v) {
      }
    },
    unitOptions: {
      get () {
        return [
          {
            categ: this.$t('unitConverter.siu'),
            units: Object.values(this.unitData.siu)
          },
          {
            categ: this.$t('unitConverter.metricUnit'),
            units: Object.values(this.unitData.metricUnit)
          },
          {
            categ: this.$t('unitConverter.municipalUnit'),
            units: Object.values(this.unitData.municipalUnit)
          },
          {
            categ: this.$t('unitConverter.imperialUnit'),
            units: Object.values(this.unitData.imperialUnit)
          }
        ]
      },
      set (v) {
      }
    },
    tableData: {
      get () {
        return [...Object.values(this.unitData.siu), ...Object.values(this.unitData.metricUnit),
          ...Object.values(this.unitData.municipalUnit), ...Object.values(this.unitData.imperialUnit)
        ]
      },
      set (v) {
      }
    }
  },
  data () {
    return {
      unit1: 'Jin',
      unit2: 'kg',
      input: null,
      enableShowAllDialog: false
    }
  },
  methods: {
    onInput () {
      this.result = this.calUnitResult()
      if (this.enableShowAllDialog) {
        this.showAll()
      }
    },

    onUnitChange () {
      this.result = this.calUnitResult()
      if (this.enableShowAllDialog) {
        this.showAll()
      }
    },

    showAll () {
      this.allUnitResult = this.calAllUnit()
      this.enableShowAllDialog = true
    },
    exchangeUnit () {
      const tempUnit = this.unit1
      this.unit1 = this.unit2
      this.unit2 = tempUnit
      this.onUnitChange()
    },
    calAllUnit () {
      const allUnitResult = []
      for (let i = 0; i < this.unitOptions.length; i++) {
        if (i !== 0) {
          const unitOptions = this.unitOptions.at(i).units
          for (const unitOption of unitOptions) {
            allUnitResult.push(this.calResult(unitOption))
          }
        }
      }
      return allUnitResult
    },
    calUnitResult () {
      if (this.input && this.unit1 && this.unit2) {
        const inputNum = new BigNumber(math.evaluate(this.input))
        if (inputNum.isFinite()) {
          const unit1Data = this.getUnitDataByUnitKey(this.unit1)
          const unit2Data = this.getUnitDataByUnitKey(this.unit2)
          if (unit1Data && unit2Data) {
            const to = math.evaluate(unit1Data.toSiuExp.replaceAll('y', this.input))
            const from = math.evaluate(unit2Data.fromSiuExp.replaceAll('x', to))
            return this.input + ' ' + this.$refs.unit1OptionRef.selected.label + '  =  ' + from + ' ' + this.$refs.unit2OptionRef.selected.label
          } else {
            return null
          }
        }
        return null
      } else {
        return null
      }
    },
    calResult (resultUnit) {
      if (this.input && this.unit1 && resultUnit) {
        const inputNum = new BigNumber(math.evaluate(this.input))
        if (inputNum.isFinite()) {
          const unit1Data = this.getUnitDataByUnitKey(this.unit1)
          const unit2Data = resultUnit
          if (unit1Data && unit2Data) {
            const to = math.evaluate(unit1Data.toSiuExp.replaceAll('y', this.input))
            const from = math.evaluate(unit2Data.fromSiuExp.replaceAll('x', to))
            return this.input + ' ' + this.$refs.unit1OptionRef.selected.label + '  =  ' + from + ' ' + resultUnit.unitName
          } else {
            return null
          }
        }
        return null
      } else {
        return null
      }
    },
    getUnitDataByUnitKey (unitKey) {
      for (const unit of Object.values(this.unitData)) {
        if (unit && unit[unitKey]) {
          return unit[unitKey]
        }
      }
      return null
    },
    objectSpanMethod ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 1,
            colspan: 1
          }
        }
        if (rowIndex === 1) {
          return {
            rowspan: 7,
            colspan: 1
          }
        }
        if (rowIndex === 8) {
          return {
            rowspan: 3,
            colspan: 1
          }
        }
        if (rowIndex === 11) {
          return {
            rowspan: 9,
            colspan: 1
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
