import resultHttpTool from '@/http/ResultHttpTool'

const API_PREFIX = '/api/usr/usrzDayRecord'

export function findOrGenLatest () {
  return resultHttpTool.post(API_PREFIX + '/findOrGenLatest')
}

export function canNew () {
  return resultHttpTool.get(API_PREFIX + '/canNew')
}

export function toDoNew () {
  return resultHttpTool.post(API_PREFIX + '/toDoNew')
}

export function doneNew (ip) {
  return resultHttpTool.post(API_PREFIX + '/doneNew', ip)
}

export function doNew (ip) {
  return resultHttpTool.post(API_PREFIX + '/doNew', ip)
}

export function setDayWords (ip) {
  return resultHttpTool.post(API_PREFIX + '/setDayWords', ip)
}

export function toToday () {
  return resultHttpTool.post(API_PREFIX + '/toToday')
}

export function findByPage (findByPageIp) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', formatIp(findByPageIp))
}

function formatIp (findByPageIp) {
  if (findByPageIp === null) {
    findByPageIp = {
      srchIp: null,
      pageIp: {
        current: 1,
        size: 7
      },
      orderBys: [
        {
          key: 'update_msec',
          asc: false
        }
      ],
      timeIp: null
    }
  }
  return findByPageIp
}

export default {
  findOrGenLatest,
  canNew,
  toDoNew,
  doneNew,
  doNew,
  setDayWords,
  toToday,
  findByPage
}
