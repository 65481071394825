<template>
  <div>
    <h2>【{{ $t('magicCorner.bigWin') }}】 {{ $t('cmn.plsWaitingFor') }}</h2>
    <p>{{ $t('cmn.plsJoinUs') }}</p>
  </div>
</template>

<script>
export default {
  name: 'BigWin'
}
</script>

<style scoped>

</style>
