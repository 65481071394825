<template>
  <div>
    <template v-for="menu in this.menuList">
      <el-submenu :key="menu.index" :index="menu.index" v-if="menu.children!=null && menu.children.length>0">
        <template slot="title">
          <i :class="menu.icon"></i>
          <span v-if="menu.nameKey" slot="title">{{ $t(menu.nameKey) }}</span>
          <span v-else slot="title">{{ menu.name }}</span>
        </template>
        <menu-tree :menu-list="menu.children"></menu-tree>
      </el-submenu>
      <el-menu-item :key="menu.index" :index="menu.index" v-else>
        <i :class="menu.icon"></i>
        <span v-if="menu.nameKey" slot="title">{{ $t(menu.nameKey) }}</span>
        <span v-else slot="title">{{ menu.name }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  props: ['menuList'],
  name: 'MenuTree'
}
</script>
