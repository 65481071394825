const moment = require('moment-timezone')
export default class TimeUtil {
  TimeUtil () {
  }

  static guessCurrentTimezoneId () {
    const timeZoneId = -Math.floor(new Date().getTimezoneOffset() / 60)
    return timeZoneId
  }

  static convertDateTimeStrToTsSec (dateTimeStr, timezoneId) {
    return moment.tz(dateTimeStr, 'Atlantic/Reykjavik').unix() - timezoneId * 60 * 60
  }

  static convertTsMillisToDateTimeStr (tsMillis, timezoneId) {
    return moment(Number(tsMillis))
      .utcOffset(timezoneId)
      .format('YYYY-MM-DD HH:mm:ss')
  }

  static convertMsecToDateTimeStr (msec) {
    return moment(Number(msec))
      .utcOffset(this.guessCurrentTimezoneId())
      .format('YYYY-MM-DD HH:mm:ss')
  }

  static convertMsecToDateTimeWeekStr (msec) {
    return moment(Number(msec))
      .utcOffset(this.guessCurrentTimezoneId())
      .format('YYYY-MM-DD HH:mm:ss ~ d')
    // .format('YYYY-MM-DD HH:mm:ss ddd')
  }

  static convertMsecToDateStr (msec) {
    return moment(Number(msec))
      .utcOffset(this.guessCurrentTimezoneId())
      .format('YYYY-MM-DD')
  }
}
