<template>
  <div class="app-container">
    <el-row :gutter="30">
      <!--  左侧标签栏    -->
      <!--      <el-col v-if="isExtended" :xs="9" :sm="6" :md="5" :lg="4" :xl="4">-->
      <el-col v-if="isExtended" :span="4">
        <div class="head-container">
          <el-input
            v-model="toolCategSrchKw"
            clearable
            size="small"
            prefix-icon="el-icon-search"
            class="filter-item"
            @input="srchToolCateg"
          />
        </div>
        <el-tree
          node-key="id"
          :data="toolCategTreeData"
          :load="loadToolCategTrees"
          :props="toolCategoryProps"
          :expand-on-click-node="false"
          show-checkbox
          check-strictly
          lazy
          @node-click="handleNodeClick"
          @check="handleTreeNodeCheck"
        />
      </el-col>

      <!--   右侧栏   -->
      <!--      <el-col :xs="15" :sm="18" :md="19" :lg="20" :xl="20">-->
      <el-col :span="isExtended ? 20 : 24">
        <!-- 内容页面上部  -->
        <div>
          <div class="srch-div">
            <el-button v-if="isExtended" icon="el-icon-s-fold" @click="inExtend"></el-button>
            <el-button v-else icon="el-icon-s-unfold" @click="inExtend"></el-button>
            <el-input
              v-model="findByPageIp.srchIp.srchKw"
              @input="delaySrch">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-dropdown @command="handleCmd">
              <el-button icon="el-icon-s-operation"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="checkCurPage">{{ $t('cmn.checkCurPage') }}</el-dropdown-item>
                <el-dropdown-item v-if="checkedTool&&checkedTool.size>0" command="cancelCheckAll">
                  {{ $t('cmn.cancelCheckAll') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="checkedTool&&checkedTool.size>0" command="batchFav">
                  {{ $t('cmn.batchFav') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="checkedTool&&checkedTool.size>0" command="batchCancelFav">
                  {{ $t('cmn.cancelFav') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="tool-source-type-div">
            <el-radio v-model="toolSourceType" label="VJV_TOOL" @input="onChangeToolSourceType">
              {{ this.$t('toolHall.platformTool') }}
            </el-radio>
            <el-radio v-model="toolSourceType" label="THIRD_PARTY_TOOL" @input="onChangeToolSourceType">
              {{ this.$t('toolHall.thirdPartTool') }}
            </el-radio>
            <el-radio v-model="toolSourceType" label="ALL_TOOL" @input="onChangeToolSourceType">
              {{ this.$t('toolHall.allTool') }}
            </el-radio>
          </div>

          <div class="tool-shower">
            <div v-infinite-scroll="loadMoreTool"
                 :infinite-scroll-disabled="isLoadingMoreTool"
                 class="scroll-div"
            >
              <!--工具Card渲染-->
              <div class="tool-card-container">
                <el-card
                  v-for="tool in toolList"
                  :key="tool.id"
                  class="tool-card"
                >

                  <div>
                    <el-row type="flex" align="middle" :gutter="20">
                      <el-col :span="8" class="tool-logo-col">
                        <router-link v-if="tool.isVjvTool===1" :to="{path:tool.path}" target="_blank">
                          <!--                          <img src="../../assets/img/toollogo/CoCoder.png" alt="LOGO" class="tool-logo">-->
                          <img :src=toolLogo(tool) alt="Logo" class="tool-logo">
                        </router-link>
                        <a
                          v-else
                          target="_blank"
                          :href="tool.uri"
                          rel="external nofollow"
                          class="text-decoration-none"
                        >
                          <img :src="tool.logoUrl" alt="Logo" class="tool-logo">
                        </a>
                      </el-col>
                      <el-col :span="16">
                        <div class="card-info-container">
                          <div class="tool-name-area">
                            <router-link :to="{path:'/toolDetail',query:{toolId:tool.id}}" target="_blank">
                              <h3>{{ tool.name }}</h3>
                            </router-link>
                          </div>
                          <div class="tool-main-categ-area">
                            <el-tag
                              v-for="mainCateg in tool.mainCategList"
                              :key="mainCateg.id"
                              type="warning"
                              effect="dark"
                              size="mini"
                            >
                              {{ mainCateg.name }}
                            </el-tag>
                          </div>
                          <div class="opn-area">
                            <el-button
                              icon="el-icon-info"
                              circle
                              size="small"
                              @click="goToDetail(tool)"
                            />
                            <el-popover
                              :ref="'CancelFavPop.' + tool.id"
                              placement="top"
                              width="200"
                              v-if="tool.beFav"
                            >
                              <p>{{ $t('cmn.cancelFavConfirm') }}</p>
                              <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text"
                                           @click="$refs['CancelFavPop.' + tool.id].at(0).doClose()">
                                  {{ $t('cmn.cancel') }}
                                </el-button>
                                <el-button type="primary" size="mini" @click="doCancelFav(tool.id)">{{
                                    $t('cmn.confirm')
                                  }}
                                </el-button>
                              </div>
                              <el-button v-if="tool.beFav" slot="reference" icon="el-icon-star-on" circle size="small"/>
                            </el-popover>
                            <el-button v-if="!tool.beFav" icon="el-icon-star-off" circle size="small"
                                       @click="toFav(tool)"/>
                            <el-button v-if="tool.isVjvTool===1" icon="el-icon-right" circle size="small"
                                       @click="routeToTool(tool.path)"/>

                            <a
                              v-else
                              target="_blank"
                              :href="tool.uri"
                              rel="external nofollow"
                              class="text-decoration-none"
                            >
                              <el-button icon="el-icon-right" circle size="small"/>
                            </a>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div>
                    <div class="tool-profile-area">{{ tool.profile }}</div>
                    <!--                    <a-->
                    <!--                      target="_blank"-->
                    <!--                      :href="tool.uri"-->
                    <!--                      class="text-decoration-none"-->
                    <!--                    >-->
                    <!--                      {{ tool.uri }}-->
                    <!--                    </a>-->
                  </div>
                  <el-checkbox
                    :key="tool.id"
                    v-model="tool.checked"
                    class="tool-check-box"
                    @change="handleToolChecked(tool,$event)"
                  />
                </el-card>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div>
      <!-- Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableFavForm"
        :title="$t('cmn.fav')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="favFormRef"
          :inline="true"
          :model="favForm"
          :rules="favFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('toolCmn.toolName')" prop="toolName">
            <el-input v-model="favForm.toolName" disabled style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cmn.group')" prop="toolGroupIds">
            <el-tree
              v-model="favForm.toolGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              :check-strictly="true"
              show-checkbox
              lazy
              @check="handleGroupTreeNodeCheck"
            />
          </el-form-item>
          <el-form-item :label="$t('cmn.customName')" prop="customName">
            <el-input v-model="favForm.customName" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.customProfile')" prop="customProfile">
            <el-input v-model="favForm.customProfile" style="width: 300px;"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchFavForm"
        :title="$t('cmn.batchFav')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="batchFavFormRef"
          :inline="true"
          :model="batchFavForm"
          :rules="batchFavFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('toolHall.toolList')" prop="toolIds">
            <el-select
              v-model="batchFavForm.toolIds"
              :multiple="true"
              clearable
              style="width: 600px;"
            >
              <el-option
                v-for="tool in checkedTool.values()"
                :key="tool.id"
                :label="tool.name"
                :value="tool.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('cmn.group')" prop="toolGroupIds">
            <el-tree
              v-model="batchFavForm.toolGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              :check-strictly="true"
              show-checkbox
              lazy
              @check="handleGroupTreeNodeCheck"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Cancel Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchCancelFavForm"
        title="批量收藏"
        append-to-body
        width="800px"
      >
        <el-form
          ref="batchCancelFavFormRef"
          :inline="true"
          :model="batchCancelFavForm"
          :rules="batchCancelFavFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('toolHall.toolList')" prop="toolIds">
            <el-select
              v-model="batchCancelFavForm.toolIds"
              :multiple="true"
              clearable
              style="width: 600px;"
            >
              <el-option
                v-for="tool in checkedTool.values()"
                :key="tool.id"
                :label="tool.name"
                :value="tool.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchCancelFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchCancelFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import ToolApi from '@/api/vojivosvc/toolhall/ToolApi'
import ToolCategApi from '@/api/vojivosvc/toolhall/ToolCategApi'
import UsrzFavToolApi from '@/api/vojivosvc/toolhall/UsrzFavToolApi'
import UsrzToolGroupApi from '@/api/vojivosvc/toolhall/UsrzToolGroupApi'
import { DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE, LOCAL_FILE_PREFIX } from '@/constant/CmnBizConst'
import lodash from 'lodash'
import ProtRule from '@/constant/ProtRule'

export default {
  name: 'ToolHall',
  created: function () {
    // this.assignToolCategories()
    this.doSrch()
    this.initFormRules()
  },
  data () {
    return {
      isExtended: true,
      toolSourceType: 'VJV_TOOL',
      defaultPage: {
        total: 0,
        current: 1,
        size: 10
      },
      delaySrchTimeoutId: null,
      pageInfo: {},
      findByPageIp: {
        ...lodash.cloneDeep(DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE),
        toolCategTreeRootIds: [],
        vjvPlc: null,
        isVjvTool: 1
      },
      srchToolCategTreeRootIds: [],
      toolCategoryProps: {
        label: 'name',
        hasChildren: 'name'
      },
      toolCategTreeData: [],
      toolCategSrchKw: null,
      isLoadingMoreTool: true,
      toolList: [],
      enableFavForm: false,
      favForm: {
        toolName: null,
        toolId: null,
        toolGroupIds: [],
        customName: null,
        customProfile: null
      },
      favFormRules: {},
      enableBatchFavForm: false,
      batchFavForm: {
        toolNames: [],
        toolIds: [],
        toolGroupIds: []
      },
      batchFavFormRules: {},
      enableBatchCancelFavForm: false,
      batchCancelFavForm: {
        toolNames: [],
        toolIds: []
      },
      batchCancelFavFormRules: {},
      groupTrees: [
        {
          id: 0,
          label: 'NULL PARENT',
          children: null
        }
      ],
      checkedGroupIds: [],
      checkedTool: new Map()
    }
  },
  methods: {
    initFormRules () {
      this.favFormRules = {
        customName: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().length_32
        ],
        customProfile: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().length_1024
        ]
      }
    },
    inExtend () {
      this.isExtended = !this.isExtended
    },
    onChangeToolSourceType () {
      this.pageInfo = {}
      this.isLoadingMoreTool = true
      this.doSrch()
    },
    async srchToolCateg () {
      if (this.toolCategSrchKw === null || this.toolCategSrchKw.trim() === '') {
        const findTreesIp = {
          srchIp: null,
          timeIp: null,
          orderBys: [
            {
              key: 'rankValue',
              asc: true
            }
          ],
          parentIds: [],
          treeHeight: 1
        }
        this.toolCategTreeData = (await ToolCategApi.findTrees(findTreesIp))
        return
      }
      const ip = {
        srchIp: {
          srchKey: null,
          srchKw: this.toolCategSrchKw
        },
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        pageIp: {
          current: 1,
          size: 100
        }
      }
      this.toolCategTreeData = (await ToolCategApi.findByPage(ip)).data
    },
    loadToolCategTrees (node, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        ToolCategApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.toolCategTreeData = resultData
            }
          })
      }, 100)
    },

    handleNodeClick (data, node, obj) {
      if (this.srchToolCategTreeRootIds.includes(data.id)) {
        this.srchToolCategTreeRootIds.remove(data.id)
      } else {
        this.srchToolCategTreeRootIds.push(data.id)
      }
      this.doSrch()
    },
    handleTreeNodeCheck (data, checkedData) {
      this.srchToolCategTreeRootIds = []
      this.srchToolCategTreeRootIds.push(...checkedData.checkedKeys)
      this.doSrch()
    },

    refreshPageInfo (pageOp) {
      this.pageInfo.size = pageOp.pageIp.size
      this.pageInfo.current = pageOp.pageIp.current
      this.pageInfo.total = pageOp.total
    },
    delaySrch () {
      this.clearDelaySrchTimeout()
      this.delaySrchTimeoutId = setTimeout(() => {
        this.doSrch(false)
      }, 1000)
    },
    clearDelaySrchTimeout () {
      if (this.delaySrchTimeoutId) {
        clearTimeout(this.delaySrchTimeoutId)
        this.delaySrchTimeoutId = null
      }
    },
    doSrch (isLoadingMore) {
      if (isLoadingMore) {
        this.findByPageIp.pageIp.current = this.pageInfo.current
        this.findByPageIp.pageIp.size = this.pageInfo.size
      } else {
        this.findByPageIp.pageIp = { ...this.defaultPage }
      }

      this.findByPageIp.toolCategTreeRootIds = this.srchToolCategTreeRootIds
      switch (this.toolSourceType) {
        case 'VJV_TOOL':
          this.findByPageIp.isVjvTool = 1
          break
        case 'THIRD_PARTY_TOOL':
          this.findByPageIp.isVjvTool = 0
          break
        case 'ALL_TOOL':
          this.findByPageIp.isVjvTool = null
          break
      }
      ToolApi.findByPage(this.findByPageIp).then(pageOp => {
        this.isLoadingMoreTool = false
        this.refreshPageInfo(pageOp)
        const data = pageOp.data
        data.map(item => {
          if (this.checkedTool.has(item.id)) {
            this.$set(item, 'checked', true)
          } else {
            this.$set(item, 'checked', false)
          }
          return item
        })
        if (isLoadingMore) {
          this.toolList.push(...data)
        } else {
          this.toolList = data
        }
      })
    },

    loadMoreTool () {
      this.isLoadingMoreTool = true
      if (this.pageInfo.current >= this.pageInfo.total / this.pageInfo.size) {
        return
      }
      this.pageInfo.current += 1
      this.doSrch(true)
    },

    routeToTool (path) {
      this.$router.push(path)
    },
    goToDetail (tool) {
      const routeData = this.$router.resolve({
        path: '/toolDetail',
        query: {
          toolId: tool.id
        }
      })
      window.open(routeData.href, '_blank')
    },
    toFav (tool) {
      this.enableFavForm = true
      this.favForm.toolId = tool.id
      this.favForm.toolName = tool.name
      this.favForm.toolGroupIds = []
    },
    doCancelFav (toolId) {
      const ip = {
        toolIds: []
      }
      ip.toolIds.push(toolId)
      UsrzFavToolApi.batchCancelFav(ip)
        .then(resultData => {
          if (resultData) {
            for (const tool of this.toolList) {
              if (toolId === tool.id) {
                tool.beFav = false
              }
            }
            this.$refs['CancelFavPop.' + toolId].at(0).doClose()
          }
        })
    },
    doFav () {
      this.$refs.favFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          ...this.favForm
        }
        UsrzFavToolApi.fav(ip).then(rd => {
          if (rd) {
            this.enableFavForm = false
            for (const tool of this.toolList) {
              if (this.favForm.toolId === tool.id) {
                tool.beFav = true
              }
            }
          }
        })
      })
    },
    loadGroupTrees (node, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        UsrzToolGroupApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.groupTrees = resultData
            }
          })
      }, 100)
    },
    handleGroupTreeNodeCheck (data, checkedData) {
      this.checkedGroupIds = []
      this.checkedGroupIds.push(...checkedData.checkedKeys)
      this.favForm.toolGroupIds = []
      this.favForm.toolGroupIds.push(...checkedData.checkedKeys)
    },
    handleToolChecked (tool, data) {
      this.checkedTool.set(tool.id, tool)
    },
    toolLogo (tool) {
      if (tool.logoUrl === LOCAL_FILE_PREFIX) {
        return require('@/assets/img/toollogo/' + tool.unid + '.png')
      } else if (tool.logoUrl.startsWith(LOCAL_FILE_PREFIX)) {
        return require(tool.logoUrl.substring(LOCAL_FILE_PREFIX.length))
      } else {
        return tool.logoUrl
      }
    },
    handleCmd (cmd) {
      switch (cmd) {
        case 'batchFav':
          this.toBatchFav()
          break
        case 'batchCancelFav':
          this.toBatchCancelFav()
          break
        case 'checkCurPage':
          this.checkCurPage()
          break
        case 'cancelCheckAll':
          this.cancelCheckAll()
          break
      }
    },
    toBatchFav () {
      this.enableBatchFavForm = true
      this.batchFavForm.toolIds.push(...this.checkedTool.keys())
    },
    doBatchFav () {
      this.$refs.batchFavFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          toolIds: this.batchFavForm.toolIds,
          toolGroupIds: this.checkedGroupIds
        }
        UsrzFavToolApi.batchFav(ip).then(rd => {
          if (rd) {
            for (const tool of this.toolList) {
              if (this.batchFavForm.toolIds.includes(tool.id)) {
                tool.beFav = true
              }
            }
            this.enableBatchFavForm = false
          }
        })
      })
    },
    toBatchCancelFav () {
      this.enableBatchCancelFavForm = true
      this.batchCancelFavForm.toolIds.push(...this.checkedTool.keys())
    },
    doBatchCancelFav () {
      this.$refs.batchCancelFavFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          toolIds: this.batchCancelFavForm.toolIds
        }
        UsrzFavToolApi.batchCancelFav(ip)
          .then(rd => {
            if (rd) {
              for (const tool of this.toolList) {
                if (this.batchFavForm.toolIds.includes(tool.id)) {
                  tool.beFav = false
                }
              }
              this.enableBatchCancelFavForm = false
            }
          })
      })
    },
    checkCurPage () {
      this.toolList.map(item => {
        // 这里，给对象添加属性，用$set方法。
        this.$set(item, 'checked', true)
        this.checkedTool.set(item.id, item)
        return item
      })
    },
    cancelCheckAll () {
      this.toolList.map(item => {
        // 这里，给对象添加属性，用$set方法。
        this.$set(item, 'checked', false)
        this.checkedTool.delete(item.id)
        return item
      })
    }
  }

}
</script>

<style lang="less" scoped>
//.card-container {
//  display: flex;
//  justify-content: space-between;
//  flex-wrap: wrap;
//  align-items: start;
//}
//
//.el-row {
//  height: 100%
//}
//
//.tool-logo {
//  border-radius: 100%;
//  //background-color: red;
//  width: 30px;
//  height: 30px;
//}
//
//.card-info-container {
//  background-color: white;
//}
//
//.basic-area {
//  //background-color: red;
//  //width: 60px;
//  //height: 60px;
//}
//
//.name-category {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//}
//
//.btn-area {
//  display: flex;
//  //background-color: green;
//  //width: 60px;
//  //height: 60px;
//}
//
//.tool-card {
//  padding: 0;
//  margin: 9px;
//  width: 300px;
//  height: 200px;
//}
//
//.el-divider {
//  margin: 2px;
//}
//
//a {
//  text-decoration: none;
//  color: black;
//}
//
//.router-link-active {
//  text-decoration: none;
//  color: black;
//}
//
//.tool-profile {
//  color: #4c4c4c;
//}
//
//span {
//  color: #a1a1a1;
//}
//
//.tool-url {
//  color: #a1a1a1;
//}
</style>
