import resultHttpTool from '@/http/ResultHttpTool'
import opMsgHttpTool from '@/http/OpMsgHttpTool'

const API_PREFIX = '/api/usr'

export function deregister (unid, pwd) {
  const ip = {
    unid: unid,
    pwd: pwd
  }
  return resultHttpTool.post(API_PREFIX + '/deregister', ip)
}

export function register (ip) {
  return opMsgHttpTool.post(API_PREFIX + '/register', ip)
}

export function sendActivationUrl (ip) {
  return opMsgHttpTool.post(API_PREFIX + '/sendActivationUrl', ip)
}

export default {
  register,
  deregister,
  sendActivationUrl
}
