import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/hash'

export function crack (hashValue, hashAlg) {
  return resultHttpTool.get(API_PREFIX + '/crack?hashValue=' + hashValue + '&hashAlg=' + hashAlg)
    .then(resultData => {
      if (resultData) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return resultData
    })
}

export default { crack }
