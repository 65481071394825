<template>
  <div>
    <el-form ref="usrPerFormRef" :model="usrPerForm" :rules="usrPerFormRules"
             label-width="80px"
             label-position='left'
             class="user-form">
      <el-form-item :label="$t('userCenter.usrPer.name')" prop="name">
        <el-input v-model="usrPerForm.name" style="width: 300px"></el-input>
      </el-form-item>

      <el-form-item :label="$t('userCenter.usrPer.gender')">
        <el-radio-group v-model="usrPerForm.gender">
          <el-radio label="MALE">{{ $t('userCenter.male') }}</el-radio>
          <el-radio label="FEMALE">{{ $t('userCenter.female') }}</el-radio>
          <el-radio label="HIDE">{{ $t('userCenter.unknown') }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('cmn.remark')" prop="remark">
        <el-input v-model="usrPerForm.remark" style="width: 300px"></el-input>
      </el-form-item>
    </el-form>

    <el-popover
      ref="updateUsrPerPopover"
      placement="top"
      width="200"
    >
      <p>{{ $t('cmn.confirmSave') }}</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="$refs['updateUsrPerPopover'].doClose()">
          取消
        </el-button>
        <el-button type="primary" size="mini" @click="update">{{ $t('cmn.confirm') }}</el-button>
      </div>
      <el-button slot="reference" type="primary">{{ $t('cmn.save') }}</el-button>
    </el-popover>
  </div>
</template>

<script>
import UsrPerApi from '@/api/usercenter/UsrPerApi'
import ProtRule from '@/constant/ProtRule'
import LoginManager from '@/sys/LoginManager'

export default {
  name: 'UsrPerInfo',
  created () {
    this.initUsrPerForm()
    this.initFormRules()
  },
  data () {
    return {
      usrPerForm: {
        gender: '',
        name: '',
        remark: ''
      },
      usrPerFormRules: {}
    }
  },
  methods: {
    initFormRules () {
      this.usrPerFormRules = {
        name: [
          ProtRule.cmnRule().cmnNameLength,
          ProtRule.cmnRule().notWhite
        ],
        ...ProtRule.rankValueRemark()
      }
    },
    async initUsrPerForm () {
      this.usrPerForm = await UsrPerApi.findCurrent()
    },
    update () {
      this.$refs.usrPerFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          findField: {},
          updateField: {
            ...this.usrPerForm
          },
          extraField: {
            igLessRemark: true
          }
        }
        UsrPerApi.update(ip)
          .then(rd => {
            if (rd) {
              LoginManager.syncCurUsr()
              this.$refs.updateUsrPerPopover.doClose()
            }
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
