<template>
  <div id="vue_app">
    <img src="./assets/logo.png">
    <div>
      <div>
        <router-link to="/home">Home</router-link>
        <router-link to="/about">About</router-link>
        <router-link to="/welcome">Welcome</router-link>
      </div>
      <div>
        <el-button @click="jumpToWelcome">JumpToWelcome</el-button>
      </div>
    </div>
    <div>
      <p>
        If Element is successfully added to this project, you'll see an
        <code v-text="'<el-button>'"></code>
        below
      </p>
      <el-button>el-button</el-button>
    </div>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'VueApp',
  components: {
    HelloWorld
  },
  methods: {
    jumpToWelcome () {
      this.$router.push({ path: '/welcome' })
    }
  }
}
</script>

<style>
#vue_app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
