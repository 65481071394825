<template>
  <div>
    <div class="date-div">
      <h2>{{ latestDate }}</h2>
      <p>{{ latestDayRecord.dayWords }}</p>
      <el-image :src="latestDayImg"></el-image>
    </div>
    <el-divider></el-divider>
    <h2>{{ now }}</h2>
    <el-divider></el-divider>
    <div class="btn-div">
      <el-button @click="toDoNewDay">ToDoNewDay</el-button>
      <el-button @click="doneNewDay">DoneNewDay</el-button>
      <el-button @click="doNewDay">DoNewDay</el-button>
      <el-button @click="toToday">ToToday</el-button>
    </div>
  </div>
</template>

<script>
import UsrzDayRecordApi from '@/api/vojivosvc/toolsubject/UsrzDayRecordApi'
import TimeUtil from '@/util/TimeUtil'

export default {
  name: 'ComingTime',
  data () {
    return {
      now: null,
      latestDate: null,
      newDate: null,
      latestDayImg: null,
      newDateImg: null,
      latestDayRecord: {
        id: null,
        dayMsec: null,
        dayWords: null,
        morningImgUri: null,
        noonImgUri: null,
        eveningImgUri: null
      },
      newDayRecord: {
        id: null,
        dayMsec: null,
        dayWords: null,
        morningImgUri: null,
        noonImgUri: null,
        eveningImgUri: null
      }
    }
  },
  created () {
    this.init()
    this.startNow()
  },
  beforeRouteLeave (to, from, next) {
    this.stopNow()
    next()
  },
  methods: {
    async init () {
      this.latestDayRecord = await UsrzDayRecordApi.findOrGenLatest()
      this.refresh()
    },
    refresh () {
      this.latestDate = TimeUtil.convertMsecToDateStr(this.latestDayRecord.dayMsec)
      this.latestDayImg = this.latestDayRecord.morningImgUri
    },

    refreshNow () {
      setTimeout(() => {
        this.now = TimeUtil.convertMsecToDateTimeStr(new Date().getTime())
      }, 0)
    },
    startNow () {
      this.refreshNow()
      this.nowTimerId = setInterval(() => {
        this.refreshNow()
      }, 1000)
    },

    stopNow () {
      if (this.nowTimerId) {
        clearInterval(this.nowTimerId)
        this.nowTimerId = null
      }
    },

    toDoNewDay () {
      UsrzDayRecordApi.toDoNew()
        .then(rt => {
          this.newDayRecord = rt
        })
    },
    doneNewDay () {
      const ip = {
        id: this.newDayRecord.id
      }
      UsrzDayRecordApi.doneNew(ip)
        .then(rt => {
          if (rt) {
            this.latestDayRecord = this.newDayRecord
            this.refresh()
          }
        })
    },
    doNewDay () {
      const ip = {
        dayWords: 'Ha Ha Ha'
      }
      UsrzDayRecordApi.doNew(ip)
        .then(rt => {
          this.latestDayRecord = rt
          this.refresh()
        })
    },
    toToday () {
      UsrzDayRecordApi.toToday()
        .then(rt => {
          this.latestDayRecord = rt
          this.refresh()
        })
    }
  }
}
</script>

<style scoped>

</style>
