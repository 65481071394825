import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/system/Home.vue'
import Welcome from '../views/system/Welcome.vue'
import VueApp from '../VueApp'
import Login from '../views/system/Login'
import Register from '../views/system/Register'
import ToolHall from '@/views/toolhall/ToolHall'
import MyRoom from '@/views/myroom/MyRoom'
import MagicCorner from '@/views/magiccorner/MagicCorner'
import PerCenter from '../views/percenter/PerCenter.vue'
import UsrPerInfo from '../views/percenter/UsrPerInfo'
import UsrAcctInfo from '../views/percenter/UsrAcctInfo'
import Test from '../views/Test'
import toolRoutes from './tool'
import ToolDetail from '@/views/toolhall/ToolDetail'
import IESpace from '@/views/iespace/IESpace'
import CommentWall from '@/views/iespace/CommentWall'
import PrdWindow from '@/views/iespace/PrdWindow'
import GameRoom from '@/views/iespace/GameRoom'
import StudyLab from '@/views/studylab/StudyLab'
import MyOrder from '@/views/percenter/MyOrder'
import MyBill from '@/views/percenter/MyBill'
import Inbox from '@/views/iespace/Inbox'
import WinkBalcony from '@/views/iespace/WinkBalcony'
import PaperPlane from '@/views/iespace/PaperPlane.vue'
import BigWin from '@/views/magiccorner/BigWin.vue'
import BlueBox from '@/views/magiccorner/BlueBox'
import VojiRoom from '@/views/magiccorner/VojiRoom'
import ResPkgDetail from '@/views/studylab/ResPkgDetail'
import MyFavTool from '@/views/myroom/MyFavTool.vue'
import MyFavResPkg from '@/views/myroom/MyFavResPkg.vue'
import MyPurResPkg from '@/views/myroom/MyPurResPkg.vue'
import TestMain from '@/views/myroom/TestMain.vue'
import TestRouter from '@/views/myroom/TestRouter.vue'
import Activate from '@/views/system/Activate.vue'
import VojistarCard from '@/views/percenter/VojistarCard.vue'
import VjbJar from '@/views/percenter/VjbJar.vue'

Vue.use(VueRouter)

const mainRoutes = [
  {
    path: '/',
    redirect: '/toolHall'
  },
  {
    path: '*',
    redirect: '/toolHall'
  },
  {
    path: '/vueApp',
    component: VueApp
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/testMain',
    name: 'TestMain',
    component: TestMain,
    children: [
      {
        path: '/testRouter',
        name: 'TestRouter',
        component: TestRouter
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/vueApp',
    name: 'VueApp',
    component: VueApp
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/activate',
    name: 'Activate',
    component: Activate
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/toolHall',
    children: [
      {
        path: '/myRoom',
        name: 'MyRoom',
        redirect: '/myRoom/myFavTool',
        component: MyRoom,
        children: [
          {
            path: 'myFavTool',
            name: 'MyFavTool',
            component: MyFavTool
          },
          {
            path: 'myFavResPkg',
            name: 'MyFavResPkg',
            component: MyFavResPkg
          },
          {
            path: 'myPurResPkg',
            name: 'MyPurResPkg',
            component: MyPurResPkg
          }
        ]
      },
      {
        path: '/toolHall',
        name: 'ToolHall',
        component: ToolHall
      },
      {
        path: '/toolDetail',
        name: 'ToolDetail',
        component: ToolDetail
      },
      {
        path: '/studyLab',
        name: 'StudyLab',
        component: StudyLab
      },
      {
        path: '/resPkgDetail',
        name: 'ResPkgDetail',
        component: ResPkgDetail
      },
      {
        path: '/magicCorner',
        name: 'MagicCorner',
        component: MagicCorner,
        redirect: '/magicCorner/blueBox',
        children: [
          {
            path: 'blueBox',
            name: 'BlueBox',
            component: BlueBox
          },
          {
            path: 'bigWin',
            name: 'BigWin',
            component: BigWin
          },
          {
            path: 'vojiRoom',
            name: 'VojiRoom',
            component: VojiRoom
          }
        ]
      },
      {
        path: '/ieSpace',
        name: 'IESpace',
        component: IESpace,
        redirect: '/ieSpace/winkBalcony',
        children: [
          {
            path: 'winkBalcony',
            name: 'WinkBalcony',
            component: WinkBalcony
          },
          {
            path: 'paperPlane',
            name: 'PaperPlane',
            component: PaperPlane
          },
          {
            path: 'inbox',
            name: 'Inbox',
            component: Inbox
          },
          {
            path: 'commentWall',
            name: 'CommentWall',
            component: CommentWall
          },
          {
            path: 'prdWindow',
            name: 'PrdWindow',
            component: PrdWindow
          },
          {
            path: 'gameRoom',
            name: 'GameRoom',
            component: GameRoom
          }
        ]
      },
      {
        path: '/perCenter',
        name: 'PerCenter',
        component: PerCenter,
        redirect: '/perCenter/usrPerInfo',
        children: [
          {
            path: 'usrPerInfo',
            name: 'UsrPerInfo',
            component: UsrPerInfo
          },
          {
            path: 'usrAcctInfo',
            name: 'UsrAcctInfo',
            component: UsrAcctInfo
          },
          {
            path: 'vjbJar',
            name: 'VjbJar',
            component: VjbJar
          },
          {
            path: 'vojistarCard',
            name: 'VojistarCard',
            component: VojistarCard
          },
          {
            path: 'myOrder',
            name: 'MyOrder',
            component: MyOrder
          },
          {
            path: 'myBill',
            name: 'MyBill',
            component: MyBill
          }
        ]
      }
    ]
  }
]

// const routes = mainRoutes.concat(toolRoutes)
const newHomeChildren = mainRoutes[mainRoutes.length - 1].children.concat(toolRoutes)
mainRoutes[mainRoutes.length - 1].children = newHomeChildren
const routes = mainRoutes
const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
