<template>
  <el-container>
    <el-aside width="150px">
      <el-menu :default-active="this.$route.path" router>
        <el-menu-item index="/magicCorner/blueBox">
          <i class="iconfont vj-icon-baohe"></i>
          <span slot="title">{{ this.$t('magicCorner.blueBox') }}</span>
        </el-menu-item>
        <el-menu-item index="/magicCorner/bigWin">
          <i class="iconfont vj-icon-chuchuang"></i>
          <span slot="title">{{ this.$t('magicCorner.bigWin') }}</span>
        </el-menu-item>
        <el-menu-item index="/magicCorner/vojiRoom">
          <i class="iconfont vj-icon-20"></i>
          <span slot="title">{{ this.$t('magicCorner.vojiRoom') }}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>

export default {
  name: 'MagicCorner',
  data () {
    return {
      labelPosition: 'right',
      formLabelAlign: {
        name: '',
        region: '',
        type: ''
      }
    }
  }
}
</script>

<style scoped>
</style>
