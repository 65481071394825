<template>
  <hash-cmpt hashAlg="CRC32"></hash-cmpt>
</template>

<script>
import HashCmpt from './HashCmpt.vue'

export default {
  name: 'Crc32',
  components: { HashCmpt }
}
</script>
