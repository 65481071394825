import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/usrzCodeGenProject'

export function add (ip) {
  return resultHttpTool.post(API_PREFIX + '/add', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.addSuccess()
      } else {
        ApiMsgBox.addFailed()
      }
    })
}

export function findByPage (findByPageIp) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', formatIp(findByPageIp))
}

export function findDetail (id) {
  return resultHttpTool.get(API_PREFIX + '/findDetail?id=' + id)
}

export function countTotal (id) {
  return resultHttpTool.get(API_PREFIX + '/countTotal')
}

export function batchDelete (ip) {
  return resultHttpTool.post(API_PREFIX + '/batchDelete', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.delSuccess()
      } else {
        ApiMsgBox.delFailed()
      }
      return op
    })
}

function formatIp (findByPageIp) {
  if (findByPageIp === null) {
    findByPageIp = {
      srchIp: null,
      pageIp: {
        current: 1,
        size: 7
      },
      orderBys: [
        {
          key: 'update_msec',
          asc: false
        }
      ],
      timeIp: null
    }
  }
  return findByPageIp
}

export default {
  add,
  countTotal,
  findByPage,
  findDetail,
  batchDelete
}
