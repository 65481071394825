<template>
  <div>
    <!-- 内容页面上部  -->
    <div class="menu-src-div">
      <el-input
        v-model="findByPageIp.srchIp.srchKw"
        @input="delaySrch">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-dropdown @command="handleCmd">
        <el-button icon="el-icon-s-operation"></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="checkCurPage">{{ $t('cmn.checkCurPage') }}</el-dropdown-item>
          <el-dropdown-item v-if="checkedUsrzFavResPkgMap&&checkedUsrzFavResPkgMap.size>0" command="cancelCheckAll">
            {{ $t('cmn.cancelCheckAll') }}
          </el-dropdown-item>
          <el-dropdown-item v-if="checkedUsrzFavResPkgMap&&checkedUsrzFavResPkgMap.size>0" command="batchSetGroup">
            {{ $t('myRoom.setGroup') }}
          </el-dropdown-item>
          <el-dropdown-item v-if="checkedUsrzFavResPkgMap&&checkedUsrzFavResPkgMap.size>0" command="batchPur">
            {{ $t('myRoom.batchPur') }}
          </el-dropdown-item>
          <el-dropdown-item v-if="checkedUsrzFavResPkgMap&&checkedUsrzFavResPkgMap.size>0" command="batchCancelFav">
            {{ $t('cmn.cancelFav') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 内容页面下部  -->
    <div class="res-pkg-shower">
      <div v-infinite-scroll="loadMoreUsrzFavResPkg"
           :infinite-scroll-disabled="isLoadingMoreUsrzFavResPkg"
           class="scroll-div"
      >
        <!--资源Card渲染-->
        <div class="res-pkg-card-container">
          <el-card
            v-for="usrzFavResPkg in usrzFavResPkgList"
            :key="usrzFavResPkg.resPkgId"
            class="res-pkg-card"
          >
            <div v-if="usrzFavResPkg.resPkgOp">
              <div class="img-area">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <img :src="usrzFavResPkg.resPkgOp.firstImgUrl" class="left-img">
                  </el-col>
                  <el-col :span="12">
                    <div>
                      <img :src="usrzFavResPkg.resPkgOp.secondImgUrl" class="right-img">
                    </div>
                    <div>
                      <img :src="usrzFavResPkg.resPkgOp.thirdImgUrl" class="right-img">
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="tag-area">
                <div class="base-tag">
                  <el-tag
                    v-for="growthStage in usrzFavResPkg.resPkgOp.growthStages"
                    :key="growthStage"
                    effect="dark"
                    size="mini"
                  >
                    {{ $t('studyLab.' + growthStage) }}
                  </el-tag>
                  <el-badge
                    v-for="carrierInfo in usrzFavResPkg.resPkgOp.carrierInfos"
                    :key="carrierInfo.carrierType"
                    :value="carrierInfo.count"
                    class="item"
                  >
                    <el-tag
                      type="info"
                      effect="dark"
                      size="mini"
                    >
                      {{ $t('studyLab.' + carrierInfo.carrierType) }}
                    </el-tag>
                  </el-badge>

                </div>
                <div class="categ-tag">
                  <el-tag
                    v-for="mainCategTag in usrzFavResPkg.resPkgOp.mainCategTags"
                    :key="mainCategTag.id"
                    type="warning"
                    effect="dark"
                    size="mini"
                  >
                    {{ mainCategTag.name }}
                  </el-tag>
                </div>
                <div class="highlight-tag">
                  <el-tag
                    v-for="mainHltTag in usrzFavResPkg.resPkgOp.mainHltTags"
                    :key="mainHltTag.id"
                    type="danger"
                    effect="dark"
                    size="mini"
                  >
                    {{ mainHltTag.name }}
                  </el-tag>
                </div>
              </div>

              <div class="name-area">
                <h2>{{ usrzFavResPkg.resPkgOp.name }}</h2>
              </div>
              <div class="profile-area">
                {{ usrzFavResPkg.resPkgOp.profile }}
              </div>
              <div class="price-opn-area">
                <el-row :gutter="20">
                  <el-col :span="10">
                    <div v-if="usrzFavResPkg.resPkgOp.bePur">
                      <el-tag type="info" size="mini">{{ $t('trade.bePur') }}</el-tag>
                    </div>
                    <div v-else class="price-area">
                      <div v-if="usrzFavResPkg.resPkgOp.vojistarCardPricing">
                        <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                        <span class="final-price-span">{{ usrzFavResPkg.resPkgOp.finalPrice }}</span>
                        <div>
                          <el-tag
                            type="info" size="mini">
                            {{ $t('trade.' + usrzFavResPkg.resPkgOp.vojistarCardPricing.unid) }}
                          </el-tag>
                        </div>
                        <div>
                          <span class="final-cur-price-span">{{ usrzFavResPkg.resPkgOp.curPrice }}</span>
                          <span class="orig-price-span">{{ usrzFavResPkg.resPkgOp.origPrice }}</span>
                        </div>
                      </div>
                      <div v-else>
                        <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                        <span class="cur-price-span">{{ usrzFavResPkg.resPkgOp.curPrice }}</span>
                        <span class="orig-price-span">{{ usrzFavResPkg.resPkgOp.origPrice }}</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="14">
                    <div class="opn-area">
                      <el-button
                        icon="el-icon-info"
                        circle
                        size="small"
                        @click="goToResPkgDetail(usrzFavResPkg.resPkgOp.id)"
                      />
                      <el-button icon="el-icon-download" circle size="small"
                                 @click="downloadOrPur(usrzFavResPkg.resPkgOp)"/>
                      <el-popover
                        v-if="usrzFavResPkg.resPkgOp.beFav"
                        :ref="'CancelFavPop.' + usrzFavResPkg.resPkgOp.id"
                        placement="top"
                        width="200"
                      >
                        <p>{{ $t('cmn.cancelFavConfirm') }}</p>
                        <div style="text-align: right;">
                          <el-button size="mini" type="text"
                                     @click="$refs['CancelFavPop.' + usrzFavResPkg.resPkgOp.id].at(0).doClose()">
                            {{ $t('cmn.cancel') }}
                          </el-button>
                          <el-button type="primary" size="mini" @click="doCancelFav(usrzFavResPkg.resPkgOp)">
                            {{ $t('cmn.confirm') }}
                          </el-button>
                        </div>
                        <el-button v-if="usrzFavResPkg.resPkgOp.beFav" slot="reference" icon="el-icon-star-on" circle
                                   size="small"/>
                      </el-popover>
                      <el-button v-if="!usrzFavResPkg.resPkgOp.beFav" icon="el-icon-star-off" circle size="small"
                                 @click="toFav(usrzFavResPkg.resPkgOp)"/>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div v-else>
              已失效
            </div>

            <el-checkbox
              :key="usrzFavResPkg.id"
              v-model="usrzFavResPkg.checked"
              class="res-pkg-check-box"
              @change="handleResChecked(usrzFavResPkg,$event)"
            ></el-checkbox>
          </el-card>
        </div>
      </div>
    </div>
    <!-- Dialog   -->
    <div>
      <!-- Batch Pur Order Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchPurForm"
        :title="$t('trade.batchPur')"
        append-to-body
        width="600px"
      >
        <el-form
          ref="batchPurFormRef"
          :model="batchPurForm"
          :rules="batchPurFormRules"
          size="small"
          label-width="50px"
        >
          <el-form-item prop="resPkgs">
            <el-card v-for="resPkg in batchPurForm.resPkgs" :key="resPkg.id" style="width: 400px">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="pur-res-pkg-img-area">
                    <el-row :gutter="5">
                      <el-col :span="12">
                        <img :src="resPkg.firstImgUrl" class="left-img">
                      </el-col>
                      <el-col :span="12">
                        <div>
                          <img :src="resPkg.secondImgUrl" class="right-img">
                        </div>
                        <div>
                          <img :src="resPkg.thirdImgUrl" class="right-img">
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <h2>{{ resPkg.name }}</h2>
                </el-col>
                <el-col :span="16">
                  <div class="pur-res-pkg-price-area">
                    <div v-if="resPkg.vojistarCardPricing">
                      <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                      <span class="final-price-span">{{ resPkg.finalPrice }}</span>
                      <el-tag
                        type="info" size="mini">
                        {{ $t('trade.' + resPkg.vojistarCardPricing.unid) }}
                      </el-tag>
                      <div>
                        <span class="final-cur-price-span">{{ resPkg.curPrice }}</span>
                        <span class="orig-price-span">{{ resPkg.origPrice }}</span>
                      </div>
                    </div>
                    <div v-else>
                      <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                      <span class="cur-price-span">{{ resPkg.curPrice }}</span>
                      <span class="orig-price-span">{{ resPkg.origPrice }}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <div v-if="batchPurForm.price > this.walletVjb">
            <span>{{ $t('trade.walletVjb') }}</span>{{ this.walletVjb }}
          </div>
          <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
          <span class="vjb-amt-span">{{ batchPurForm.price }}</span>
          <el-button v-if="batchPurForm.price > this.walletVjb" type="primary" @click="toTopUp">
            {{ $t('trade.toTopUp') }}
          </el-button>
          <el-button v-else type="danger" @click="doBatchPurOrder">{{ $t('trade.orderPay') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Set Group Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchSetGroupForm"
        :title="$t('myRoom.setGroup')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="batchSetGroupFormRef"
          :inline="true"
          :model="batchSetGroupForm"
          :rules="batchSetGroupFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('studyLab.resPkgList')" prop="resPkgIds">
            <el-select
              v-model="batchSetGroupForm.resPkgIds"
              :multiple="true"
              clearable
              style="width: 600px;"
            >
              <el-option
                v-for="usrzFavResPkg in checkedUsrzFavResPkgMap.values()"
                :key="usrzFavResPkg.id"
                :label="usrzFavResPkg.resPkgOp === null?'':usrzFavResPkg.resPkgOp.name"
                :value="usrzFavResPkg.resPkgId"
              />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('cmn.group')" prop="toolGroupIds">
            <el-tree
              v-model="batchSetGroupForm.toolGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              :check-strictly="true"
              show-checkbox
              lazy
              @check="handleGroupTreeNodeCheck"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchSetGroupForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchSetGroup">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Cancel Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchCancelFavForm"
        :title="$t('cmn.cancelFav')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="batchCancelFavFormRef"
          :inline="true"
          :model="batchCancelFavForm"
          :rules="batchCancelFavFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('studyLab.resPkgList')" prop="resPkgIds">
            <el-select
              v-model="batchCancelFavForm.resPkgIds"
              :multiple="true"
              clearable
              style="width: 600px;"
            >
              <el-option
                v-for="usrzFavResPkg in checkedUsrzFavResPkgMap.values()"
                :key="usrzFavResPkg.id"
                :label="usrzFavResPkg.resPkgOp === null?'':usrzFavResPkg.resPkgOp.name"
                :value="usrzFavResPkg.resPkgId"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchCancelFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchCancelFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import { DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE, DEFAULT_ORDER_BY } from '@/constant/CmnBizConst'
import UsrzFavResPkgApi from '@/api/vojivosvc/studylab/UsrzFavResPkgApi'
import UsrzPurResPkgApi from '@/api/vojivosvc/studylab/UsrzPurResPkgApi'
import UsrzResPkgGroupApi from '@/api/vojivosvc/studylab/UsrzResPkgGroupApi'
import ProtRule from '@/constant/ProtRule'
import VjbWalletApi from '@/api/tradecenter/VjbWalletApi'
import BizConfig from '@/config/BizConfig'

export default {
  name: 'MyFavResPkg',
  watch: {
    '$route.query.groupTreeRootIds': {
      handler: function (newVal, oldVal) {
        this.groupTreeRootIds = newVal
        this.srch()
      }
    },
    deep: true
  },
  created () {
    this.groupTreeRootIds = this.$route.query.groupTreeRootIds
    this.srch()
    this.initFormRules()
  },
  data () {
    return {
      defaultPage: {
        total: 0,
        current: 1,
        size: 4
      },
      pageInfo: {},
      groupTreeRootIds: [],
      findByPageIp: {
        ...lodash.cloneDeep(DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE),
        groupTreeRootIds: [],
        growthStage: null,
        carrierType: null
      },
      delaySrchTimeoutId: null,
      isLoadingMoreUsrzFavResPkg: true,
      usrzFavResPkgList: [],
      checkedUsrzFavResPkgMap: new Map(),
      enablePurOrderForm: false,
      purOrderForm: {
        resPkgName: null,
        resPkgId: null,
        resPkgGroupIds: [],
        customName: null,
        customProfile: null,
        origPrice: null,
        curPrice: null
      },
      purOrderFormRules: {},
      walletVjb: 0,
      enableBatchPurForm: false,
      batchPurForm: {
        resPkgs: [],
        price: null,
        resPkgGroupIds: []
      },
      batchPurFormRules: {},
      enableBatchSetGroupForm: false,
      batchSetGroupForm: {
        resPkgNames: [],
        resPkgIds: [],
        resPkgGroupIds: []
      },
      batchSetGroupFormRules: {},
      enableBatchCancelFavForm: false,
      batchCancelFavForm: {
        resPkgNames: [],
        resPkgIds: []
      },
      batchCancelFavFormRules: {},
      checkedGroupIds: [],
      groupTrees: [
        {
          id: 0,
          label: 'NULL PARENT',
          children: null
        }
      ]
    }
  },
  methods: {
    initFormRules () {
      this.batchCancelFavFormRules = {
        resPkgIds: [
          ProtRule.cmnRule().cmnBatchSize
        ]
      }
      this.purOrderFormRules = {
        resPkgGroupIds: [
          ProtRule.cmnRule().mBatchSize
        ],
        customName: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().length_64
        ],
        customProfile: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().cmnProfileLength
        ],
        origPrice: [
          ProtRule.cmnRule().priceRule
        ],
        curPrice: [
          ProtRule.cmnRule().priceRule
        ]
      }
      this.batchPurFormRules = {
        resPkgs: [
          ProtRule.cmnRule().mBatchSize
        ],
        price: [
          ProtRule.cmnRule().priceRule
        ],
        resPkgGroupIds: [
          ProtRule.cmnRule().mBatchSize
        ]
      }
    },
    loadMoreUsrzFavResPkg () {
      this.isLoadingMoreUsrzFavResPkg = true
      if (this.pageInfo.current >= this.pageInfo.total / this.pageInfo.size) {
        return
      }
      this.pageInfo.current += 1
      this.doLoadMoreUsrzFavResPkg()
    },
    doLoadMoreUsrzFavResPkg () {
      this.findByPageIp.pageIp.current = this.pageInfo.current
      this.findByPageIp.pageIp.size = this.pageInfo.size
      this.findByPageIp.groupTreeRootIds = this.groupTreeRootIds
      UsrzFavResPkgApi.findByPage(this.findByPageIp).then(pageOp => {
        this.isLoadingMoreUsrzFavResPkg = false
        this.refreshPageInfo(pageOp)
        const data = pageOp.data
        data.map(item => {
          if (this.checkedUsrzFavResPkgMap.has(item.id)) {
            this.$set(item, 'checked', true)
          } else {
            this.$set(item, 'checked', false)
          }
          return item
        })
        this.usrzFavResPkgList.push(...data)
      })
    },

    async srch () {
      this.findByPageIp.pageIp = { ...this.defaultPage }
      this.findByPageIp.groupTreeRootIds = this.groupTreeRootIds
      const pageOp = await UsrzFavResPkgApi.findByPage(this.findByPageIp)
      this.refreshPageInfo(pageOp)
      this.isLoadingMoreUsrzFavResPkg = false

      const data = pageOp.data
      data.map(item => {
        if (this.checkedUsrzFavResPkgMap.has(item.id)) {
          this.$set(item, 'checked', true)
        } else {
          this.$set(item, 'checked', false)
        }
        return item
      })
      this.usrzFavResPkgList = data
    },
    refreshPageInfo (pageOp) {
      this.pageInfo.size = pageOp.pageIp.size
      this.pageInfo.current = pageOp.pageIp.current
      this.pageInfo.total = pageOp.total
    },
    delaySrch () {
      this.clearDelaySrchTimeout()
      this.delaySrchTimeoutId = setTimeout(() => {
        this.srch()
      }, BizConfig.cmnDelayMsec)
    },
    clearDelaySrchTimeout () {
      if (this.delaySrchTimeoutId) {
        clearTimeout(this.delaySrchTimeoutId)
        this.delaySrchTimeoutId = null
      }
    },
    doResetSrch () {
      this.findByPageIp = { ...DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE }
      this.srch()
    },
    doRefresh () {
      this.srch()
    },
    handleSortChange (data) {
      const orderBy = { ...DEFAULT_ORDER_BY }
      orderBy.key = data.prop
      orderBy.asc = data.order === 'ascending'
      this.orderBys = [
        { ...orderBy }
      ]
      this.srch()
    },
    handleResChecked (usrzFavResPkg, isChecked) {
      if (isChecked) {
        this.checkedUsrzFavResPkgMap.set(usrzFavResPkg.id, usrzFavResPkg)
      } else {
        this.checkedUsrzFavResPkgMap.delete(usrzFavResPkg.id)
      }
    },
    doCancelFav (resPkg) {
      const ip = {
        resPkgIds: []
      }
      ip.resPkgIds.push(resPkg.id)
      UsrzFavResPkgApi.batchCancelFav(ip)
        .then(resultData => {
          if (resultData) {
            resPkg.beFav = false
            this.$refs['CancelFavPop.' + resPkg.id].at(0).doClose()
          }
        })
    },
    downloadOrPur (resPkg) {
      if (!resPkg.bePur) {
        this.toPurOrder(resPkg)
      } else {
        this.toDownloadResPkg(resPkg)
      }
    },
    toDownloadResPkg (resPkg) {
      this.goToResPkgDetail(resPkg.id)
    },
    goToResPkgDetail (resPkgId) {
      const routeData = this.$router.resolve({
        path: '/resPkgDetail',
        query: {
          resPkgId: resPkgId
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleCmd (cmd) {
      switch (cmd) {
        case 'batchSetGroup':
          this.toBatchSetGroup()
          break
        case 'batchCancelFav':
          this.toBatchCancelFav()
          break
        case 'batchPur':
          this.toBatchPur()
          break
        case 'checkCurPage':
          this.checkCurPage()
          break
        case 'cancelCheckAll':
          this.cancelCheckAll()
          break
      }
    },

    /** *************** Batch Pur ******************** **/
    toPurOrder (resPkg) {
      this.enableBatchPurForm = true
      this.batchPurForm.resPkgs = []
      this.batchPurForm.price = 0
      this.batchPurForm.resPkgs.push({
        id: resPkg.id,
        name: resPkg.name,
        firstImgUrl: resPkg.firstImgUrl,
        secondImgUrl: resPkg.secondImgUrl,
        thirdImgUrl: resPkg.thirdImgUrl,
        origPrice: resPkg.origPrice,
        curPrice: resPkg.curPrice,
        finalPrice: resPkg.finalPrice,
        vojistarCardPricing: resPkg.vojistarCardPricing
      })
      this.batchPurForm.price += resPkg.finalPrice
      VjbWalletApi.findOrInit().then(op => {
        if (op) {
          this.walletVjb = op.walletVjb
        }
      })
    },
    toBatchPur () {
      this.batchPurForm.resPkgs = []
      this.batchPurForm.price = 0
      for (const usrzFavResPkg of this.checkedUsrzFavResPkgMap.values()) {
        const resPkg = usrzFavResPkg.resPkgOp
        if (resPkg.bePur) {
          continue
        }
        this.batchPurForm.resPkgs.push({
          id: resPkg.id,
          name: resPkg.name,
          firstImgUrl: resPkg.firstImgUrl,
          secondImgUrl: resPkg.secondImgUrl,
          thirdImgUrl: resPkg.thirdImgUrl,
          origPrice: resPkg.origPrice,
          curPrice: resPkg.curPrice,
          finalPrice: resPkg.finalPrice,
          vojistarCardPricing: resPkg.vojistarCardPricing
        })
        this.batchPurForm.price += resPkg.finalPrice
      }
      if (this.batchPurForm.resPkgs.length < 1) {
        this.$message.info(this.$t('trade.allCheckedBePur'))
        return
      }
      this.enableBatchPurForm = true
      VjbWalletApi.findOrInit().then(op => {
        if (op) {
          this.walletVjb = op.walletVjb
        }
      })
    },
    doBatchPurOrder () {
      const ip = {
        ...this.batchPurForm
      }
      UsrzPurResPkgApi.batchPurOrder(ip)
        .then(purOrderOp => {
          this.purPay(purOrderOp.resPkgOrderOp)
        })
    },
    purPay (resPkgOrder) {
      const ip = {
        orderId: resPkgOrder.id,
        orderPrice: resPkgOrder.price,
        txnId: resPkgOrder.txnId
      }
      UsrzPurResPkgApi.purPay(ip)
        .then(op => {
          if (op) {
            this.enableBatchPurForm = false
          }
        })
    },

    toTopUp () {
      this.$router.push('/perCenter/vjbJar')
    },

    toBatchSetGroup () {
      this.enableBatchSetGroupForm = true
      this.batchSetGroupForm.resPkgIds = []
      this.checkedUsrzFavResPkgMap.forEach((value, key) => {
        this.batchSetGroupForm.resPkgIds.push(value.resPkgId)
      })
    },
    doBatchSetGroup () {
      const ip = {
        resPkgIds: this.batchSetGroupForm.resPkgIds,
        resPkgGroupIds: this.checkedGroupIds
      }
      UsrzFavResPkgApi.batchFav(ip)
        .then(() => {
          this.srch()
          this.enableBatchSetGroupForm = false
        })
    },
    toBatchCancelFav () {
      this.enableBatchCancelFavForm = true
      this.batchCancelFavForm.resPkgIds = []
      this.checkedUsrzFavResPkgMap.forEach((value, key) => {
        this.batchCancelFavForm.resPkgIds.push(value.resPkgId)
      })
    },
    doBatchCancelFav () {
      const ip = {
        resPkgIds: this.batchCancelFavForm.resPkgIds
      }
      UsrzFavResPkgApi.batchCancelFav(ip)
        .then(() => {
          this.srch()
          this.enableBatchCancelFavForm = false
        })
    },
    checkCurPage () {
      this.usrzFavResPkgList.map(item => {
        // 这里，给对象添加属性，用$set方法。
        this.$set(item, 'checked', true)
        if (!this.checkedUsrzFavResPkgMap.has(item.id)) {
          this.checkedUsrzFavResPkgMap.set(item.id, item)
        }
        return item
      })
    },
    cancelCheckAll () {
      this.usrzFavResPkgList.map(item => {
        // 这里，给对象添加属性，用$set方法。
        this.$set(item, 'checked', false)
        this.checkedUsrzFavResPkgMap.delete(item.id)
        return item
      })
    },
    handleToolChecked (usrzFavTool, data) {
      if (!this.checkedUsrzFavResPkgMap.has(usrzFavTool.id)) {
        this.checkedUsrzFavResPkgMap.set(usrzFavTool.id, usrzFavTool)
      }
    },
    loadGroupTrees (node, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        UsrzResPkgGroupApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.groupTrees = resultData
            }
          })
      }, 100)
    },
    handleGroupTreeNodeCheck (data, checkedData) {
      this.checkedGroupIds = []
      this.checkedGroupIds.push(...checkedData.checkedKeys)
    }
  }

}
</script>

<style scoped>

</style>
