import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'
import ObjectUtil from '@/util/ObjectUtil'

const API_PREFIX = '/api/usr/langCorner'

export function add (ip) {
  return resultHttpTool.post(API_PREFIX + '/add', ip)
    .then(resultData => {
      if (resultData) {
        ApiMsgBox.addSuccess()
      } else {
        ApiMsgBox.addFailed()
      }
      return resultData
    })
}

export function update (ip) {
  const updateIp = {
    findField: {
      id: null
    },
    updateField: {
      regionId: null,
      topic: null,
      content: null,
      tags: null
    },
    extraField: null
  }
  return resultHttpTool.post(API_PREFIX + '/update', ObjectUtil.copyFieldValue(ip, updateIp))
    .then(resultData => {
      if (resultData) {
        ApiMsgBox.editSuccess()
      } else {
        ApiMsgBox.editFailed()
      }
      return resultData
    })
}

export function findByPage (findByPageIp) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', formatIp(findByPageIp))
}

function formatIp (findByPageIp) {
  if (findByPageIp === null) {
    findByPageIp = {
      srchIp: null,
      pageIp: {
        current: 1,
        size: 7
      },
      orderBys: [
        {
          key: 'update_msec',
          asc: false
        }
      ],
      timeIp: null
    }
  }
  return findByPageIp
}

export default {
  findByPage,
  add,
  update
}
