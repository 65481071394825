import resultHttpTool from '@/http/ResultHttpTool'

const API_PREFIX = '/api/usr/studyLabResPkg'

export function findById (resPkgId) {
  return resultHttpTool.get(API_PREFIX + '/findById?id=' + resPkgId)
}

export function findTags (resPkgId) {
  return resultHttpTool.get(API_PREFIX + '/findTags?id=' + resPkgId)
}

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export default {
  findById,
  findTags,
  findByPage
}
