<template>
  <div>
    <div class="my-card-div">
      <h2>{{ $t('trade.myVojistarCard') }}</h2>
      <el-card v-if="myVojistarCard" :class="myVojistarCard.unid.toLowerCase().replaceAll('_','-')">
        <div class="card-name-div">
          <span>{{ myVojistarCard.i18nName }}</span>
        </div>
        <div class="card-ccy-div">
          <span>{{ myVojistarCard.showCcySymbol }}{{ myVojistarCard.showCcyAmt }}</span>
        </div>
        <div class="card-benefits-div">
          <div v-for="(benefit,idx) in myVojistarCard.i18nBenefits" :key="idx" class="card-benefit-div">
            {{ benefit }}
          </div>
        </div>
        <div class="card-remark-div">
          <p>{{ myVojistarCard.i18nRemark }}</p>
        </div>
      </el-card>
      <div v-else>{{ $t('trade.noVojistarCard') }}</div>
    </div>

    <div class="all-level-div">
      <h2>{{ $t('trade.allVojistarCard') }}</h2>
      <div v-if="gnlLevelVojistarCards!==null && gnlLevelVojistarCards.length>0" style="display: flex; flex-wrap: wrap">
        <div v-for="vojistarCard in gnlLevelVojistarCards"
             :key="vojistarCard.unid">
          <el-card
            :class="vojistarCard.unid.toLowerCase().replaceAll('_','-')"
            @click.native="onClickVojistarCard(vojistarCard)">
            <!--              <el-row :gutter="10">-->
            <!--                <el-col :span="12">-->
            <!--                  <div class="card-name-div">-->
            <!--                    <span>{{ vojistarCard.i18nName }}</span>-->
            <!--                  </div>-->
            <!--                  <div class="card-ccy-div">-->
            <!--                    <span>{{ vojistarCard.showCcyAmt }}</span>-->
            <!--                  </div>-->
            <!--                </el-col>-->
            <!--                <el-col :span="12">-->
            <!--                  <div v-for="(benefit,idx) in vojistarCard.i18nBenefits" :key="idx" class="card-benefit-div">-->
            <!--                    {{ benefit }}-->
            <!--                  </div>-->
            <!--                </el-col>-->
            <!--              </el-row>-->

            <div class="card-name-div">
              <span>{{ vojistarCard.i18nName }}</span>
            </div>
            <div class="card-ccy-div">
              <span>{{ vojistarCard.showCcySymbol }}{{ vojistarCard.showCcyAmt }}</span>
            </div>
            <div class="card-benefits-div">
              <div v-for="(benefit,idx) in vojistarCard.i18nBenefits" :key="idx" class="card-benefit-div">
                {{ benefit }}
              </div>
            </div>
            <div class="card-remark-div">
              <p>{{ vojistarCard.i18nRemark }}</p>
            </div>
          </el-card>

        </div>
      </div>
      <div v-if="spLevelVojistarCard">
        <el-card
          class="uni-star-sp"
          @click.native="onClickVojistarCard(spLevelVojistarCard)">
          <div class="card-name-div">
            <span>{{ spLevelVojistarCard.i18nName }}</span>
          </div>
          <div class="card-ccy-div">
            <span>{{ spLevelVojistarCard.showCcySymbol }}{{ spLevelVojistarCard.showCcyAmt }}</span>
          </div>
          <div class="card-benefits-div">
            <div v-for="(benefit,idx) in spLevelVojistarCard.i18nBenefits" :key="idx" class="card-benefit-div">
              {{ benefit }}
            </div>
          </div>
          <div class="card-remark-div">
            <p>{{ spLevelVojistarCard.i18nRemark }}</p>
          </div>
        </el-card>
      </div>
    </div>

    <div>
      <!--   Pur Card Dialog   -->
      <el-dialog
        :close-on-click-modal="false"
        v-if="enablePurCardDialog"
        :visible.sync="enablePurCardDialog"
        :title="$t('trade.purVojistarCard')"
        append-to-body
        width="500px"
      >
        <el-form
          ref="purVojistarCardFormRef"
          :model="purVojistarCardForm"
          :rules="purVojistarCardFormRules"
          size="small"
          label-width="100px"
        >
          <div style="margin-left: 50px">
            <el-card
              :class="purVojistarCardForm.vojistarCard.unid.toLowerCase().replaceAll('_','-')">
              <div class="card-name-div">
                <span>{{ purVojistarCardForm.vojistarCard.i18nName }}</span>
              </div>
              <div class="card-ccy-div">
                <span>{{ purVojistarCardForm.vojistarCard.showCcySymbol }}{{
                    purVojistarCardForm.vojistarCard.showCcyAmt
                  }}</span>
              </div>
              <div class="card-benefits-div">
                <div v-for="(benefit,idx) in purVojistarCardForm.vojistarCard.i18nBenefits" :key="idx"
                     class="card-benefit-div">
                  {{ benefit }}
                </div>
              </div>
              <div class="card-remark-div">
                <p>{{ purVojistarCardForm.vojistarCard.i18nRemark }}</p>
              </div>
            </el-card>
          </div>
          <el-divider></el-divider>
          <div>
            <el-form-item :label="$t('trade.payWay')" prop="payWay">
              <el-radio-group v-model="purVojistarCardForm.payWay">
                <el-radio label="WECHAT_PAY">
                  <img class="pay-way-img" src="@/assets/img/trade/WechatPay.jpeg" alt="WechatPay">
                  {{ $t('trade.WECHAT_PAY') }}
                </el-radio>
                <el-radio label="ALI_PAY">
                  <img class="pay-way-img" src="@/assets/img/trade/AliPay.jpeg" alt="AliPay">
                  {{ $t('trade.ALI_PAY') }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer">
          <span class="ccy-amt">{{ purVojistarCardForm.vojistarCard.showCcySymbol }}{{
              purVojistarCardForm.vojistarCard.showCcyAmt
            }}</span>
          <el-button type="danger" @click="doPurVojistarCard">{{ $t('trade.orderPay') }}</el-button>
        </div>
      </el-dialog>

      <!--   Upgrade Card Dialog   -->
      <el-dialog
        :close-on-click-modal="false"
        v-if="enableUpgradeCardDialog"
        :visible.sync="enableUpgradeCardDialog"
        :title="$t('trade.upgradeVojistarCard')"
        append-to-body
        width="600px"
      >
        <el-form
          ref="upgradeVojistarCardFormRef"
          :inline="true"
          :model="upgradeVojistarCardForm"
          :rules="upgradeVojistarCardFormRules"
          size="small"
          label-width="100px"
        >
          <div class="upgrade-container">
            <div>
              <el-card
                :class="upgradeVojistarCardForm.fromVojistarCard.unid.toLowerCase().replaceAll('_','-')">
                <div class="card-name-div">
                  <span>{{ upgradeVojistarCardForm.fromVojistarCard.i18nName }}</span>
                </div>
                <div class="card-ccy-div">
                  <span>{{ upgradeVojistarCardForm.fromVojistarCard.showCcySymbol }}{{
                      upgradeVojistarCardForm.fromVojistarCard.showCcyAmt
                    }}</span>
                </div>
                <div class="card-benefits-div">
                  <div v-for="(benefit,idx) in upgradeVojistarCardForm.fromVojistarCard.i18nBenefits" :key="idx"
                       class="card-benefit-div">
                    {{ benefit }}
                  </div>
                </div>
                <div class="card-remark-div">
                  <p>{{ upgradeVojistarCardForm.fromVojistarCard.i18nRemark }}</p>
                </div>
              </el-card>
            </div>
            <div>
              升级为
            </div>
            <div>
              <el-card :class="upgradeVojistarCardForm.toVojistarCard.unid.toLowerCase().replaceAll('_','-')">
                <div class="card-name-div">
                  <span>{{ upgradeVojistarCardForm.toVojistarCard.i18nName }}</span>
                </div>
                <div class="card-ccy-div">
                  <span>{{ upgradeVojistarCardForm.toVojistarCard.showCcySymbol }}{{
                      upgradeVojistarCardForm.toVojistarCard.showCcyAmt
                    }}</span>
                </div>
                <div class="card-benefits-div">
                  <div v-for="(benefit,idx) in upgradeVojistarCardForm.toVojistarCard.i18nBenefits" :key="idx"
                       class="card-benefit-div">
                    {{ benefit }}
                  </div>
                </div>
                <div class="card-remark-div">
                  <p>{{ upgradeVojistarCardForm.toVojistarCard.i18nRemark }}</p>
                </div>
              </el-card>
            </div>
          </div>
          <el-divider></el-divider>
          <div>
            <el-form-item :label="$t('trade.payWay')" prop="payWay">
              <el-radio-group v-model="upgradeVojistarCardForm.payWay">
                <el-radio label="WECHAT_PAY">
                  <img class="pay-way-img" src="@/assets/img/trade/WechatPay.jpeg" alt="WechatPay">
                  {{ $t('trade.WECHAT_PAY') }}
                </el-radio>
                <el-radio label="ALI_PAY">
                  <img class="pay-way-img" src="@/assets/img/trade/AliPay.jpeg" alt="AliPay">
                  {{ $t('trade.ALI_PAY') }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <span class="ccy-amt">{{ upgradeVojistarCardForm.toVojistarCard.showCcySymbol }}{{
              upgradeVojistarCardForm.showDiffCcyAmt
            }}</span>
          <el-button type="danger" @click="doUpgradeVojistarCard">{{ $t('trade.orderPay') }}</el-button>
        </div>
      </el-dialog>

      <!-- Pay Dialog   -->
      <el-dialog
        :close-on-click-modal="false"
        v-if="payInfo.enablePayQrCodeDialog"
        :visible.sync="payInfo.enablePayQrCodeDialog"
        :title="$t('trade.payQrCode')"
        append-to-body
        width="500px"
      >
        <el-card class="pay-qr-code-card">
          <img class="pay-qr-code-img" :src="payInfo.payQrCode" alt="PayQrCode">
          <p>{{ $t('trade.plsUse') }}{{ $t('trade.' + payInfo.payWay) }}{{ $t('trade.scanToPay') }}</p>
        </el-card>
      </el-dialog>
    </div>

  </div>
</template>

<script>

import TimeUtil from '@/util/TimeUtil'

import { STR_ID_MAX_LENGTH } from '@/constant/CmnBizConst'
import VojistarCardApi from '@/api/tradecenter/VojistarCardApi'
import WechatPayApi from '@/api/tradecenter/WechatPayApi'
import AliPayApi from '@/api/tradecenter/AliPayApi'
import { BigNumber } from 'bignumber.js'
import ProtRule from '@/constant/ProtRule'

export default {
  name: 'VojistarCard',
  data () {
    return {
      myVojistarCard: null,
      gnlLevelVojistarCards: [],
      spLevelVojistarCard: null,
      enablePurCardDialog: false,
      purVojistarCardForm: {
        vojistarCard: {},
        payWay: null
      },
      purVojistarCardFormRules: {},
      enableUpgradeCardDialog: false,
      upgradeVojistarCardForm: {
        fromVojistarCard: null,
        toVojistarCard: null,
        diffCcyAmt: null,
        showDiffCcyAmt: null,
        payWay: null
      },
      upgradeVojistarCardFormRules: {},
      payInfo: {
        enablePayQrCodeDialog: false,
        payQrCode: null,
        payWay: null,
        payStatusTimer: null
      }
    }
  },
  created () {
    this.initFormRules()
    this.initMyVojistarCard()
    this.initAllLevelCards()
  },
  beforeDestroy () {
    if (this.payInfo.payStatusTimer) {
      clearInterval(this.payInfo.payStatusTimer)
      this.payInfo.payStatusTimer = null
    }
  },
  methods: {
    initFormRules () {
      this.purVojistarCardFormRules = {
        payWay: [
          ProtRule.cmnRule().required
        ]
      }
      this.upgradeVojistarCardFormRules = {
        payWay: [
          ProtRule.cmnRule().required
        ]
      }
    },
    initMyVojistarCard () {
      VojistarCardApi.syncStatus()
      VojistarCardApi.findCurUsrz().then(op => {
        if (op) {
          this.myVojistarCard = op
        }
      })
    },
    initAllLevelCards () {
      VojistarCardApi.findAllLevelCards().then(op => {
        if (op) {
          for (const card of op) {
            if (card.unid === 'UNI_STAR') {
              this.spLevelVojistarCard = card
            } else {
              this.gnlLevelVojistarCards.push(card)
            }
          }
        }
      })
    },
    onClickVojistarCard (vojistarCard) {
      if (this.myVojistarCard) {
        if (this.myVojistarCard.level < vojistarCard.level) {
          this.toUpgradeVojistarCard(this.myVojistarCard, vojistarCard)
        }
      } else {
        this.toPurVojistarCard(vojistarCard)
      }
    },
    toPurVojistarCard (vojistarCard) {
      this.purVojistarCardForm = {
        vojistarCard: {
          ...vojistarCard
        },
        payWay: null
      }
      this.enablePurCardDialog = true
    },
    doPurVojistarCard () {
      this.$refs.purVojistarCardFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          unid: this.purVojistarCardForm.vojistarCard.unid,
          level: this.purVojistarCardForm.vojistarCard.level,
          ccyAmt: this.purVojistarCardForm.vojistarCard.ccyAmt,
          discount: this.purVojistarCardForm.vojistarCard.discount,
          payWay: this.purVojistarCardForm.payWay
        }
        VojistarCardApi.init(ip).then(op => {
          if (op) {
            if (op.payWay === 'WECHAT_PAY') {
              this.wechatPay(op)
            } else {
              this.aliPay(op)
            }
          }
        })
      })
    },
    toUpgradeVojistarCard (fromVojistarCard, toVojistarCard) {
      this.upgradeVojistarCardForm.fromVojistarCard = fromVojistarCard
      this.upgradeVojistarCardForm.toVojistarCard = toVojistarCard
      this.upgradeVojistarCardForm.diffCcyAmt = toVojistarCard.ccyAmt - fromVojistarCard.ccyAmt
      this.upgradeVojistarCardForm.showDiffCcyAmt = new BigNumber(toVojistarCard.ccyAmt).minus(fromVojistarCard.ccyAmt).dividedBy(100).toFixed(2)
      this.enableUpgradeCardDialog = true
    },
    doUpgradeVojistarCard () {
      this.$refs.upgradeVojistarCardFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          from: {
            id: this.upgradeVojistarCardForm.fromVojistarCard.id,
            unid: this.upgradeVojistarCardForm.fromVojistarCard.unid,
            level: this.upgradeVojistarCardForm.fromVojistarCard.level,
            ccyAmt: this.upgradeVojistarCardForm.fromVojistarCard.ccyAmt,
            discount: this.upgradeVojistarCardForm.fromVojistarCard.discount
          },
          to: {
            unid: this.upgradeVojistarCardForm.toVojistarCard.unid,
            level: this.upgradeVojistarCardForm.toVojistarCard.level,
            ccyAmt: this.upgradeVojistarCardForm.toVojistarCard.ccyAmt,
            discount: this.upgradeVojistarCardForm.toVojistarCard.discount
          },
          diffCcyAmt: this.upgradeVojistarCardForm.diffCcyAmt,
          payWay: this.upgradeVojistarCardForm.payWay
        }
        VojistarCardApi.upgrade(ip).then(op => {
          if (op) {
            if (op.payWay === 'WECHAT_PAY') {
              this.wechatPay(op)
            } else {
              this.aliPay(op)
            }
          }
        })
      })
    },
    wechatPay (payInitOp) {
      const ip = {
        txnId: payInitOp.txnId,
        payBiz: 'PUR_VOJISTAR_CARD',
        ccyAmt: payInitOp.ccyAmt
      }
      WechatPayApi.genPayQrCode(ip).then(op => {
        this.payInfo.payWay = 'WECHAT_PAY'
        this.payInfo.payQrCode = op
        this.payInfo.enablePayQrCodeDialog = true
        this.payInfo.payStatusTimer = setInterval(() => {
          this.findVojistarCardStatus(payInitOp)
        }, 10000)
      })
    },
    aliPay (payInitOp) {
      const ip = {
        txnId: payInitOp.txnId,
        payBiz: 'PUR_VOJISTAR_CARD',
        ccyAmt: payInitOp.ccyAmt
      }
      AliPayApi.genPayQrCode(ip).then(op => {
        this.payInfo.payWay = 'ALI_PAY'
        this.payInfo.payQrCode = op
        this.payInfo.enablePayQrCodeDialog = true
        this.payInfo.payStatusTimer = setInterval(() => {
          this.findVojistarCardStatus(payInitOp)
        }, 10000)
      })
    },

    findVojistarCardStatus (payInitOp) {
      const ip = {
        id: payInitOp.id,
        payWay: payInitOp.payWay,
        txnId: payInitOp.txnId
      }
      VojistarCardApi.findOrSyncStatus(ip).then(op => {
        if (op === 'PAID') {
          clearInterval(this.payInfo.payStatusTimer)
          this.payInfo.payStatusTimer = null
          this.payInfo.enablePayQrCodeDialog = false
          VojistarCardApi.complete(payInitOp).then(op => {
            if (op) {
              this.enablePurCardDialog = false
              this.enableUpgradeCardDialog = false
              this.$message.success(this.$t('trade.purVojistarCardSuccess'))
              this.initMyVojistarCard()
            }
          })
        }
      })
    },

    STR_ID_MAX_LENGTH () {
      return STR_ID_MAX_LENGTH
    },

    msecFormatter (row, column, cellValue, index) {
      if (cellValue) {
        return TimeUtil.convertMsecToDateTimeStr(cellValue)
      }
      return null
    },
    tradeFormatter (row, column, cellValue, index) {
      return this.$t('trade.' + cellValue)
    }
  }
}
</script>

<style scoped>

</style>
