<template>
  <div>
    <div>
      <h3>什么是时间戳</h3>
      <div class="code-container">
        <pre>{{ $t('timestamp.define') }}</pre>
      </div>
    </div>
    <!---->
    <div>
      <h3>获取当前时间戳</h3>
      <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column
          prop="lang"
          label="语言"
          sortable
          width="100px">
        </el-table-column>
        <el-table-column
          prop="code"
          label="代码">
          <template slot-scope="scope">
            <div class="code-container" @mouseover="showCopyButton(scope.$index)"
                 @mouseleave="hideCopyButton(scope.$index)">
              <pre><code class="language-go">{{ scope.row.code }}</code></pre>
              <!--              <el-button v-if="copyButtonVisible[scope.$index]" class="copy-button" icon="el-icon-document-copy"-->
              <!--                         @click="copyCode(scope.row.code)">-->
              <!--              </el-button>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TsKnowledge',
  data () {
    return {
      tableData: [
        {
          lang: 'Java',
          code: 'long tsSec = System.currentTimeMillis() / 1000'
        },
        {
          lang: 'Go',
          code: 'import ("time")\n' + 'int64(time.Now().Unix())'
        },
        {
          lang: 'C/C++',
          code: '#include <stdio.h>\n' +
            '#include <time.h>\n' +
            '\n' +
            'int main() {\n' +
            '    time_t ts_sec;\n' +
            '    time(&ts_sec);\n' +
            '    printf("now: %ld",ts_sec);\n' +
            '    return 0;\n' +
            '}'
        },
        {
          lang: 'JavaScript',
          code: 'var tsSec = Math.round(new Date() / 1000)'
        },
        {
          lang: 'Python',
          code: 'import time\n' + 'time.time()'
        },
        {
          lang: 'PHP',
          code: '<?php\n' + 'time();'
        },
        {
          lang: 'MYSQL',
          code: 'SELECT unix_timestamp(now());'
        },
        {
          lang: 'SQLite',
          code: 'SELECT strftime(\'%s\', \'now\')'
        },
        {
          lang: 'Swift',
          code: 'NSDate().timeIntervalSince1970'
        },
        {
          lang: 'Objective-C',
          code: '[[NSDate date] timeIntervalSince1970]'
        },
        {
          lang: 'Erlang',
          code: 'calendar:datetime_to_gregorian_seconds(calendar:universal_time())-719528*24*3600.'
        },
        {
          lang: 'Lua',
          code: 'os.time()'
        },
        {
          lang: 'Ruby',
          code: 'Time.now.to_i'
        },
        {
          lang: 'Groovy',
          code: '(new Date().time / 1000).longValue()'
        },
        {
          lang: 'Shell',
          code: 'date +%s'
        },
        {
          lang: '.NET/C#',
          code: 'DateTimeOffset.UtcNow.ToUnixTimeSeconds();'
        },
        {
          lang: 'Dart',
          code: '(new DateTime.now().millisecondsSinceEpoch / 1000).truncate()'
        }
      ]
    }
  },
  methods: {
    formatter (row, column) {
      return row.address
    },
    showCopyButton (index) {
      this.$set(this.copyButtonVisible, index, true)
    },
    hideCopyButton (index) {
      this.$setset(this.copyButtonVisible, index, false)
    },
    copyCode (code) {
      this.$clipboard.copy(code).then(() => {
        this.$message({
          message: '代码已复制',
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped>

.code-container {
  position: relative;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  padding-left: 10px;
}

.copy-button {
  position: absolute;
  top: 0;
  right: 0;
}

.code-area {
  border: 1px solid #ccc;
  background-color: #f5f5f5;
}

.code-toolbar {
  position: relative;
}
</style>
