<template>
  <div>
    <h2>【{{ $t('ieSpace.inbox') }}】 {{ $t('cmn.plsWaitingFor') }}</h2>
    <p>{{ $t('cmn.plsJoinUs') }}</p>
  </div></template>

<script>
export default {
  name: 'Inbox'
}
</script>

<style scoped>

</style>
