<template>
  <div class='i18n-btn'>
    <el-button v-if="$i18n.locale === 'en'" @click="changeLang" circle
               class="iconfont vj-icon-zhongyingwenqiehuan-yingwen"></el-button>
    <el-button v-else @click="changeLang" circle class="iconfont vj-icon-zhongyingwenqiehuan-zhongwen"></el-button>
  </div>
</template>

<script>

export default {
  name: 'I18nBtn',
  methods: {
    changeLang () {
      if (this.$i18n.locale === 'zh') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'zh'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.i18n-btn {
  .iconfont {
    padding: 6px;
    font-size: 26px;
  }
}

</style>
