<template>
  <div>
    <div class="unit-input-div">
      <el-input class="item unit-converter-input-number-area" v-model="input" @input="onInput"></el-input>
      <el-select class="item" v-model="unit1" @change="onUnitChange" ref="unit1OptionRef">
        <el-option-group
          v-for="unitOption in unitOptions"
          :key="unitOption.categ"
          :label="unitOption.categ">
          <el-option
            v-for="(item, idx) in unitOption.units"
            :key="unitOption.categ + '.' + idx"
            :label="item.unitName"
            :value="item.unitKey">
            <span style="float: left">{{ item.unitName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.unitExtra }}</span>
          </el-option>
        </el-option-group>
      </el-select>
      <el-button class="item" icon="el-icon-refresh" circle @click="exchangeUnit"></el-button>
      <el-select class="item" v-model="unit2" @change="onUnitChange" ref="unit2OptionRef">
        <el-option-group
          v-for="unitOption in unitOptions"
          :key="unitOption.categ"
          :label="unitOption.categ">
          <el-option
            v-for="(item, idx) in unitOption.units"
            :key="unitOption.categ + '.' + idx"
            :label="item.unitName"
            :value="item.unitKey">
            <span style="float: left">{{ item.unitName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.unitExtra }}</span>
          </el-option>
        </el-option-group>
      </el-select>
      <el-button class="item" icon="el-icon-more" circle @click="showAll"></el-button>
    </div>
    <div v-if="this.result">
      <el-divider></el-divider>
      <h3 v-if="this.result">{{ this.result }}</h3>
    </div>
    <el-divider></el-divider>
    <div>
      <el-table
        :data="tableData"
        height="500px"
        border
        :span-method="objectSpanMethod"
        style="width: 100%">
        <el-table-column
          prop="categ"
          :label="$t('unitConverter.categ')"
          width="150">
        </el-table-column>
        <el-table-column
          prop="unitName"
          :label="$t('unitConverter.unit')"
          width="180">
        </el-table-column>
        <el-table-column
          prop="fromSiuFormula"
          label="FROM V(m³)">
        </el-table-column>
        <el-table-column
          prop="toSiuFormula"
          label="TO V(m³)">
        </el-table-column>
        <el-table-column
          prop="remark"
          :label="$t('cmn.remark')">
        </el-table-column>
      </el-table>
    </div>

    <div>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableShowAllDialog"
        append-to-body
      >
        <div style="display: flex">
          <el-input class="unit-converter-input-number-area" v-model="input" @input="onInput"></el-input>
          <el-select v-model="unit1" @change="onUnitChange" ref="unit1OptionRef">
            <el-option-group
              v-for="unitOption in unitOptions"
              :key="unitOption.categ"
              :label="unitOption.categ">
              <el-option
                v-for="(item, idx) in unitOption.units"
                :key="unitOption.categ + '.' + idx"
                :label="item.unitName"
                :value="item.unitKey">
              </el-option>
            </el-option-group>
          </el-select>
        </div>
        <div>
          <div v-for="(item, idx) in allUnitResult" :key="idx">
            {{ item }}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js'

const math = require('mathjs')

export default {
  name: 'VolumeUnit',
  computed: {
    result: {
      get () {
        return this.calUnitResult()
      },
      set (v) {
      }
    },
    allUnitResult: {
      get () {
        return this.calAllUnit()
      },
      set (v) {
      }
    },
    unitData: {
      get () {
        return {
          siu: {
            'm³': {
              categ: this.$t('unitConverter.siu'),
              unitName: this.$t('volumeUnit.cubicM'),
              unitKey: 'm³',
              fromSiuFormula: 'V(m³) = V(m³)',
              toSiuFormula: 'V(m³) = V(m³)',
              fromSiuExp: 'x * 1',
              toSiuExp: 'y * 1',
              remark: this.$t('volumeUnit.remark.cubicM')
            }
          },
          metricUnit: {
            'mm³': {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.cubicMm'),
              unitKey: 'mm³',
              fromSiuFormula: 'V(mm³) = V(m³) * 1e+9',
              toSiuFormula: 'V(m³) = V(mm³) * 1e-9',
              fromSiuExp: 'x * 1e+9',
              toSiuExp: 'y * 1e-9',
              remark: this.$t('volumeUnit.remark.cubicMm')
            },
            'cm³': {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.cubicCm'),
              unitKey: 'cm³',
              fromSiuFormula: 'V(cm³) = V(m³) * 1e+6',
              toSiuFormula: 'V(m³) = V(cm³) * 1e-6',
              fromSiuExp: 'x * 1e+6',
              toSiuExp: 'y * 1e-6',
              remark: this.$t('volumeUnit.remark.cubicCm')
            },
            'dm³': {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.cubicDm'),
              unitKey: 'dm³',
              fromSiuFormula: 'V(dm³) = V(m³) * 1e+3',
              toSiuFormula: 'V(m³) = V(dm³) * 1e-3',
              fromSiuExp: 'x * 1000',
              toSiuExp: 'y * 0.001',
              remark: this.$t('volumeUnit.remark.cubicDm')
            },
            'm³': {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.cubicM'),
              unitKey: 'm³',
              fromSiuFormula: 'V(m³) = V(m³)',
              toSiuFormula: 'V(m³) = V(m³)',
              fromSiuExp: 'x * 1',
              toSiuExp: 'y * 1',
              remark: this.$t('volumeUnit.remark.cubicM')
            },
            'km³': {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.cubicKm'),
              unitKey: 'km³',
              fromSiuFormula: 'V(km³) = V(m³) * 1e-9',
              toSiuFormula: 'V(m³) = V(km³) * 1e+9',
              fromSiuExp: 'x * 1e-9',
              toSiuExp: 'y * 1e+9',
              remark: this.$t('volumeUnit.remark.cubicKm')
            },

            ul: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.ul'),
              unitKey: 'ul',
              unitExtra: this.$t('volumeUnit.extra.ul'),
              fromSiuFormula: 'V(ul) = V(m³) * 1e+9',
              toSiuFormula: 'V(m³) = V(ul) * 1e-9',
              fromSiuExp: 'x * 1e+9',
              toSiuExp: 'y * 1e-9',
              remark: this.$t('volumeUnit.remark.ul')
            },
            ml: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.ml'),
              unitKey: 'ml',
              unitExtra: this.$t('volumeUnit.extra.ml'),
              fromSiuFormula: 'V(ml) = V(m³) * 1e+6',
              toSiuFormula: 'V(m³) = V(ml) * 1e-6',
              fromSiuExp: 'x * 1e+6',
              toSiuExp: 'y * 1e-6',
              remark: this.$t('volumeUnit.remark.ml')
            },
            cl: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.cl'),
              unitKey: 'cl',
              unitExtra: this.$t('volumeUnit.extra.cl'),
              fromSiuFormula: 'V(cl) = V(m³) * 1e+5',
              toSiuFormula: 'V(m³) = V(cl) * 1e-5',
              fromSiuExp: 'x * 1e+5',
              toSiuExp: 'y * 1e-5',
              remark: this.$t('volumeUnit.remark.cl')
            },
            dl: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.dl'),
              unitKey: 'dl',
              unitExtra: this.$t('volumeUnit.extra.dl'),
              fromSiuFormula: 'V(dl) = V(m³) * 1e+4',
              toSiuFormula: 'V(m³) = V(dl) * 1e-4',
              fromSiuExp: 'x * 1e+4',
              toSiuExp: 'y * 1e-4',
              remark: this.$t('volumeUnit.remark.dl')
            },
            l: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.l'),
              unitKey: 'l',
              unitExtra: this.$t('volumeUnit.extra.l'),
              fromSiuFormula: 'V(l) = V(m³) * 1e+3',
              toSiuFormula: 'V(m³) = V(l) * 1e-3',
              fromSiuExp: 'x * 1e+3',
              toSiuExp: 'y * 1e-3',
              remark: this.$t('volumeUnit.remark.l')
            },
            hl: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('volumeUnit.hl'),
              unitKey: 'hl',
              unitExtra: this.$t('volumeUnit.extra.hl'),
              fromSiuFormula: 'V(hl) = V(m³) * 10',
              toSiuFormula: 'V(m³) = V(hl) * 0.1',
              fromSiuExp: 'x * 10',
              toSiuExp: 'y * 0.1',
              remark: this.$t('volumeUnit.remark.hl')
            }
          },
          municipalUnit: {
            LiFangCun: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('volumeUnit.LiFangCun'),
              unitKey: 'LiFangCun',
              fromSiuFormula: 'V(立方寸) = V(m³) * 30 * 30 * 30 = V(m³) * 27000',
              toSiuFormula: 'V(m³) = V(立方寸) / 27000',
              fromSiuExp: 'x * 27000',
              toSiuExp: 'y / 27000',
              remark: this.$t('volumeUnit.remark.LiFangCun')
            },
            LiFangChi: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('volumeUnit.LiFangChi'),
              unitKey: 'LiFangChi',
              fromSiuFormula: 'V(立方尺) = V(m³) * 3 * 3 * 3 = V(m³) * 27',
              toSiuFormula: 'V(m³) = V(立方尺) / 27',
              fromSiuExp: 'x * 27',
              toSiuExp: 'y / 27',
              remark: this.$t('volumeUnit.remark.LiFangChi')
            },
            ShiSheng: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('volumeUnit.ShiSheng'),
              unitKey: 'ShiSheng',
              unitExtra: this.$t('volumeUnit.extra.ShiSheng'),
              fromSiuFormula: 'V(市升) = V(m³) * 1000',
              toSiuFormula: 'V(m³) = V(市升) / 1000',
              fromSiuExp: 'x * 1000',
              toSiuExp: 'y / 1000',
              remark: this.$t('volumeUnit.remark.ShiSheng')
            },
            Dou: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('volumeUnit.Dou'),
              unitKey: 'Dou',
              unitExtra: this.$t('volumeUnit.extra.Dou'),
              fromSiuFormula: 'V(斗) = V(m³) * 100',
              toSiuFormula: 'V(m³) = V(斗) / 100',
              fromSiuExp: 'x * 100',
              toSiuExp: 'y / 100',
              remark: this.$t('volumeUnit.remark.Dou')
            }
          },
          imperialUnit: {
            'in³': {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('volumeUnit.cubicInch'),
              unitKey: 'in³',
              fromSiuFormula: 'V(in³) = V(m³) * 1e+5 / 1.6387064',
              toSiuFormula: 'V(m³) = V(in²) * 2.54e-2 * 2.54e-2 * 2.54e-2 = V(in²) * 1.6387064e-5',
              fromSiuExp: 'x * 1e+5 / 1.6387064',
              toSiuExp: 'y * 1.6387064e-5',
              remark: this.$t('volumeUnit.remark.cubicInch')
            },
            'ft³': {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('volumeUnit.cubicFoot'),
              unitKey: 'ft³',
              fromSiuFormula: 'V(ft³) = V(m³) / (0.3048^3)',
              toSiuFormula: 'V(m³) = V(ft³) * 0.3048^3 = V(ft³) * 0.028316846592',
              fromSiuExp: 'x / (0.3048^3)',
              toSiuExp: 'y * 0.3048^3',
              remark: this.$t('volumeUnit.remark.cubicFoot')
            },
            'yd³': {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('volumeUnit.cubicYard'),
              unitKey: 'yd³',
              fromSiuFormula: 'V(yd³) = V(m³) / (0.9144^3)',
              toSiuFormula: 'V(m³) = V(yd³) * 0.9144^3',
              fromSiuExp: 'x / (0.9144^3)',
              toSiuExp: 'y * (0.9144^3)',
              remark: this.$t('volumeUnit.remark.cubicYard')
            },
            ukOz: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('volumeUnit.ukOz'),
              unitKey: 'ukOz',
              unitExtra: this.$t('volumeUnit.extra.ukOz'),
              fromSiuFormula: 'V(oz) = V(m³) * 1e+5 / 2.841',
              toSiuFormula: 'V(m³) = V(oz) * 2.841e-5',
              fromSiuExp: 'x * 1e+5 / 2.841',
              toSiuExp: 'y * 2.841e-5',
              remark: this.$t('volumeUnit.remark.ukOz')
            },
            usOz: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('volumeUnit.usOz'),
              unitKey: 'usOz',
              unitExtra: this.$t('volumeUnit.extra.usOz'),
              fromSiuFormula: 'V(oz) = V(m³) * 1e+5 / 2.957',
              toSiuFormula: 'V(m³) = V(oz) * 2.957e-5',
              fromSiuExp: 'x * 1e+5 / 2.957',
              toSiuExp: 'y * 2.957e-5',
              remark: this.$t('volumeUnit.remark.usOz')
            },
            ukGal: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('volumeUnit.ukGal'),
              unitKey: 'ukGal',
              unitExtra: this.$t('volumeUnit.extra.ukGal'),
              fromSiuFormula: 'V(gal) = V(m³) * 1e+3 / 4.54609',
              toSiuFormula: 'V(m³) = V(oz) * 4.54609e-3',
              fromSiuExp: 'x * 1e+3 / 4.54609',
              toSiuExp: 'y * 4.54609e-3',
              remark: this.$t('volumeUnit.remark.ukGal')
            },
            usGal: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('volumeUnit.usGal'),
              unitKey: 'usGal',
              unitExtra: this.$t('volumeUnit.extra.usGal'),
              fromSiuFormula: 'V(gal) = V(m³) * 1e+3 / 3.785411784',
              toSiuFormula: 'V(m³) = V(oz) * 3.785411784e-3',
              fromSiuExp: 'x * 1e+3 / 3.785411784',
              toSiuExp: 'y * 3.785411784e-3',
              remark: this.$t('volumeUnit.remark.usGal')
            }
          }
        }
      },
      set (v) {
      }
    },
    unitOptions: {
      get () {
        return [
          {
            categ: this.$t('unitConverter.siu'),
            units: Object.values(this.unitData.siu)
          },
          {
            categ: this.$t('unitConverter.metricUnit'),
            units: Object.values(this.unitData.metricUnit)
          },
          {
            categ: this.$t('unitConverter.municipalUnit'),
            units: Object.values(this.unitData.municipalUnit)
          },
          {
            categ: this.$t('unitConverter.imperialUnit'),
            units: Object.values(this.unitData.imperialUnit)
          }
        ]
      },
      set (v) {
      }
    },
    tableData: {
      get () {
        return [...Object.values(this.unitData.siu), ...Object.values(this.unitData.metricUnit),
          ...Object.values(this.unitData.municipalUnit), ...Object.values(this.unitData.imperialUnit)]
      },
      set (v) {
      }
    }
  },
  data () {
    return {
      unit1: 'l',
      unit2: 'm³',
      input: null,
      enableShowAllDialog: false
    }
  },
  methods: {
    onInput () {
      this.result = this.calUnitResult()
      if (this.enableShowAllDialog) {
        this.showAll()
      }
    },

    onUnitChange () {
      this.result = this.calUnitResult()
      if (this.enableShowAllDialog) {
        this.showAll()
      }
    },

    showAll () {
      this.allUnitResult = this.calAllUnit()
      this.enableShowAllDialog = true
    },
    exchangeUnit () {
      const tempUnit = this.unit1
      this.unit1 = this.unit2
      this.unit2 = tempUnit
      this.onUnitChange()
    },
    calAllUnit () {
      const allUnitResult = []
      for (let i = 0; i < this.unitOptions.length; i++) {
        if (i !== 0) {
          const unitOptions = this.unitOptions.at(i).units
          for (const unitOption of unitOptions) {
            allUnitResult.push(this.calResult(unitOption))
          }
        }
      }
      return allUnitResult
    },
    calUnitResult () {
      if (this.input && this.unit1 && this.unit2) {
        const inputNum = new BigNumber(math.evaluate(this.input))
        if (inputNum.isFinite()) {
          const unit1Data = this.getUnitDataByUnitKey(this.unit1)
          const unit2Data = this.getUnitDataByUnitKey(this.unit2)
          if (unit1Data && unit2Data) {
            const to = math.evaluate(unit1Data.toSiuExp.replaceAll('y', this.input))
            const from = math.evaluate(unit2Data.fromSiuExp.replaceAll('x', to))
            return this.input + ' ' + this.$refs.unit1OptionRef.selected.label + '  =  ' + from + ' ' + this.$refs.unit2OptionRef.selected.label
          } else {
            return null
          }
        }
        return null
      } else {
        return null
      }
    },
    calResult (resultUnit) {
      if (this.input && this.unit1 && resultUnit) {
        const inputNum = new BigNumber(math.evaluate(this.input))
        if (inputNum.isFinite()) {
          const unit1Data = this.getUnitDataByUnitKey(this.unit1)
          const unit2Data = resultUnit
          if (unit1Data && unit2Data) {
            const to = math.evaluate(unit1Data.toSiuExp.replaceAll('y', this.input))
            const from = math.evaluate(unit2Data.fromSiuExp.replaceAll('x', to))
            return this.input + ' ' + this.$refs.unit1OptionRef.selected.label + '  =  ' + from + ' ' + resultUnit.unitName
          } else {
            return null
          }
        }
        return null
      } else {
        return null
      }
    },
    getUnitDataByUnitKey (unitKey) {
      for (const unit of Object.values(this.unitData)) {
        if (unit && unit[unitKey]) {
          return unit[unitKey]
        }
      }
      return null
    },
    objectSpanMethod ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 1,
            colspan: 1
          }
        }
        if (rowIndex === 1) {
          return {
            rowspan: 11,
            colspan: 1
          }
        }
        if (rowIndex === 12) {
          return {
            rowspan: 4,
            colspan: 1
          }
        }
        if (rowIndex === 16) {
          return {
            rowspan: 7,
            colspan: 1
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
