export default {
  cmn: {
    name: 'Name',
    rankValue: 'Rank',
    remark: 'Remark',
    confirm: 'Confirm',
    cancel: 'Cancel',
    save: 'Save',
    saveType: 'Save Type',
    new: 'New',
    update: 'Update',
    copy: 'Copy',
    back: 'Back',
    add: 'Add',
    edit: 'Edit',
    del: 'Delete',
    download: 'Download',
    opn: 'Operation',
    deal: 'Deal',
    view: 'View',
    recordName: 'Record Name',
    selectAll: 'Select All',
    srch: 'Search',
    refresh: 'Refresh',
    reset: 'Reset',
    field: 'Field',
    srchField: 'Search Field',
    timeType: 'Time Type',
    plsInputSrchKw: 'Please input search keyword',
    plsChooseBeginDateTime: 'Please select the begin time',
    plsChooseEndDateTime: 'Please select the end time',
    createTime: 'Create Time',
    updateTime: 'Update Time',
    beginTime: 'Begin Time',
    endTime: 'End Time',
    includeUpper: 'Include Upper',
    includeLower: 'Include Lower',
    batchFav: 'Batch Fav',
    fav: 'Fav',
    cancelFav: 'Cancel Fav',
    cancelFavConfirm: 'Are you sure to cancel fav?',
    uploadDrag: 'Drag it here, or ',
    uploadClick: 'Click To Upload',
    fileName: 'File Name',
    confirmDel: 'Are you sure to delete?',
    confirmSave: 'Are you sure to save?',
    checkCurPage: 'Check Current Page',
    cancelCheckAll: 'Cancel Check All',
    customName: 'Custom Name',
    customProfile: 'Custom Profile',
    profile: 'Profile',
    group: 'Group',
    groups: 'Groups',
    allGroups: 'All Groups',
    belongingGroups: 'Belonging Groups',
    tag: 'Tag',
    tags: 'Tags',
    allTags: 'All Tag',
    ctlg: 'Category',
    ctlgs: 'Categories',
    allCtlgs: 'All Categories',
    plsWaitingFor: 'is under rapid development. Please look forward to ~',
    plsJoinUs: 'You are cordially invited to join us. Please contact【seakeer@163.com】'
  },
  cmnBizMsg: {
    success: 'Successfully & Congratulations!',
    failed: 'Sorry! Something went wrong!',
    addSuccess: 'Add successfully',
    addFailed: 'Add failed',
    editSuccess: 'Edit successfully',
    editFailed: 'Edit failed',
    delSuccess: 'Delete successfully',
    delFailed: 'Delete failed',
    opSuccess: 'Operate successfully',
    opFailed: 'Operate failed',
    copySuccess: 'Copy successfully',
    copyFailed: 'Copy failed'
  },
  timezone: {
    utcMinus12: {
      displayName: 'UTC-12',
      cityIns: 'US-Baker Island, US-Holland Island'
    },
    utcMinus11: {
      displayName: 'UTC-11',
      cityIns: 'Samoa-Apia'
    },
    utcMinus10: {
      displayName: 'UTC-10',
      cityIns: 'US-Hawaii, Cook Islands-Avarua'
    },
    utcMinus9: {
      displayName: 'UTC-9',
      cityIns: 'US-Alaska'
    },
    utcMinus8: {
      displayName: 'UTC-8',
      cityIns: 'US-Los Angeles, US-San Francisco, Canada-Vancouver'
    },
    utcMinus7: {
      displayName: 'UTC-7',
      cityIns: 'US-Denver, Canada-Calgary'
    },
    utcMinus6: {
      displayName: 'UTC-6',
      cityIns: 'US-Chicago, Canada-Winnipeg, Mexico-Mexico City'
    },
    utcMinus5: {
      displayName: 'UTC-5',
      cityIns: 'US-Washington D.C., US-New York, Canada-Toronto'
    },
    utcMinus4: {
      displayName: 'UTC-4',
      cityIns: 'Canada-St.John\'s, Venezuela-Caracas'
    },
    utcMinus3: {
      displayName: 'UTC-3',
      cityIns: 'Brazil-São Paulo, Argentina-Buenos Aires'
    },
    utcMinus2: {
      displayName: 'UTC-2',
      cityIns: 'South Georgia Island, South Sandwich Islands'
    },
    utcMinus1: {
      displayName: 'UTC-1',
      cityIns: 'Cape Verde-Praia, Portugal-Azores'
    },
    utc0: {
      displayName: 'UTC+0',
      cityIns: 'UK-London, Ireland-Dublin, Portugal-Lisbon'
    },
    utc1: {
      displayName: 'UTC+1',
      cityIns: 'Germany-Berlin, France-Paris, Italy-Rome'
    },
    utc2: {
      displayName: 'UTC+2',
      cityIns: 'Greece-Athens, Turkey-Istanbul, Egypt-Cairo'
    },
    utc3: {
      displayName: 'UTC+3',
      cityIns: 'Russia-Moscow, Iraq-Baghdad, Saudi Arabia-Riyadh'
    },
    utc4: {
      displayName: 'UTC+4',
      cityIns: 'UAE-Abu Dhabi, Oman-Muscat, Uzbekistan-Tashkent'
    },
    utc5: {
      displayName: 'UTC+5',
      cityIns: 'Pakistan-Islamabad, Bangladesh-Dhaka, Uzbekistan-Tashkent'
    },
    utc6: {
      displayName: 'UTC+6',
      cityIns: 'Kazakhstan-Astana, Tajikistan-Dushanbe'
    },
    utc7: {
      displayName: 'UTC+7',
      cityIns: 'Thailand-Bangkok, Vietnam-Hanoi, Indonesia-Jakarta'
    },
    utc8: {
      displayName: 'UTC+8',
      cityIns: 'China-Beijing, China-Hong Kong, Singapore-Singapore'
    },
    utc9: {
      displayName: 'UTC+9',
      cityIns: 'Japan-Tokyo, South Korea-Seoul'
    },
    utc10: {
      displayName: 'UTC+10',
      cityIns: 'Australia-Sydney, Australia-Melbourne'
    },
    utc11: {
      displayName: 'UTC+11',
      cityIns: 'Vanuatu-Port Vila'
    },
    utc12: {
      displayName: 'UTC+12',
      cityIns: 'New Zealand-Auckland, New Zealand-Wellington, Fiji-Suva'
    },
    utc13: {
      displayName: 'UTC+13',
      cityIns: 'Tonga-Nukualofa'
    },
    utc14: {
      displayName: 'UTC+14',
      cityIns: 'Kiribati-Tarawa'
    }
  },
  sys: {
    unAuth: 'You are not logged in or login expired. Please login.',
    activateSuccess: 'Congratulations on the activation! Welcome to VOJIVO! Please login and receive rewords ~',
    timeoutRetry: 'Sorry！The system timed out，please try again.'
  },
  deregister: {},
  register: {
    vojivo: 'VOJIVO',
    login: 'Login',
    register: 'Register',
    inputVojiUnid: 'Please input voji unid',
    inputEmail: 'Please input email',
    inputPwd: 'Please input password',
    inputConfirmPwd: 'Please confirm password',
    inputCaptcha: 'Please input captcha',
    registerFailed: 'Register failed',
    registerSuccess: 'Register successfully'
  },
  activate: {
    activateAcct: 'Activate Account',
    activating: 'Activating...',
    activateSuccess: 'Activate successfully',
    activateSuccessTips: 'Please login and receive rewards ~~ Click login button or you will be redirected to log in after 3 seconds',
    toLogin: 'To Login',
    activateFailed: 'Activate failed',
    activateFailedTips: 'Sorry! Please try again or contact【vojivo@yeah.net】',
    retry: 'Retry'
  },
  login: {
    inputUnameOrEmail: 'Please input voji unid or email',
    inputPwd: 'Please input password',
    inputCaptcha: 'Please input captcha',
    login: 'Login',
    register: 'Register',
    loginFailed: 'Login failed',
    loginSuccess: 'Login successfully',
    wrongCaptcha: 'Wrong captcha',
    rememberMe: 'Remember me',
    resendEmail: 'Resend Activation Email',
    resendEmailSuccess: 'Send email successfully',
    resendEmailFailed: 'Send email failed'
  },
  share: {
    ifrComm: 'Share & Communicate',
    invite: ' invite you to join VOJIVO',
    invitation: 'Invitation',
    invitationTip: 'Copy the link to share with your friends, you all will receive [99,88,77,66,55,33,22,11] VojiBean by random after your friends register successfully',
    invitationLink: 'Link',
    invitationWords: 'Word',
    commGroup: 'Channel',
    commTip: 'Join the chanel to share and communicate. So many benefits are waiting for you ~',
    qqGroup: 'QQ Group',
    wechatGroup: 'Wechat Group',
    rewardAfterActive: 'You will receive[99,88,77,66,55,33,22,11] VojiBean by random after activate your account.'

  },
  home: {
    myRoom: 'My Room',
    toolHall: 'Tool Hall',
    studyLab: 'Voji Lab',
    magicCorner: 'Magic Corner',
    ieSpace: 'IE Space',
    perCenter: 'Personal Center',
    logout: 'Logout',
    login: 'Login',
    register: 'Register',
    deregister: 'Deregister'
  },
  myRoom: {
    myGroup: 'My Group',
    addGroup: 'Add Group',
    editGroup: 'Edit Group',
    favTool: 'Fav Tool',
    favRes: 'Fav Resource',
    purRes: 'Purchased Resource',
    toolGroup: 'Tool Group',
    resGroup: 'Resource Group',
    parentGroup: 'Parent Group',
    setGroup: 'Set Group',
    delGroupConfirm: 'Deleting the parent group will delete all children groups together. Are you sure to delete?',
    batchPur: 'Batch Purchase'
  },
  toolCmn: {
    inputTextArea: 'Input Area',
    resultTextArea: 'Result Area',
    toolName: 'Tool Name',
    onlineTime: 'Online Time'
  },
  toolHall: {
    toolCategory: 'Tool Category',
    platformTool: 'Platform Tool',
    thirdPartTool: 'Third Part Tool',
    allTool: 'All Tool',
    toolList: 'Tool List'
  },
  studyLab: {
    resPkgList: 'Resource List',
    resPkgName: 'Resource Name',
    resPkg: 'Resource',
    viewCtlg: 'View Catalog',
    ctlg: 'Catalog',
    VJV_SEED: 'Voji Seed',
    VJV_BUD: 'Voji Bud',
    VJV_FLOWER: 'Voji Flower',
    VJV_FRUIT: 'Voji Fruit',
    IMG: 'Image',
    DOC: 'Doc',
    BOOK: 'Book',
    AUDIO: 'Audio',
    VIDEO: 'Video',
    CODE: 'Code',
    SOFTWARE: 'Software'
  },
  trade: {
    bePur: 'Purchased',
    purOrder: 'Place Order',
    purPay: 'Pay',
    orderPay: 'Order Pay',
    confirmPay: 'Confirm Pay',
    pay: 'Pay',
    order: 'Order',
    orderNo: 'Order No.',
    placeTime: 'Place Time',
    orderStatus: 'Order Status',
    COMPLETED: 'Completed',
    UNPAID: 'Unpaid',
    PAID: 'Paid',
    CANCELED: 'Canceled',
    EXPIRED: 'Expired',
    payWay: 'Pay Way',
    plsUse: 'Please use',
    scanToPay: 'Please scan the code to pay. If you have paid,the page will be refreshed automatically.',
    payQrCode: 'Pay QR Code',
    VJB_WALLET: 'Voji Bean',
    ALI_PAY: 'Ali Pay',
    WECHAT_PAY: 'Wechat Pay',
    CASH: 'Cash',
    BANK: 'Bank',
    CREDIT: 'Credit',
    origPrice: 'Original Price',
    curPrice: 'Current Price',
    orderPrice: 'Order Price',
    batchPur: 'Batch Purchase',
    allCheckedBePur: 'All selected are purchased, you can download them directly~',
    vjbJar: 'Voji Bean Jar',
    walletVjb: 'Voji Bean Balance',
    clickReceiveReward: 'Click here to receive rewards ~',
    vjbRewardItem: 'Reword Item',
    receive: 'Receive',
    customTopUp: 'Custom Top Up',
    topUpPkg: 'Top Up Package',
    topUp: 'Top Up',
    toTopUp: 'To Top Up',
    topUpSuccess: 'Congratulations! Top up successfully~',
    myTopUp: 'My Top Up Record',
    topUpTime: 'Top Up Time',
    ccyAmt: 'Amount',
    txnCcyAmt: 'Txn Amount',
    topUpStatus: 'Top Up Status',
    txnWay: 'Txn Way',
    SELF: 'Self',
    CS: 'Customer Service',
    vojistarCard: 'Vojistar Card',
    myVojistarCard: 'My Vojistar Card',
    noVojistarCard: 'You do not have a vojistar card, it will bring you more convenient experience.',
    allVojistarCard: 'Vojistar Cards',
    purVojistarCard: 'Purchase Vojistar Card',
    upgradeVojistarCard: 'Upgrade Vojistar Card',
    vojistarCardLevel: 'Vojistar Card Level',
    maxCountTip: 'Reached the maximum count',
    vojistarCardNoLimitTip: 'With a Vojistar Card, All data will be unlimited！',
    click2VojistarCard: 'Click here to view Vojistar Card~',

    myOrder: 'My Order',
    myBill: 'My Bill',
    txnId: 'Txn Id',
    vjb: 'Voji Bean',
    txnBiz: 'Txn Biz',
    TOP_UP: 'Voji Bean Top Up',
    PUR_RES_PKG: 'Purchase Resource',
    PUR_VOJISTAR_CARD: 'Purchase Vojistar Card',
    REG_INVITER: 'Inviter Reward',
    REG_INVITEE: 'Invitee Reward',
    REWARD_REG_INVITER: 'Inviter Reward',
    REWARD_REG_INVITEE: 'Invitee Reward',
    ORB_STAR: 'Orb Star',
    NEBU_STAR: 'Nebu Star',
    CLU_STAR: 'Clu Star',
    GALA_STAR: 'Gala Star',
    UNI_STAR: 'Uni Star'
  },
  magicCorner: {
    blueBox: 'Blue Box',
    bigWin: 'Big Window',
    vojiRoom: 'Voji Story'
  },
  ieSpace: {
    winkBalcony: 'Wink Balcony',
    paperPlane: 'Paper Plane',
    inbox: 'Message Inbox',
    commentWall: 'Comment Wall',
    prdWindow: 'PRD Window',
    gameRoom: 'Game Room'
  },
  userCenter: {
    myInfo: 'My Info',
    usrPerInfo: 'User Info',
    usrAcctInfo: 'Account Info',
    male: 'Male',
    female: 'Female',
    unknown: 'Hide',
    deregister: 'Deregister',
    usrPer: {
      name: 'Name',
      gender: 'Gender',
      remark: 'Remark'
    },
    usrAcct: {
      unid: 'Voji Unid',
      pwd: 'Password',
      oldUnid: 'Old Voji Unid',
      newUnid: 'New Voji Unid',
      showName: 'Show Name',
      showGender: 'Show Gender',
      showProfile: 'Show Profile',
      oldEmail: 'Old Email',
      newEmail: 'New Email',
      email: 'Email',
      phone: 'Phone',
      wechat: 'Wechat',
      level: 'Level',
      isVip: 'Membership',
      status: 'Status',
      changeUnid: 'Change Voji Unid',
      upgradeAcctLevel: 'Upgrade Level',
      changePwd: 'Change Password',
      changeEmail: 'Change Email',
      oldPwd: 'Current Password',
      newPwd: 'New Password',
      confirmNewPwd: 'Confirm Password',
      avatarUri: 'Avatar URI',
      uploadAvatar: 'Click To Upload',
      setAvatar: 'Set Avatar',
      usrPerId: '',
      mainAcctId: ''
    }
  },

  jsonOne: {
    editor: ' Editor',
    javaEditor: 'Java Editor',
    plsInputJmesPath2Srch: 'JMESPath',
    jmesPathSrchResult: 'JMESPath Search Result',
    invalidInput: 'Invalid input',

    save: 'Save Record',
    view: 'View Record',
    convertTo: 'Convert To',
    parseFrom: 'Parse From',
    extractFrom: 'Extract From',

    toExcel: 'To EXCEL',
    toCsv: 'To CSV',
    toSql: 'To SQL',
    toYaml: 'To YAML',
    toProperties: 'To PROPERTIES',

    toJava: 'To JAVA',
    toGo: 'To GO',
    toCpp: 'To C++',
    toPhp5: 'To PHP5',
    toPhp7: 'To PHP7',

    fromExcel: 'From EXCEL',
    fromCsv: 'From CSV',
    fromSql: 'From SQL',
    fromYaml: 'From YAML',
    fromProperties: 'From PROPERTIES',
    fromJava: 'From JAVA',
    fromGo: 'From GO',
    fromCpp: 'From C++',
    fromPhp: 'From PHP',

    fromText: 'From Text',
    fromImg: 'From Image',

    className: 'Class Name',
    enableGenKey: 'Enable Gen Key Mode',
    enableGenKeyDes: '若启用生成KEY模式，则在识别到有多个JSON时，将返回一个完整的JSON，自动生成JSON_1, JSON_2等KEY，其值为识别到的多个JSON。' +
      '若不启用生成KEY模式，则在识别到多个JSON时，直接返回多个JSON，因为JSON校验器会校验是否完整的JSON结构，所以返回多个JSON时JSON校验器可能会有错误提示。',

    jsonArrayMode: 'Array Mode',
    jsonArrayEleMode: 'Array Ele Mode',
    jsonObjectMode: 'Object Mode',
    multiCol: 'Multi Column',
    oneCol: 'Single Column',
    multiColTip: 'arr:[1,2,3] --> 3 columns: arr[0]、arr[1]、arr[3]',
    oneColTip: 'arr:[1,2,3] ---> 1 column: arr',
    subHeader: 'Key As Header',
    subHeaderTip: 'Attribute name as excel header mode',
    kv: 'Key Value',
    kvTip: 'key=value mode',
    jsonString: 'JSON',
    jsonStringTip: 'JSON string mode',
    headerCount: 'Header Count',
    multiType: 'Multi Type',
    multiTypeTip: 'Auto detect data type if enabled',
    multiLayer: 'Multi Layer',
    multiLayerTip: 'Auto detect object if enabled',
    enableArray: 'Array Mode',
    enableArrayTip: 'Auto detect array if enabled'
  },

  coCoder: {
    genCode: 'Generate Code',
    myProject: 'My Project',
    newProject: 'New Project',
    projectView: 'Project Viewer',
    exampleProject: 'Example Project',

    databaseType: 'Database Type',
    databaseSelect: 'Select Database',
    entityModeConfig: 'Config Entity Model',
    frameworkSelect: 'Select Framework',

    mainFramework: 'Main Framework',
    extraFramework: 'Extra Framework',
    commonBase: 'SpringBootWeb',
    commonBaseTip: 'Basic spring boot web framework',
    vojivoSeakeerTip: 'Very convenient and concise framework based on Spring Boot.',
    swaggerTip: 'Swagger V3, It will add annotations like @Tag @Operation for generate API document.',
    springCloudFeignTip: 'Spring Cloud Feign,It will generate the code of Feign Client calling server API.',
    viewExample: 'View Example',
    exampleCode: 'Example Code',

    prevStep: 'Prev',
    nextStep: 'Next',
    restart: 'Restart',

    entityName: 'Entity Name',
    entityRemark: 'Entity Remark',
    entityModelType: 'Entity Model Type',
    entityModelContent: 'Entity Model Content',
    entityModelContentPlaceholder: 'Please input JSON or SQL(CREATE TABLE or INSERT), It will be parsed to fields of the entity.',
    tableDocName: 'Table Doc Name',
    projectName: 'Project Name',
    authorName: 'Author Name',
    moduleParentPkgPath: 'Parent Package Path',
    moduleName: 'Module Name',
    moduleNamePlaceholder: 'Full package path will be {Parent Package Path}.{Module Name}',
    entityJsonModel: 'Entity JSON Model',
    entitySqlModel: 'Entity SQL Model'
  },

  timingLendar: {
    calendar: 'Calendar',
    comingTime: 'Coming Time',
    pastTime: 'Past Time'
  },

  timestamp: {
    curTs: 'Current Timestamp',
    tsConvert: 'Time Converter',
    tsToTime: 'To Datetime',
    timeToTs: 'To Timestamp',
    tsUnit: 'Timestamp',
    timeZone: 'Datetime',
    tsKnowledge: 'Timestamp Intro',
    define: '时间戳是指从GMT/UTC 1970-01-01 00:00:00 开始所经过的秒数，不考虑闰秒。\n' +
      'GMT: 格林威治时间(Greenwich Mean Time) \n' +
      'UTC: 协调世界时间(Coordinated Universal Time),又称世界统一时间、世界标准时间、国际协调时间'
  },
  geoLonLat: {
    geoCal: 'Distance Calculator',
    geoRecord: 'Historical Record',
    recognize: 'Recognize',
    recognizeLonLat: 'Recognize Lon Lat',
    cal: 'Calculate',
    clear: 'Clear',
    saveAsNew: 'Save As New',
    update: 'Update',
    toJson: 'To Json',
    record: 'View Record',
    number: 'Number',
    distance: 'Distance',
    unit: 'Unit',
    locA: 'Location A',
    locB: 'Location B',
    lonLatText: 'Text',
    inputTextA: 'Location Text A',
    inputTextB: 'Location Text B',
    geoResultsJson: 'Result JSON',
    recordName: 'Record Name'

  },
  enDecoder: {
    encode: 'Encode',
    decode: 'Decode',
    enDecodeOverview: 'EnDecode Overview',
    enDecodeDefine: '编解码指在数据的表示、存储和传输时对数据进行格式转换的一种技术。\n' +
      '编码是将输入的原始数据转换为特定格式的过程，而解码则是将这种特定格式的数据转换回原始输入数据的过程。\n' +
      '编解码的目的是为了确保数据在不同的系统或设备之间能够正确地传输和解析。',
    beLe: '大端BE(Big-Endian)和小端LE(Little-Endian): 计算机在存储多字节数据时，会存在字节序的概念，即数据的高字节（指代表数值最高有效位的字节）和低字节存储在低地址还是高地址，由此产生了BE和LE两种存储方式。\n' +
      '\n' +
      '大端BE：数据的高字节，存放在低地址中。大端存储较为直观，读取数据时从低地址开始读取，数值最高有效位先出现，符合人类的阅读习惯。且符号位比较容易判断，但大端模式在进行数值运算效率较低且内存对齐优化会受限。大端模式通常应用于网络传输（网络字节序）。\n' +
      '\n' +
      '小端LE：数据的低字节，存放在低地址中。读取数据时从高地址开始读取。小端存储在进行位操作时比较方便。另外小端存储可以更自然地利用低地址部分存放最常访问或最重要的数据，利于某些特定的内存对齐优化。但小端存储不直观，不符合人类的阅读习惯，且符号位判断复杂。\n' +
      '\n' +
      'UTF-8的编码是以1个字节为单位进行处理的，所以不涉及大小端存储，而UTF-16和UTF-32则分别存在大端编码和小端编码的方式：UTF-16BE、UTF-16LE和UTF-32BE、UTF-32LE.',
    charset: 'Charset',
    charCoding: 'Char Coding',
    intro: 'Intro',
    coding: 'Coding',
    define: 'Define',
    principle: 'Principle',
    usageScenario: 'Usage Scenario',
    base64: 'BASE64',
    url: 'URL',
    hex: 'HEX',
    char: 'Character',
    // ascii: 'ASCII字符集编解码',
    // chinese: '汉字字符集编解码',
    // gb2312: 'GB2312编解码',
    // gbk: 'GBK编解码',
    // gb18030: 'GB18030编解码',
    // unicode: 'UNICODE字符集编解码',
    // utf8: 'UTF-8编解码',
    // utf16: 'UTF-16编解码',
    // utf32: 'UTF-32编解码'
    ascii: 'ASCII',
    chinese: 'Chinese',
    gb2312: 'GB2312',
    gbk: 'GBK',
    gb18030: 'GB18030',
    unicode: 'UNICODE',
    utf8: 'UTF-8',
    utf16: 'UTF-16',
    utf32: 'UTF-32',
    be: 'Big Endian',
    le: 'Little Endian',
    unicodeCharset: 'UNICODE Charset',
    toUnicode: 'To UNICODE',
    fromUnicode: 'From UNICODE',
    inputUnicode: 'Please input UNICODE, like \\u4f60\\u597d'
  },
  hash: {
    hashIntro: 'Hash Overview',
    spaceHash: 'Hash',
    crackHash: 'Crack Hash',
    spaceConfirm: 'Confirm',
    crack: 'Crack',
    hashAlg: 'Hash Algorithm',
    outputResult: 'Output Result',
    performance: 'Performance',
    collisionProbability: 'Collision Probability',
    usageScenario: 'Usage Scenario',
    principle: 'Principle',
    hashDefine: 'Hash(哈希/散列)是一种将任意长度的输入(预映射pre-image)映射为固定长度的输出(Hash值)的算法。\n' +
      '这种映射是一种压缩映射，即散列值的空间通常远小于输入的空间，不同的输入可能会映射成相同的输出(即产生碰撞)。\n' +
      'Hash具有正向简单，逆向困难的特点。'
  },

  unitConverter: {
    unit: 'Unit',
    categ: 'Category',
    siu: 'SIU',
    regularUnit: 'Regular Unit',
    metricUnit: 'Metric Unit',
    municipalUnit: 'Municipal Unit',
    imperialUnit: 'Imperial Unit',
    otherRelated: 'Others Related',
    lengthUnit: 'Length Unit',
    areaUnit: 'Area Unit',
    volumeUnit: 'Volume Unit',
    massUnit: 'Mass Unit',
    tempUnit: 'Temperature Unit',
    storageUnit: 'Storage Unit',
    timeUnit: 'Time Unit',
    speedUnit: 'Speed Unit',
    angularUnit: 'Angular Unit'
  },
  timeUnit: {
    fs: 'Femtosecond(fs)',
    ps: 'Picosecond(ps)',
    ns: 'Nanosecond(ns)',
    us: 'Microsecond(μs)',
    ms: 'Millisecond(ms)',
    s: 'Second(s)',
    min: 'Minute(min)',
    ke: '刻[Ke]',
    h: 'Hour(h)',
    d: 'Day(d)',
    w: 'Week(w)',
    m: 'Month(m)',
    quarter: 'Quarter(q)',
    y: 'Year',
    c: 'Century',
    remark: {
      fs: '',
      ps: '',
      ns: '',
      us: '',
      ms: '',
      s: '一秒等于铯-133原子在基态下的两个超精细能级之间跃迁所对应的辐射的9192631770个周期的时间。',
      min: '',
      ke: '',
      h: '1 h = 60 min',
      d: '1 d = 24 h',
      w: '1 w = 7 d',
      m: '小月 = 30 天 或者 大月 = 31天; 大月 --- 1,3,5,7,8,10,12; 小月 --- 2,4,6,9,11',
      quarter: '',
      y: '',
      c: '1c = 100 y'
    }
  },
  lengthUnit: {
    nm: 'Nanometer(nm)',
    um: 'Micrometer(μm)',
    mm: 'Millimeter(mm)',
    cm: 'Centimeter(cm)',
    dm: 'Decimeter(dm)',
    m: 'Meter(m)',
    km: 'Kilometer(km)',

    Hao: '毫[Hao]',
    Li: '厘[Li]',
    Fen: '分[Fen]',
    Cun: '寸[Cun]',
    Chi: '尺[Chi]',
    Zhang: '丈[Zhang]',
    Lii: '里[Lii]',

    milliInch: 'Milli Inch(mil)',
    inch: 'Inch(in)',
    foot: 'Foot(ft)',
    yard: 'Yard(yd)',
    fathom: 'Fathom(fm)',
    rd: 'Rod(rd)',
    furlong: 'Furlong(fur)',
    mile: 'Mile(mi)',
    nauticalMile: 'Nautical Mile(nmi)',

    lightYear: 'Light Year(ly)',
    au: 'AU(A.U.)',

    remark: {
      nm: '',
      um: '',
      mm: '',
      cm: '',
      dm: '',
      m: '光在真空中传播1/299792458秒所经过的距离定义为1m。',
      km: '',

      Hao: '',
      Li: '',
      Fen: '',
      Cun: '',
      Chi: '',
      Zhang: '',
      Lii: '',

      milliInch: '',
      inch: '',
      foot: '',
      yard: '',
      fathom: '',
      rd: '',
      furlong: '',
      mile: '英里，又称为迈',
      nauticalMile: '海里(nmi)，常用语航海领域',

      lightYear: '光年(ly)是指光在真空中沿直线传播一儒略年(天文学时间单位，数值定义为365.25天)所经过的距离，1 ly = 299792458 km/s * 365.25 d * 86400 s = 9460730472580800 m = 9.4607304725808e+15 ~ 9.46e+15。光年一般用于衡量天体之间的距离，如距离太阳最近的恒星是位于半人马座的比邻星，距离太阳4.22光年。',
      au: '天文单位(A.U.)是天文学中计量天体之间距离的一种单位，其数值取地球和太阳之间的平均距离1.495978707e+11米。月球距离地球0.0026 A.U.'
    }
  },
  areaUnit: {
    squareMm: 'SQ Millimeter(m㎡)',
    squareCm: 'SQ Centimeter(c㎡)',
    squareDm: 'SQ Decimeter(d㎡)',
    squareM: 'SQ Meter(㎡)',
    are: 'Are(a)',
    hectare: 'Hectare(ha)',
    squareKm: 'SQ Kilometer(k㎡)',

    PingFangCun: '平方寸[SQ Cun]',
    PingFangChi: '平方尺[SQ Chi]',
    Fen: '分[Fen]',
    Mu: '亩[Mu]',
    Qing: '倾[Qing]',

    squareInch: 'SQ Inch(in²)',
    squareFoot: 'SQ Foot(ft²)',
    squareYard: 'SQ Yard(yd²)',
    squareRod: 'SQ Rod(rd²)',
    acre: 'Acre(ac)',
    squareMile: 'SQ Mile(mi²)',

    remark: {
      squareMm: '',
      squareCm: '',
      squareDm: '',
      squareM: '',
      are: '',
      hectare: '',
      squareKm: '',

      PingFangCun: '',
      PingFangChi: '',
      Fen: '',
      Mu: '亩，常用来衡量土地面积',
      Qing: '',

      squareInch: '',
      squareFoot: '',
      squareYard: '',
      squareRod: '',
      acre: '1 ac = 160 rd²',
      squareMile: '平方英里(mi²)'
    }
  },
  volumeUnit: {
    cubicM: 'Cubic Meter(m³)',
    cubicMm: 'Cubic Millimeter(mm³)',
    cubicCm: 'Cubic Centimeter(cm³)',
    cubicDm: 'Cubic Decimeter(dm³)',
    cubicKm: 'Cubic Kilometer(km³)',

    ul: 'Microliter(μl)',
    ml: 'Milliliter(ml)',
    cl: 'Centiliter(cl)',
    dl: 'Deciliter(dl)',
    l: 'Liter(l)',
    hl: 'Hektoliter(hl)',

    LiFangCun: '立方寸[Cubic Cun]',
    LiFangChi: '立方尺[Cubic Chi]',
    ShiSheng: '市升[Shi Sheng]',
    Dou: '斗[Dou]',

    cubicInch: 'Cubic Inch(in³)',
    cubicFoot: 'Cubic Foot(ft³)',
    cubicYard: 'Cubic Yard(yd³)',

    ukOz: 'Ounce(oz)-UK',
    usOz: 'Ounce(oz)-US',
    ukGal: 'Gallon(gal)-UK',
    usGal: 'Gallon(gal)-US',

    remark: {
      cubicM: '',
      cubicMm: '',
      cubicCm: '',
      cubicDm: '',
      cubicKm: '',

      ul: '',
      ml: '',
      cl: '',
      dl: '',
      l: '',
      hl: '公石dàn',

      LiFangCun: '',
      LiFangChi: '',
      ShiSheng: '',
      Dou: '',

      cubicInch: '',
      cubicFoot: '',
      cubicYard: '',

      ukOz: '1 oz = 28.41 ml',
      usOz: '1 oz = 29.57 ml',
      ukGal: '1 gal = 4.54609 l',
      usGal: '1 gal = 231 in³'
    },
    extra: {
      ul: '容积',
      ml: '容积',
      cl: '容积',
      dl: '容积',
      l: '容积',
      hl: '容积',
      ShiSheng: '容积',
      Dou: '容积',
      ukOz: '容积-液体',
      usOz: '容积-液体',
      ukGal: '容积',
      usGal: '容积'
    }
  },
  massUnit: {
    ug: 'Microgram(μg)',
    mg: 'Milligram(mg)',
    carat: 'Carat(ct)',
    g: 'Gram(g)',
    quintal: 'Quintal(q)',
    kg: 'Kilogram(kg)',
    tonne: 'Tonne(t)',

    Qian: '钱[Qian]',
    Liang: '两[Liang]',
    Jin: '斤[Jin]',

    grain: 'Grain(gr)',
    dram: 'Dram(dr)',
    ounce: 'Ounce(oz)',
    pound: 'Pound(lb)',
    stone: 'Stone(st)',
    usHundredweight: 'Hundredweight-US(cwt)',
    ukHundredweight: 'Hundredweight-UK(cwt)',
    shortTon: 'Short Ton-US Ton(ton)',
    longTon: 'Long Ton-UK Ton(ton)',
    remark: {
      ug: '',
      mg: '',
      carat: '一般用于钻石质量',
      g: '',
      quintal: '',
      kg: '',
      tonne: '',

      Qian: '',
      Liang: '',
      Jin: '',

      grain: '1 gr = 0.06479891 g',
      dram: '1 dr = 1/16 oz',
      ounce: '1 oz = 437.5 gr = 16 dr',
      pound: '1 lb = 16 oz',
      stone: '1 st = 14 lb',
      ukHundredweight: '英担，又名长担，1 英担 = 112 lb = 8 st',
      usHundredweight: '美担，又名长担，1 美担 = 100 lb',
      shortTon: '1 美吨 = 2000 lb',
      longTon: '1 英吨 = 2240 lb'
    },
    extra: {}
  },
  tempUnit: {
    r: 'Rankine Degree(°R)',
    k: 'Kelvin Degree(K)',
    f: 'Fahrenheit Degree(℉)',
    c: 'Celsius Degree(℃)',
    re: 'Reichsen Degree(°Re)',
    remark: {
      r: '',
      k: '',
      f: '',
      c: '',
      re: ''
    }
  },
  storageUnit: {
    bit: 'Bit(bit)',
    byte: 'Byte(B)',
    kb: 'KiloByte(KB)',
    mb: 'MegaByte(MB)',
    gb: 'GigaByte(GB)',
    tb: 'TeraByte(TB)',
    pb: 'PetaByte(PB)',
    eb: 'ExaByte(EB)',
    remark: {
      bit: '',
      byte: '1 B = 8 bit',
      kb: '',
      mb: '',
      gb: '',
      tb: '',
      pb: '',
      eb: ''
    }
  },
  speedUnit: {
    mPerSec: 'Meter Per Second(m/s)',
    kmPerHour: 'Kilometer Per Hour(km/h)',
    kmPerSec: 'Kilometer Per Second(km/s)',
    inchPerSec: 'Inch Per Second(in/s)',
    milePerHour: 'Mile Per Hour(mile/h)',
    mach: 'Mach(mach)',
    remark: {
      mPerSec: '',
      kmPerHour: '同公里/时',
      kmPerSec: '',
      inchPerSec: '',
      milePerHour: '',
      mach: '马赫(mach)，又称马赫数(Mach Number)，是速度与音速的比值，是一个无量纲数。一般用于航空航天领域。' +
        '马赫不是一个单位，但一般使用时会用1马赫，10马赫来表示速度，对应1倍音速和、10倍音速。' +
        '因为音速在不同环境中不一样，一般可按15摄氏度1个标准大气压下的音速，即340.3 m/s'
    }
  },
  angularUnit: {
    angleSystem: 'Angle System',
    radianSystem: 'Radian System',

    degree: 'Degree(°)',
    minute: 'Minute(\')',
    second: 'Second(")',
    rad: 'Rad(rad)',
    mrad: 'Microrad(mrad)',
    remark: {
      degree: '圆周的1/360定义为1°',
      minute: '角度分',
      second: '角度秒',
      rad: '弧度(rad) = 弧长 / 半径; 圆周的弧度 = 2πr/r = 2π',
      mrad: ''
    }
  },

  protMsg: {
    notWhite: 'Can not be white spaces',
    notEmpty: 'Can not be empty',
    paramInvalid: 'Invalid parameter',
    meaningful: 'Must be meaningful string',
    lengthRange: 'The length range',
    sizeRange: 'The size range',
    lengthMustLte: 'The length must <= ',
    required: 'Required parameter',
    patternNotMatch: 'Pattern not match',
    progNaming: 'Please enter letters, numbers, or underscores and must not start with numbers.',
    pwdPlainRegex: 'Must be 3-32 digits or English letters',
    unidRegex: 'Must be 2-32 English letters or numbers',
    emailRegex: 'Please input valid email address',
    notSamePwd: 'Entered passwords differ',
    unidExists: 'Voji UNID exists',
    emailExists: 'Email exists'

  }
}
