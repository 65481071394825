import { Message } from 'element-ui'
import i18n from '@/i18n/i18n'

import SysConfig from '@/SysConfig'

export function success () {
  Message.success({
    message: i18n.t('cmnBizMsg.success'),
    duration: SysConfig.msgDurationMsec
  })
}

export function failed () {
  Message.success({
    message: i18n.t('cmnBizMsg.failed'),
    duration: SysConfig.msgDurationMsec
  })
}

export function addSuccess () {
  Message.success({
    message: i18n.t('cmnBizMsg.addSuccess'),
    duration: SysConfig.msgDurationMsec
  })
}

export function addFailed () {
  Message.error({
    message: i18n.t('cmnBizMsg.addFailed'),
    duration: SysConfig.msgDurationMsec
  })
}

export function editSuccess () {
  Message.success({
    message: i18n.t('cmnBizMsg.editSuccess'),
    duration: SysConfig.msgDurationMsec
  })
}

export function editFailed () {
  Message.error({
    message: i18n.t('cmnBizMsg.editFailed'),
    duration: SysConfig.msgDurationMsec
  })
}

export function opSuccess () {
  Message.success({
    message: i18n.t('cmnBizMsg.opSuccess'),
    duration: SysConfig.msgDurationMsec
  })
}

export function opFailed () {
  Message.error({
    message: i18n.t('cmnBizMsg.opFailed'),
    duration: SysConfig.msgDurationMsec
  })
}

export function delSuccess () {
  Message.success({
    message: i18n.t('cmnBizMsg.delSuccess'),
    duration: SysConfig.msgDurationMsec
  })
}

export function delFailed () {
  Message.error({
    message: i18n.t('cmnBizMsg.delFailed'),
    duration: SysConfig.msgDurationMsec
  })
}

export default {
  success,
  failed,
  opSuccess,
  opFailed,
  addSuccess,
  addFailed,
  editSuccess,
  editFailed,
  delSuccess,
  delFailed
}
