module.exports = {
  /**
   * @description 网站标题
   */
  title: 'VOJIVO-USR-WEB',
  /**
   * @description 是否显示 tagsView
   */
  tagsView: true,
  /**
   * @description 固定头部
   */
  fixedHeader: true,
  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: 1,
  /**
   * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天s
   */
  passCookieExpires: 1,
  /**
   * @description token key
   */
  tokenKey: 'VOJIVO-USR-WEB-TOKEN',
  curUsrKey: 'VOJIVO-CUR-USR',
  rememberMeExpireDay: 7,
  rememberMeKey: 'VOJIVO-REM-ME',

  reqTimeoutMsec: 10000,
  refreshDelayMsec: 800,
  msgDurationMsec: 2600,

  /**
   * enableMockRespResultData
   */
  enableMockRespResultData: true,
  /**
   * @description 是否显示logo
   */
  sidebarLogo: true,
  /**
   * 是否显示设置的底部信息
   */
  showFooter: true,
  /**
   * 底部文字，支持html语法
   */
  footerTxt: '© 2024 Seakeer <a href="https://www.vojivo.xyz" target="_blank">VOJIVO</a>',
  /**
   * 备案号
   */
  caseNumber: 'Seakeer'
}
