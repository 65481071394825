import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/usrPer'

export function findCurrent () {
  return resultHttpTool.get(API_PREFIX + '/findCurrent')
}

export function update (ip) {
  return resultHttpTool.post(API_PREFIX + '/update', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.editSuccess()
      } else {
        ApiMsgBox.editFailed()
      }
      return op
    })
}

export default {
  findCurrent,
  update
}
