import resultHttpTool from '@/http/ResultHttpTool'

const API_PREFIX = '/api/usr/usrzBizConfig'

export function findUsrzBizConfig () {
  return resultHttpTool.get(API_PREFIX + '/findUsrzBizConfig')
}

export default {
  findUsrzBizConfig
}
