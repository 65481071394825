<template>
  <div>
    <el-row :gutter="8">
      <el-col :span="10">
        <h3>{{ this.$t('toolCmn.inputTextArea') }}</h3>
        <el-input v-model="inputTextArea" type="textarea" rows="10"
                  @input="onInput">
        </el-input>
      </el-col>
      <el-col :span="4">
        <div class="execute-mode-div">
          <div>
            <el-radio v-model="executeMode" label="ENCODE" @input="onChangeExecuteMode">
              <!--              <span v-html="this.$t('enDecoder.encode')"></span>-->
              {{ this.$t('enDecoder.encode') }}
            </el-radio>
          </div>
          <div>
            <el-radio v-model="executeMode" label="DECODE" @input="onChangeExecuteMode">
              {{ this.$t('enDecoder.decode') }}
            </el-radio>
          </div>
        </div>
      </el-col>
      <el-col :span="10">
        <h3 v-if="executeMode==='ENCODE'">
          {{ encodeAlg }} {{ this.$t('enDecoder.encode') }}{{ this.$t('toolCmn.resultTextArea') }}
        </h3>
        <h3 v-else>
          {{ encodeAlg }} {{ this.$t('enDecoder.decode') }}{{ this.$t('toolCmn.resultTextArea') }}
        </h3>
        <el-input v-model="resultTextArea" type="textarea" rows="10" disabled>
        </el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EnDecodeUtil from '@/util/EnDecodeUtil'
import EnDecoderApi from '@/api/vojivosvc/toolsubject/EnDecoderApi'

export default {
  name: 'EnDecoderCmpt',
  props: {
    encodeAlg: String
  },
  data () {
    return {
      inputTextArea: null,
      resultTextArea: null,
      executeMode: 'ENCODE',
      delayExecuteTimeoutId: null,
      charCodingList: ['ASCII', 'GB2312', 'GBK', 'GB18030', 'UTF-8', 'UTF-16', 'UTF-32', 'UTF-16BE', 'UTF-16LE', 'UTF-32BE', 'UTF-32LE']
    }
  },
  methods: {
    onChangeExecuteMode (selectedValue) {
      this.delayExecute()
    },
    onInput () {
      this.delayExecute()
    },
    delayExecute () {
      this.clearDelayExecuteTimeout()
      this.delayExecuteTimeoutId = setTimeout(() => {
        this.execute()
      }, 500)
    },
    clearDelayExecuteTimeout () {
      if (this.delayExecuteTimeoutId) {
        clearTimeout(this.delayExecuteTimeoutId)
      }
    },
    execute () {
      this.clearDelayExecuteTimeout()
      if (this.inputTextArea === '' || this.inputTextArea === null || this.inputTextArea === undefined) {
        this.resultTextArea = null
        return
      }
      if (this.executeMode === 'ENCODE') {
        if (this.charCodingList.includes(this.encodeAlg)) {
          EnDecoderApi.charEncode(this.inputTextArea, this.encodeAlg).then(rd => {
            this.resultTextArea = rd
          })
        } else {
          this.resultTextArea = EnDecodeUtil.encode(this.inputTextArea, this.encodeAlg)
        }
      } else {
        if (this.charCodingList.includes(this.encodeAlg)) {
          EnDecoderApi.charDecode(this.inputTextArea, this.encodeAlg).then(rd => {
            this.resultTextArea = rd
          })
        }
        this.resultTextArea = EnDecodeUtil.decode(this.inputTextArea, this.encodeAlg)
      }
    }
  }
}
</script>
