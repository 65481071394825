<template>
  <el-container>
    <el-aside width="200px">
      <div>
        <h2>{{ $t('cmn.tags') }}</h2>
        <div>
          <el-tag v-for="toolCateg in toolDetail.toolCategList" :key="toolCateg.id">
            {{ toolCateg.name }}
          </el-tag>
        </div>
        <h2>{{ $t('cmn.allTags') }}</h2>
        <el-tree
          ref="toolCategTreeRef"
          node-key="id"
          :data="toolCategTreeData"
          :default-checked-keys="toolCategIds"
          :load="loadToolCategTrees"
          :props="{label: 'name', children: 'children', hasChildren: 'name', disabled:(data,node)=>true}"
          :expand-on-click-node="false"
          show-checkbox
          check-strictly
          lazy
        />
      </div>

      <div v-if="isLoggedIn">
        <h2>{{ $t('cmn.groups') }}</h2>
        <div>
          <el-tag v-for="toolGroup in toolGroups" :key="toolGroup.id">
            {{ toolGroup.name }}
          </el-tag>
        </div>

        <h2>{{ $t('cmn.allGroups') }}</h2>
        <el-tree
          ref="toolGroupTreeRef"
          node-key="id"
          :data="toolGroupTree"
          :default-checked-keys="toolGroupIds"
          :load="loadToolGroupTrees"
          :props="{label: 'name', children: 'children', hasChildren: 'name', disabled:(data,node)=>true}"
          :expand-on-click-node="false"
          show-checkbox
          check-strictly
          lazy
        />
      </div>

    </el-aside>

    <el-main>
      <div class="tool-overview-div">
        <!--工具Card渲染-->
        <div class="tool-card-container">
          <el-card class="tool-card">
            <div>
              <el-row type="flex" align="middle" :gutter="20">
                <el-col :span="8" class="tool-logo-col">
                  <router-link v-if="toolDetail.isVjvTool===1" :to="{path:toolDetail.path}" target="_blank">
                    <img :src="toolLogo(toolDetail)" class="tool-logo" alt="LOGO">
                  </router-link>
                  <a
                    v-else
                    target="_blank"
                    :href="toolDetail.uri"
                    rel="external nofollow"
                    class="text-decoration-none"
                  >
                    <img :src="toolDetail.logoUri" class="tool-logo" alt="LOGO">
                  </a>
                </el-col>
                <el-col :span="16">
                  <div class="card-info-container">
                    <div class="tool-name-area">
                      <router-link :to="{path:'/toolDetail',query:{toolId:toolDetail.id}}" target="_blank">
                        <h3>{{ toolDetail.name }}</h3>
                      </router-link>
                    </div>
                    <div class="tool-main-categ-area">
                      <el-tag
                        v-for="mainCateg in toolDetail.mainCategList"
                        :key="mainCateg.id"
                        type="warning"
                        effect="dark"
                        size="mini"
                      >
                        {{ mainCateg.name }}
                      </el-tag>
                    </div>
                    <div class="opn-area">
                      <el-button
                        v-if="isLoggedIn"
                        icon="el-icon-edit" circle size="small"
                        @click="toChangeInfo(toolDetail)"/>
                      <el-popover
                        v-if="toolDetail.beFav"
                        :ref="'CancelFavPop.' + toolDetail.id"
                        placement="top"
                        width="200"
                      >
                        <p>{{ $t('cmn.cancelFavConfirm') }}</p>
                        <div style="text-align: right; margin: 0">
                          <el-button
                            size="mini"
                            type="text"
                            @click="$refs['CancelFavPop.' + toolDetail.id].doClose()"
                          >
                            {{ $t('cmn.cancel') }}
                          </el-button>
                          <el-button type="primary" size="mini" @click="doCancelFav(toolDetail)">
                            {{ $t('cmn.confirm') }}
                          </el-button>
                        </div>
                        <el-button v-if="toolDetail.beFav" slot="reference" icon="el-icon-star-on" circle size="small"/>
                      </el-popover>
                      <el-button v-if="!toolDetail.beFav" icon="el-icon-star-off" circle size="small"
                                 @click="toFav(toolDetail)"/>
                      <el-button v-if="toolDetail.isVjvTool===1" icon="el-icon-right" circle size="small"
                                 @click="routeToTool(toolDetail.path)"/>

                      <a
                        v-else
                        target="_blank"
                        :href="toolDetail.uri"
                        rel="external nofollow"
                        class="text-decoration-none"
                      >
                        <el-button icon="el-icon-right" circle size="small"/>
                      </a>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div>
              <div class="tool-profile-area">{{ toolDetail.profile }}</div>
<!--              <div>{{ toolDetail.uri }}</div>-->
            </div>
          </el-card>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="tool-base-info-div">
        <p>{{ $t('cmn.name') }}: {{ toolDetail.name }}</p>
        <p>{{ $t('cmn.profile') }}: {{ toolDetail.profile }}</p>
        <p>URI: {{ toolDetail.uri }}</p>
      </div>
      <div class="tool-comment-div"></div>

      <!-- Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableFavForm"
        :title="$t('cmn.fav')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="favFormRef"
          :inline="true"
          :model="favForm"
          :rules="favFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('toolCmn.toolName')" prop="toolName">
            <el-input v-model="favForm.toolName" disabled style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cmn.group')" prop="toolGroupIds">
            <el-tree
              v-model="favForm.toolGroupIds"
              :data="groupTrees"
              ref="favFormToolGroupTreeRef"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              :check-strictly="true"
              show-checkbox
              lazy
              @check="handleGroupTreeNodeCheck"
            />
          </el-form-item>
          <el-form-item :label="$t('cmn.customName')" prop="customName">
            <el-input v-model="favForm.customName" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.customProfile')" prop="customProfile">
            <el-input v-model="favForm.customProfile" style="width: 300px;"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Change Info Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableChangeInfoForm"
        :title="$t('cmn.edit')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="changeInfoFormRef"
          :inline="true"
          :model="changeInfoForm"
          :rules="changeInfoFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('toolCmn.toolName')" prop="toolName">
            <el-input v-model="changeInfoForm.toolName" disabled style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cmn.group')" prop="toolGroupIds">
            <el-tree
              v-model="changeInfoForm.toolGroupIds"
              :data="groupTrees"
              ref="changeInfoFormToolGroupTreeRef"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              show-checkbox
              check-strictly
              lazy
              @check="handleGroupTreeNodeCheck"
            />
          </el-form-item>
          <el-form-item :label="$t('cmn.customName')" prop="customName">
            <el-input v-model="changeInfoForm.customName" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.customProfile')" prop="customProfile">
            <el-input v-model="changeInfoForm.customProfile" style="width: 300px;"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableChangeInfoForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doChangeInfo">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

    </el-main>
  </el-container>
</template>

<script>
import ToolCategApi from '@/api/vojivosvc/toolhall/ToolCategApi'
import ToolApi from '@/api/vojivosvc/toolhall/ToolApi'
import UsrzFavToolApi from '@/api/vojivosvc/toolhall/UsrzFavToolApi'
import UsrzToolGroupApi from '@/api/vojivosvc/toolhall/UsrzToolGroupApi'
import LoginManager from '@/sys/LoginManager'
import { LOCAL_FILE_PREFIX } from '@/constant/CmnBizConst'

export default {
  name: 'ToolDetail',
  computed: {},
  data () {
    return {
      isLoggedIn: false,
      toolId: null,
      toolDetail: {
        id: null,
        name: null,
        nameI18nRes: null,
        profile: null,
        profileI18nRes: null,
        uri: null,
        logoUri: null,
        component: null,
        path: null,
        onlineMsec: null,
        opAdmAcctId: null,
        opAdmAcctUnid: null,
        mainCategList: [],
        vjvPlc: null,
        beFav: null,
        toolCategList: []
      },
      enableResCtlgDialog: false,
      toolCategTreeData: [],
      groupTrees: [],
      toolCategIds: [],
      toolGroups: [],
      toolGroupIds: [],
      toolGroupTree: [],
      enableFavForm: false,
      favForm: {
        toolName: null,
        toolId: null,
        toolGroupIds: [],
        customName: null,
        customProfile: null
      },
      favFormRules: {},
      enableChangeInfoForm: false,
      changeInfoForm: {
        toolName: null,
        toolId: null,
        toolGroupIds: [],
        customName: null,
        customProfile: null
      },
      changeInfoFormRules: {}
    }
  },
  created () {
    this.toolId = this.$route.query.toolId
    this.initToolDetail()
    LoginManager.isLoggedIn().then(rd => {
      if (rd) {
        this.isLoggedIn = true
        this.initToolGroups()
      }
    })
  },
  methods: {
    initToolDetail () {
      ToolApi.findDetail(this.toolId)
        .then(rd => {
          this.toolDetail = rd
          this.toolCategIds = []
          for (const categ of this.toolDetail.toolCategList) {
            this.toolCategIds.push(categ.id)
          }
        })
    },
    initToolGroups () {
      UsrzToolGroupApi.findByToolId(this.toolId)
        .then(data => {
          this.toolGroups = data
          this.toolGroupIds = this.toolGroups.map(group => group.id)
          this.$refs.toolGroupTreeRef.setCheckedKeys(this.toolGroupIds)
        })
    },
    loadToolGroupTrees (node, resolve) {
      if (!this.isLoggedIn) {
        return
      }
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        UsrzToolGroupApi.findTrees(findTreesIp)
          .then(resultData => {
            resolve(resultData)
          })
      }, 100)
    },

    loadToolCategTrees (node, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        ToolCategApi.findTrees(findTreesIp)
          .then(resultData => {
            resolve(resultData)
          })
      }, 100)
    },
    handleGroupTreeNodeCheck () {
    },
    toFav (tool) {
      this.enableFavForm = true
      this.favForm.toolId = tool.id
      this.favForm.toolName = tool.name
      this.favForm.toolGroupIds = []
    },
    async doFav () {
      const ip = {
        ...this.favForm
      }
      ip.toolGroupIds = this.$refs.favFormToolGroupTreeRef.getCheckedKeys()
      const retData = await UsrzFavToolApi.fav(ip)
      if (retData) {
        this.toolDetail.beFav = true
        this.enableFavForm = false
      }
    },
    doCancelFav (tool) {
      const ip = {
        toolIds: []
      }
      ip.toolIds.push(tool.id)
      UsrzFavToolApi.batchCancelFav(ip)
        .then(resultData => {
          if (resultData) {
            tool.beFav = false
            this.$refs['CancelFavPop.' + tool.id].doClose()
          }
        })
    },
    loadGroupTrees (node, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        UsrzToolGroupApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.groupTrees = resultData
            }
          })
      }, 100)
    },
    routeToTool (index) {
      this.$router.push(index)
    },
    toChangeInfo (tool) {
      this.enableChangeInfoForm = true
      this.changeInfoForm.toolId = tool.id
      this.changeInfoForm.toolName = tool.name
      this.changeInfoForm.customName = tool.customName
      this.changeInfoForm.customProfile = tool.customProfile
      this.changeInfoForm.toolGroupIds = []
    },
    doChangeInfo () {
      const ip = {
        ...this.changeInfoForm
      }
      ip.toolGroupIds = this.$refs.changeInfoFormToolGroupTreeRef.getCheckedKeys()
      UsrzFavToolApi.fav(ip)
        .then(op => {
          if (op) {
            this.enableChangeInfoForm = false
            setTimeout(() => this.initToolGroups(), 500)
          }
        })
    },
    toolLogo (tool) {
      if (tool.logoUrl === LOCAL_FILE_PREFIX) {
        return require('@/assets/img/toollogo/' + tool.unid + '.png')
      } else if (tool.logoUrl.startsWith(LOCAL_FILE_PREFIX)) {
        return require(tool.logoUrl.substring(LOCAL_FILE_PREFIX.length))
      } else {
        return tool.logoUrl
      }
    }
  }
}
</script>

<style scoped>

</style>
