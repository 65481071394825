<template>
  <el-container>
    <el-aside width="150px">
      <el-menu :default-active="this.$route.path"
               @open="handleOpen" @close="handleClose" @select="handleSelect">
        <menu-tree :menu-list="menuList"></menu-tree>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>

</template>

<script>

import MenuTree from '@/components/MenuTree'

export default {
  name: 'IESpace',
  components: { MenuTree },
  data () {
    return {
      menuList: [
        {
          index: '/ieSpace/winkBalcony',
          icon: 'iconfont vj-icon-diy-pro-twinkle',
          name: '闻可台',
          nameKey: 'ieSpace.winkBalcony',
          children: []
        },
        {
          index: '/ieSpace/paperPlane',
          icon: 'iconfont vj-icon-zhifeiji',
          name: '纸飞机',
          nameKey: 'ieSpace.paperPlane',
          children: []
        },
        {
          index: '/ieSpace/inbox',
          icon: 'iconfont vj-icon-sixin',
          name: '私信箱',
          nameKey: 'ieSpace.inbox',
          children: []
        },
        {
          index: '/ieSpace/commentWall',
          icon: 'iconfont vj-icon-huabanbeifen54',
          name: '评论墙',
          nameKey: 'ieSpace.commentWall',
          children: []
        },
        {
          index: '/ieSpace/prdWindow',
          icon: 'iconfont vj-icon-chuangkou1',
          name: '需求窗',
          nameKey: 'ieSpace.prdWindow',
          children: []
        },
        {
          index: '/ieSpace/gameRoom',
          icon: 'iconfont vj-icon-dianziyouxishi',
          name: '游戏室',
          nameKey: 'ieSpace.gameRoom',
          children: [
          ]
        }
      ]
    }
  },
  methods: {
    handleOpen (index, indexPath) {
      if (this.$route.path === index) {
        return
      }
      this.$router.push({
        path: '/ieSpace/gameRoom',
        query: {
          gameCategoryId: null
        }
      })
    },
    handleSelect (index, indexPath) {
      if (this.$route.path === index) {
        return
      }
      console.log(this.$route.path)
      if (!indexPath.includes('/ieSpace/gameRoom')) {
        this.$router.push(index)
        return
      }
      if (indexPath.length > 1) {
        this.$router.push({
          path: '/ieSpace/gameRoom',
          query: {
            gameCategoryId: index
          }
        })
      }
    },
    handleClose (index, indexPath) {
      if (this.$route.path === index) {
        return
      }
      this.$router.push({
        path: '/ieSpace/gameRoom',
        query: {
          gameCategoryId: null
        }
      })
    }
  }
}
</script>

<style scoped>
.el-container {
  height: 100%;
}

/*.el-menu-vertical-demo:not(.el-menu--collapse) {*/
/*  width: 200px;*/
/*  min-height: 400px;*/
/*}*/

.el-aside {
  height: 100%;
  background-color: #ffffff;
}
</style>
