export const PROG_NAMING = '^(_|[a-z]|[A-Z])\\w*$'
export const PWD_PLAIN = '^([0-9]|[a-z]|[A-Z])\\w{2,31}$'

export const UNID = '^([a-z]|[A-Z]|[0-9])\\w{1,31}$'

export const EMAIL = '^([a-z0-9A-Z]+)([a-z0-9A-Z-_.]+)@([a-z0-9A-Z]+\\.)+([a-z0-9A-Z]+)$'

export default {
  PROG_NAMING,
  PWD_PLAIN,
  UNID,
  EMAIL
}
