<template>
  <el-container>
    <el-aside width="200px">
      <div>
        <tool-shower tool-unid="EnDecoder"></tool-shower>
      </div>
      <el-menu
        router
        :default-active="this.$route.path"
        :default-openeds="openedSubmenuIdxList"
        background-color="#ECF0F6"
      >
        <menu-tree :menu-list="menuList"></menu-tree>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>

</template>

<script>
import MenuTree from '@/components/MenuTree.vue'
import ToolShower from '@/components/ToolShower.vue'

export default {
  name: 'EnDecoder',
  components: { ToolShower, MenuTree },
  data () {
    return {
      openedSubmenuIdxList: [
        '/tool/enDecoder/char'
      ],
      menuList: [
        {
          index: '/tool/enDecoder/enDecodeOverview',
          icon: 'iconfont vj-icon-bianjiema_OSD',
          name: '编解码概述',
          nameKey: 'enDecoder.enDecodeOverview',
          children: []
        },
        {
          index: '/tool/enDecoder/base64',
          icon: 'iconfont vj-icon-Base64bianjiema',
          name: 'Base64编解码',
          nameKey: 'enDecoder.base64',
          children: []
        },
        {
          index: '/tool/enDecoder/url',
          icon: 'iconfont vj-icon-url',
          name: 'URL编解码',
          nameKey: 'enDecoder.url',
          children: []
        },
        {
          index: '/tool/enDecoder/hex',
          icon: 'iconfont vj-icon-file-HEX',
          name: 'HEX编解码',
          nameKey: 'enDecoder.hex',
          children: []
        },
        {
          index: '/tool/enDecoder/char',
          icon: 'iconfont vj-icon-bianhao05',
          name: '字符编解码',
          nameKey: 'enDecoder.char',
          children: [
            {
              index: '/tool/enDecoder/char/ascii',
              icon: 'iconfont vj-icon-ASCII',
              name: 'ASCII字符集编解码',
              nameKey: 'enDecoder.ascii',
              children: []
            },
            {
              index: '/tool/enDecoder/char/chinese',
              icon: 'iconfont vj-icon-hanzi',
              name: '汉字字符集编解码',
              nameKey: 'enDecoder.chinese',
              children: [
                {
                  index: '/tool/enDecoder/char/chinese/gb2312',
                  icon: 'iconfont vj-icon-guobiaoicon',
                  name: 'GB2312编解码',
                  nameKey: 'enDecoder.gb2312',
                  children: []
                },
                {
                  index: '/tool/enDecoder/char/chinese/gbk',
                  icon: 'iconfont vj-icon-guobiaoicon',
                  name: 'GBK编解码',
                  nameKey: 'enDecoder.gbk',
                  children: []
                },
                {
                  index: '/tool/enDecoder/char/chinese/gb18030',
                  icon: 'iconfont vj-icon-guobiaoicon',
                  name: 'GB18030编解码',
                  nameKey: 'enDecoder.gb18030',
                  children: []
                }
              ]
            },
            {
              index: '/tool/enDecoder/char/unicode',
              icon: 'iconfont vj-icon-unicode1',
              name: 'UNICODE字符集编解码',
              nameKey: 'enDecoder.unicode',
              children: [
                {
                  index: '/tool/enDecoder/char/unicode/utf8',
                  icon: 'iconfont vj-icon-icon_formula_5t',
                  name: 'UTF-8编解码',
                  nameKey: 'enDecoder.utf8',
                  children: []
                },
                {
                  index: '/tool/enDecoder/char/unicode/utf16',
                  icon: 'iconfont vj-icon-icon_formula_5t',
                  name: 'UTF-16编解码',
                  nameKey: 'enDecoder.utf16',
                  children: []
                },
                {
                  index: '/tool/enDecoder/char/unicode/utf32',
                  icon: 'iconfont vj-icon-icon_formula_5t',
                  name: 'UTF-32编解码',
                  nameKey: 'enDecoder.utf32',
                  children: []
                },
                {
                  index: '/tool/enDecoder/char/unicode/unicodeCharset',
                  icon: 'iconfont vj-icon-unicode',
                  name: 'Unicode字符集',
                  nameKey: 'enDecoder.unicodeCharset',
                  children: []
                }
              ]
            }
          ]
        }
      ]
    }
  },
  methods: {
    handleOpen (index, indexPath) {
      console.log(this.$route.path)
    },
    handleSelect (index, indexPath) {
      if (this.$route.path === index) {
        return
      }
      console.log(this.$route.path)
    },
    handleClose (index, indexPath) {
    }
  }
}
</script>

<style lang="less" scoped>
.en-de-code-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 60px;

  .el-button {
    margin-top: 30px;
  }
}
</style>
