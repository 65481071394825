<template>
  <div>
    <el-option
      v-for="timeZone in timeZoneList"
      :key="timeZone.timeZoneId"
      :label="timeZone.label"
      :value="timeZone.timeZoneId">
      <span style="float: left">{{ timeZone.label }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 30px">{{ timeZone.cityIns }}</span>
    </el-option>
  </div>
</template>

<script>
export default {
  name: 'Timezone',
  data () {
    return {
      timeZoneList: [
        {
          timeZoneId: -12,
          label: this.$t('timezone.utcMinus12.displayName'),
          cityIns: this.$t('timezone.utcMinus12.cityIns')
        },
        {
          timeZoneId: -11,
          label: this.$t('timezone.utcMinus11.displayName'),
          cityIns: this.$t('timezone.utcMinus11.cityIns')
        },
        {
          timeZoneId: -10,
          label: this.$t('timezone.utcMinus10.displayName'),
          cityIns: this.$t('timezone.utcMinus10.cityIns')
        },
        {
          timeZoneId: -9,
          label: this.$t('timezone.utcMinus9.displayName'),
          cityIns: this.$t('timezone.utcMinus9.cityIns')
        },
        {
          timeZoneId: -8,
          label: this.$t('timezone.utcMinus8.displayName'),
          cityIns: this.$t('timezone.utcMinus8.cityIns')
        },
        {
          timeZoneId: -7,
          label: this.$t('timezone.utcMinus7.displayName'),
          cityIns: this.$t('timezone.utcMinus7.cityIns')
        },
        {
          timeZoneId: -6,
          label: this.$t('timezone.utcMinus6.displayName'),
          cityIns: this.$t('timezone.utcMinus6.cityIns')
        },
        {
          timeZoneId: -5,
          label: this.$t('timezone.utcMinus5.displayName'),
          cityIns: this.$t('timezone.utcMinus5.cityIns')
        },
        {
          timeZoneId: -4,
          label: this.$t('timezone.utcMinus4.displayName'),
          cityIns: this.$t('timezone.utcMinus4.cityIns')
        },
        {
          timeZoneId: -3,
          label: this.$t('timezone.utcMinus3.displayName'),
          cityIns: this.$t('timezone.utcMinus3.cityIns')
        },
        {
          timeZoneId: -2,
          label: this.$t('timezone.utcMinus2.displayName'),
          cityIns: this.$t('timezone.utcMinus2.cityIns')
        },
        {
          timeZoneId: -1,
          label: this.$t('timezone.utcMinus1.displayName'),
          cityIns: this.$t('timezone.utcMinus1.cityIns')
        },
        {
          timeZoneId: 0,
          label: this.$t('timezone.utc0.displayName'),
          cityIns: this.$t('timezone.utc0.cityIns')
        },
        {
          timeZoneId: 1,
          label: this.$t('timezone.utc1.displayName'),
          cityIns: this.$t('timezone.utc1.cityIns')
        },
        {
          timeZoneId: 2,
          label: this.$t('timezone.utc2.displayName'),
          cityIns: this.$t('timezone.utc2.cityIns')
        },
        {
          timeZoneId: 3,
          label: this.$t('timezone.utc3.displayName'),
          cityIns: this.$t('timezone.utc3.cityIns')
        },
        {
          timeZoneId: 4,
          label: this.$t('timezone.utc4.displayName'),
          cityIns: this.$t('timezone.utc4.cityIns')
        },
        {
          timeZoneId: 5,
          label: this.$t('timezone.utc5.displayName'),
          cityIns: this.$t('timezone.utc5.cityIns')
        },
        {
          timeZoneId: 6,
          label: this.$t('timezone.utc6.displayName'),
          cityIns: this.$t('timezone.utc6.cityIns')
        },
        {
          timeZoneId: 7,
          label: this.$t('timezone.utc7.displayName'),
          cityIns: this.$t('timezone.utc7.cityIns')
        },
        {
          timeZoneId: 8,
          label: this.$t('timezone.utc8.displayName'),
          cityIns: this.$t('timezone.utc8.cityIns')
        },
        {
          timeZoneId: 9,
          label: this.$t('timezone.utc9.displayName'),
          cityIns: this.$t('timezone.utc9.cityIns')
        },
        {
          timeZoneId: 10,
          label: this.$t('timezone.utc10.displayName'),
          cityIns: this.$t('timezone.utc10.cityIns')
        },
        {
          timeZoneId: 11,
          label: this.$t('timezone.utc11.displayName'),
          cityIns: this.$t('timezone.utc11.cityIns')
        },
        {
          timeZoneId: 12,
          label: this.$t('timezone.utc12.displayName'),
          cityIns: this.$t('timezone.utc12.cityIns')
        },
        {
          timeZoneId: 13,
          label: this.$t('timezone.utc13.displayName'),
          cityIns: this.$t('timezone.utc13.cityIns')
        },
        {
          timeZoneId: 14,
          label: this.$t('timezone.utc14.displayName'),
          cityIns: this.$t('timezone.utc14.cityIns')
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>
