<template>
  <div class="block">
    <el-timeline>
      <el-timeline-item
        v-for="(emotto, idx) in emottoList"
        :key="idx"
        :timestamp="formatRelMsec(emotto.relMsec)" placement="top">
        <el-card>
          <h4 v-if="emotto.contentZh">{{ emotto.contentZh }}</h4>
          <h4 v-else>{{ emotto.content }}</h4>
          <p v-if="emotto.contentEn">{{ emotto.contentEn }}</p>
          <p v-else>{{ emotto.i18nContent }}</p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import TimeUtil from '@/util/TimeUtil'
import EmottoApi from '@/api/vojivosvc/toolsubject/EmottoApi'

export default {
  name: 'Emotto',
  data () {
    return {
      emottoList: []
    }
  },
  created () {
    this.findEmotto()
  },
  methods: {
    async findEmotto () {
      const ip = {
        pageIp: {
          current: 1,
          size: 7
        },
        orderBys: [
          {
            key: 'relMsec',
            asc: false
          }
        ],
        timeIp: null
      }
      const respData = await EmottoApi.findByPage(ip)
      this.emottoList = respData.data
      this.emottoList.forEach(emotto => {
        emotto.contentI18nRes.forEach(each => {
          if (each.langId === 'zh') {
            emotto.contentZh = each.value
          } else if (each.langId === 'en') {
            emotto.contentEn = each.value
          }
        })
      })
    },
    formatRelMsec (relMsec) {
      return TimeUtil.convertMsecToDateTimeStr(relMsec)
    }

  }
}
</script>

<style scoped>

</style>
