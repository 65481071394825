export default class StringUtil {
  StringUtil () {
  }

  /**
   * <p>Checks if a String is empty ("") or null.</p>
   *
   * <pre>
   * StringUtil.isEmpty(null)      = true
   * StringUtil.isEmpty("")        = true
   * StringUtil.isEmpty(" ")       = false
   * StringUtil.isEmpty("bob")     = false
   * StringUtil.isEmpty("  bob  ") = false
   * </pre>
   *
   * It no longer trims the String.
   * That functionality is available in isBlank().</p>
   *
   * @param str  the String to check, may be null
   * @return <code>true</code> if the String is empty or null
   */
  static isEmpty = function (str) {
    return str === null || str.length === 0
  }

  /**
   * <p>Checks if a String is not empty ("") and not null.</p>
   *
   * <pre>
   * StringUtil.isNotEmpty(null)      = false
   * StringUtil.isNotEmpty("")        = false
   * StringUtil.isNotEmpty(" ")       = true
   * StringUtil.isNotEmpty("bob")     = true
   * StringUtil.isNotEmpty("  bob  ") = true
   * </pre>
   *
   * @param str  the String to check, may be null
   * @return <code>true</code> if the String is not empty and not null
   */
  static isNotEmpty = function (str) {
    return !StringUtil.isEmpty(str)
  }

  /**
   * <p>Checks if a String is whitespace, empty ("") or null.</p>
   *
   * <pre>
   * StringUtil.isBlank(null)      = true
   * StringUtil.isBlank("")        = true
   * StringUtil.isBlank(" ")       = true
   * StringUtil.isBlank("bob")     = false
   * StringUtil.isBlank("  bob  ") = false
   * </pre>
   *
   * @param str  the String to check, may be null
   * @return <code>true</code> if the String is null, empty or whitespace
   * @since 2.0
   */
  static isBlank = function (str) {
    var strLen
    if (str == null || (strLen = str.length) === 0) {
      return true
    }
    for (var i = 0; i < strLen; i++) {
      if (str[i] !== '' && str[i] !== ' ' && str[i] !== '\n' && str[i] !== '\t') {
        return false
      }
    }
    return true
  }

  /**
   * <p>Checks if a String is not empty (""), not null and not whitespace only.</p>
   *
   * <pre>
   * StringUtil.isNotBlank(null)      = false
   * StringUtil.isNotBlank("")        = false
   * StringUtil.isNotBlank(" ")       = false
   * StringUtil.isNotBlank("bob")     = true
   * StringUtil.isNotBlank("  bob  ") = true
   * </pre>
   *
   * @param str  the String to check, may be null
   * @return <code>true</code> if the String is
   *  not empty and not null and not whitespace
   * @since 2.0
   */
  static isNotBlank = function (str) {
    return !StringUtil.isBlank(str)
  }

  static isMeaningful = function (str) {
    return !StringUtil.isMeaningless(str)
  }

  static isMeaningless = function (str) {
    return str === null || str === undefined || StringUtil.isBlank(str)
  }

  static isWhite = function (str) {
    if (str === null || str === undefined || str === '') {
      return false
    }
    return str.trim() === ''
  }

  static isZls = function (str) {
    return str === ''
  }

  static isNumber = function (str) {
    const regex = /^-?\d+(\.\d+)?$/
    return regex.test(str)
  }

  /**
   * <p>Removes control characters (char &lt;= 32) from both
   * ends of this String, handling <code>null</code> by returning
   * an empty String ("").</p>
   *
   * <pre>
   * StringUtil.clean(null)          = ""
   * StringUtil.clean("")            = ""
   * StringUtil.clean("abc")         = "abc"
   * StringUtil.clean("    abc    ") = "abc"
   * StringUtil.clean("     ")       = ""
   * </pre>
   *
   * @param str  the String to clean, may be null
   * @return the trimmed text, never <code>null</code>
   * @deprecated Use the clearer named {@link #trimToEmpty(String)}.
   *             Method will be removed in Commons Lang 3.0.
   */
  static clean = function (str) {
    return str == null ? StringUtil.EMPTY : str.trim()
  }

  /**
   * <p>Removes control characters from both
   * ends of this String, handling <code>null</code> by returning
   * <code>null</code>.</p>
   *
   * Trim removes start and end characters &lt;= 32.
   *
   * <pre>
   * StringUtil.trim(null)          = null
   * StringUtil.trim("")            = ""
   * StringUtil.trim("     ")       = ""
   * StringUtil.trim("abc")         = "abc"
   * StringUtil.trim("    abc    ") = "abc"
   * </pre>
   *
   * @param str  the String to be trimmed, may be null
   * @return the trimmed string, <code>null</code> if null String input
   */
  static trim = function (str) {
    return str == null ? null : str.trim()
  }

  /**
   * <p>Removes control characters (char &lt;= 32) from both
   * ends of this String returning <code>null</code> if the String is
   * empty ("") after the trim or if it is <code>null</code>.
   *
   *
   * <pre>
   * StringUtil.trimToNull(null)          = null
   * StringUtil.trimToNull("")            = null
   * StringUtil.trimToNull("     ")       = null
   * StringUtil.trimToNull("abc")         = "abc"
   * StringUtil.trimToNull("    abc    ") = "abc"
   * </pre>
   *
   * @param str  the String to be trimmed, may be null
   * @return the trimmed String,
   *  <code>null</code> if only chars &lt;= 32, empty or null String input
   */
  static trimToNull = function (str) {
    var ts = StringUtil.trim(str)
    return StringUtil.isEmpty(ts) ? null : ts
  }

  /**
   * <p>Removes control characters (char &lt;= 32) from both
   * ends of this String returning an empty String ("") if the String
   * is empty ("") after the trim or if it is <code>null</code>.
   *
   * <p>The String is trimmed using {@link String#trim()}.
   * Trim removes start and end characters &lt;= 32.
   * To strip whitespace use {@link #stripToEmpty(String)}.</p>
   *
   * <pre>
   * StringUtil.trimToEmpty(null)          = ""
   * StringUtil.trimToEmpty("")            = ""
   * StringUtil.trimToEmpty("     ")       = ""
   * StringUtil.trimToEmpty("abc")         = "abc"
   * StringUtil.trimToEmpty("    abc    ") = "abc"
   * </pre>
   *
   * @param str  the String to be trimmed, may be null
   * @return the trimmed String, or an empty String if <code>null</code> input
   */
  static trimToEmpty = function (str) {
    return str == null ? StringUtil.EMPTY : str.trim()
  }

  /**
   * <p>Replaces a String with another String inside a larger String,
   * for the first <code>max</code> values of the search String.</p>
   *
   * <p>A <code>null</code> reference passed to this method is a no-op.</p>
   *
   * <pre>
   * StringUtil.replace("abaa", "a", null, -1) = "abaa"
   * StringUtil.replace("abaa", "a", "", -1)   = "b"
   * StringUtil.replace("abaa", "a", "z", 0)   = "abaa"
   * StringUtil.replace("abaa", "a", "z", 1)   = "zbaa"
   * StringUtil.replace("abaa", "a", "z", 2)   = "zbza"
   * StringUtil.replace("abaa", "a", "z", -1)  = "zbzz"
   * </pre>
   *
   * @param text  text to search and replace in, may be null
   * @param searchString  the String to search for, may be null
   * @param replacement  the String to replace it with, may be null
   * @param max  maximum number of values to replace, or <code>-1</code> if no maximum
   * @return the text with any replacements processed,
   *  <code>null</code> if null String input
   */
  static replace = function (text, searchString, replacement, max) {
    if (max == null || typeof max === 'undefined') {
      max = -1
    }
    if (StringUtil.isEmpty(text) || StringUtil.isEmpty(searchString) || replacement === null || max === 0) {
      return text
    }
    var start = 0
    var end = text.indexOf(searchString, start)
    if (end === StringUtil.INDEX_NOT_FOUND) {
      return text
    }
    var replLength = searchString.length
    var buf = []
    while (end !== StringUtil.INDEX_NOT_FOUND) {
      buf.push(text.substring(start, end))
      buf.push(replacement)
      start = end + replLength
      if (--max === 0) {
        break
      }
      end = text.indexOf(searchString, start)
    }
    buf.push(text.substring(start))
    return buf.join('')
  }

  /**
   * Represents a failed index search.
   */
  static INDEX_NOT_FOUND = -1
  /**
   * The empty String <code>""</code>.
   */
  static EMPTY = ''
  /**
   * <p>The maximum size to which the padding constant(s) can expand.</p>
   */
  static PAD_LIMIT = 8192
}
