<template>
  <div>
    <div class="unit-input-div">
      <el-input class="item unit-converter-input-number-area" v-model="input" @input="onInput"></el-input>
      <el-select class="item" v-model="unit1" @change="onUnitChange" ref="unit1OptionRef">
        <el-option-group
          v-for="unitOption in unitOptions"
          :key="unitOption.categ"
          :label="unitOption.categ">
          <el-option
            v-for="(item, idx) in unitOption.units"
            :key="unitOption.categ + '.' + idx"
            :label="item.unitName"
            :value="item.unitKey">
          </el-option>
        </el-option-group>
      </el-select>
      <el-button class="item" icon="el-icon-refresh" circle @click="exchangeUnit"></el-button>
      <el-select class="item" v-model="unit2" @change="onUnitChange" ref="unit2OptionRef">
        <el-option-group
          v-for="unitOption in unitOptions"
          :key="unitOption.categ"
          :label="unitOption.categ">
          <el-option
            v-for="(item, idx) in unitOption.units"
            :key="unitOption.categ + '.' + idx"
            :label="item.unitName"
            :value="item.unitKey">
          </el-option>
        </el-option-group>
      </el-select>
      <el-button class="item" icon="el-icon-more" circle @click="showAll"></el-button>
    </div>
    <div v-if="this.result">
      <el-divider></el-divider>
      <h3 v-if="this.result">{{ this.result }}</h3>
    </div>
    <el-divider></el-divider>
    <div>
      <el-table
        :data="tableData"
        height="500px"
        border
        :span-method="objectSpanMethod"
        style="width: 100%">
        <el-table-column
          prop="categ"
          :label="$t('unitConverter.categ')"
          width="150">
        </el-table-column>
        <el-table-column
          prop="unitName"
          :label="$t('unitConverter.unit')"
          width="180">
        </el-table-column>
        <el-table-column
          prop="fromSiuFormula"
          label="FROM S(㎡)">
        </el-table-column>
        <el-table-column
          prop="toSiuFormula"
          label="TO S(㎡)">
        </el-table-column>
        <el-table-column
          prop="remark"
          :label="$t('cmn.remark')">
        </el-table-column>
      </el-table>
    </div>

    <div>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableShowAllDialog"
        append-to-body
      >
        <div style="display: flex">
          <el-input class="unit-converter-input-number-area" v-model="input" @input="onInput"></el-input>
          <el-select v-model="unit1" @change="onUnitChange" ref="unit1OptionRef">
            <el-option-group
              v-for="unitOption in unitOptions"
              :key="unitOption.categ"
              :label="unitOption.categ">
              <el-option
                v-for="(item, idx) in unitOption.units"
                :key="unitOption.categ + '.' + idx"
                :label="item.unitName"
                :value="item.unitKey">
              </el-option>
            </el-option-group>
          </el-select>
        </div>
        <div>
          <div v-for="(item, idx) in allUnitResult" :key="idx">
            {{ item }}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js'

const math = require('mathjs')

export default {
  name: 'AreaUnit',
  computed: {
    result: {
      get () {
        return this.calUnitResult()
      },
      set (v) {
      }
    },
    allUnitResult: {
      get () {
        return this.calAllUnit()
      },
      set (v) {
      }
    },
    unitData: {
      get () {
        return {
          siu: {
            '㎡': {
              categ: this.$t('unitConverter.siu'),
              unitName: this.$t('areaUnit.squareM'),
              unitKey: '㎡',
              fromSiuFormula: 'S(㎡) = S(㎡)',
              toSiuFormula: 'S(㎡) = S(㎡)',
              fromSiuExp: '1 * x',
              toSiuExp: '1 * y',
              remark: this.$t('areaUnit.remark.squareM')
            }
          },
          metricUnit: {
            'm㎡': {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('areaUnit.squareMm'),
              unitKey: 'm㎡',
              fromSiuFormula: 'S(m㎡) = S(㎡) * 1e+6',
              toSiuFormula: 'S(㎡) = S(m㎡) * 1e-6',
              fromSiuExp: 'x * 1e+6',
              toSiuExp: 'y * 1e-6',
              remark: this.$t('areaUnit.remark.squareMm')
            },
            'c㎡': {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('areaUnit.squareCm'),
              unitKey: 'c㎡',
              fromSiuFormula: 'S(c㎡) = S(㎡) * 1e+4',
              toSiuFormula: 'S(㎡) = S(c㎡) * 1e-4',
              fromSiuExp: 'x * 1e+4',
              toSiuExp: 'y * 1e-4',
              remark: this.$t('areaUnit.remark.squareCm')
            },
            'd㎡': {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('areaUnit.squareDm'),
              unitKey: 'd㎡',
              fromSiuFormula: 'S(d㎡) = S(㎡) * 1e+2 = S(㎡) * 100',
              toSiuFormula: 'S(㎡) = S(d㎡) * 1e-2 = S(d㎡) * 0.01',
              fromSiuExp: 'x * 100',
              toSiuExp: 'y * 0.01',
              remark: this.$t('areaUnit.remark.squareDm')
            },
            '㎡': {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('areaUnit.squareM'),
              unitKey: '㎡',
              fromSiuFormula: 'S(㎡) = S(㎡)',
              toSiuFormula: 'S(㎡) = S(㎡)',
              fromSiuExp: '1 * x',
              toSiuExp: '1 * y',
              remark: this.$t('areaUnit.remark.squareM')
            },
            a: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('areaUnit.are'),
              unitKey: 'a',
              fromSiuFormula: 'S(a) = S(㎡) * 1e-2 = S(㎡) * 0.01',
              toSiuFormula: 'S(㎡) = S(a) * 1e+2 = S(a) * 100',
              fromSiuExp: 'x * 0.01',
              toSiuExp: 'y * 100',
              remark: this.$t('areaUnit.remark.are')
            },
            ha: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('areaUnit.hectare'),
              unitKey: 'ha',
              fromSiuFormula: 'S(ha) = S(㎡) * 1e-4',
              toSiuFormula: 'S(㎡) = S(ha) * 1e+4',
              fromSiuExp: 'x * 1e-4',
              toSiuExp: 'y * 1e+4',
              remark: this.$t('areaUnit.remark.hectare')
            },
            'k㎡': {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('areaUnit.squareKm'),
              unitKey: 'k㎡',
              fromSiuFormula: 'S(k㎡) = S(㎡) * 1e-6',
              toSiuFormula: 'S(㎡) = S(k㎡) * 1e+6',
              fromSiuExp: 'x * 1e-6',
              toSiuExp: 'y * 1e+6',
              remark: this.$t('areaUnit.remark.squareKm')
            }
          },
          municipalUnit: {
            PingFangCun: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('areaUnit.PingFangCun'),
              unitKey: 'PingFangCun',
              fromSiuFormula: 'S(平方寸) = S(㎡) * 30 * 30 = S(㎡) * 900',
              toSiuFormula: 'S(㎡) = S(平方寸) / 900',
              fromSiuExp: 'x * 900',
              toSiuExp: 'y / 900',
              remark: this.$t('areaUnit.remark.PingFangCun')
            },
            PingFangChi: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('areaUnit.PingFangChi'),
              unitKey: 'PingFangChi',
              fromSiuFormula: 'S(平方尺) = S(㎡) * 3 * 3 = S(㎡) * 9',
              toSiuFormula: 'S(㎡) = S(平方尺) / 9',
              fromSiuExp: 'x * 9',
              toSiuExp: 'y / 9',
              remark: this.$t('areaUnit.remark.PingFangChi')
            },
            Fen: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('areaUnit.Fen'),
              unitKey: 'Fen',
              fromSiuFormula: 'S(分) = S(㎡) * 1.5e-2 = S(㎡) * 0.015',
              toSiuFormula: 'S(㎡) = S(分) * 1e+2 / 1.5 = S(分) / 0.015',
              fromSiuExp: 'x * 0.015',
              toSiuExp: 'y / 0.015',
              remark: this.$t('areaUnit.remark.Fen')
            },
            Mu: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('areaUnit.Mu'),
              unitKey: 'Mu',
              fromSiuFormula: 'S(亩) = S(㎡) * 1.5e-3 = S(㎡) * 0.0015',
              toSiuFormula: 'S(㎡) = S(分) * 1e+3 / 1.5 = S(分) / 0.0015',
              fromSiuExp: 'x * 0.0015',
              toSiuExp: 'y / 0.0015',
              remark: this.$t('areaUnit.remark.Mu')
            },
            Qing: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('areaUnit.Qing'),
              unitKey: 'Qing',
              fromSiuFormula: 'S(倾) = S(㎡) * 1.5e-5 = S(㎡) * 0.000015',
              toSiuFormula: 'S(㎡) = S(分) * 1e+5 / 1.5 = S(分) / 0.000015',
              fromSiuExp: 'x * 0.000015',
              toSiuExp: 'y / 0.000015',
              remark: this.$t('areaUnit.remark.Qing')
            }
          },
          imperialUnit: {
            'in²': {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('areaUnit.squareInch'),
              unitKey: 'in²',
              fromSiuFormula: 'S(in²) = S(㎡) * 1e+4 / 6.4516',
              toSiuFormula: 'S(㎡) = S(in²) * 2.54e-2 * 2.54e-2 = S(in²) * 6.4516e-4',
              fromSiuExp: 'x * 1e+4 / 6.4516',
              toSiuExp: 'y * 6.4516e-4',
              remark: this.$t('areaUnit.remark.squareInch')
            },
            'ft²': {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('areaUnit.squareFoot'),
              unitKey: 'ft²',
              fromSiuFormula: 'S(ft²) = S(㎡) / 0.09290304',
              toSiuFormula: 'S(㎡) = S(ft²) * 0.3048 * 0.3048 = S(ft²) * 0.09290304',
              fromSiuExp: 'x / 0.09290304',
              toSiuExp: 'y * 0.09290304',
              remark: this.$t('areaUnit.remark.squareFoot')
            },
            'yd²': {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('areaUnit.squareYard'),
              unitKey: 'yd²',
              fromSiuFormula: 'S(yd²) = S(㎡) / 0.83612736',
              toSiuFormula: 'S(㎡) = S(yd²) * 0.9144 * 0.9144 = S(yd²) * 0.83612736',
              fromSiuExp: 'x / 0.83612736',
              toSiuExp: 'y * 0.83612736',
              remark: this.$t('areaUnit.remark.squareYard')
            },
            'rd²': {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('areaUnit.squareRod'),
              unitKey: 'rd²',
              fromSiuFormula: 'S(rd²) = S(㎡) / 25.29285264',
              toSiuFormula: 'S(㎡) = S(rd²) * 5.0292 * 5.0292 = S(rd²) * 25.29285264',
              fromSiuExp: 'x / 25.29285264',
              toSiuExp: 'y * 25.29285264',
              remark: this.$t('areaUnit.remark.squareRod')
            },
            ac: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('areaUnit.acre'),
              unitKey: 'ac',
              fromSiuFormula: 'S(ac) = S(㎡) / 4046.8564224',
              toSiuFormula: 'S(㎡) = S(ac) * 160 * 25.29285264 = S(ac) * 4046.8564224',
              fromSiuExp: 'x / 4046.8564224',
              toSiuExp: 'y * 4046.8564224',
              remark: this.$t('areaUnit.remark.acre')
            },
            'mi²': {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('areaUnit.squareMile'),
              unitKey: 'mi²',
              fromSiuFormula: 'S(mi²) = S(㎡) / 2589988.110336',
              toSiuFormula: 'S(㎡) = S(mi²) * 1609.344 * 1609.344 = S(mi²) * 2589988.110336',
              fromSiuExp: 'x / 2589988.110336',
              toSiuExp: 'y * 2589988.110336',
              remark: this.$t('areaUnit.remark.squareMile')
            }
          }
        }
      },
      set (v) {
      }
    },
    unitOptions: {
      get () {
        return [
          {
            categ: this.$t('unitConverter.siu'),
            units: Object.values(this.unitData.siu)
          },
          {
            categ: this.$t('unitConverter.metricUnit'),
            units: Object.values(this.unitData.metricUnit)
          },
          {
            categ: this.$t('unitConverter.municipalUnit'),
            units: Object.values(this.unitData.municipalUnit)
          },
          {
            categ: this.$t('unitConverter.imperialUnit'),
            units: Object.values(this.unitData.imperialUnit)
          }
        ]
      },
      set (v) {
      }
    },
    tableData: {
      get () {
        return [...Object.values(this.unitData.siu), ...Object.values(this.unitData.metricUnit),
          ...Object.values(this.unitData.municipalUnit), ...Object.values(this.unitData.imperialUnit)]
      },
      set (v) {
      }
    }
  },
  data () {
    return {
      unit1: 'Mu',
      unit2: '㎡',
      input: null,
      enableShowAllDialog: false
    }
  },
  methods: {
    onInput () {
      this.result = this.calUnitResult()
      if (this.enableShowAllDialog) {
        this.showAll()
      }
    },

    onUnitChange () {
      this.result = this.calUnitResult()
      if (this.enableShowAllDialog) {
        this.showAll()
      }
    },

    showAll () {
      this.allUnitResult = this.calAllUnit()
      this.enableShowAllDialog = true
    },
    exchangeUnit () {
      const tempUnit = this.unit1
      this.unit1 = this.unit2
      this.unit2 = tempUnit
      this.onUnitChange()
    },
    calAllUnit () {
      const allUnitResult = []
      for (let i = 0; i < this.unitOptions.length; i++) {
        if (i !== 0) {
          const unitOptions = this.unitOptions.at(i).units
          for (const unitOption of unitOptions) {
            allUnitResult.push(this.calResult(unitOption))
          }
        }
      }
      return allUnitResult
    },
    calUnitResult () {
      if (this.input && this.unit1 && this.unit2) {
        const inputNum = new BigNumber(math.evaluate(this.input))
        if (inputNum.isFinite()) {
          const unit1Data = this.getUnitDataByUnitKey(this.unit1)
          const unit2Data = this.getUnitDataByUnitKey(this.unit2)
          if (unit1Data && unit2Data) {
            const to = math.evaluate(unit1Data.toSiuExp.replaceAll('y', this.input))
            const from = math.evaluate(unit2Data.fromSiuExp.replaceAll('x', to))
            return this.input + ' ' + this.$refs.unit1OptionRef.selected.label + '  =  ' + from + ' ' + this.$refs.unit2OptionRef.selected.label
          } else {
            return null
          }
        }
        return null
      } else {
        return null
      }
    },
    calResult (resultUnit) {
      if (this.input && this.unit1 && resultUnit) {
        const inputNum = new BigNumber(math.evaluate(this.input))
        if (inputNum.isFinite()) {
          const unit1Data = this.getUnitDataByUnitKey(this.unit1)
          const unit2Data = resultUnit
          if (unit1Data && unit2Data) {
            const to = math.evaluate(unit1Data.toSiuExp.replaceAll('y', this.input))
            const from = math.evaluate(unit2Data.fromSiuExp.replaceAll('x', to))
            return this.input + ' ' + this.$refs.unit1OptionRef.selected.label + '  =  ' + from + ' ' + resultUnit.unitName
          } else {
            return null
          }
        }
        return null
      } else {
        return null
      }
    },
    getUnitDataByUnitKey (unitKey) {
      for (const unit of Object.values(this.unitData)) {
        if (unit && unit[unitKey]) {
          return unit[unitKey]
        }
      }
      return null
    },
    objectSpanMethod ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 1,
            colspan: 1
          }
        }
        if (rowIndex === 1) {
          return {
            rowspan: 7,
            colspan: 1
          }
        }
        if (rowIndex === 8) {
          return {
            rowspan: 5,
            colspan: 1
          }
        }
        if (rowIndex === 13) {
          return {
            rowspan: 6,
            colspan: 1
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
