<template>
  <div>
    <!-- 要给外层div指定合适的宽度 -->
    <div style="width: 700px; margin-top: 16px; margin-left: 16px">
      <DefaultCalendar @change="change" :date="date" :range="range"/>
    </div>
  </div>
</template>

<script>
import DefaultCalendar from '../../../components/DefaultCalendar.vue'

export default {
  name: 'Calendar',
  data () {
    return {
      date: '',
      range: [1990, 2050]
    }
  },
  components: {
    DefaultCalendar
  },
  methods: {
    change (obj) {
      console.log('obj', obj)
    }
  }
}
</script>

<style scoped>

</style>
