import resultHttpTool from '@/http/ResultHttpTool'
import { changeParent } from '@/api/vojivosvc/toolhall/UsrzToolGroupApi'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/usrzStudyLabResPkgGroup'

export function countTotal () {
  return resultHttpTool.get(API_PREFIX + '/countTotal')
}

export function add (ip) {
  return resultHttpTool.post(API_PREFIX + '/add', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.addSuccess()
      } else {
        ApiMsgBox.addFailed()
      }
      return op
    })
}

export function findTrees (ip) {
  return resultHttpTool.post(API_PREFIX + '/findTrees', ip)
}

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export function findResPkgBelongingGroups (resPkgId) {
  return resultHttpTool.get(API_PREFIX + '/findResPkgBelongingGroups?resPkgId=' + resPkgId)
}

export function changeRankValue (ip) {
  return resultHttpTool.post(API_PREFIX + '/changeRankValue', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function reRank (ip) {
  return resultHttpTool.post(API_PREFIX + '/reRank', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function update (ip) {
  return resultHttpTool.post(API_PREFIX + '/update', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function batchDeleteTree (ip) {
  return resultHttpTool.post(API_PREFIX + '/batchDeleteTree', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.delSuccess()
      } else {
        ApiMsgBox.delFailed()
      }
      return op
    })
}

export default {
  countTotal,
  add,
  findTrees,
  findByPage,
  findResPkgBelongingGroups,
  update,
  reRank,
  changeRankValue,
  changeParent,
  batchDeleteTree
}
