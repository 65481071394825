export default class RankValueUtil {
  RankValueUtil () {
  }

  static genMiddle (pre, next) {
    if (pre === next) {
      return pre
    }
    const diff = next - pre
    let power = 1.0
    let powerDiff = diff
    while (powerDiff % 1 !== 0) {
      powerDiff *= 10
      power *= 10
    }
    let mid = 0
    if (powerDiff === 1) {
      mid = 0.5
    } else {
      mid = Math.ceil(powerDiff / 2.0)
    }
    return pre + mid / power
  }

  static genPre (cur) {
    return cur - 100.0
  }

  static genNext (cur) {
    return cur + 100.0
  }
}
