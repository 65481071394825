import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './svgconfig/svg.js'
import '@/assets/css/IconFontAll.css'
import './assets/css/global.css'
import './assets/scss/index.scss'

import i18n from './i18n/i18n'
import store from '@/store/VuexStore'
import VueCryptojs from 'vue-cryptojs/src/index.mjs'

Vue.use(VueCryptojs)

Vue.config.productionTip = false
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
