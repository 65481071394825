export default {
  'principal.credential.wrong': 'Username or password is wrong',
  'email.exists': 'Email exists',
  'unid.exists': 'VOJI UNID exists',
  'unid.pwd.wrong': 'VOJI UNID or password is wrong',
  'unid.invalid': 'VOJI UNID invalid',
  'unid.not.found': 'VOJI UNID not found',
  'email.invalid': 'Email invalid',
  'acct.not.found': 'The account not found',
  'acct.disabled': 'The account is disabled',
  'acct.unactivated': 'The account is not activated, please click the link send to your email to activate',
  'invalid.active.code': 'Invalid activation code',
  'invalid.principle': 'Invalid account',
  'can.not.delete.default.group': 'Default group can`t be deleted'
}
