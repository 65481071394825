<template>
  <en-decoder-cmpt encode-alg="ASCII"></en-decoder-cmpt>
</template>

<script>
import EnDecoderCmpt from '@/views/tool/a02_endecoder/EnDecoderCmpt.vue'

export default {
  name: 'ASCII',
  components: { EnDecoderCmpt }
}
</script>

<style scoped>

</style>
