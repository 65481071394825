import resultHttpTool from '@/http/ResultHttpTool'

const API_PREFIX = '/api/usr/vojistarCard'

export function findAllLevelCards () {
  return resultHttpTool.get(API_PREFIX + '/findAllLevelCards')
    .then(rd => {
      if (rd && rd.length > 1) {
        return rd
      }
      return mockAllLevelCards()
    })
    .catch(() => {
      return mockAllLevelCards()
    })
}

export function findCurUsrz () {
  return resultHttpTool.get(API_PREFIX + '/findCurUsrz')
}

export function syncStatus () {
  return resultHttpTool.get(API_PREFIX + '/syncStatus')
}

export function init (ip) {
  return resultHttpTool.post(API_PREFIX + '/init', ip)
}

export function upgrade (ip) {
  return resultHttpTool.post(API_PREFIX + '/upgrade', ip)
}

export function complete (ip) {
  return resultHttpTool.post(API_PREFIX + '/complete', ip)
}

export function findOrSyncStatus (ip) {
  return resultHttpTool.get(API_PREFIX + '/findOrSyncStatus', {
    params: {
      id: ip.id,
      txnId: ip.txnId,
      payWay: ip.payWay
    }
  })
}

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export default {
  findCurUsrz,
  syncStatus,
  findAllLevelCards,
  findOrSyncStatus,
  init,
  upgrade,
  complete,
  findByPage
}

function mockAllLevelCards () {
  return [
    {
      unid: 'ORB_STAR',
      level: 1,
      ccyAmt: 999,
      showCcyAmt: '9.99',
      showCcySymbol: '￥',
      discount: 10,
      name: '星球卡',
      i18nName: '星球卡',
      nameI18nRes: [
        {
          langId: 'zh',
          value: '星球卡'
        },
        {
          langId: 'en',
          value: 'OrbStar'
        }
      ],
      remark: '一缕星光，邀您一起探寻乌及星的秘密',
      i18nRemark: '一缕星光，邀您一起探寻乌及星的秘密',
      remarkI18nRes: [
        {
          langId: 'zh',
          value: '一缕星光，邀您一起探寻乌及星的秘密'
        },
        {
          langId: 'en',
          value: 'A ray of starlight invites you to explore the secrets of the VojiStar together.'
        }
      ],
      benefits: [
        '①记录存储数不限',
        '②创建分组数不限',
        '③所有资源享9折'
      ],
      i18nBenefits: [
        '①记录存储数不限',
        '②创建分组数不限',
        '③所有资源享9折'
      ],
      langIdBenefitsMap: {
        en: [
          '①Records no limit',
          '②Groups no limit',
          '③10% discount'
        ],
        zh: [
          '①记录存储数不限',
          '②创建分组数不限',
          '③所有资源享9折'
        ]
      }
    },
    {
      unid: 'NEBU_STAR',
      level: 2,
      ccyAmt: 9999,
      showCcyAmt: '99.99',
      showCcySymbol: '￥',
      discount: 50,
      name: '星云卡',
      i18nName: '星云卡',
      nameI18nRes: [
        {
          langId: 'zh',
          value: '星云卡'
        },
        {
          langId: 'en',
          value: 'NebuStar'
        }
      ],
      remark: '多彩斑斓，如梦似幻，联系就存在于彼此之间',
      i18nRemark: '多彩斑斓，如梦似幻，联系就存在于彼此之间',
      remarkI18nRes: [
        {
          langId: 'zh',
          value: '多彩斑斓，如梦似幻，联系就存在于彼此之间'
        },
        {
          langId: 'en',
          value: 'A ray of starlight invites you to explore the secrets of the Vojistar together.'
        }
      ],
      benefits: [
        '①记录存储数不限',
        '②创建分组数不限',
        '③所有资源享5折'
      ],
      i18nBenefits: [
        '①记录存储数不限',
        '②创建分组数不限',
        '③所有资源享5折'
      ],
      langIdBenefitsMap: {
        en: [
          '①Records no limit',
          '②Groups no limit',
          '③50% discount'
        ],
        zh: [
          '①记录存储数不限',
          '②创建分组数不限',
          '③所有资源享5折'
        ]
      }
    },
    {
      unid: 'CLU_STAR',
      level: 3,
      ccyAmt: 99999,
      showCcyAmt: '999.99',
      showCcySymbol: '￥',
      discount: 70,
      name: '星团卡',
      i18nName: '星团卡',
      nameI18nRes: [
        {
          langId: 'zh',
          value: '星团卡'
        },
        {
          langId: 'en',
          value: 'CluStar'
        }
      ],
      remark: '积少成多，聚簇成团，稳固和力量总是紧密相连',
      i18nRemark: '积少成多，聚簇成团，稳固和力量总是紧密相',
      remarkI18nRes: [
        {
          langId: 'zh',
          value: '积少成多，聚簇成团，稳固和力量总是紧密相'
        },
        {
          langId: 'en',
          value: 'A ray of starlight invites you to explore the secrets of the Vojistar together.'
        }
      ],
      benefits: [
        '①记录存储数不限',
        '②创建分组数不限',
        '③所有资源享3折'
      ],
      i18nBenefits: [
        '①记录存储数不限',
        '②创建分组数不限',
        '③所有资源享3折'
      ],
      langIdBenefitsMap: {
        en: [
          '①Records no limit',
          '②Groups no limit',
          '③70% discount'
        ],
        zh: [
          '①记录存储数不限',
          '②创建分组数不限',
          '③所有资源享3折'
        ]
      }
    },
    {
      unid: 'GALA_STAR',
      level: 4,
      ccyAmt: 999999,
      showCcyAmt: '9999.99',
      showCcySymbol: '￥',
      discount: 90,
      name: '星系卡',
      i18nName: '星系卡',
      nameI18nRes: [
        {
          langId: 'zh',
          value: '星系卡'
        },
        {
          langId: 'en',
          value: 'GalaStar'
        }
      ],
      remark: '神秘星系，旋转轮回，一条条轨迹，一层层韵律',
      i18nRemark: '神秘星系，旋转轮回，一条条轨迹，一层层韵律',
      remarkI18nRes: [
        {
          langId: 'zh',
          value: '神秘星系，旋转轮回，一条条轨迹，一层层韵律'
        },
        {
          langId: 'en',
          value: 'A ray of starlight invites you to explore the secrets of the Vojistar together.'
        }
      ],
      benefits: [
        '①记录存储数不限',
        '②创建分组数不限',
        '③所有资源享1折'
      ],
      i18nBenefits: [
        '①记录存储数不限',
        '②创建分组数不限',
        '③所有资源享1折'
      ],
      langIdBenefitsMap: {
        en: [
          '①Records no limit',
          '②Groups no limit',
          '③90% discount'
        ],
        zh: [
          '①记录存储数不限',
          '②创建分组数不限',
          '③所有资源享1折'
        ]
      }
    },
    {
      unid: 'UNI_STAR',
      level: 5,
      ccyAmt: 9999999,
      showCcyAmt: '99999.99',
      showCcySymbol: '￥',
      discount: 100,
      name: '星宇卡',
      i18nName: '星宇卡',
      nameI18nRes: [
        {
          langId: 'zh',
          value: '星宇卡'
        },
        {
          langId: 'en',
          value: 'UniStar'
        }
      ],
      remark: '浩瀚星宇，璀璨深邃，无穷的能量，畅享无尽的自由',
      i18nRemark: '浩瀚星宇，璀璨深邃，无穷的能量，畅享无尽的自由',
      remarkI18nRes: [
        {
          langId: 'zh',
          value: '浩瀚星宇，璀璨深邃，无穷的能量，畅享无尽的自由'
        },
        {
          langId: 'en',
          value: 'A ray of starlight invites you to explore the secrets of the Vojistar together.'
        }
      ],
      benefits: [
        '①记录存储数不限',
        '②创建分组数不限',
        '③所有资源任意下载',
        '④提供技术支持服务'
      ],
      i18nBenefits: [
        '①记录存储数不限',
        '②创建分组数不限',
        '③所有资源任意下载',
        '④提供技术支持服务'
      ],
      langIdBenefitsMap: {
        en: [
          '①Records no limit',
          '②Groups no limit',
          '③All resources are free',
          '④Tech Support services'
        ],
        zh: [
          '①记录存储数不限',
          '②创建分组数不限',
          '③所有资源任意下载',
          '④提供技术支持服务'
        ]
      }
    }
  ]
}
