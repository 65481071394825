import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/usrzFavStudyLabResPkg'

export function fav (ip) {
  return resultHttpTool.post(API_PREFIX + '/fav', ip)
    .then(rd => {
      if (rd) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return rd
    })
}

export function batchFav (ip) {
  return resultHttpTool.post(API_PREFIX + '/batchFav', ip)
    .then(rd => {
      if (rd) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return rd
    })
}

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export function updateBasicInfo (ip) {
  return resultHttpTool.post(API_PREFIX + '/updateBasicInfo', ip)
    .then(rd => {
      if (rd) {
        ApiMsgBox.editSuccess()
      } else {
        ApiMsgBox.editFailed()
      }
      return rd
    })
}

export function batchDelete (idsIp) {
  return resultHttpTool.post(API_PREFIX + '/batchDelete', idsIp)
    .then(rd => {
      if (rd) {
        ApiMsgBox.delSuccess()
      } else {
        ApiMsgBox.delFailed()
      }
      return rd
    })
}

export function batchCancelFav (ip) {
  return resultHttpTool.post(API_PREFIX + '/batchCancelFav', ip)
    .then(rd => {
      if (rd) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return rd
    })
}

export default {
  fav,
  batchFav,
  batchCancelFav,
  findByPage,
  batchDelete
}
