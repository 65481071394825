import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/usrzPurStudyLabResPkg'

export function purOoper (ip) {
  return resultHttpTool.post(API_PREFIX + '/purOoper', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function batchPurOoper (ip) {
  return resultHttpTool.post(API_PREFIX + '/batchPurOoper', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function purPay (ip) {
  return resultHttpTool.post(API_PREFIX + '/purPay', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function acquireResDownloadUrl (ip) {
  return resultHttpTool.post(API_PREFIX + '/acquireResDownloadUrl', ip)
}

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export function updateBasicInfo (ip) {
  return resultHttpTool.post(API_PREFIX + '/updateBasicInfo', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function batchDelete (idsIp) {
  return resultHttpTool.post(API_PREFIX + '/batchDelete', idsIp)
    .then(op => {
      if (op) {
        ApiMsgBox.delSuccess()
      } else {
        ApiMsgBox.delFailed()
      }
      return op
    })
}

export function cancelPur (ip) {
  return resultHttpTool.post(API_PREFIX + '/cancelPur', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export default {
  purOoper,
  purPay,
  acquireResDownloadUrl,
  batchPurOoper,
  findByPage,
  updateBasicInfo,
  batchDelete
}
