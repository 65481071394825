<template>
  <div>
    <!--    Main -->
    <div>
      <div class="srch-div">
        <el-input
          v-model="findByPageIp.srchIp.srchKw"
          @input="delaySrch">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-dropdown @command="handleCmd">
          <el-button icon="el-icon-s-operation"></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="checkCurPage">{{ $t('cmn.checkCurPage') }}</el-dropdown-item>
            <el-dropdown-item v-if="checkedUsrzFavTool&&checkedUsrzFavTool.size>0" command="cancelCheckAll">
              {{ $t('cmn.cancelCheckAll') }}
            </el-dropdown-item>
            <el-dropdown-item v-if="checkedUsrzFavTool&&checkedUsrzFavTool.size>0" command="batchSetGroup">
              {{ $t('myRoom.setGroup') }}
            </el-dropdown-item>
            <el-dropdown-item v-if="checkedUsrzFavTool&&checkedUsrzFavTool.size>0" command="batchCancelFav">
              {{ $t('cmn.cancelFav') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="tool-source-type-div">
        <el-radio v-model="toolSourceType" label="VJV_TOOL" @input="onChangeToolSourceType">
          {{ this.$t('toolHall.platformTool') }}
        </el-radio>
        <el-radio v-model="toolSourceType" label="THIRD_PARTY_TOOL" @input="onChangeToolSourceType">
          {{ this.$t('toolHall.thirdPartTool') }}
        </el-radio>
        <el-radio v-model="toolSourceType" label="ALL_TOOL" @input="onChangeToolSourceType">
          {{ this.$t('toolHall.allTool') }}
        </el-radio>
      </div>

      <div class="tool-shower">
        <div v-infinite-scroll="loadMoreUsrzFavTool"
             :infinite-scroll-disabled="isLoadingMoreUsrzFavTool"
             class="scroll-div"
        >
          <div class="tool-card-container">
            <el-card v-for="usrzFavTool in usrzFavToolList" :key="usrzFavTool.id" class="tool-card">
              <div>
                <el-row type="flex" align="middle" :gutter="10">
                  <el-col :span="7" class="tool-logo-col">
                    <router-link :to="{path:usrzFavTool.toolOp.path}" target="_blank">
                      <img :src="toolLogo(usrzFavTool.toolOp)" class="tool-logo" alt="LOGO">
                    </router-link>
                  </el-col>
                  <el-col :span="17">
                    <div class="card-info-container">
                      <div class="tool-name-area">
                        <router-link :to="{path:'/toolDetail',query:{toolId:usrzFavTool.toolOp.id}}" target="_blank">
                          <h3 v-if="usrzFavTool.customName">{{ usrzFavTool.customName }}</h3>
                          <h3 v-else>{{ usrzFavTool.toolOp.name }}</h3>
                        </router-link>
                      </div>
                      <div class="tool-main-categ-area">
                        <el-tag
                          v-for="mainCateg in usrzFavTool.toolOp.mainCategList"
                          :key="mainCateg.id"
                          type="warning"
                          effect="dark"
                          size="mini"
                        >
                          {{ mainCateg.name }}
                        </el-tag>
                      </div>
                      <div class="opn-area">
                        <el-button
                          icon="el-icon-info"
                          circle
                          size="small"
                          @click="goToDetail(usrzFavTool.toolOp)"
                        />
                        <el-popover
                          :ref="'CancelFavPop.' + usrzFavTool.toolOp.id"
                          placement="top"
                          width="200"
                        >
                          <p>{{ $t('cmn.cancelFavConfirm') }}</p>
                          <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text"
                                       @click="$refs['CancelFavPop.' + usrzFavTool.toolOp.id].at(0).doClose()">
                              {{ $t('cmn.cancel') }}
                            </el-button>
                            <el-button type="primary" size="mini" @click="doCancelFav(usrzFavTool.toolOp.id)">
                              {{ $t('cmn.confirm') }}
                            </el-button>
                          </div>
                          <el-button slot="reference" icon="el-icon-star-on" circle
                                     size="small"/>
                        </el-popover>
                        <el-button icon="el-icon-right" circle size="small"
                                   @click="routeToTool(usrzFavTool.toolOp.path)"/>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div>
                <div class="tool-profile-area">{{ usrzFavTool.toolOp.profile }}</div>
<!--                <a-->
<!--                  target="_blank"-->
<!--                  :href="usrzFavTool.toolOp.uri"-->
<!--                  class="text-decoration-none"-->
<!--                >-->
<!--                  {{ usrzFavTool.toolOp.uri }}-->
<!--                </a>-->
              </div>
              <el-checkbox
                :key="usrzFavTool.id"
                v-model="usrzFavTool.checked"
                class="tool-check-box"
                @change="handleToolChecked(usrzFavTool,$event)"
              />
            </el-card>
          </div>
        </div>
      </div>
    </div>
    <!--    Dialog-->
    <div>
      <!-- Batch Set Group Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchSetGroupForm"
        :title="$t('myRoom.setGroup')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="batchSetGroupFormRef"
          :inline="true"
          :model="batchSetGroupForm"
          :rules="batchSetGroupFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('toolHall.toolList')" prop="toolIds">
            <el-select
              v-model="batchSetGroupForm.toolIds"
              :multiple="true"
              clearable
              style="width: 600px;"
            >
              <el-option
                v-for="usrzFavTool in checkedUsrzFavTool.values()"
                :key="usrzFavTool.id"
                :label="usrzFavTool.toolOp.name"
                :value="usrzFavTool.toolOp.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('cmn.group')" prop="toolGroupIds">
            <el-tree
              v-model="batchSetGroupForm.toolGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              :check-strictly="true"
              show-checkbox
              lazy
              @check="handleGroupTreeNodeCheck"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchSetGroupForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchSetGroup">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Cancel Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchCancelFavForm"
        :title="$t('cmn.cancelFav')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="batchCancelFavFormRef"
          :inline="true"
          :model="batchCancelFavForm"
          :rules="batchCancelFavFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('toolHall.toolList')" prop="toolIds">
            <el-select
              v-model="batchCancelFavForm.toolIds"
              :multiple="true"
              clearable
              style="width: 600px;"
            >
              <el-option
                v-for="usrzFavTool in checkedUsrzFavTool.values()"
                :key="usrzFavTool.id"
                :label="usrzFavTool.toolOp.name"
                :value="usrzFavTool.toolOp.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchCancelFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchCancelFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>

</template>

<script>
import UsrzFavToolApi from '@/api/vojivosvc/toolhall/UsrzFavToolApi'
import lodash from 'lodash'
import { DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE, LOCAL_FILE_PREFIX } from '@/constant/CmnBizConst'
import UsrzToolGroupApi from '@/api/vojivosvc/toolhall/UsrzToolGroupApi'
import BizConfig from '@/config/BizConfig'

export default {
  name: 'MyFavTool',
  watch: {
    '$route.query.groupTreeRootIds': {
      handler: function (newVal, oldVal) {
        this.groupTreeRootIds = newVal
        this.srch()
      }
    },
    deep: true
  },
  created () {
    this.groupTreeRootIds = this.$route.query.groupTreeRootIds
    this.srch()
  },
  data () {
    return {
      toolSourceType: 'VJV_TOOL',
      defaultPage: {
        total: 0,
        current: 1,
        size: 10
      },
      delaySrchTimeoutId: null,
      pageInfo: {},
      findByPageIp: {
        ...lodash.cloneDeep(DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE),
        groupTreeRootIds: [],
        isVjvTool: 1
      },
      isLoadingMoreUsrzFavTool: true,
      usrzFavToolList: [],
      groupTreeRootIds: [],
      enableBatchSetGroupForm: false,
      batchSetGroupForm: {
        toolNames: [],
        toolIds: [],
        toolGroupIds: []
      },
      batchSetGroupFormRules: {},
      enableBatchCancelFavForm: false,
      batchCancelFavForm: {
        toolNames: [],
        toolIds: []
      },
      batchCancelFavFormRules: {},
      checkedUsrzFavTool: new Map(),
      checkedGroupIds: [],
      groupTrees: [
        {
          id: 0,
          label: 'NULL PARENT',
          children: null
        }
      ]
    }
  },
  methods: {
    delaySrch () {
      this.clearDelaySrchTimeout()
      this.delaySrchTimeoutId = setTimeout(() => {
        this.srch(false)
      }, BizConfig.cmnDelayMsec)
    },
    clearDelaySrchTimeout () {
      if (this.delaySrchTimeoutId) {
        clearTimeout(this.delaySrchTimeoutId)
        this.delaySrchTimeoutId = null
      }
    },
    srch (isLoadingMore) {
      if (isLoadingMore) {
        this.findByPageIp.pageIp.current = this.pageInfo.current
        this.findByPageIp.pageIp.size = this.pageInfo.size
      } else {
        this.findByPageIp.pageIp = { ...this.defaultPage }
      }
      this.findByPageIp.groupTreeRootIds = this.groupTreeRootIds
      switch (this.toolSourceType) {
        case 'VJV_TOOL':
          this.findByPageIp.isVjvTool = 1
          break
        case 'THIRD_PARTY_TOOL':
          this.findByPageIp.isVjvTool = 0
          break
        case 'ALL_TOOL':
          this.findByPageIp.isVjvTool = null
          break
      }
      UsrzFavToolApi.findByPage(this.findByPageIp).then(pageOp => {
        this.isLoadingMoreUsrzFavTool = false
        this.refreshPageInfo(pageOp)
        const data = pageOp.data
        data.map(item => {
          if (this.checkedUsrzFavTool.has(item.id)) {
            this.$set(item, 'checked', true)
          } else {
            this.$set(item, 'checked', false)
          }
          return item
        })
        if (isLoadingMore) {
          this.usrzFavToolList.push(...data)
        } else {
          this.usrzFavToolList = data
        }
      })
    },
    toolLogo (tool) {
      if (tool.logoUrl === LOCAL_FILE_PREFIX) {
        return require('@/assets/img/toollogo/' + tool.unid + '.png')
      } else if (tool.logoUrl.startsWith(LOCAL_FILE_PREFIX)) {
        return require(tool.logoUrl.substring(LOCAL_FILE_PREFIX.length))
      } else {
        return tool.logoUrl
      }
    },
    refreshPageInfo (pageOp) {
      this.pageInfo.total = pageOp.total
      this.pageInfo.size = pageOp.pageIp.size
      this.pageInfo.current = pageOp.pageIp.current
    },
    onChangeToolSourceType () {
      this.pageInfo = {}
      this.isLoadingMoreTool = true
      this.srch()
    },
    loadMoreUsrzFavTool () {
      this.isLoadingMoreUsrzFavTool = true
      if (this.pageInfo.current >= this.pageInfo.total / this.pageInfo.size) {
        return
      }
      this.pageInfo.current += 1
      this.srch(true)
    },
    routeToTool (index) {
      this.$router.push(index)
    },
    goToDetail (tool) {
      const routeData = this.$router.resolve({
        path: '/toolDetail',
        query: {
          toolId: tool.id
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleCmd (cmd) {
      switch (cmd) {
        case 'batchSetGroup':
          this.toBatchSetGroup()
          break
        case 'batchCancelFav':
          this.toBatchCancelFav()
          break
        case 'checkCurPage':
          this.checkCurPage()
          break
        case 'cancelCheckAll':
          this.cancelCheckAll()
          break
      }
    },
    toBatchSetGroup () {
      this.enableBatchSetGroupForm = true
      this.batchSetGroupForm.toolIds = []
      this.checkedUsrzFavTool.forEach((value, key) => {
        this.batchSetGroupForm.toolIds.push(value.toolOp.id)
      })
    },
    doBatchSetGroup () {
      const ip = {
        toolIds: this.batchSetGroupForm.toolIds,
        toolGroupIds: this.checkedGroupIds
      }
      UsrzFavToolApi.batchFav(ip)
        .then(() => {
          this.srch()
          this.enableBatchSetGroupForm = false
        })
    },
    toBatchCancelFav () {
      this.enableBatchCancelFavForm = true
      this.batchCancelFavForm.toolIds = []
      this.checkedUsrzFavTool.forEach((value, key) => {
        this.batchCancelFavForm.toolIds.push(value.toolId)
      })
    },
    doBatchCancelFav () {
      const ip = {
        toolIds: this.batchCancelFavForm.toolIds
      }
      UsrzFavToolApi.batchCancelFav(ip)
        .then(() => {
          this.srch()
          this.enableBatchCancelFavForm = false
        })
    },
    doCancelFav (toolId) {
      const ip = {
        toolIds: [toolId]
      }
      UsrzFavToolApi.batchCancelFav(ip)
        .then(() => {
          this.$refs['CancelFavPop.' + toolId].at(0).doClose()
          this.srch()
        })
    },
    checkCurPage () {
      this.usrzFavToolList.map(item => {
        // 这里，给对象添加属性，用$set方法。
        this.$set(item, 'checked', true)
        if (!this.checkedUsrzFavTool.has(item.id)) {
          this.checkedUsrzFavTool.set(item.id, item)
        }
        return item
      })
    },
    cancelCheckAll () {
      this.usrzFavToolList.map(item => {
        // 这里，给对象添加属性，用$set方法。
        this.$set(item, 'checked', false)
        this.checkedUsrzFavTool.delete(item.id)
        return item
      })
    },
    handleToolChecked (usrzFavTool, data) {
      if (!this.checkedUsrzFavTool.has(usrzFavTool.id)) {
        this.checkedUsrzFavTool.set(usrzFavTool.id, usrzFavTool)
      }
    },
    loadGroupTrees (node, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        UsrzToolGroupApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.groupTrees = resultData
            }
          })
      }, 100)
    },
    handleGroupTreeNodeCheck (data, checkedData) {
      this.checkedGroupIds = []
      this.checkedGroupIds.push(...checkedData.checkedKeys)
      this.batchSetGroupForm.toolGroupIds = []
      this.batchSetGroupForm.toolGroupIds.push(...checkedData.checkedKeys)
    }
  }
}
</script>

<style scoped>

</style>
