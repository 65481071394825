<template>
  <div class="app-container">
    <!-- 内容页面上部  -->
    <div class="upper-container">
      <!--  条件项目容器    -->
      <div class="cond-container">
        <div class="time-container">
          <el-select v-model="timeIp.timeKey" clearable :placeholder="$t('cmn.timeType')" size="small">
            <el-option :label="$t('cmn.createTime')" value="createMsec"/>
            <el-option :label="$t('cmn.updateTime')" value="updateMsec"/>
          </el-select>
          <el-date-picker
            v-model="timeIp.beginMsec"
            type="datetime"
            value-format="timestamp"
            :placeholder="$t('cmn.plsChooseBeginDateTime')"
            align="right"
            size="small"
            :picker-options="srchTimePickerOptions"
          />
          <el-date-picker
            v-model="timeIp.endMsec"
            type="datetime"
            value-format="timestamp"
            :placeholder="$t('cmn.plsChooseEndDateTime')"
            align="right"
            size="small"
            :picker-options="srchTimePickerOptions"
          />
          <span>
            <el-checkbox v-model="timeIp.includeLower" :label="$t('cmn.includeLower')" border size="small"/>
          </span>
          <span>
            <el-checkbox v-model="timeIp.includeUpper" :label="$t('cmn.includeUpper')" border size="small"/>
          </span>
        </div>
        <div class="kw-container">
          <el-select v-model="srchIp.srchKey" clearable :placeholder="$t('cmn.srchField')" size="small">
            <el-option :label="$t('cmn.recordName')" value="record_name"/>
            <el-option :label="$t('cmn.remark')" value="remark"/>
          </el-select>
          <el-input
            v-model="srchIp.srchKw"
            clearable
            :placeholder="$t('cmn.plsInputSrchKw')"
            class="kw-input-box"
            style="width: 445px;"
          />
        </div>
      </div>
      <!--  操作项目容器-->
      <div class="opn-container">
        <el-button
          class="opn-btn"
          size="mini"
          type="success"
          icon="el-icon-search"
          @click="doSrch"
        >{{ $t('cmn.srch') }}
        </el-button>

        <el-button
          class="opn-btn"
          size="mini"
          type="info"
          icon="el-icon-refresh-left"
          @click="doResetSrch"
        >{{ $t('cmn.reset') }}
        </el-button>

        <el-button
          class="opn-btn"
          size="mini"
          type="info"
          icon="el-icon-refresh"
          @click="doRefresh"
        >{{ $t('cmn.refresh') }}
        </el-button>

        <!--   删除按钮Popover     -->
        <el-popover
          ref="BatchDelPop"
          placement="top"
          width="200"
        >
          <p>{{ $t('cmn.confirmDel') }}</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="$refs['BatchDelPop'].doClose()">{{ $t('cmn.cancel') }}
            </el-button>
            <el-button type="primary" size="mini" @click="doBatchDel">{{ $t('cmn.confirm') }}</el-button>
          </div>
          <el-button
            v-if="true"
            slot="reference"
            class="opn-btn"
            type="danger"
            icon="el-icon-delete"
            size="mini"
            :disabled="selectedData.length === 0"
          >{{ $t('cmn.del') }}
          </el-button>
        </el-popover>

        <!-- 字段Popover -->
        <el-popover
          placement="bottom-end"
          width="150"
          trigger="click"
        >
          <el-button
            slot="reference"
            class="opn-btn"
            type="success"
            size="mini"
            icon="el-icon-s-grid"
            @click="updateTableColumns"
          >{{ $t('cmn.field') }}
          </el-button>
          <el-checkbox
            v-model="allColumnsSelected"
            :indeterminate="allColumnsIndeterminate"
            @change="handleSelectedAllColumnsChange"
          >
            {{ $t('cmn.selectAll') }}
          </el-checkbox>
          <el-checkbox
            v-for="item in tableColumns"
            :key="item.property"
            v-model="item.visible"
            @change="handleCheckedColumnsChange(item)"
          >
            {{ item.label }}
          </el-checkbox>
        </el-popover>
      </div>
    </div>

    <!-- 内容页面下部  -->
    <div class="lower-container">

      <!--表格渲染-->
      <el-table
        ref="table"
        :data="tableData"
        style="width: 100%;"
        :border="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55"/>
        <el-table-column prop="recordName" :label="$t('geoLonLat.recordName')" sortable="custom" resizable
                         min-width="100px"/>
        <el-table-column prop="inputTextA" :label="$t('geoLonLat.inputTextA')" resizable width="200px">
          <template v-slot="scope">
            <el-input type="textarea" rows="6" disabled v-model="scope.row.inputTextA"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="inputTextB" :label="$t('geoLonLat.inputTextB')" resizable width="200px">
          <template v-slot="scope">
            <el-input type="textarea" rows="6" disabled v-model="scope.row.inputTextB"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="geoResults" :label="$t('geoLonLat.geoResultsJson')" resizable min-width="260px"
                         :formatter="toPrettyJson">
          <template v-slot="scope">
            <el-input type="textarea" rows="6" disabled
                      :value="JSON.stringify(scope.row.geoResults, null, 2)"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="createMsec"
          :label="$t('cmn.createTime')"
          :formatter="msecFormatter"
          sortable="custom"
          resizable
          :width="timeColumnWidth"
        />
        <el-table-column
          prop="updateMsec"
          :label="$t('cmn.updateTime')"
          :formatter="msecFormatter"
          sortable="custom"
          resizable
          :width="timeColumnWidth"
        />
        <el-table-column prop="remark" :label="$t('cmn.remark')" resizable min-width="200px"/>

        <el-table-column
          :label="$t('cmn.opn')"
          width="100px"
          align="center"
          fixed="right"
        >
          <template v-slot="scope">
            <el-button
              size="mini"
              style="margin-right: 3px;"
              type="text"
              @click="toGeoLonLat(scope.row)"
            >{{ $t('cmn.deal') }}
            </el-button>
            <el-popover
              :ref="'DelPop.' + scope.row.id"
              placement="top"
              width="200"
            >
              <p>{{ $t('cmn.confirmDel') }}</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs['DelPop.' + scope.row.id].doClose()">
                  {{ $t('cmn.cancel') }}
                </el-button>
                <el-button type="primary" size="mini" @click="doDelete(scope.row.id)">{{ $t('cmn.confirm') }}
                </el-button>
              </div>
              <el-button slot="reference" type="text" size="mini">{{ $t('cmn.del') }}</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>

      <!--分页组件-->
      <el-pagination
        :total="page.total"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        layout="total, sizes, prev, pager, next, jumper"
        style="margin-top: 8px;"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      />
    </div>
  </div>
</template>

<script>
import GeoRecordApi from '../../../api/vojivosvc/toolsubject/GeoRecordApi'
import TimeUtil from '@/util/TimeUtil'
import {
  DEFAULT_ORDER_BYS,
  DEFAULT_PAGE_PO, DEFAULT_SRCH_PO,
  DEFAULT_TIME_PO,
  TABLE_COLUMN_WIDTH_OBJ
} from '@/constant/CmnBizConst'
import { MSG_LEVEL_TYPE } from '@/constant/CmnConst'
import { sortWithRef } from '@/bizfunc/BizFunc'
import Config from '@/SysConfig'

const defaultOrderBy = {
  key: 'updateMsec',
  asc: false
}
export default {
  name: 'GeoRecord',
  data () {
    return {
      tool: {
        name: 'GeoLonLat',
        menu: 'GeoRecord',
        isVjvTool: 1,
        path: '/tool/geoLonLat',
        logoUrl: 'https://gss0.baidu.com/9vo3dSag_xI4khGko9WTAnF6hhy/zhidao/pic/item/aa64034f78f0f736d623160d0555b319eac413d8.jpg'
      },
      timeIp: {
        timeKey: null,
        beginMsec: null,
        endMsec: null,
        includeLower: true,
        includeUpper: false
      },
      srchIp: {
        srchKey: null,
        srchKw: null
      },
      orderBys: DEFAULT_ORDER_BYS,
      page: {
        total: 0,
        current: 1,
        size: 5
      },
      tableData: [],
      selectedData: [],
      srchTimePickerOptions: {
        shortcuts: [
          {
            text: '7天前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              date.setHours(0)
              date.setMinutes(0)
              date.setSeconds(0)
              date.setMilliseconds(0)
              picker.$emit('pick', date)
            }
          },
          {
            text: '昨天0点',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              date.setHours(0)
              date.setMinutes(0)
              date.setSeconds(0)
              date.setMilliseconds(0)
              picker.$emit('pick', date)
            }
          },
          {
            text: '过去8小时',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 8)
              picker.$emit('pick', date)
            }
          },
          {
            text: '此时此刻',
            onClick (picker) {
              const date = new Date()
              picker.$emit('pick', date)
            }
          },
          {
            text: '未来8小时',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 8)
              picker.$emit('pick', date)
            }
          },
          {
            text: '明天0点',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              date.setHours(0)
              date.setMinutes(0)
              date.setSeconds(0)
              date.setMilliseconds(0)
              picker.$emit('pick', date)
            }
          },
          {
            text: '7天后',
            onClick (picker) {
              const date = new Date()
              date.setDate(date.getDate() + 1)
              date.setHours(8)
              date.setMinutes(0)
              date.setSeconds(0)
              date.setMilliseconds(0)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      tableColumns: [],
      allColumnsSelected: true,
      allColumnsIndeterminate: false,
      // 忽略下次表格列变动
      ignoreNextTableColumnsChange: false,
      ignoreColumns: [],
      timeColumnWidth: TABLE_COLUMN_WIDTH_OBJ.dateTime
    }
  },
  created () {
    this.doSrch()
  },
  methods: {
    async doSrch () {
      const ip = {
        srchKw: this.srchIp.srchKw,
        srchIp: { ...this.srchIp },
        timeIp: { ...this.timeIp },
        orderBys: this.orderBys,
        pageIp: {
          current: this.page.current,
          size: this.page.size
        }
      }
      const pageOp = await GeoRecordApi.findByPage(ip)
      this.page.total = pageOp.total
      this.tableData = pageOp.data
    },
    doResetSrch () {
      this.srchIp = { ...DEFAULT_SRCH_PO }
      this.timeIp = { ...DEFAULT_TIME_PO }
      this.orderBys = DEFAULT_ORDER_BYS
      this.page.size = DEFAULT_PAGE_PO.size
      this.page.current = DEFAULT_PAGE_PO.current
      this.doSrch()
    },
    doRefresh () {
      this.doSrch()
    },
    delayRefresh () {
      setTimeout(() => this.doSrch(),
        Config.refreshDelayMsec)
    },
    toGeoLonLat (row) {
      const routeData = this.$router.resolve({
        path: '/tool/geoLonLat',
        query: {
          geoRecordId: row.id
        }
      })
      window.open(routeData.href, '_blank')
    },
    async doBatchDel () {
      const ip = {
        ids: []
      }
      for (const item of this.selectedData) {
        ip.ids.push(item.id)
      }
      const delRetData = await GeoRecordApi.batchDelete(ip)
      if (delRetData) {
        this.delayRefresh()
        this.$refs.BatchDelPop.doClose()
      }
    },
    async doDelete (id) {
      const ip = {
        ids: []
      }
      ip.ids.push(id)
      const delRetData = await GeoRecordApi.batchDelete(ip)
      if (delRetData) {
        this.$refs['DelPop.' + id].doClose()
      }
    },
    handleSelectedAllColumnsChange (val) {
      if (val === false) {
        this.allColumnsSelected = true
        return
      }
      this.tableColumns.forEach(column => {
        if (!column.visible) {
          column.visible = true
          this.updateColumnVisible(column)
        }
      })
      this.allColumnsSelected = val
      this.allColumnsIndeterminate = false
    },
    handleCheckedColumnsChange (item) {
      let totalCount = 0
      let selectedCount = 0
      this.tableColumns.forEach(column => {
        ++totalCount
        selectedCount += column.visible ? 1 : 0
      })
      if (selectedCount === 0) {
        this.$notify({
          title: '注意',
          message: '请至少选择一列',
          type: MSG_LEVEL_TYPE.WARNING
        })
        this.$nextTick(function () {
          item.visible = true
        })
        return
      }
      this.allColumnsSelected = selectedCount === totalCount
      this.allColumnsIndeterminate = selectedCount !== totalCount && selectedCount !== 0
      this.updateColumnVisible(item)
    },
    updateColumnVisible (item) {
      const table = this.$refs.table
      const vm = table.$children.find(e => e.prop === item.property)
      const columnConfig = vm.columnConfig
      if (item.visible) {
        // 找出合适的插入点
        const columnIndex = this.tableColumns.indexOf(item)
        vm.owner.store.commit('insertColumn', columnConfig, columnIndex + 1, null)
      } else {
        vm.owner.store.commit('removeColumn', columnConfig, null)
      }
      this.ignoreNextTableColumnsChange = true
    },
    updateTableColumns () {
      const table = this.$refs.table
      if (!table) {
        this.tableColumns = []
        return
      }
      let cols = null
      const columnFilter = e => e && e.type === 'default' && e.property && this.ignoreColumns.indexOf(e.property) === -1
      const refCols = table.columns.filter(columnFilter)
      if (this.ignoreNextTableColumnsChange) {
        this.ignoreNextTableColumnsChange = false
        return
      }
      this.ignoreNextTableColumnsChange = false
      const columns = []
      const fullTableColumns = table.$children.map(e => e.columnConfig).filter(columnFilter)
      cols = sortWithRef(fullTableColumns, refCols)
      cols.forEach(config => {
        const column = {
          property: config.property,
          label: config.label,
          visible: refCols.indexOf(config) !== -1
        }
        columns.push(column)
      })
      this.tableColumns = columns
    },
    handleSelectionChange (data) {
      this.selectedData = data
    },
    handleSortChange (data) {
      const orderBy = { ...defaultOrderBy }
      orderBy.key = data.prop
      orderBy.asc = data.order === 'ascending'
      this.orderBys = [
        { ...orderBy }
      ]
      this.doSrch()
    },
    handlePageSizeChange (pageSize) {
      this.page.size = pageSize
      this.doSrch()
    },
    handleCurrentPageChange (currentPage) {
      this.page.current = currentPage
      this.doSrch()
    },
    toPrettyJson (row, column, cellValue, index) {
      if (cellValue) {
        return JSON.stringify(cellValue)
      }
      return null
    },
    msecFormatter (row, column, cellValue, index) {
      if (cellValue) {
        return TimeUtil.convertMsecToDateTimeStr(cellValue)
      }
      return null
    }
  }
}
</script>
