import resultHttpTool from '@/http/ResultHttpTool'
import Config from '@/SysConfig'

const API_PREFIX = '/api/usr/toolCateg'

export function findTrees (ip) {
  return resultHttpTool.post(API_PREFIX + '/findTrees', ip)
    .then(op => {
      if (op) {
        return op
      } else {
        return mockFindTreesResultData()
      }
    }, () => {
      return mockFindTreesResultData()
    })
}

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export function findAll () {
  return resultHttpTool.get(API_PREFIX + '/findAll')
}

export default {
  findTrees,
  findByPage
}

function mockFindTreesResultData () {
  if (Config.enableMockRespResultData) {
    return [
      {
        id: 1,
        createMsec: 1715490840000,
        updateMsec: 1715490840000,
        rankValue: 10,
        remark: '',
        name: '软件技术',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '软件技术'
          },
          {
            langId: 'en',
            value: 'Software Tech'
          }
        ],
        profile: '软件技术',
        profileI18nRes: [
          {
            langId: 'zh',
            value: '软件技术'
          }
        ],
        parentId: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        children: null,
        hasChildren: true
      },
      {
        id: 2,
        createMsec: 1715490840000,
        updateMsec: 1715490840000,
        rankValue: 20,
        remark: '',
        name: '人工智能',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '人工智能'
          },
          {
            langId: 'en',
            value: 'AI Tool'
          }
        ],
        profile: '',
        profileI18nRes: [
          {
            langId: 'zh',
            value: ''
          },
          {
            langId: 'en',
            value: ''
          }
        ],
        parentId: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        children: null,
        hasChildren: true
      },
      {
        id: 3,
        createMsec: 1715490840000,
        updateMsec: 1715490840000,
        rankValue: 30,
        remark: '',
        name: '教育学习',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '教育学习'
          },
          {
            langId: 'en',
            value: 'Education'
          }
        ],
        profile: '',
        profileI18nRes: [
          {
            langId: 'zh',
            value: ''
          },
          {
            langId: 'en',
            value: ''
          }
        ],
        parentId: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        children: null,
        hasChildren: true
      },
      {
        id: 4,
        createMsec: 1715490840000,
        updateMsec: 1715490840000,
        rankValue: 40,
        remark: '',
        name: '办公辅助',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '办公辅助'
          },
          {
            langId: 'en',
            value: 'Office Aid'
          }
        ],
        profile: '',
        profileI18nRes: [
          {
            langId: 'zh',
            value: ''
          },
          {
            langId: 'en',
            value: ''
          }
        ],
        parentId: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        children: null,
        hasChildren: true
      },
      {
        id: 5,
        createMsec: 1715490840000,
        updateMsec: 1715490840000,
        rankValue: 50,
        remark: '',
        name: '日常生活',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '日常生活'
          },
          {
            langId: 'en',
            value: 'Daily Life'
          }
        ],
        profile: '',
        profileI18nRes: [
          {
            langId: 'zh',
            value: ''
          },
          {
            langId: 'en',
            value: ''
          }
        ],
        parentId: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        children: null,
        hasChildren: true
      },
      {
        id: 6,
        createMsec: 1715490840000,
        updateMsec: 1717466033830,
        rankValue: 1000,
        remark: '',
        name: '其他工具',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '其他工具'
          },
          {
            langId: 'en',
            value: 'Others'
          }
        ],
        profile: '',
        profileI18nRes: [
          {
            langId: 'zh',
            value: ''
          },
          {
            langId: 'en',
            value: ''
          }
        ],
        parentId: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        children: null,
        hasChildren: true
      }
    ]
  }
}
