import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/studyLabResTag'

export function add (ip) {
  return resultHttpTool.post(API_PREFIX + '/add', ip)
}

export function findTrees (ip) {
  return resultHttpTool.post(API_PREFIX + '/findTrees', ip)
}

export function findByPage (ip) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', ip)
}

export function update (ip) {
  return resultHttpTool.post(API_PREFIX + '/update', ip)
}

export function batchDeleteTree (idsIp) {
  return resultHttpTool.post(API_PREFIX + '/batchDeleteTree', idsIp)
    .then(op => {
      if (op) {
        ApiMsgBox.delSuccess()
      } else {
        ApiMsgBox.delFailed()
      }
    })
}

export default {
  add,
  findTrees,
  findByPage,
  update,
  batchDeleteTree
}
