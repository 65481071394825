<template>
  <el-container>
    <el-aside width="150px">
      <div>
        <tool-shower tool-unid="GeoLonLat"></tool-shower>
      </div>
      <el-menu :default-active="$route.path" class="el-menu-vertical-demo" router>
        <el-menu-item index="/tool/geoLonLat/geoCal">
          <i class="iconfont vj-icon-jaince"></i>
          <span slot="title">{{ $t('geoLonLat.geoCal') }}</span>
        </el-menu-item>
        <el-menu-item index="/tool/geoLonLat/geoRecord">
          <i class="iconfont vj-icon-lishijilu_line"></i>
          <span slot="title">{{ $t('geoLonLat.geoRecord') }}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import ToolShower from '@/components/ToolShower.vue'

export default {
  name: 'GeoLonLat',
  components: { ToolShower }
}
</script>

<style scoped>

</style>
