<template>
  <div>
    <h2>乌及屋VojiRoom</h2>
  </div>
</template>

<script>

export default {
  name: 'VojiRoom',
  data () {
    return {
      editor: null
    }
  },
  methods: {
    init () {
      // this.editor = new EditorJS({
      //   tools: {
      //     math: MathEditor,
      //   }
      // })
    }
  }
}
</script>

<style scoped>

</style>
