import CookieStore from '@/store/CookieStore'
import UsrAuthApi from '@/api/UsrAuthApi'
import VuexStore from '@/store/VuexStore'

function convert2CurUsr (loginOp) {
  return {
    acctId: loginOp.usrAcct.id,
    acctUnid: loginOp.usrAcct.unid,
    showName: loginOp.usrAcct.showName,
    showGender: loginOp.usrAcct.showGender,
    avatarUrl: loginOp.usrAcct.avatarUrl,
    perId: loginOp.usrPer.id,
    perName: loginOp.usrPer.name
  }
}

export function login (loginOp) {
  CookieStore.setToken(loginOp.token)
  VuexStore.commit('SET_CUR_USR', convert2CurUsr(loginOp))
}

export function token () {
  const token = CookieStore.getToken()
  if (token === null || token === undefined) {
    return ''
  }
  return token
}

export function rememberMe (remMeData) {
  CookieStore.rememberMe(remMeData)
}

export function remMeData () {
  return CookieStore.remMeData()
}

export function forgetMe () {
  CookieStore.forgetMe()
}

export function logout () {
  CookieStore.removeToken()
  VuexStore.commit('SET_CUR_USR', null)
}

export function curUsr () {
  return new Promise((resolve, reject) => {
    const curUsr = VuexStore.getters.getCurUsr
    if (curUsr && curUsr.acctId) {
      resolve(curUsr)
    } else {
      const token = CookieStore.getToken()
      if (token === null) {
        resolve(null)
      } else {
        UsrAuthApi.curUsrInfo().then(op => {
          if (op) {
            const curUsr = convert2CurUsr(op)
            VuexStore.commit('SET_CUR_USR', curUsr)
            resolve(curUsr)
          } else {
            CookieStore.removeToken()
            VuexStore.commit('SET_CUR_USR', null)
            resolve(null)
          }
        }).catch(() => {
          resolve(null)
        })
      }
    }
  })
}

export function syncCurUsr () {
  const token = CookieStore.getToken()
  if (token === null) {
    return
  }
  UsrAuthApi.curUsrInfo().then(op => {
    if (op) {
      const curUsr = convert2CurUsr(op)
      VuexStore.commit('SET_CUR_USR', curUsr)
    } else {
      CookieStore.removeToken()
      VuexStore.commit('SET_CUR_USR', null)
    }
  }).catch(() => {
    VuexStore.commit('SET_CUR_USR', null)
  })
}

export function isLoggedIn () {
  return new Promise((resolve, reject) => {
    const token = CookieStore.getToken()
    if (token === null) {
      resolve(false)
    } else {
      UsrAuthApi.isLoggedIn().then(rd => {
        if (rd) {
          resolve(rd)
        } else {
          CookieStore.removeToken()
          VuexStore.commit('SET_CUR_USR', null)
          resolve(false)
        }
      }).catch(() => {
        resolve(false)
      })
    }
  })
}

export function isCurUsr (usrAcctId) {
  return new Promise((resolve, reject) => {
    curUsr().then(op => {
      if (op) {
        resolve(op.acctId === usrAcctId)
      } else {
        resolve(false)
      }
    }).catch(() => {
      resolve(false)
    })
  })
}

export default {
  token,
  curUsr,
  syncCurUsr,
  isLoggedIn,
  isCurUsr,
  login,
  logout,
  rememberMe,
  remMeData,
  forgetMe
}
