<template>
  <div>
    <!-- 内容页面上部  -->
    <div class="menu-src-div">
      <el-input
        v-model="findByPageIp.srchIp.srchKw"
        @input="srch">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-dropdown @command="handleCmd">
        <el-button icon="el-icon-s-operation"></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="checkCurPage">{{ $t('cmn.checkCurPage') }}</el-dropdown-item>
          <el-dropdown-item v-if="checkedUsrzPurResPkgMap&&checkedUsrzPurResPkgMap.size>0" command="cancelCheckAll">
            {{ $t('cmn.cancelCheckAll') }}
          </el-dropdown-item>
          <el-dropdown-item v-if="checkedUsrzPurResPkgMap&&checkedUsrzPurResPkgMap.size>0" command="batchSetGroup">
            {{ $t('myRoom.setGroup') }}
          </el-dropdown-item>
          <el-dropdown-item v-if="checkedUsrzPurResPkgMap&&checkedUsrzPurResPkgMap.size>0" command="batchFav">
            {{ $t('cmn.batchFav') }}
          </el-dropdown-item>
          <el-dropdown-item v-if="checkedUsrzPurResPkgMap&&checkedUsrzPurResPkgMap.size>0" command="batchCancelFav">
            {{ $t('cmn.cancelFav') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 内容页面下部  -->
    <div class="res-pkg-shower">
      <div v-infinite-scroll="loadMoreUsrzPurResPkg"
           :infinite-scroll-disabled="isLoadingMoreUsrzPurResPkg"
           class="scroll-div"
      >
        <!--资源Card渲染-->
        <div class="res-pkg-card-container">
          <el-card
            v-for="usrzPurResPkg in usrzPurResPkgList"
            :key="usrzPurResPkg.resPkgId"
            class="res-pkg-card"
          >
            <div class="img-area">
              <el-row :gutter="20">
                <el-col :span="12">
                  <img :src="usrzPurResPkg.resPkgOp.firstImgUri" class="left-img">
                </el-col>
                <el-col :span="12">
                  <div>
                    <img :src="usrzPurResPkg.resPkgOp.secondImgUri" class="right-img">
                  </div>
                  <div>
                    <img :src="usrzPurResPkg.resPkgOp.thirdImgUri" class="right-img">
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="tag-area">
              <div class="base-tag">
                <el-tag
                  v-for="growthStage in usrzPurResPkg.resPkgOp.growthStages"
                  :key="growthStage"
                  effect="dark"
                  size="mini"
                >
                  {{ $t('studyLab.' + growthStage) }}
                </el-tag>
                <el-badge
                  v-for="carrierInfo in usrzPurResPkg.resPkgOp.carrierInfos"
                  :key="carrierInfo.carrierType"
                  :value="carrierInfo.count"
                  class="item"
                >
                  <el-tag
                    type="info"
                    effect="dark"
                    size="mini"
                  >
                    {{ $t('studyLab.' + carrierInfo.carrierType) }}
                  </el-tag>
                </el-badge>

              </div>
              <div class="categ-tag">
                <el-tag
                  v-for="mainCategTag in usrzPurResPkg.resPkgOp.mainCategTags"
                  :key="mainCategTag.id"
                  type="warning"
                  effect="dark"
                  size="mini"
                >
                  {{ mainCategTag.name }}
                </el-tag>
              </div>
              <div class="highlight-tag">
                <el-tag
                  v-for="mainHltTag in usrzPurResPkg.resPkgOp.mainHltTags"
                  :key="mainHltTag.id"
                  type="danger"
                  effect="dark"
                  size="mini"
                >
                  {{ mainHltTag.name }}
                </el-tag>
              </div>
            </div>

            <div class="name-area">
              <h2>{{ usrzPurResPkg.resPkgOp.name }}</h2>
            </div>
            <div class="profile-area">
              {{ usrzPurResPkg.resPkgOp.profile }}
            </div>
            <div class="price-opn-area">
              <el-row :gutter="20">
                <el-col :span="10">
                  <div v-if="usrzPurResPkg.resPkgOp.bePur">
                    <el-tag type="info" size="mini">{{ $t('trade.bePur') }}</el-tag>
                  </div>
                  <div v-else>去支付</div>
                </el-col>
                <el-col :span="14">
                  <div class="opn-area">
                    <el-button
                      icon="el-icon-info"
                      circle
                      size="small"
                      @click="goToResPkgDetail(usrzPurResPkg.resPkgOp.id)"
                    />
                    <el-button icon="el-icon-download" circle size="small"
                               @click="download(usrzPurResPkg.resPkgOp)"/>
                    <el-popover
                      v-if="usrzPurResPkg.resPkgOp.beFav"
                      :ref="'CancelFavPop.' + usrzPurResPkg.resPkgOp.id"
                      placement="top"
                      width="200"
                    >
                      <p>{{ $t('cmn.cancelFavConfirm') }}</p>
                      <div style="text-align: right;">
                        <el-button size="mini" type="text"
                                   @click="$refs['CancelFavPop.' + usrzPurResPkg.resPkgOp.id].at(0).doClose()">
                          {{ $t('cmn.cancel') }}
                        </el-button>
                        <el-button type="primary" size="mini" @click="doCancelFav(usrzPurResPkg.resPkgOp)">
                          {{ $t('cmn.confirm') }}
                        </el-button>
                      </div>
                      <el-button v-if="usrzPurResPkg.resPkgOp.beFav" slot="reference" icon="el-icon-star-on" circle
                                 size="small"/>
                    </el-popover>
                    <el-button v-if="!usrzPurResPkg.resPkgOp.beFav" icon="el-icon-star-off" circle size="small"
                               @click="toFav(usrzPurResPkg.resPkgOp)"/>
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-checkbox
              :key="usrzPurResPkg.id"
              v-model="usrzPurResPkg.checked"
              class="res-pkg-check-box"
              @change="handleResChecked(usrzPurResPkg,$event)"
            ></el-checkbox>
          </el-card>
        </div>
      </div>
    </div>

    <!--    Dialog -->
    <div>
      <!-- Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableFavForm"
        :title="$t('cmn.fav')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="favFormRef"
          :inline="true"
          :model="favForm"
          :rules="favFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('studyLab.resPkgName')" prop="resPkgName">
            <el-input v-model="favForm.resPkgName" disabled="true" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cmn.group')" prop="resPkgGroupIds">
            <el-tree
              v-model="favForm.resPkgGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              show-checkbox
              lazy
              @check="handleGroupTreeNodeCheck"
            />
          </el-form-item>
          <el-form-item :label="$t('cmn.customName')" prop="customName">
            <el-input v-model="favForm.customName" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.customProfile')" prop="customProfile">
            <el-input v-model="favForm.customProfile" style="width: 300px;"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchFavForm"
        :title="$t('cmn.batchFav')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="batchFavFormRef"
          :inline="true"
          :model="batchFavForm"
          :rules="batchFavFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('toolHall.toolList')" prop="resPkgIds">
            <el-select
              v-model="batchFavForm.resPkgIds"
              :multiple="true"
              clearable
              style="width: 600px;"
            >
              <el-option
                v-for="resPkg in checkedUsrzPurResPkgMap.values()"
                :key="resPkg.id"
                :label="resPkg.resPkgOp.name"
                :value="resPkg.resPkgOp.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('cmn.group')" prop="resPkgGroupIds">
            <el-tree
              v-model="batchFavForm.resPkgGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              :check-strictly="true"
              show-checkbox
              lazy
              @check="handleGroupTreeNodeCheck"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Cancel Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchCancelFavForm"
        :title="$t('cmn.cancelFav')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="batchCancelFavFormRef"
          :inline="true"
          :model="batchCancelFavForm"
          :rules="batchCancelFavFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('studyLab.resPkgList')" prop="resPkgIds">
            <el-select
              v-model="batchCancelFavForm.resPkgIds"
              :multiple="true"
              clearable
              style="width: 600px;"
            >
              <el-option
                v-for="usrzPurResPkg in checkedUsrzPurResPkgMap.values()"
                :key="usrzPurResPkg.id"
                :label="usrzPurResPkg.resPkgOp.name"
                :value="usrzPurResPkg.resPkgOp.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchCancelFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchCancelFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Set Group Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchSetGroupForm"
        :title="$t('myRoom.setGroup')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="batchSetGroupFormRef"
          :inline="true"
          :model="batchSetGroupForm"
          :rules="batchSetGroupFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('studyLab.resPkgList')" prop="resPkgIds">
            <el-select
              v-model="batchSetGroupForm.resPkgIds"
              :multiple="true"
              clearable
              style="width: 600px;"
            >
              <el-option
                v-for="usrzPurResPkg in checkedUsrzPurResPkgMap.values()"
                :key="usrzPurResPkg.id"
                :label="usrzPurResPkg.resPkgOp.name"
                :value="usrzPurResPkg.resPkgOp.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('cmn.group')" prop="resPkgGroupIds">
            <el-tree
              v-model="batchSetGroupForm.resPkgGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              :check-strictly="true"
              show-checkbox
              lazy
              @check="handleGroupTreeNodeCheck"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchSetGroupForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchSetGroup">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import { DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE, DEFAULT_ORDER_BY } from '@/constant/CmnBizConst'
import UsrzPurResPkgApi from '@/api/vojivosvc/studylab/UsrzPurResPkgApi'
import UsrzFavResPkgApi from '@/api/vojivosvc/studylab/UsrzFavResPkgApi'
import UsrzResPkgGroupApi from '@/api/vojivosvc/studylab/UsrzResPkgGroupApi'
import ProtRule from '@/constant/ProtRule'
import BizConfig from '@/config/BizConfig'

export default {
  name: 'MyPurResPkg',
  watch: {
    '$route.query.groupTreeRootIds': {
      handler: function (newVal, oldVal) {
        this.groupTreeRootIds = newVal
        this.srch()
      }
    },
    deep: true
  },
  data () {
    return {
      defaultPage: {
        total: 0,
        current: 1,
        size: 4
      },
      pageInfo: {},
      groupTreeRootIds: [],
      findByPageIp: {
        ...lodash.cloneDeep(DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE),
        groupTreeRootIds: [],
        growthStage: null,
        carrierType: null
      },
      isLoadingMoreUsrzPurResPkg: true,
      usrzPurResPkgList: [],
      checkedUsrzPurResPkgMap: new Map(),
      enableBatchSetGroupForm: false,
      batchSetGroupForm: {
        resPkgNames: [],
        resPkgIds: [],
        resPkgGroupIds: []
      },
      batchSetGroupFormRules: {},
      enableFavForm: false,
      favForm: {
        resPkgName: null,
        resPkgId: null,
        resPkgGroupIds: [],
        customName: null,
        customProfile: null
      },
      favFormRules: {},
      enableBatchFavForm: false,
      batchFavForm: {
        resPkgNames: [],
        resPkgIds: [],
        resPkgGroupIds: []
      },
      batchFavFormRules: {},
      enableBatchCancelFavForm: false,
      batchCancelFavForm: {
        resPkgNames: [],
        resPkgIds: []
      },
      batchCancelFavFormRules: {},
      groupTrees: [
        {
          id: 0,
          label: 'NULL PARENT',
          children: null
        }
      ]
    }
  },
  created () {
    this.groupTreeRootIds = this.$route.query.groupTreeRootIds
    this.initFormRules()
    this.srch()
  },
  methods: {
    initFormRules () {
      this.batchFavFormRules = {
        resPkgIds: [
          ProtRule.cmnRule().cmnBatchSize
        ],
        resPkgGroupIds: [
          ProtRule.cmnRule().mBatchSize
        ]
      }
      this.batchCancelFavFormRules = {
        resPkgIds: [
          ProtRule.cmnRule().cmnBatchSize
        ]
      }
    },
    loadMoreUsrzPurResPkg () {
      this.isLoadingMoreUsrzPurResPkg = true
      if (this.pageInfo.current >= this.pageInfo.total / this.pageInfo.size) {
        return
      }
      this.pageInfo.current += 1
      this.doLoadMoreUsrzPurResPkg()
    },
    doLoadMoreUsrzPurResPkg () {
      this.findByPageIp.pageIp.current = this.pageInfo.current
      this.findByPageIp.pageIp.size = this.pageInfo.size
      this.findByPageIp.groupTreeRootIds = this.groupTreeRootIds
      UsrzPurResPkgApi.findByPage(this.findByPageIp).then(pageOp => {
        this.isLoadingMoreUsrzPurResPkg = false
        this.refreshPageInfo(pageOp)
        const data = pageOp.data
        data.map(item => {
          if (this.checkedUsrzPurResPkgMap.has(item.id)) {
            this.$set(item, 'checked', true)
          } else {
            this.$set(item, 'checked', false)
          }
          return item
        })
        this.usrzPurResPkgList.push(...data)
      })
    },
    async srch () {
      this.findByPageIp.pageIp = { ...this.defaultPage }
      this.findByPageIp.groupTreeRootIds = this.groupTreeRootIds
      const pageOp = await UsrzPurResPkgApi.findByPage(this.findByPageIp)
      this.refreshPageInfo(pageOp)
      this.isLoadingMoreUsrzPurResPkg = false
      const data = pageOp.data
      data.map(item => {
        if (this.checkedUsrzPurResPkgMap.has(item.id)) {
          this.$set(item, 'checked', true)
        } else {
          this.$set(item, 'checked', false)
        }
        return item
      })
      this.usrzPurResPkgList = data
    },
    delaySrch () {
      this.clearDelaySrchTimeout()
      this.delaySrchTimeoutId = setTimeout(() => {
        this.srch()
      }, BizConfig.cmnDelayMsec)
    },
    clearDelaySrchTimeout () {
      if (this.delaySrchTimeoutId) {
        clearTimeout(this.delaySrchTimeoutId)
        this.delaySrchTimeoutId = null
      }
    },
    refreshPageInfo (pageOp) {
      this.pageInfo.size = pageOp.pageIp.size
      this.pageInfo.current = pageOp.pageIp.current
      this.pageInfo.total = pageOp.total
    },
    doResetSrch () {
      this.findByPageIp = { ...DEFAULT_FIND_BY_PAGE_PO_RANK_VALUE }
      this.doSrch()
    },
    doRefresh () {
      this.doSrch()
    },
    handleSortChange (data) {
      const orderBy = { ...DEFAULT_ORDER_BY }
      orderBy.key = data.prop
      orderBy.asc = data.order === 'ascending'
      this.orderBys = [
        { ...orderBy }
      ]
      this.doSrch()
    },
    handleResChecked (usrzPurResPkg, isChecked) {
      if (isChecked) {
        this.checkedUsrzPurResPkgMap.set(usrzPurResPkg.id, usrzPurResPkg)
      } else {
        this.checkedUsrzPurResPkgMap.delete(usrzPurResPkg.id)
      }
    },
    toFav (resPkg) {
      this.enableFavForm = true
      this.favForm.resPkgId = resPkg.id
      this.favForm.resPkgName = resPkg.name
      this.favForm.resPkgGroupIds = []
    },
    async doFav () {
      const ip = {
        ...this.favForm
      }
      const retData = await UsrzFavResPkgApi.fav(ip)
      if (retData) {
        this.enableFavForm = false
        for (const resPkg of this.studyLabResPkgList) {
          if (resPkg.id === this.favForm.resPkgId) {
            resPkg.beFav = true
          }
        }
      }
    },
    doCancelFav (resPkg) {
      const ip = {
        resPkgIds: []
      }
      ip.resPkgIds.push(resPkg.id)
      UsrzFavResPkgApi.batchCancelFav(ip)
        .then(resultData => {
          if (resultData) {
            resPkg.beFav = false
            this.$refs['CancelFavPop.' + resPkg.id].at(0).doClose()
          }
        })
    },
    download (resPkg) {
      this.goToResPkgDetail(resPkg.id)
    },
    goToResPkgDetail (resPkgId) {
      const routeData = this.$router.resolve({
        path: '/resPkgDetail',
        query: {
          resPkgId: resPkgId
        }
      })
      window.open(routeData.href, '_blank')
    },

    handleCmd (cmd) {
      switch (cmd) {
        case 'batchSetGroup':
          this.toBatchSetGroup()
          break
        case 'batchFav':
          this.toBatchFav()
          break
        case 'batchCancelFav':
          this.toBatchCancelFav()
          break
        case 'checkCurPage':
          this.checkCurPage()
          break
        case 'cancelCheckAll':
          this.cancelCheckAll()
          break
      }
    },
    toBatchSetGroup () {
      this.enableBatchSetGroupForm = true
      this.batchSetGroupForm.resPkgIds = []
      this.checkedUsrzPurResPkgMap.forEach((value, key) => {
        this.batchSetGroupForm.resPkgIds.push(value.resPkgOp.id)
      })
    },
    doBatchSetGroup () {
      const ip = {
        resPkgIds: this.batchSetGroupForm.resPkgIds,
        resPkgGroupIds: this.checkedGroupIds
      }
      UsrzFavResPkgApi.batchFav(ip)
        .then(() => {
          this.srch()
          this.enableBatchSetGroupForm = false
        })
    },
    toBatchFav () {
      this.enableBatchFavForm = true
      this.batchFavForm.resPkgIds = []
      this.checkedUsrzPurResPkgMap.forEach((value, key) => {
        this.batchFavForm.resPkgIds.push(value.resPkgOp.id)
      })
    },
    doBatchFav () {
      const ip = {
        resPkgIds: this.batchFavForm.resPkgIds,
        resPkgGroupIds: this.checkedGroupIds
      }
      UsrzFavResPkgApi.batchFav(ip)
        .then(() => {
          this.enableBatchFavForm = false
        })
    },

    toBatchCancelFav () {
      this.enableBatchCancelFavForm = true
      this.batchCancelFavForm.resPkgIds = []
      this.checkedUsrzPurResPkgMap.forEach((value, key) => {
        this.batchCancelFavForm.resPkgIds.push(value.resPkgOp.id)
      })
    },
    doBatchCancelFav () {
      const ip = {
        resPkgIds: this.batchCancelFavForm.resPkgIds
      }
      UsrzFavResPkgApi.batchCancelFav(ip)
        .then(() => {
          this.srch()
          this.enableBatchCancelFavForm = false
        })
    },
    checkCurPage () {
      this.usrzPurResPkgList.map(item => {
        // 这里，给对象添加属性，用$set方法。
        this.$set(item, 'checked', true)
        if (!this.checkedUsrzPurResPkgMap.has(item.id)) {
          this.checkedUsrzPurResPkgMap.set(item.id, item)
        }
        return item
      })
    },
    cancelCheckAll () {
      this.usrzPurResPkgList.map(item => {
        // 这里，给对象添加属性，用$set方法。
        this.$set(item, 'checked', false)
        this.checkedUsrzPurResPkgMap.delete(item.id)
        return item
      })
    },
    handleResPkgChecked (usrzFavTool, data) {
      if (!this.checkedUsrzPurResPkgMap.has(usrzFavTool.id)) {
        this.checkedUsrzPurResPkgMap.set(usrzFavTool.id, usrzFavTool)
      }
    },
    loadGroupTrees (node, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        UsrzResPkgGroupApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.groupTrees = resultData
            }
          })
      }, 100)
    },
    handleGroupTreeNodeCheck (data, checkedData) {
      this.checkedGroupIds = []
      this.checkedGroupIds.push(...checkedData.checkedKeys)
    }
  }

}
</script>

<style scoped>

</style>
