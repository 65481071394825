<template>
  <div>
    <div v-for="data in showData" :key="data">
      {{ data }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestRouter',
  watch: {
    '$route.query.data': {
      handler: function (newVal, oldVal) {
        console.log('newVal', newVal, oldVal)
        this.showData = newVal
      }
    },
    deep: true
  },
  created () {
    this.showData = this.$route.query.data
    console.log('showData', this.showData)
  },
  data () {
    return {
      showData: []
    }
  }
}
</script>

<style scoped>

</style>
