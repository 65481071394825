import Vue from 'vue'
import ElementUI from 'element-ui'
import i18n from '@/i18n/i18n'

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.prototype.$message = ElementUI.Message
Vue.prototype.$notify = ElementUI.Notification
Vue.prototype.$prompt = ElementUI.MessageBox.prompt

//
// import {
//   Image,
//   Avatar,
//   Upload,
//   Button,
//   Form,
//   FormItem,
//   Input,
//   Message,
//   Notification,
//   Container,
//   Header,
//   Main,
//   Aside,
//   Footer,
//   Menu,
//   Submenu,
//   MenuItem,
//   Card,
//   Tree,
//   Row,
//   Col,
//   Divider,
//   RadioGroup,
//   Radio,
//   Switch,
//   Dialog,
//   RadioButton,
//   MenuItemGroup,
//   Select,
//   Option, OptionGroup, Tag, Table, TableColumn,
//   MessageBox, Tabs, TabPane, InputNumber,
//   Steps, Step, Checkbox,
//   Timeline, TimelineItem,
//   DatePicker,
//   Pagination,
//   Badge, Popover, Drawer, Dropdown, DropdownItem, DropdownMenu, Carousel, CarouselItem
// } from 'element-ui'

//
// Vue.use(Image)
// Vue.use(Avatar)
// Vue.use(Upload)
// Vue.use(Button)
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Input)
// Vue.use(Container)
// Vue.use(Header)
// Vue.use(Main)
// Vue.use(Aside)
// Vue.use(Footer)
// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(Card)
// Vue.use(Tree)
// Vue.use(Row)
// Vue.use(Col)
// Vue.use(Divider)
// Vue.use(RadioGroup)
// Vue.use(Radio)
// Vue.use(Switch)
// Vue.use(Dialog)
//
// Vue.use(RadioButton)
// Vue.use(MenuItemGroup)
//
// Vue.use(Select)
// Vue.use(OptionGroup)
// Vue.use(Option)
//
// Vue.use(Tag)
// Vue.use(Table)
// Vue.use(TableColumn)
// Vue.use(Tabs)
// Vue.use(TabPane)
// Vue.use(InputNumber)
// Vue.use(Steps)
// Vue.use(Step)
// Vue.use(Checkbox)
// Vue.use(Timeline)
// Vue.use(TimelineItem)
// Vue.use(DatePicker)
// Vue.use(Pagination)
// Vue.use(Badge)
// Vue.use(Popover)
// Vue.use(Drawer)
// Vue.use(Dropdown)
// Vue.use(DropdownItem)
// Vue.use(DropdownMenu)
// Vue.use(Carousel)
// Vue.use(CarouselItem)
// Vue.prototype.$message = Message
// Vue.prototype.$notify = Notification
// Vue.prototype.$prompt = MessageBox.prompt
