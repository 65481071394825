import { BigNumber } from 'bignumber.js'

export default class CcyManager {
  CcyManager () {
  }

  static showCcySymbol () {
    return '￥'
  }

  static showCcyAmt (ccyAmt) {
    return new BigNumber(ccyAmt).dividedBy(100).toFixed(2)
  }
}
