<template>
  <div>
    <el-container>
      <el-header>
        <el-steps :active="activatedStep" finish-status="success">
          <el-step :title="$t('coCoder.databaseSelect')"></el-step>
          <el-step :title="$t('coCoder.entityModeConfig')"></el-step>
          <el-step :title="$t('coCoder.frameworkSelect')"></el-step>
          <el-step :title="$t('coCoder.genCode')"></el-step>
        </el-steps>
      </el-header>
      <el-main>
        <el-form
          :model="genCodeParam"
          ref="genCodeFormRef"
          :rules="genCodeRulesObj"
          label-width="150px"
          label-position='left'>
          <div v-show="activatedStep===0">
            <div class="co-coder-db-item-container">
              <div class="co-coder-db-item">
                <img class="co-coder-db-img" src="@/assets/img/toolsubject/cocoder/db/MYSQL.jpeg" alt="MYSQL"
                     @click="onClickDbImg('MYSQL')">
                <el-radio v-model="genCodeParam.databaseType" label="MYSQL">
                  MySQL
                </el-radio>
              </div>
              <div class="co-coder-db-item">
                <img class="co-coder-db-img" src="@/assets/img/toolsubject/cocoder/db/ES.jpeg" alt="ES"
                     @click="onClickDbImg('ES')">
                <el-radio v-model="genCodeParam.databaseType" label="ES">
                  ES
                </el-radio>
              </div>
              <div class="co-coder-db-item">
                <img class="co-coder-db-img" src="@/assets/img/toolsubject/cocoder/db/MONGO.jpeg" alt="MONGO"
                     @click="onClickDbImg('MONGO')">
                <el-radio v-model="genCodeParam.databaseType" label="MONGO">
                  MongoDB
                </el-radio>
              </div>
            </div>
          </div>
          <div v-show="activatedStep===1">
            <el-form-item :label="$t('coCoder.entityName')" prop="entityModelName">
              <el-input v-model="genCodeParam.entityModelName"></el-input>
            </el-form-item>

            <el-form-item :label="$t('coCoder.entityRemark')" prop="entityModelRemark">
              <div class="form-item-box">
                <el-input v-model="genCodeParam.entityModelRemark"></el-input>
              </div>
            </el-form-item>
            <el-form-item :label="$t('coCoder.tableDocName')" prop="tableDocName">
              <div class="form-item-box">
                <el-input v-model="genCodeParam.tableDocName"></el-input>
              </div>
            </el-form-item>
            <!--        <div>-->
            <!--          <el-checkbox v-model="genCodeParam.enableEntityInnerClass" label="是否启用内部类模式" border></el-checkbox>-->
            <!--        </div>-->
            <el-form-item :label="$t('coCoder.entityModelType')" prop="entityModelType">
              <el-radio v-model="genCodeParam.entityModelType" label="EntityJsonModel" border>JSON</el-radio>
              <el-radio v-model="genCodeParam.entityModelType" label="EntitySqlModel" border>SQL</el-radio>
            </el-form-item>
            <el-form-item :label="$t('coCoder.entityModelContent')" prop="entityModel">
              <el-input
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 10}"
                :placeholder="$t('coCoder.entityModelContentPlaceholder')"
                v-model="genCodeParam.entityModel">
              </el-input>
            </el-form-item>
          </div>
          <div v-show="activatedStep===2">
            <el-form-item :label="$t('coCoder.mainFramework')">
              <el-radio-group v-model='genCodeParam.enableVojivoSeakeer'>
                <div class="co-coder-popover-item">
                  <el-popover
                    placement="top"
                    width="260"
                    :open-delay="1000"
                    trigger="hover">
                    <span>{{ $t('coCoder.commonBaseTip') }}</span>
                    <el-button type="text" @click="showCodeExample('COMMON_BASE',$t('coCoder.commonBase') )">
                      {{
                        $t('coCoder.viewExample')
                      }}
                    </el-button>
                    <el-radio :label="false" slot="reference">{{ $t('coCoder.commonBase') }}</el-radio>
                  </el-popover>
                </div>
                <div class="co-coder-popover-item">
                  <el-popover
                    placement="top"
                    width="300"
                    :open-delay="1000"
                    trigger="hover">
                    <span>{{ $t('coCoder.vojivoSeakeerTip') }}</span>
                    <el-button type="text" @click="showCodeExample('VOJIVO_SEAKEER','VojiFramework')">
                      {{
                        $t('coCoder.viewExample')
                      }}
                    </el-button>
                    <el-radio :label="true" slot="reference">VojiFramework</el-radio>
                  </el-popover>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('coCoder.extraFramework')">
              <div class="co-coder-popover-item">
                <el-popover
                  placement="top"
                  width="260"
                  :open-delay="1000"
                  trigger="hover">
                  <span>{{ $t('coCoder.swaggerTip') }}</span>
                  <el-button type="text" @click="showCodeExample('SWAGGER','Swagger')">
                    {{ $t('coCoder.viewExample') }}
                  </el-button>
                  <el-checkbox v-model="genCodeParam.enableSwagger" label="Swagger" border
                               slot="reference"></el-checkbox>
                </el-popover>
              </div>
              <div v-if="genCodeParam.enableVojivoSeakeer" class="co-coder-popover-item">
                <el-popover
                  placement="top"
                  width="260"
                  :open-delay="1000"
                  trigger="hover">
                  <span>{{ $t('coCoder.springCloudFeignTip') }}</span>
                  <el-button type="text" @click="showCodeExample('SPRING_CLOUD_FEIGN', 'SpringCloudFeign')">
                    {{
                      $t('coCoder.viewExample')
                    }}
                  </el-button>
                  <el-checkbox v-model="genCodeParam.enableFeign"
                               label="SpringCloudFeign" border slot="reference"></el-checkbox>
                </el-popover>
              </div>
            </el-form-item>
          </div>
          <div v-show="activatedStep===3">
            <el-form-item :label="$t('coCoder.projectName')" prop="projectName">
              <div class="form-item-box">
                <el-input :disabled=false v-model="genCodeParam.projectName"></el-input>
              </div>
            </el-form-item>

            <el-form-item :label="$t('coCoder.authorName')" prop="authorName">
              <div class="form-item-box">
                <el-input :disabled=false v-model="genCodeParam.authorName"></el-input>
              </div>
            </el-form-item>
            <el-form-item :label="$t('coCoder.moduleParentPkgPath')" prop="moduleParentPkgPath">
              <div class="form-item-box">
                <el-input :disabled=false v-model="genCodeParam.moduleParentPkgPath"></el-input>
              </div>
            </el-form-item>
            <el-form-item :label="$t('coCoder.moduleName')" prop="moduleName">
              <div class="form-item-box">
                <el-input :disabled=false v-model="genCodeParam.moduleName"
                          :placeholder="$t('coCoder.moduleNamePlaceholder')"></el-input>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </el-main>
      <el-footer>
        <div class="co-coder-footer-btn-container">
          <el-button :disabled="activatedStep === 0" @click="last">{{ $t('coCoder.prevStep') }}</el-button>
          <el-button :disabled="activatedStep === 3" @click="next">{{ $t('coCoder.nextStep') }}</el-button>
          <el-button :disabled="activatedStep === 0" @click="reGenCode">{{ $t('coCoder.restart') }}</el-button>
          <el-button v-show="activatedStep===3" @click="genCode"> {{ $t('coCoder.genCode') }}</el-button>
        </div>
      </el-footer>
    </el-container>
    <div>
      <!-- Code Example Dialog-->
      <el-dialog
        :visible.sync="enableCodeExampleDialog"
        :title="codeExampleTitle"
      >
        <el-card>
          <img class="co-coder-example-img"
               v-if="codeExample === 'COMMON_BASE'"
               alt="CommonBase"
               src="@/assets/img/toolsubject/cocoder/examplecode/CommonBase.png"/>
          <img class="co-coder-example-img"
               v-if="codeExample === 'VOJIVO_SEAKEER'"
               alt="VojiFramework"
               src="@/assets/img/toolsubject/cocoder/examplecode/VojivoSeakeer.png"/>
          <img class="co-coder-example-img"
               v-if="codeExample === 'SWAGGER'"
               alt="VojiFramework"
               src="@/assets/img/toolsubject/cocoder/examplecode/Swagger.png"/>
          <img class="co-coder-example-img"
               v-if="codeExample === 'SPRING_CLOUD_FEIGN'"
               alt="VojiFramework"
               src="@/assets/img/toolsubject/cocoder/examplecode/SpringCloudFeign.png"/>
        </el-card>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import CoCoderApi from '@/api/vojivosvc/toolsubject/CoCoderApi'
import ProtRule from '@/constant/ProtRule'
import ProtRegex from '@/constant/ProtRegex'

export default {
  name: 'GenCode',
  watch: {
    '$i18n.locale': {
      handler: function (newVal, oldVal) {
        this.initFormRules()
      }
    },
    immediate: true
  },
  data () {
    return {
      activatedStep: 0,
      genCodeParam: {
        databaseType: 'MYSQL',
        entityModel: '',
        entityModelType: 'EntityJsonModel',
        entityModelName: '',
        entityModelRemark: '',
        tableDocName: '',
        enableEntityInnerClass: false,
        enableFeign: true,
        enableSwagger: true,
        enableVojivoSeakeer: true,
        projectName: '',
        authorName: '',
        moduleParentPkgPath: '',
        moduleName: ''
      },
      genCodeRulesObj: null,
      genCodeIp: {
        databaseType: 'MYSQL',
        entityJsonModel: null,
        entitySqlModel: '',
        entityModelName: '',
        entityModelRemark: '',
        tableDocName: '',
        codeGenFrameworks: [],
        projectName: '',
        authorName: '',
        moduleParentPkgPath: '',
        moduleName: '',
        enableEntityInnerClass: false
      },
      genCodeOp: {},
      enableCodeExampleDialog: false,
      codeExample: null,
      codeExampleTitle: null
    }
  },
  mounted () {
    this.init()
  },
  created () {
    this.initFormRules()
  },
  methods: {
    init () {
      var storedGenCodeParam = window.sessionStorage.getItem('genCodeParam')
      if (storedGenCodeParam !== null) {
        this.genCodeParam = JSON.parse(storedGenCodeParam)
      }
      var storedActivatedStep = window.sessionStorage.getItem('activatedStep')
      if (storedActivatedStep !== null) {
        this.activatedStep = Number.parseInt(storedActivatedStep)
      }
    },
    initFormRules () {
      this.genCodeRulesObj = {
        entityModelName: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().notWhite,
          {
            min: 1,
            max: 32,
            message: this.$t('protMsg.lengthRange') + '[1,32]',
            trigger: 'blur'
          },
          {
            pattern: ProtRegex.PROG_NAMING,
            message: this.$t('protMsg.patternNotMatch') + '【' + this.$t('protMsg.progNaming') + '】',
            trigger: 'blur'
          }
        ],
        databaseType: [
          ProtRule.cmnRule().required
        ],
        entityModel: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().notWhite,
          {
            min: 2,
            max: 4096,
            message: this.$t('protMsg.lengthRange') + '[2,4096]',
            trigger: 'blur'
          }
        ],
        entityModelType: [
          ProtRule.cmnRule().required
        ],
        entityModelRemark: [
          ProtRule.cmnRule().notWhite,
          {
            min: 1,
            max: 128,
            message: this.$t('protMsg.lengthRange') + '[1,128]',
            trigger: 'blur'
          }
        ],
        tableDocName: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().notWhite,
          {
            min: 1,
            max: 32,
            message: this.$t('protMsg.lengthRange') + '[1,32]',
            trigger: 'blur'
          },
          {
            pattern: ProtRegex.PROG_NAMING,
            message: this.$t('protMsg.patternNotMatch') + '【' + this.$t('protMsg.progNaming') + '】',
            trigger: 'blur'
          }
        ],
        projectName: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().notWhite,
          {
            min: 1,
            max: 32,
            message: this.$t('protMsg.lengthRange') + '[1,32]',
            trigger: 'blur'
          }
        ],
        authorName: [
          ProtRule.cmnRule().notWhite,
          {
            min: 1,
            max: 32,
            message: this.$t('protMsg.lengthRange') + '[1,32]',
            trigger: 'blur'
          }
        ],
        moduleParentPkgPath: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().notWhite,
          {
            min: 1,
            max: 1024,
            message: this.$t('protMsg.lengthRange') + '[1,1024]',
            trigger: 'blur'
          }
        ],
        moduleName: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().notWhite,
          {
            min: 1,
            max: 32,
            message: this.$t('protMsg.lengthRange') + '[1,32]',
            trigger: 'blur'
          },
          {
            pattern: ProtRegex.PROG_NAMING,
            message: this.$t('protMsg.patternNotMatch') + '【' + this.$t('protMsg.progNaming') + '】',
            trigger: 'blur'
          }
        ]
      }
    },
    next () {
      window.sessionStorage.setItem('genCodeParam', JSON.stringify(this.genCodeParam))
      if (this.activatedStep !== 3) this.activatedStep++
      window.sessionStorage.setItem('activatedStep', this.activatedStep)
    },
    last () {
      window.sessionStorage.setItem('genCodeParam', JSON.stringify(this.genCodeParam))
      if (this.activatedStep !== 0) {
        this.activatedStep--
      }
      window.sessionStorage.setItem('activatedStep', this.activatedStep)
    },
    reGenCode () {
      window.sessionStorage.removeItem('genCodeParam')
      window.sessionStorage.removeItem('activatedStep')
      this.activatedStep = 0
    },
    genCode () {
      this.$refs.genCodeFormRef.validate(
        isValid => {
          if (!isValid) {
            this.$message.error(this.$t('protMsg.paramInvalid'))
          } else {
            window.sessionStorage.setItem('genCodeParam', JSON.stringify(this.genCodeParam))
            window.sessionStorage.removeItem('activatedStep')
            this.assignGenCodeIp()
            CoCoderApi.genCode(this.genCodeIp)
              .then(rd => {
                this.genCodeOp = rd
                this.route2CoCoderView()
              })
          }
        }
      )
    },
    route2CoCoderView () {
      this.$router.push({
        name: 'CoCoderView',
        params: {
          genCodeData: {
            genCodeIp: this.genCodeIp,
            genCodeOp: this.genCodeOp
          }
        }
      })
    },
    assignGenCodeIp () {
      this.genCodeIp = this.genCodeParam
      if (this.genCodeParam.entityModelType === 'EntityJsonModel') {
        this.genCodeIp.entityJsonModel = JSON.parse(this.genCodeParam.entityModel)
      } else {
        this.genCodeIp.entitySqlModel = this.genCodeParam.entityModel
      }
      var frameworks = []
      if (this.genCodeParam.enableSwagger) {
        frameworks.push('SWAGGER')
      }
      if (this.genCodeParam.enableVojivoSeakeer) {
        frameworks.push('VOJIVO_SEAKEER')
        if (this.genCodeParam.enableFeign) {
          frameworks.push('SPRING_CLOUD_FEIGN')
        }
      } else {
        frameworks.push('COMMON_BASE')
      }
      this.genCodeIp.codeGenFrameworks = frameworks
    },
    onClickDbImg (dbType) {
      this.genCodeParam.databaseType = dbType
    },
    showCodeExample (framework, titlePrefix) {
      this.enableCodeExampleDialog = true
      this.codeExample = framework
      this.codeExampleTitle = titlePrefix + this.$t('coCoder.exampleCode')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/CoCoder.scss";

</style>
