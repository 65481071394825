<template>
  <div>
    <div class="balance-div">
      <div style="display: flex">
        <h2>{{ $t('trade.walletVjb') }}</h2>
        <el-button v-if="vjbRewardList.length > 0" @click="toReceiveReward" type="text">
          {{ $t('trade.clickReceiveReward') }}
        </el-button>
      </div>
      <div class="icon-number-div">
        <i class="iconfont vj-icon-menu-soybean"></i>
        <span>{{ this.walletVjb }}</span>
      </div>
    </div>

    <div class="top-up-pkg-div">
      <div style="display: flex">
        <h2>{{ $t('trade.topUpPkg') }}</h2>
        <el-button @click="toTopUp" type="text">{{ $t('trade.customTopUp') }}</el-button>
      </div>
      <div class="card-container">
        <el-card v-for="topUpPkg in gnlTopUpPkgs " :v-model="topUpPkg" :key=topUpPkg.id
                 @click.native="toTopUp(topUpPkg)"
                 class="top-up-pkg-card">
          <h3>{{ topUpPkg.i18nName }}</h3>
          <div class="vjb-ccy-div">
            <div class="icon-number-div">
              <i class="iconfont vj-icon-menu-soybean"></i>
              <span>{{ topUpPkg.txnVjb }}</span>
            </div>
            <div class="ccy-container">
              <div class="ccy-div">
                <span class="cur-ccy-span">{{ topUpPkg.showCcySymbol }}{{ topUpPkg.showCurCcyAmt }}</span>
                <span class="orig-ccy-span">{{ topUpPkg.showOrigCcyAmt }}</span>
              </div>
            </div>
          </div>
          <p>{{ topUpPkg.i18nRemark }}</p>
        </el-card>
      </div>
      <div>
        <el-card v-model="spTopUpPkg"
                 @click.native="toTopUp(spTopUpPkg)"
                 class="sp-top-up-pkg-card">
          <h3>{{ spTopUpPkg.i18nName }}</h3>
          <div class="vjb-ccy-div">
            <div class="icon-number-div">
              <i class="iconfont vj-icon-menu-soybean"></i>
              <span>{{ spTopUpPkg.txnVjb }}</span>
            </div>
            <div class="ccy-container">
              <div class="ccy-div">
                <span class="cur-ccy-span">{{ spTopUpPkg.showCcySymbol }}{{ spTopUpPkg.showCurCcyAmt }}</span>
                <span class="orig-ccy-span">{{ spTopUpPkg.showOrigCcyAmt }}</span>
              </div>
            </div>
          </div>
          <p>{{ spTopUpPkg.i18nRemark }}</p>
        </el-card>
      </div>
    </div>

    <div class="top-up-div">
      <h2>{{ $t('trade.myTopUp') }}</h2>
      <div>
        <!--表格渲染-->
        <el-table
          ref="table"
          :data="topUpList"
          style="width: 100%;"
          :border="true"
        >
          <el-table-column
            prop="createMsec"
            :label="$t('trade.topUpTime')"
            :formatter="msecFormatter"
            sortable="custom"
            resizable
            width="155px"
          />

          <el-table-column prop="txnVjb" :label="$t('trade.vjb')" resizable min-width="100px"/>
          <el-table-column prop="showCurCcyAmt" :label="$t('trade.ccyAmt')" resizable min-width="100px"/>
          <el-table-column prop="status" :label="$t('trade.topUpStatus')" resizable min-width="80px"
                           :formatter="tradeFormatter"/>
          <el-table-column prop="payWay" :label="$t('trade.payWay')" resizable min-width="80px"
                           :formatter="tradeFormatter"/>
          <el-table-column prop="txnWay" :label="$t('trade.txnWay')" resizable min-width="80px"
                           :formatter="tradeFormatter"/>
          <el-table-column prop="txnId" :label="$t('trade.txnId')" resizable min-width="100px"/>
          <el-table-column prop="remark" :label="$t('cmn.remark')" resizable min-width="100px"/>
        </el-table>

        <!--分页组件-->
        <el-pagination
          :total="page.total"
          :page-sizes="[5, 10, 20]"
          :page-size.sync="page.size"
          :current-page.sync="page.current"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 8px;"
          @size-change="handlePageSizeChange"
          @current-change="handleCurrentPageChange"
        />

      </div>
    </div>

    <div>
      <!--      Top Up Dialog Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableTopUpForm"
        :title="$t('trade.topUp')"
        append-to-body
        width="600px"
      >
        <el-form
          ref="topUpForm"
          :inline="true"
          :model="topUpForm"
          :rules="topUpFormRules"
          size="small"
          label-width="200px"
        >
          <el-form-item :label="$t('trade.topUpPkg')" prop="name" v-if="topUpForm.name">
            <el-input v-model="topUpForm.name" disabled style="width: 100px;"/>
          </el-form-item>
          <el-form-item :label="$t('trade.vjb')" prop="txnVjb">
            <el-input v-model="topUpForm.txnVjb"
                      :disabled="topUpForm.name!=null"
                      @input="onInputVjb"
                      style="width: 100px;"/>
          </el-form-item>
          <el-form-item :label="$t('trade.origPrice')" prop="origCcy">
            <el-input v-model="topUpForm.showOrigCcyAmt" disabled style="width: 100px;"/>
          </el-form-item>
          <el-form-item :label="$t('trade.curPrice')" prop="curCcyAmt">
            <el-input v-model="topUpForm.showCurCcyAmt" disabled style="width: 100px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.remark')" prop="remark">
            <el-input v-model="topUpForm.remark" style="width: 100px;"/>
          </el-form-item>
          <el-form-item :label="$t('trade.payWay')" prop="payWay">
            <el-radio-group v-model="topUpForm.payWay">
              <el-radio label="WECHAT_PAY">
                <img class="pay-way-img" src="@/assets/img/trade/WechatPay.jpeg" alt="WechatPay">
                {{ $t('trade.WECHAT_PAY') }}
              </el-radio>
              <el-radio label="ALI_PAY">
                <img class="pay-way-img" src="@/assets/img/trade/AliPay.jpeg" alt="AliPay">
                {{ $t('trade.ALI_PAY') }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <span class="ccy-amt">{{ topUpForm.showCcySymbol }}
            {{ topUpForm.showCurCcyAmt }}</span>
          <el-button type="danger" @click="doTopUp">{{ $t('trade.orderPay') }}</el-button>
        </div>
      </el-dialog>

      <!-- Pay Dialog   -->
      <el-dialog
        :close-on-click-modal="false"
        v-if="payInfo.enablePayQrCodeDialog"
        :visible.sync="payInfo.enablePayQrCodeDialog"
        :title="$t('trade.payQrCode')"
        append-to-body
        width="500px"
      >
        <el-card class="pay-qr-code-card">
          <img class="pay-qr-code-img" :src="payInfo.payQrCode" alt="PayQrCode">
          <p>{{ $t('trade.plsUse') }}{{ $t('trade.' + payInfo.payWay) }}{{ $t('trade.scanToPay') }}</p>
        </el-card>
      </el-dialog>
    </div>

    <div>
      <!--      Reward Dialog-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableRewordDialog"
        :title="$t('trade.reward')"
        append-to-body
        width="600px"
      >
        <!--表格渲染-->
        <el-table
          ref="vjbRewardTable"
          :data="vjbRewardList"
          style="width: 100%;"
          :border="true"
        >
          <el-table-column prop="id" label="ID" resizable min-width="80px"/>
          <el-table-column prop="vjb" :label="$t('trade.vjb')" resizable min-width="60px"/>
          <el-table-column prop="item" :label="$t('trade.vjbRewardItem')" resizable min-width="80px"
                           :formatter="tradeFormatter"/>
          <el-table-column
            prop="createMsec"
            :label="$t('cmn.createTime')"
            :formatter="msecFormatter"
            sortable="custom"
            resizable
            width="155px"
          />
          <el-table-column
            :label="$t('cmn.opn')"
            width="100px"
            align="center"
            fixed="right"
          >
            <template v-slot="scope">
              <el-button
                slot="reference"
                type="text"
                @click="doReceiveReward(scope.row)"
              >{{ $t('trade.receive') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-dialog>
    </div>

  </div>
</template>

<script>

import VjbTopUpApi from '@/api/tradecenter/VjbTopUpApi'
import TimeUtil from '@/util/TimeUtil'
import VjbWalletApi from '@/api/tradecenter/VjbWalletApi'
import ProtRule, { intNumRangeValidator } from '@/constant/ProtRule'
import VjbRewardApi from '@/api/tradecenter/VjbRewardApi'
import { STR_ID_MAX_LENGTH } from '@/constant/CmnBizConst'
import CcyManager from '@/util/CcyManager'
import WechatPayApi from '@/api/tradecenter/WechatPayApi'
import AliPayApi from '@/api/tradecenter/AliPayApi'

export default {
  name: 'VjbJar',
  data () {
    return {
      walletVjb: null,
      enableTopUpForm: false,
      topUpForm: {
        name: null,
        payWay: null,
        curCcyAmt: null,
        origCcyAmt: null,
        showCurCcyAmt: null,
        showOrigCcyAmt: null,
        showCcySymbol: null,
        topPkgId: null,
        txnVjb: null
      },
      topUpFormRules: {},
      gnlTopUpPkgs: [],
      spTopUpPkg: {},
      payInfo: {
        enablePayQrCodeDialog: false,
        payQrCode: null,
        payWay: null,
        payStatusTimer: null
      },
      page: {
        total: 0,
        current: 1,
        size: 5
      },
      orderBys: [
        {
          key: 'createMsec',
          asc: false
        }
      ],
      topUpList: [],
      vjbRewardList: [],
      enableRewordDialog: false
    }
  },
  created () {
    this.initFormRules()
    this.findOrInitWallet()
    this.initTopUpPkgs()
    this.initVjbReward()
    this.syncTopUpStatus()
    this.doSrch()
  },
  beforeDestroy () {
    if (this.payInfo.payStatusTimer) {
      clearInterval(this.payInfo.payStatusTimer)
      this.payInfo.payStatusTimer = null
    }
  },
  methods: {
    STR_ID_MAX_LENGTH () {
      return STR_ID_MAX_LENGTH
    },
    initFormRules () {
      this.topUpFormRules = {
        ...ProtRule.rankValueRemark(),
        txnVjb: [
          {
            validator: intNumRangeValidator,
            min: 1,
            max: 10000,
            required: true,
            trigger: 'blur'
          }
        ],
        payWay: [
          ProtRule.cmnRule().required
        ]
      }
    },
    findOrInitWallet () {
      VjbWalletApi.findOrInit()
        .then(vjbWalletOp => {
          this.walletVjb = vjbWalletOp.walletVjb
        })
    },
    async initTopUpPkgs () {
      const topUpPkgs = await VjbTopUpApi.findTopUpPkgs()
      for (const topUpPkg of topUpPkgs) {
        if (topUpPkg.isSp) {
          this.spTopUpPkg = topUpPkg
        } else {
          this.gnlTopUpPkgs.push(topUpPkg)
        }
      }
    },
    initVjbReward () {
      const ip = {
        pageIp: {
          current: 1,
          size: 10
        },
        orderBys: [
          {
            key: 'createMsec',
            asc: true
          }
        ],
        isReceived: 0
      }
      VjbRewardApi.findByPage(ip).then(rd => {
        if (rd) {
          this.vjbRewardList = rd.data
        }
      })
    },
    toReceiveReward () {
      this.enableRewordDialog = true
    },
    doReceiveReward (vjbReward) {
      const ip = {
        id: vjbReward.id,
        vjb: vjbReward.vjb,
        txnId: vjbReward.txnId
      }
      VjbRewardApi.receive(ip).then(rd => {
        if (rd) {
          const newVjbRewardList = []
          this.vjbRewardList.forEach(item => {
            if (item.id !== vjbReward.id) {
              newVjbRewardList.push(item)
            }
          })
          this.vjbRewardList = newVjbRewardList
          if (newVjbRewardList.length < 1) {
            this.enableRewordDialog = false
          }
        }
      })
    },
    toTopUp (topUpPkg) {
      this.enableTopUpForm = true
      if (topUpPkg) {
        this.topUpForm.name = topUpPkg.name
        this.topUpForm.curCcyAmt = topUpPkg.curCcyAmt
        this.topUpForm.origCcyAmt = topUpPkg.origCcyAmt
        this.topUpForm.showCurCcyAmt = topUpPkg.showCurCcyAmt
        this.topUpForm.showOrigCcyAmt = topUpPkg.showOrigCcyAmt
        this.topUpForm.showCcySymbol = CcyManager.showCcySymbol()
        this.topUpForm.topPkgId = topUpPkg.id
        this.topUpForm.txnVjb = topUpPkg.txnVjb
        this.topUpForm.payWay = null
      }
    },
    onInputVjb () {
      this.topUpForm.curCcyAmt = this.topUpForm.txnVjb * 30
      this.topUpForm.origCcyAmt = this.topUpForm.txnVjb * 30
      if (this.topUpForm.curCcyAmt) {
        this.topUpForm.showCurCcyAmt = CcyManager.showCcyAmt(this.topUpForm.curCcyAmt)
        this.topUpForm.showOrigCcyAmt = CcyManager.showCcyAmt(this.topUpForm.origCcyAmt)
      }
    },
    doTopUp () {
      this.$refs.topUpForm.validate(isValid => {
        if (!isValid) {
          return
        }
        this.topUpInit()
          .then(initResult => {
            if (initResult) {
              this.topUpPay(initResult)
            }
          })
      })
    },
    topUpInit () {
      const ip = {
        ...this.topUpForm
      }
      return VjbTopUpApi.init(ip)
    },
    topUpPay (payInitOp) {
      if (payInitOp.payWay === 'WECHAT_PAY') {
        this.wechatPay(payInitOp)
      } else {
        this.aliPay(payInitOp)
      }
    },
    wechatPay (payInitOp) {
      const ip = {
        txnId: payInitOp.txnId,
        payBiz: 'PUR_VJB',
        ccyAmt: payInitOp.ccyAmt
      }
      WechatPayApi.genPayQrCode(ip).then(op => {
        if (op) {
          this.payInfo.payWay = 'WECHAT_PAY'
          this.payInfo.payQrCode = op
          this.payInfo.enablePayQrCodeDialog = true
          this.payInfo.payStatusTimer = setInterval(() => {
            this.findTopUpCardStatus(payInitOp)
          }, 10000)
        }
      })
    },
    aliPay (payInitOp) {
      const ip = {
        txnId: payInitOp.txnId,
        payBiz: 'PUR_VJB',
        ccyAmt: payInitOp.ccyAmt
      }
      AliPayApi.genPayQrCode(ip).then(op => {
        if (op) {
          this.payInfo.payWay = 'ALI_PAY'
          this.payInfo.payQrCode = op
          this.payInfo.enablePayQrCodeDialog = true
          this.payInfo.payStatusTimer = setInterval(() => {
            this.findTopUpCardStatus(payInitOp)
          }, 10000)
        }
      })
    },
    findTopUpCardStatus (payInitOp) {
      const ip = {
        id: payInitOp.id,
        payWay: payInitOp.payWay,
        txnId: payInitOp.txnId
      }
      VjbTopUpApi.findOrSyncStatus(ip).then(op => {
        if (op === 'PAID') {
          clearInterval(this.payInfo.payStatusTimer)
          this.payInfo.payStatusTimer = null
          this.payInfo.enablePayQrCodeDialog = false
          this.topUpComplete(payInitOp)
        }
      })
    },

    topUpComplete (payInitOp) {
      VjbTopUpApi.complete(payInitOp).then(op => {
        if (op) {
          this.enableTopUpForm = false
          this.$message.success(this.$t('trade.topUpSuccess'))
          this.findOrInitWallet()
        }
      })
    },
    syncTopUpStatus () {
      VjbTopUpApi.syncStatus()
    },
    async doSrch () {
      const ip = {
        orderBys: this.orderBys,
        pageIp: {
          current: this.page.current,
          size: this.page.size
        }
      }
      const pageOp = await VjbTopUpApi.findByPage(ip)
      this.page.total = pageOp.total
      this.topUpList = pageOp.data
    },

    handleSortChange (data) {
      const orderBy = {}
      orderBy.key = data.prop
      orderBy.asc = data.order === 'ascending'
      this.orderBys = [
        { ...orderBy }
      ]
      this.doSrch()
    },

    handlePageSizeChange (pageSize) {
      this.page.size = pageSize
      this.doSrch()
    },
    handleCurrentPageChange (currentPage) {
      this.page.current = currentPage
      this.doSrch()
    },

    msecFormatter (row, column, cellValue, index) {
      if (cellValue) {
        return TimeUtil.convertMsecToDateTimeStr(cellValue)
      }
      return null
    },
    tradeFormatter (row, column, cellValue, index) {
      return this.$t('trade.' + cellValue)
    }
  }
}
</script>

<style scoped>

</style>
