import resultHttpTool from '@/http/ResultHttpTool'
import * as QiNiuApi from 'qiniu-js'
import { Message } from 'element-ui'
import axios from 'axios'

const API_PREFIX = '/api/usr/fileService'

export function genUploadToken (ip) {
  return resultHttpTool.post(API_PREFIX + '/genUploadToken', ip)
}

export function genDownloadUrl (ip) {
  return resultHttpTool.post(API_PREFIX + '/genDownloadUrl', ip)
}

export function uploadFileWithObs (file, carrierType, observer) {
  const ip = {
    fileName: file.name,
    carrierType: carrierType
  }
  genUploadToken(ip).then(
    resultData => {
      const uploadToken = resultData.uploadToken
      const fullFilePath = resultData.fullFilePath
      const putExtra = {
        fname: '',
        params: {},
        mimeType: null
      }
      const config = {
        useCdnDomain: true,
        region: null
      }
      QiNiuApi.upload(
        file,
        fullFilePath,
        uploadToken,
        putExtra,
        config
      ).subscribe(observer)
    }
  )
}

export function uploadImg (file, carrierType, onComplete) {
  const ip = {
    fileName: file.name,
    carrierType: carrierType
  }
  genUploadToken(ip).then(
    resultData => {
      const uploadToken = resultData.uploadToken
      const fullFilePath = resultData.fullFilePath
      /*  file: blob对象，上传的文件;
                              key: string, 文件资源名
                              token: string, 上传验证信息，前端通过接口请求后端获得
                              putExtra: object,
                              config: object
      */
      const putExtra = {
        fname: '', // 文件原文件名
        params: {}, // 用来放置自定义变量
        mimeType: null
        // mimeType: ["image/png", "image/jpeg", "image/jpg", "image/gif"] //用来限定上传文件类型，指定null时自动判断文件类型。
      }
      const config = {
        useCdnDomain: true, // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
        region: null // 选择上传域名区域；当为 null 或 undefined 时，自动分析上传域名区域
      }
      var observable = QiNiuApi.upload(
        file,
        fullFilePath,
        uploadToken,
        putExtra,
        config
      )
      observable.subscribe({
        next (res) {
          // next: 接收上传进度信息，res 参数是一个带有 total 字段的 object，包含loaded、total、percent三个属性，提供上传进度信息。
          console.log('next', res)
        },
        // eslint-disable-next-line handle-callback-err
        error (err) {
          // 上传错误后触发，当不是 xhr 请求错误时，会把当前错误产生原因直接抛出，诸如 JSON 解析异常等；当产生 xhr 请求错误时，参数 err 为一个包含 code、message、isRequestError 三个属性的 object
          //   console.log(err);
          Message.error('上传失败!')
        },
        complete: onComplete
      })
    }
  )
}

export function download (downloadUrl, fileName) {
  return axios.get(downloadUrl, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json; application/octet-stream'
    }
  }).then(resp => {
    const blob = new Blob([resp.data])
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
    // 创建下载的链接
    downloadElement.href = href
    // 下载后文件名
    downloadElement.download = fileName
    document.body.appendChild(downloadElement)
    // 点击下载
    downloadElement.click()
    // 下载完成移除元素
    document.body.removeChild(downloadElement)
    // 释放掉blob对象
    window.URL.revokeObjectURL(href)
  }, () => {
    console.error('[Download failed]')
  })
}

export default {
  genUploadToken,
  genDownloadUrl,
  uploadImg,
  download,
  uploadFileWithObs
}
