import resultHttpTool from '@/http/ResultHttpTool'
import Config from '@/SysConfig'

const API_PREFIX = '/api/usr/tool'

export default {
  findByPage,
  findByUnid,
  findDetail
}

export function findByPage (findByPageIp) {
  return resultHttpTool.post(API_PREFIX + '/findByPage', formatIp(findByPageIp))
    .catch(() => {
      if (Config.enableMockRespResultData) {
        return mockFindByPageResultData()
      }
    })
}

export function findByUnid (toolUnid) {
  return resultHttpTool.get(API_PREFIX + '/findByUnid?unid=' + toolUnid)
    .catch(() => {
      if (Config.enableMockRespResultData) {
        return mockFindByUnidResultData()
      }
    })
}

export function findDetail (resPkgId) {
  return resultHttpTool.get(API_PREFIX + '/findDetail?id=' + resPkgId)
}

function formatIp (findByPageIp) {
  if (findByPageIp === null) {
    findByPageIp = {
      toolCategoryId: null,
      srchIp: null,
      pageIp: {
        current: 1,
        size: 9,
        orders: []
      }
    }
  }
  return findByPageIp
}

function mockFindByUnidResultData () {
  return {
    name: 'JsonOne',
    isVjvTool: 1,
    path: '/tool/jsonOne',
    unid: 'JsonOne',
    logoUrl: 'vojivo-local@'
  }
}

function mockFindByPageResultData () {
  return {
    total: 10,
    data: [
      {
        id: 1,
        createMsec: 1673602568218,
        updateMsec: 1717854059105,
        rankValue: 10,
        remark: 'https://bpic.51yuansu.com/pic2/cover/00/30/40/58107ec100ee5_610.jpg',
        vjvPlc: 'ONLINE',
        unid: 'JsonOne',
        name: 'JsonOne',
        nameI18nRes: [
          {
            langId: 'zh',
            value: 'JsonOne'
          },
          {
            langId: 'en',
            value: 'JsonOne'
          }
        ],
        profile: 'Json One & Top One! 只一次，就会占据内心TOP ONE的JSON工具。功能丰富、使用便捷。',
        profileI18nRes: [
          {
            langId: 'zh',
            value: 'Json One & Top One! 只一次，就会占据内心TOP ONE的JSON工具。功能丰富、使用便捷。'
          },
          {
            langId: 'en',
            value: 'Json One & Top One! The best JSON tool.'
          }
        ],
        isVjvTool: 1,
        uri: 'https://www.vojivo.xyz/tool/jsonOne',
        logoUri: 'vojivo-local@',
        logoUrl: 'vojivo-local@',
        path: '/tool/jsonOne',
        onlineMsec: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        mainCategList: [
          {
            id: 1,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 10,
            remark: '',
            name: '软件技术',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              },
              {
                langId: 'en',
                value: 'Software Tech'
              }
            ],
            profile: '软件技术',
            profileI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          },
          {
            id: 4,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 40,
            remark: '',
            name: '办公辅助',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '办公辅助'
              },
              {
                langId: 'en',
                value: 'Office Aid'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          }
        ],
        beFav: null
      },
      {
        id: 2,
        createMsec: 1685436265762,
        updateMsec: 1717854059105,
        rankValue: 20,
        remark: '',
        vjvPlc: 'ONLINE',
        unid: 'CoCoder',
        name: 'CoCoder',
        nameI18nRes: [
          {
            langId: 'zh',
            value: 'CoCoder'
          },
          {
            langId: 'en',
            value: 'CoCoder'
          }
        ],
        profile: 'Java Springboot Web项目代码生成利器，可解析JSON或SQL语句，快速生成CRUD代码。',
        profileI18nRes: [
          {
            langId: 'zh',
            value: 'Java Springboot Web项目代码生成利器，可解析JSON或SQL语句，快速生成CRUD代码。'
          },
          {
            langId: 'en',
            value: 'An efficient code generation tool which can parse JSON or SQL to generate CRUD code of the Java Springboot Web Project.'
          }
        ],
        isVjvTool: 1,
        uri: 'https://www.vojivo.xyz/tool/coCoder',
        logoUri: 'vojivo-local@',
        logoUrl: 'vojivo-local@',
        path: '/tool/coCoder',
        onlineMsec: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        mainCategList: [
          {
            id: 1,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 10,
            remark: '',
            name: '软件技术',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              },
              {
                langId: 'en',
                value: 'Software Tech'
              }
            ],
            profile: '软件技术',
            profileI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          },
          {
            id: 4,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 40,
            remark: '',
            name: '办公辅助',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '办公辅助'
              },
              {
                langId: 'en',
                value: 'Office Aid'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          }
        ],
        beFav: null
      },
      {
        id: 3,
        createMsec: 1685375668865,
        updateMsec: 1717854059105,
        rankValue: 30,
        remark: '',
        vjvPlc: 'ONLINE',
        unid: 'Timestamp',
        name: '时间戳',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '时间戳'
          },
          {
            langId: 'en',
            value: 'Timestamp'
          }
        ],
        profile: '时间戳便捷的时间戳工具，可批量进行时间和时间戳的互相转换。',
        profileI18nRes: [
          {
            langId: 'zh',
            value: '时间戳便捷的时间戳工具，可批量进行时间和时间戳的互相转换。'
          },
          {
            langId: 'en',
            value: 'A convenient timestamp tool supports batch conversion between datetime and timestamp.'
          }
        ],
        isVjvTool: 1,
        uri: 'https://www.vojivo.xyz/tool/timestamp',
        logoUri: 'vojivo-local@',
        logoUrl: 'vojivo-local@',
        path: '/tool/timestamp',
        onlineMsec: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        mainCategList: [
          {
            id: 1,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 10,
            remark: '',
            name: '软件技术',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              },
              {
                langId: 'en',
                value: 'Software Tech'
              }
            ],
            profile: '软件技术',
            profileI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          },
          {
            id: 4,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 40,
            remark: '',
            name: '办公辅助',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '办公辅助'
              },
              {
                langId: 'en',
                value: 'Office Aid'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          }
        ],
        beFav: null
      },
      {
        id: 4,
        createMsec: 1685415103073,
        updateMsec: 1717854059105,
        rankValue: 40,
        remark: 'Base64编解码、URL编解码、HEX编解码，GBK,UNICODE,UTF-8,UTF-16,UTF-32,大端(BE)，小端(LE)',
        vjvPlc: 'ONLINE',
        unid: 'EnDecoder',
        name: '编解码器',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '编解码器'
          },
          {
            langId: 'en',
            value: 'EnDecoder'
          }
        ],
        profile: '编码和解码工具，支持BASE64、URL、HEX、字符等编解码。',
        profileI18nRes: [
          {
            langId: 'zh',
            value: '编码和解码工具，支持BASE64、URL、HEX、字符等编解码。'
          },
          {
            langId: 'en',
            value: 'Encode&Decode Tool'
          }
        ],
        isVjvTool: 1,
        uri: 'https://www.vojivo.xyz/tool/enDecoder',
        logoUri: 'vojivo-local@',
        logoUrl: 'vojivo-local@',
        path: '/tool/enDecoder',
        onlineMsec: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        mainCategList: [
          {
            id: 1,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 10,
            remark: '',
            name: '软件技术',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              },
              {
                langId: 'en',
                value: 'Software Tech'
              }
            ],
            profile: '软件技术',
            profileI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          },
          {
            id: 4,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 40,
            remark: '',
            name: '办公辅助',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '办公辅助'
              },
              {
                langId: 'en',
                value: 'Office Aid'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          }
        ],
        beFav: null
      },
      {
        id: 5,
        createMsec: 1685418271216,
        updateMsec: 1717854059105,
        rankValue: 50,
        remark: '',
        vjvPlc: 'ONLINE',
        unid: 'Hash',
        name: '哈希',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '哈希'
          },
          {
            langId: 'en',
            value: 'Hash'
          }
        ],
        profile: '支持MD5、SHA256、SHA1、SHA512、CRC32等哈希算法。',
        profileI18nRes: [
          {
            langId: 'zh',
            value: '支持MD5、SHA256、SHA1、SHA512、CRC32等哈希算法。'
          },
          {
            langId: 'en',
            value: 'Supports common hashing algorithms such as MD5, SHA256,SHA1, SHA512, CRC32.'
          }
        ],
        isVjvTool: 1,
        uri: 'https://www.vojivo.xyz/tool/hash',
        logoUri: 'vojivo-local@',
        logoUrl: 'vojivo-local@',
        path: '/tool/hash',
        onlineMsec: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        mainCategList: [
          {
            id: 1,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 10,
            remark: '',
            name: '软件技术',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              },
              {
                langId: 'en',
                value: 'Software Tech'
              }
            ],
            profile: '软件技术',
            profileI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          },
          {
            id: 4,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 40,
            remark: '',
            name: '办公辅助',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '办公辅助'
              },
              {
                langId: 'en',
                value: 'Office Aid'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          }
        ],
        beFav: null
      },
      {
        id: 6,
        createMsec: 1685427220367,
        updateMsec: 1717854059105,
        rankValue: 60,
        remark: 'https://img-blog.csdnimg.cn/img_convert/7b5faf98445ef7aed8887b450c5320ae.png ',
        vjvPlc: 'ONLINE',
        unid: 'GeoLonLat',
        name: '经纬度',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '经纬度'
          },
          {
            langId: 'en',
            value: 'GeoLonLat'
          }
        ],
        profile: '方便快捷的地理经纬度距离计算工具，支持从文本、图片中识别和提取可能的经纬度坐标。',
        profileI18nRes: [
          {
            langId: 'zh',
            value: '方便快捷的地理经纬度距离计算工具，支持从文本、图片中识别和提取可能的经纬度坐标。'
          },
          {
            langId: 'en',
            value: 'GeoLonLat is a convenient tool for calculating the distance between two location points which can the identify and extract coordinates from text and pictures.'
          }
        ],
        isVjvTool: 1,
        uri: 'https://www.vojivo.xyz/tool/geoLonLat',
        logoUri: 'vojivo-local@',
        logoUrl: 'vojivo-local@',
        path: '/tool/geoLonLat',
        onlineMsec: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        mainCategList: [
          {
            id: 1,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 10,
            remark: '',
            name: '软件技术',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              },
              {
                langId: 'en',
                value: 'Software Tech'
              }
            ],
            profile: '软件技术',
            profileI18nRes: [
              {
                langId: 'zh',
                value: '软件技术'
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          },
          {
            id: 4,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 40,
            remark: '',
            name: '办公辅助',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '办公辅助'
              },
              {
                langId: 'en',
                value: 'Office Aid'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          }
        ],
        beFav: null
      },
      {
        id: 7,
        createMsec: 1685428262527,
        updateMsec: 1717854104332,
        rankValue: 70,
        remark: '',
        vjvPlc: 'ONLINE',
        unid: 'TimingLendar',
        name: '时光历',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '时光历'
          },
          {
            langId: 'en',
            value: 'TimingLendar'
          }
        ],
        profile: '一本可以撕的日历，记录每一天的时光！',
        profileI18nRes: [
          {
            langId: 'zh',
            value: '一本可以撕的日历，记录每一天的时光！'
          },
          {
            langId: 'en',
            value: 'A calendar that can be torn to record every day!'
          }
        ],
        isVjvTool: 1,
        uri: 'https://www.vojivo.xyz/tool/timingLendar',
        logoUri: 'vojivo-local@',
        logoUrl: 'vojivo-local@',
        path: '/tool/timingLendar',
        onlineMsec: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        mainCategList: [
          {
            id: 5,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 50,
            remark: '',
            name: '日常生活',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '日常生活'
              },
              {
                langId: 'en',
                value: 'Daily Life'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          },
          {
            id: 6,
            createMsec: 1715490840000,
            updateMsec: 1717466033830,
            rankValue: 1000,
            remark: '',
            name: '其他工具',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '其他工具'
              },
              {
                langId: 'en',
                value: 'Others'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          }
        ],
        beFav: null
      },
      {
        id: 8,
        createMsec: 1692674634982,
        updateMsec: 1717854309619,
        rankValue: 80,
        remark: '',
        vjvPlc: 'ONLINE',
        unid: 'UnitConverter',
        name: '单位转换器',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '单位转换器'
          },
          {
            langId: 'en',
            value: 'UnitConverter'
          }
        ],
        profile: '支持时间、长度、面积、体积、质量、存储、温度、速度、角度等各种单位的转换。',
        profileI18nRes: [
          {
            langId: 'zh',
            value: '支持时间、长度、面积、体积、质量、存储、温度、速度、角度等各种单位的转换。'
          },
          {
            langId: 'en',
            value: 'Support time, length, area, volume, mass, storage, temperature, speed, angle and other units of conversion.'
          }
        ],
        isVjvTool: 1,
        uri: 'https://www.vojivo.xyz/tool/unitConverter',
        logoUri: 'vojivo-local@',
        logoUrl: 'vojivo-local@',
        path: '/tool/unitConverter',
        onlineMsec: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        mainCategList: [
          {
            id: 3,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 30,
            remark: '',
            name: '教育学习',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '教育学习'
              },
              {
                langId: 'en',
                value: 'Education'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          },
          {
            id: 5,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 50,
            remark: '',
            name: '日常生活',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '日常生活'
              },
              {
                langId: 'en',
                value: 'Daily Life'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          }
        ],
        beFav: null
      },
      {
        id: 9,
        createMsec: 1685436509458,
        updateMsec: 1717854309619,
        rankValue: 90,
        remark: '',
        vjvPlc: 'ONLINE',
        unid: 'LangCorner',
        name: '语言角',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '语言角'
          },
          {
            langId: 'en',
            value: 'Language Corner'
          }
        ],
        profile: '基于具体沟通场景的语言角。',
        profileI18nRes: [
          {
            langId: 'zh',
            value: '基于具体沟通场景的语言角。'
          },
          {
            langId: 'en',
            value: 'A language corner based on specific communication scenarios.'
          }
        ],
        isVjvTool: 1,
        uri: 'https://www.vojivo.xyz/tool/langCorner',
        logoUri: 'vojivo-local@',
        logoUrl: 'vojivo-local@',
        path: '/tool/langCorner',
        onlineMsec: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        mainCategList: [
          {
            id: 3,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 30,
            remark: '',
            name: '教育学习',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '教育学习'
              },
              {
                langId: 'en',
                value: 'Education'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          },
          {
            id: 5,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 50,
            remark: '',
            name: '日常生活',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '日常生活'
              },
              {
                langId: 'en',
                value: 'Daily Life'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          }
        ],
        beFav: null
      },
      {
        id: 10,
        createMsec: 1685436999210,
        updateMsec: 1717854104332,
        rankValue: 100,
        remark: '',
        vjvPlc: 'ONLINE',
        unid: 'Emotto',
        name: '依莫',
        nameI18nRes: [
          {
            langId: 'zh',
            value: '依莫'
          },
          {
            langId: 'en',
            value: 'Emotto'
          }
        ],
        profile: '忘记了是什么时候，邂逅了那么简单的一句话，从此就打开了一扇窗，看见了一片天空！',
        profileI18nRes: [
          {
            langId: 'zh',
            value: '忘记了是什么时候，邂逅了那么简单的一句话，从此就打开了一扇窗，看见了一片天空！'
          },
          {
            langId: 'en',
            value: 'Forget when I met such a simple sentence, since then I opened a window and saw a piece of sky!'
          }
        ],
        isVjvTool: 1,
        uri: 'https://www.vojivo.xyz/tool/emotto',
        logoUri: 'vojivo-local@',
        logoUrl: 'vojivo-local@',
        path: '/tool/emotto',
        onlineMsec: null,
        opAdmAcctId: 1,
        opAdmAcctUnid: 'vojivo',
        mainCategList: [
          {
            id: 5,
            createMsec: 1715490840000,
            updateMsec: 1715490840000,
            rankValue: 50,
            remark: '',
            name: '日常生活',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '日常生活'
              },
              {
                langId: 'en',
                value: 'Daily Life'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          },
          {
            id: 6,
            createMsec: 1715490840000,
            updateMsec: 1717466033830,
            rankValue: 1000,
            remark: '',
            name: '其他工具',
            nameI18nRes: [
              {
                langId: 'zh',
                value: '其他工具'
              },
              {
                langId: 'en',
                value: 'Others'
              }
            ],
            profile: '',
            profileI18nRes: [
              {
                langId: 'zh',
                value: ''
              },
              {
                langId: 'en',
                value: ''
              }
            ],
            parentId: null,
            opAdmAcctId: 1,
            opAdmAcctUnid: 'vojivo'
          }
        ],
        beFav: null
      }
    ],
    pageIp: {
      current: 1,
      size: 10,
      orderBys: []
    }
  }
}
