<template>
  <el-container>
    <el-aside width="150px">
      <div>
        <tool-shower tool-unid="CoCoder"></tool-shower>
      </div>
      <el-menu :default-active="this.$route.path" class="el-menu-vertical-demo" router>
        <el-menu-item index="/tool/coCoder/genCode">
          <i class="iconfont vj-icon-code_Generator"></i>
          <span slot="title">{{ this.$t('coCoder.genCode') }}</span>
        </el-menu-item>
        <el-menu-item index="/tool/coCoder/coCoderProject">
          <i class="iconfont vj-icon-my-projects"></i>
          <span slot="title">{{ this.$t('coCoder.myProject') }}</span>
        </el-menu-item>
        <el-menu-item index="/tool/coCoder/coCoderView">
          <i class="iconfont vj-icon-xiangmudaping"></i>
          <span slot="title">{{ this.$t('coCoder.projectView') }}</span>
        </el-menu-item>
        <el-menu-item index="/tool/coCoder/coCoderExample">
          <i class="iconfont vj-icon-example-project"></i>
          <span slot="title">{{ this.$t('coCoder.exampleProject') }}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import ToolShower from '@/components/ToolShower.vue'

export default {
  name: 'CoCoder',
  components: { ToolShower }
}
</script>

<style lang="scss" scoped>
</style>
