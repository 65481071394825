/**
 * 消息类型
 */
export const MSG_LEVEL_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error'
}

/**
 * 通知级别
 */
export const NOTICE_LEVEL_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error'
}
