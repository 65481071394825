<template>
  <div>
    <!--表格渲染-->
    <el-table
      ref="table"
      :data="tableData"
      style="width: 100%;"
      :border="true"
    >
      <el-table-column prop="txnId" :label="$t('trade.txnId')" resizable min-width="100px"/>
      <el-table-column prop="txnBiz" :label="$t('trade.txnBiz')" resizable min-width="100px"
                       :formatter="txnBizFormatter"/>
      <el-table-column prop="txnVjb" :label="$t('trade.vjb')" resizable min-width="100px" :formatter="txnVjbFormatter"/>
      <el-table-column prop="showTxnCcyAmt" :label="$t('trade.txnCcyAmt')" resizable min-width="100px"
                       :formatter="txnCcyAmtFormatter"/>
      <el-table-column
        prop="createMsec"
        :label="$t('cmn.createTime')"
        :formatter="msecFormatter"
        sortable="custom"
        resizable
        width="155px"
      />
      <el-table-column
        prop="updateMsec"
        :label="$t('cmn.updateTime')"
        :formatter="msecFormatter"
        sortable="custom"
        resizable
        width="155px"
      />
      <el-table-column prop="remark" :label="$t('cmn.remark')" resizable min-width="100px"/>
    </el-table>

    <!--分页组件-->
    <el-pagination
      :total="page.total"
      :page-sizes="[5, 10, 20]"
      :page-size.sync="page.size"
      :current-page.sync="page.current"
      layout="total, sizes, prev, pager, next, jumper"
      style="margin-top: 8px;"
      @size-change="handlePageSizeChange"
      @current-change="handleCurrentPageChange"
    />

  </div>
</template>

<script>

import TimeUtil from '@/util/TimeUtil'
import VjbBillApi from '@/api/tradecenter/VjbBillApi'

export default {
  name: 'MyBill',
  data () {
    return {
      page: {
        total: 0,
        current: 1,
        size: 5
      },
      orderBys: [
        {
          key: 'createMsec',
          asc: false
        }
      ],
      tableData: []
    }
  },
  created () {
    this.doSrch()
  },
  methods: {
    async doSrch () {
      const ip = {
        orderBys: this.orderBys,
        pageIp: {
          current: this.page.current,
          size: this.page.size
        }
      }
      const pageOp = await VjbBillApi.findByPage(ip)
      this.page.total = pageOp.total
      this.tableData = pageOp.data
    },

    handleSortChange (data) {
      const orderBy = {}
      orderBy.key = data.prop
      orderBy.asc = data.order === 'ascending'
      this.orderBys = [
        { ...orderBy }
      ]
      this.doSrch()
    },

    handlePageSizeChange (pageSize) {
      this.page.size = pageSize
      this.doSrch()
    },
    handleCurrentPageChange (currentPage) {
      this.page.current = currentPage
      this.doSrch()
    },

    msecFormatter (row, column, cellValue, index) {
      if (cellValue) {
        return TimeUtil.convertMsecToDateTimeStr(cellValue)
      }
      return null
    },
    txnVjbFormatter (row, column, cellValue, index) {
      if (cellValue === null || cellValue === undefined || cellValue === 0 || cellValue === '0') {
        return '---'
      }
      if (row.isIn) {
        return '+' + cellValue
      } else {
        return '-' + cellValue
      }
    },
    txnCcyAmtFormatter (row, column, cellValue, index) {
      if (cellValue === null || cellValue === undefined || cellValue === 0 || cellValue === '0.00') {
        return '---'
      }
      return '-' + cellValue
    },
    txnBizFormatter (row, column, cellValue, index) {
      return this.$t('trade.' + cellValue)
    }
  }
}
</script>

<style scoped>

</style>
