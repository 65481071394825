<template>
  <hash-cmpt hashAlg="SHA1"></hash-cmpt>
</template>

<script>
import HashCmpt from './HashCmpt.vue'

export default {
  name: 'SHA1',
  components: { HashCmpt }
}
</script>
