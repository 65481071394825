<template>
  <div class="register-wrapper">
    <div>
      <I18nBtn class="i18n-btn"></I18nBtn>
    </div>
    <div class="register-box">
      <div class="logo">
        <img src="../../assets/img/RegLogo.png" alt="">
      </div>
      <h2>{{ $t('register.vojivo') }}</h2>
      <el-form ref="form" :model="regFormData" label-width="0" :rules="formRules">
        <el-form-item prop="unid">
          <el-input v-model="regFormData.unid" :placeholder="$t('register.inputVojiUnid')"
                    prefix-icon="iconfont vj-icon-user-solid"></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="regFormData.email" :placeholder="$t('register.inputEmail')"
                    prefix-icon="iconfont vj-icon-email-solid"></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input v-model="regFormData.pwd" :placeholder="$t('register.inputPwd')" type="password"
                    prefix-icon="iconfont vj-icon-pwd"></el-input>
        </el-form-item>
        <el-form-item prop="confirmPwd">
          <el-input v-model="regFormData.confirmPwd" :placeholder="$t('register.inputConfirmPwd')"
                    type="password" prefix-icon="iconfont vj-icon-pwd"></el-input>
        </el-form-item>
        <el-form-item prop="captchaCode">
          <el-input
            v-model="regFormData.captchaCode"
            :placeholder="$t('register.inputCaptcha')"
            auto-complete="off"
            style="width: 63%"
            prefix-icon="iconfont vj-icon-captcha"
          >
            <!--            <svg-icon slot="prefix" file-name="validCode" class="el-input__icon input-icon"/>-->
          </el-input>
          <div class="reg-captcha">
            <img :src="captchaUri" @click="genCaptcha">
          </div>
        </el-form-item>
        <el-form-item class="reg-btn-item">
          <el-button @click="register">{{ $t('register.register') }}</el-button>
        </el-form-item>
        <div class="login-link">
          <router-link to="/login" tag="a">{{ $t('register.login') }}</router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import I18nBtn from '../../components/I18nBtn'
import UsrAuthApi, { genCaptcha } from '@/api/UsrAuthApi'
import HashUtil from '@/util/HashUtil'
import UsrIfrApi from '@/api/usercenter/UsrIfrApi'
import ProtRule from '@/constant/ProtRule'
import UsrApi from '@/api/usercenter/UsrApi'
import UsrAcctApi from '@/api/usercenter/UsrAcctApi'

export default {
  name: 'Register',
  components: { I18nBtn },
  watch: {
    '$i18n.locale': {
      handler: function (newVal, oldVal) {
        this.initFormRules()
      }
    },
    immediate: true
  },
  data () {
    return {
      invitation: {
        invitationCode: null,
        inviterAcctId: null,
        inviterAcctUnid: null,
        invitationWords: null
      },
      captchaUri: '',
      regFormData: {
        unid: '',
        email: '',
        pwd: '',
        confirmPwd: '',
        captchaCode: ''
      },
      captchaUuid: '',
      formRules: {}
    }
  },
  created () {
    this.initFormRules()
    this.initInvitation()
    this.genCaptcha()
  },
  methods: {
    initFormRules () {
      this.formRules = {
        unid: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().unidPtn,
          {
            validator: (rule, value, callback) => {
              if (value === null || value === undefined || value === '' || value.trim() === '') {
                return callback()
              }
              UsrAcctApi.detectUnidAvl(value).then(op => {
                if (op) {
                  return callback()
                } else {
                  callback(new Error(this.$t('protMsg.unidExists')))
                }
              })
            },
            trigger: 'blur'
          }
        ],
        email: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().emailPtn,
          {
            validator: (rule, value, callback) => {
              UsrAcctApi.detectEmailAvl(value).then(op => {
                if (op) {
                  return callback()
                } else {
                  callback(new Error(this.$t('protMsg.emailExists')))
                }
              })
            },
            trigger: 'blur'
          }
        ],
        pwd: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().cmnPwdPlainPtn
        ],
        confirmPwd: [
          ProtRule.cmnRule().required,
          ProtRule.cmnRule().cmnPwdPlainPtn,
          {
            validator: (rule, value, callback) => {
              if (value !== this.regFormData.pwd) {
                callback(new Error(this.$t('protMsg.notSamePwd')))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        captchaCode: [
          ProtRule.cmnRule().required
        ]
      }
    },
    initInvitation () {
      this.invitation.invitationCode = this.$route.query.invitationCode
      if (this.invitation.invitationCode) {
        UsrIfrApi.findByInvitationCode(this.invitation.invitationCode).then(rd => {
          if (rd) {
            this.invitation = { ...rd }
            const h = this.$createElement
            this.$message({
              showClose: true,
              message: h('div', null,
                [
                  h('div', null, [h('strong', null, this.invitation.inviterAcctUnid + this.$t('share.invite'))]),
                  h('div', { style: 'font-size:12px; color:#ec615f;' }, this.$t('share.rewardAfterActive')),
                  h('el-input',
                    {
                      style: 'margin-top:5px; border-radius:5px;',
                      attrs: {
                        value: this.invitation.invitationWords,
                        disabled: true,
                        type: 'textarea',
                        autosize: {
                          minRows: 1,
                          maxRows: 3
                        }
                      }
                    },
                    null)
                ]
              ),
              duration: 0
            })
          }
        })
      }
    },
    async genCaptcha () {
      this.regFormData.captchaCode = ''
      const captchaOp = await genCaptcha()
      this.captchaUri = captchaOp.imgBase64
      this.captchaUuid = captchaOp.uuid
    },
    register () {
      this.$refs.form.validate(isValid => {
        if (!isValid) {
          return
        }
        const verifyCaptchaIp = {
          uuid: this.captchaUuid,
          code: this.regFormData.captchaCode
        }
        UsrAuthApi.verifyCaptcha(verifyCaptchaIp).then(op => {
          if (op) {
            this.doRegister()
          } else {
            this.$message.error(this.$t('login.wrongCaptcha'))
            this.genCaptcha()
          }
        })
      })
    },
    doRegister () {
      const regIp = {
        unid: this.regFormData.unid,
        email: this.regFormData.email,
        pwd: HashUtil.md5(HashUtil.md5(this.regFormData.pwd)),
        invitationCode: this.invitation.invitationCode,
        inviterAcctId: this.invitation.inviterAcctId
      }
      UsrApi.register(regIp).then(rd => {
        if (rd) {
          this.$message.success(this.$t('register.registerSuccess'))
          this.$router.push({ path: '/login' })
        } else {
          this.genCaptcha()
          return this.$message.error(this.$t('register.registerFailed'))
        }
      }).catch(() => {
        this.genCaptcha()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.i18n-btn {
  position: absolute;
  right: 10%;
  top: 10%;
}

.register-wrapper {
  width: 100%;
  height: 100vh;
  background: url("../../assets/img/RegBgImg.jpeg") center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow: hidden; // 解决高度塌陷问题
  .register-box {
    width: 350px;
    height: 580px;
    margin: 100px auto;
    border-radius: 10px;
    box-shadow: 1px 2px 15px rgba(0, 0, 0, .3);
    background: url("../../assets/img/InfoFormBg.jpg") bottom center no-repeat #fff;

    .logo {
      display: flex;
      justify-content: center;
      padding: 10px 0 0;

      img {
        width: 55px;
        border-radius: 10px;
      }
    }

    h2 {
      text-align: center;
      font-weight: 400;
      padding-bottom: 10px;
    }

    .el-form-item {
      margin-left: 10px;
      margin-right: 10px;
    }

    .reg-btn-item {
      margin: 10px 10px 0;

      .el-button {
        display: block;
        width: 100%;
        font-size: 15px;
        background-color: #5dd5c8;
        color: #fff;
      }
    }

    .login-link {
      padding-right: 24px;
      margin-top: 10px;

      a {
        float: right;
      }
    }
  }

}

.reg-captcha {
  width: 33%;
  display: inline-block;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle
  }
}
</style>
