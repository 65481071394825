/**
 * 天干地支之地支速查表<=>生肖
 * @Array Of Property
 * @trans['鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪']
 * @return Cn string
 */
export default [
  '\u9f20',
  '\u725b',
  '\u864e',
  '\u5154',
  '\u9f99',
  '\u86c7',
  '\u9a6c',
  '\u7f8a',
  '\u7334',
  '\u9e21',
  '\u72d7',
  '\u732a'
]
