<template>
  <hash-cmpt hashAlg="MD5"></hash-cmpt>
</template>

<script>
import HashCmpt from './HashCmpt.vue'

export default {
  name: 'MD5',
  components: { HashCmpt }
}
</script>
