<template>
  <div>
    <div class="unit-input-div">
      <el-input class="item unit-converter-input-number-area" v-model="input" @input="onInput"></el-input>
      <el-select class="item" v-model="unit1" filterable @change="onUnitChange" ref="unit1OptionRef">
        <el-option-group
          v-for="unitOption in unitOptions"
          :key="unitOption.categ"
          :label="unitOption.categ">
          <el-option
            v-for="(item, idx) in unitOption.units"
            :key="unitOption.categ + '.' + idx"
            :label="item.unitName"
            :value="item.unitKey">
          </el-option>
        </el-option-group>
      </el-select>
      <el-button class="item" icon="el-icon-refresh" circle @click="exchangeUnit"></el-button>
      <el-select class="item" v-model="unit2" filterable @change="onUnitChange" ref="unit2OptionRef">
        <el-option-group
          v-for="unitOption in unitOptions"
          :key="unitOption.categ"
          :label="unitOption.categ">
          <el-option
            v-for="(item, idx) in unitOption.units"
            :key="unitOption.categ + '.' + idx"
            :label="item.unitName"
            :value="item.unitKey">
          </el-option>
        </el-option-group>
      </el-select>
      <el-button class="item" icon="el-icon-more" circle @click="showAll"></el-button>
    </div>
    <div v-if="this.result">
      <el-divider></el-divider>
      <h3 v-if="this.result">{{ this.result }}</h3>
    </div>
    <el-divider></el-divider>
    <div>
      <el-table
        :data="tableData"
        height="500px"
        border
        :span-method="objectSpanMethod"
        style="width: 100%">
        <el-table-column
          prop="categ"
          :label="$t('unitConverter.categ')"
          width="150">
        </el-table-column>
        <el-table-column
          prop="unitName"
          :label="$t('unitConverter.unit')"
          width="180">
        </el-table-column>
        <el-table-column
          prop="fromSiuFormula"
          label="FROM L(m)">
        </el-table-column>
        <el-table-column
          prop="toSiuFormula"
          label="TO L(m)">
        </el-table-column>
        <el-table-column
          prop="remark"
          :label="$t('cmn.remark')">
        </el-table-column>
      </el-table>
    </div>

    <div>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableShowAllDialog"
        append-to-body
      >
        <div style="display: flex">
          <el-input class="unit-converter-input-number-area" v-model="input" @input="onInput"></el-input>
          <el-select v-model="unit1" @change="onUnitChange" ref="unit1OptionRef">
            <el-option-group
              v-for="unitOption in unitOptions"
              :key="unitOption.categ"
              :label="unitOption.categ">
              <el-option
                v-for="(item, idx) in unitOption.units"
                :key="unitOption.categ + '.' + idx"
                :label="item.unitName"
                :value="item.unitKey">
              </el-option>
            </el-option-group>
          </el-select>
        </div>
        <div>
          <div v-for="(item, idx) in allUnitResult" :key="idx">
            {{ item }}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js'

const math = require('mathjs')

export default {
  name: 'LengthUnit',
  computed: {
    result: {
      get () {
        return this.calUnitResult()
      },
      set (v) {
      }
    },
    allUnitResult: {
      get () {
        return this.calAllUnit()
      },
      set (v) {
      }
    },
    unitData: {
      get () {
        return {
          siu: {
            m: {
              categ: this.$t('unitConverter.siu'),
              unitName: this.$t('lengthUnit.m'),
              unitKey: 'm',
              fromSiuFormula: 'L(m) = L(m)',
              toSiuFormula: 'L(m) = L(m)',
              fromSiuExp: 'x * 1',
              toSiuExp: 'y * 1',
              remark: this.$t('lengthUnit.remark.m')
            }
          },
          metricUnit: {
            nm: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('lengthUnit.nm'),
              unitKey: 'nm',
              fromSiuFormula: 'L(nm) = L(m) * 1e+9',
              toSiuFormula: 'L(m) = L(nm) * 1e-9',
              fromSiuExp: 'x * 1e+9',
              toSiuExp: 'y * 1e-9',
              remark: this.$t('lengthUnit.remark.nm')
            },
            um: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('lengthUnit.um'),
              unitKey: 'um',
              fromSiuFormula: 'L(um) = L(m) * 1e+6',
              toSiuFormula: 'L(m) = L(um) * 1e-6',
              fromSiuExp: 'x * 1e+6',
              toSiuExp: 'y * 1e-6',
              remark: this.$t('lengthUnit.remark.um')
            },
            mm: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('lengthUnit.mm'),
              unitKey: 'mm',
              fromSiuFormula: 'L(d㎡) = L(m) * 1e+3 = L(m) * 1000',
              toSiuFormula: 'L(m) = L(d㎡) * 1e-3 = L(d㎡) * 0.001',
              fromSiuExp: 'x * 1000',
              toSiuExp: 'y * 0.001',
              remark: this.$t('lengthUnit.remark.mm')
            },
            cm: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('lengthUnit.cm'),
              unitKey: 'cm',
              fromSiuFormula: 'L(cm) = L(m) * 1e+2 = L(m) * 100',
              toSiuFormula: 'L(m) = L(cm) * 1e-2 = L(cm) * 0.01',
              fromSiuExp: 'x * 100',
              toSiuExp: 'y * 0.01',
              remark: this.$t('lengthUnit.remark.cm')
            },
            dm: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('lengthUnit.dm'),
              unitKey: 'dm',
              fromSiuFormula: 'L(dm) = L(m) * 10',
              toSiuFormula: 'L(m) = L(dm) * 0.1',
              fromSiuExp: 'x * 10',
              toSiuExp: 'y * 0.1',
              remark: this.$t('lengthUnit.remark.dm')
            },
            m: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('lengthUnit.m'),
              unitKey: 'm',
              fromSiuFormula: 'L(m) = L(m) * 1',
              toSiuFormula: 'L(m) = L(m) * 1',
              fromSiuExp: '1 * x',
              toSiuExp: '1 * y',
              remark: this.$t('lengthUnit.remark.m')
            },
            km: {
              categ: this.$t('unitConverter.metricUnit'),
              unitName: this.$t('lengthUnit.km'),
              unitKey: 'km',
              fromSiuFormula: 'L(km) = L(m) * 1e-3 = L(m) * 0.001',
              toSiuFormula: 'L(m) = L(km) * 1e+3 = L(km) * 1000',
              fromSiuExp: 'x * 0.001',
              toSiuExp: 'y * 1000',
              remark: this.$t('lengthUnit.remark.km')
            }
          },
          municipalUnit: {
            Hao: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('lengthUnit.Hao'),
              unitKey: 'Hao',
              fromSiuFormula: 'L(毫) = L(m) * 30000',
              toSiuFormula: 'L(m) = L(毫) / 30000',
              fromSiuExp: 'x * 30000',
              toSiuExp: 'y / 30000',
              remark: this.$t('lengthUnit.remark.Hao')
            },
            Li: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('lengthUnit.Li'),
              unitKey: 'Li',
              fromSiuFormula: 'L(厘) = L(m) * 3000',
              toSiuFormula: 'L(m) = L(厘) / 3000',
              fromSiuExp: 'x * 3000',
              toSiuExp: 'y / 3000',
              remark: this.$t('lengthUnit.remark.Li')
            },
            Fen: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('lengthUnit.Fen'),
              unitKey: 'Fen',
              fromSiuFormula: 'L(分) = L(m) * 300',
              toSiuFormula: 'L(m) = L(分) / 300',
              fromSiuExp: 'x * 300',
              toSiuExp: 'y / 300',
              remark: this.$t('lengthUnit.remark.Fen')
            },
            Cun: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('lengthUnit.Cun'),
              unitKey: 'Cun',
              fromSiuFormula: 'L(寸) = L(m) * 30',
              toSiuFormula: 'L(m) = L(寸) / 30',
              fromSiuExp: 'x * 30',
              toSiuExp: 'y / 30',
              remark: this.$t('lengthUnit.remark.Cun')
            },
            Chi: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('lengthUnit.Chi'),
              unitKey: 'Chi',
              fromSiuFormula: 'L(尺) = L(m) * 3',
              toSiuFormula: 'L(m) = L(尺) / 3',
              fromSiuExp: 'x * 3',
              toSiuExp: 'y / 3',
              remark: this.$t('lengthUnit.remark.Chi')
            },
            Zhang: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('lengthUnit.Zhang'),
              unitKey: 'Zhang',
              fromSiuFormula: 'L(丈) = L(m) * 0.3',
              toSiuFormula: 'L(m) = L(丈) / 0.3',
              fromSiuExp: 'x * 0.3',
              toSiuExp: 'y / 0.3',
              remark: this.$t('lengthUnit.remark.Zhang')
            },
            Lii: {
              categ: this.$t('unitConverter.municipalUnit'),
              unitName: this.$t('lengthUnit.Lii'),
              unitKey: 'Lii',
              fromSiuFormula: 'L(里) = L(m) / 500 ',
              toSiuFormula: 'L(m) = L(里) * 500',
              fromSiuExp: 'x / 500',
              toSiuExp: 'y * 500',
              remark: this.$t('lengthUnit.remark.Lii')
            }
          },
          imperialUnit: {
            mil: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('lengthUnit.milliInch'),
              unitKey: 'mil',
              fromSiuFormula: 'L(mil) = L(m) * 1e+5 / 2.54',
              toSiuFormula: 'L(m) = L(mil) * 2.54e-5',
              fromSiuExp: 'x * 1e+5/2.54',
              toSiuExp: 'y * 2.54e-5',
              remark: this.$t('lengthUnit.remark.milliInch')
            },
            in: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('lengthUnit.inch'),
              unitKey: 'in',
              fromSiuFormula: 'L(in) = L(m) * 1e+2 / 2.54',
              toSiuFormula: 'L(m) = L(in) * 2.54e-2',
              fromSiuExp: 'x * 1e+2 / 2.54',
              toSiuExp: 'y * 2.54e-2',
              remark: this.$t('lengthUnit.remark.inch')
            },
            ft: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('lengthUnit.foot'),
              unitKey: 'ft',
              fromSiuFormula: 'L(ft) = L(m) / 0.3048',
              toSiuFormula: 'L(m) = L(ft) * 0.3048',
              fromSiuExp: 'x / 0.3048',
              toSiuExp: 'y * 0.3048',
              remark: this.$t('lengthUnit.remark.foot')
            },
            yd: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('lengthUnit.yard'),
              unitKey: 'yd',
              fromSiuFormula: 'L(yd) = L(m) / 0.9144',
              toSiuFormula: 'L(m) = L(yd) * 0.9144',
              fromSiuExp: 'x / 0.9144',
              toSiuExp: 'y * 0.9144',
              remark: this.$t('lengthUnit.remark.yard')
            },
            fm: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('lengthUnit.fathom'),
              unitKey: 'fm',
              fromSiuFormula: 'L(fm) = L(m) / 1.8288',
              toSiuFormula: 'L(m) = L(fm) * 1.8288',
              fromSiuExp: 'x / 1.8288',
              toSiuExp: 'y * 1.8288',
              remark: this.$t('lengthUnit.remark.fathom')
            },
            rd: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('lengthUnit.rd'),
              unitKey: 'rd',
              fromSiuFormula: 'L(rd) = L(m) / 5.0292',
              toSiuFormula: 'L(m) = L(rd) * 5.0292',
              fromSiuExp: 'x / 5.0292',
              toSiuExp: 'y * 5.0292',
              remark: this.$t('lengthUnit.remark.rd')
            },
            fur: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('lengthUnit.furlong'),
              unitKey: 'fur',
              fromSiuFormula: 'L(fur) = L(m) / 201.168',
              toSiuFormula: 'L(m) = L(fur) * 201.168',
              fromSiuExp: 'x / 201.168',
              toSiuExp: 'y * 201.168',
              remark: this.$t('lengthUnit.remark.furlong')
            },
            mi: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('lengthUnit.mile'),
              unitKey: 'mi',
              fromSiuFormula: 'L(mi) = L(m) / 1609.344',
              toSiuFormula: 'L(m) = L(mi) * 1609.344',
              fromSiuExp: 'x / 1609.344',
              toSiuExp: 'y * 1609.344',
              remark: this.$t('lengthUnit.remark.mile')
            },
            nmi: {
              categ: this.$t('unitConverter.imperialUnit'),
              unitName: this.$t('lengthUnit.nauticalMile'),
              unitKey: 'nmi',
              fromSiuFormula: 'L(nmi) = L(m) / 1852',
              toSiuFormula: 'L(m) = L(nmi) * 1852',
              fromSiuExp: 'x / 1852',
              toSiuExp: 'y * 1852',
              remark: this.$t('lengthUnit.remark.nauticalMile')
            }
          },
          otherRelated: {
            ly: {
              categ: this.$t('unitConverter.otherRelated'),
              unitName: this.$t('lengthUnit.lightYear'),
              unitKey: 'ly',
              fromSiuFormula: 'L(ly) = L(m) / (299792458 * 365.25 * 24 * 60 * 60)',
              toSiuFormula: 'L(m) = L(ly) * 299792458 * (365.25 * 24 * 60 * 60)',
              fromSiuExp: 'x / (299792458 * 365.25 * 24 * 60 * 60)',
              toSiuExp: 'y * 299792458 * 365.25 * 24 * 60 * 60',
              remark: this.$t('lengthUnit.remark.lightYear')
            },
            au: {
              categ: this.$t('unitConverter.otherRelated'),
              unitName: this.$t('lengthUnit.au'),
              unitKey: 'au',
              fromSiuFormula: 'L(A.U.) = L(m) * 1e-11 / 1.495978707',
              toSiuFormula: 'L(m) = L(A.U.) * 1.495978707e+11',
              fromSiuExp: 'x * 1e-11 / 1.495978707',
              toSiuExp: 'y * 1.495978707e+11',
              remark: this.$t('lengthUnit.remark.au')
            }
          }
        }
      },
      set (v) {
      }
    },
    unitOptions: {
      get () {
        return [
          {
            categ: this.$t('unitConverter.siu'),
            units: Object.values(this.unitData.siu)
          },
          {
            categ: this.$t('unitConverter.metricUnit'),
            units: Object.values(this.unitData.metricUnit)
          },
          {
            categ: this.$t('unitConverter.municipalUnit'),
            units: Object.values(this.unitData.municipalUnit)
          },
          {
            categ: this.$t('unitConverter.imperialUnit'),
            units: Object.values(this.unitData.imperialUnit)
          },
          {
            categ: this.$t('unitConverter.otherRelated'),
            units: Object.values(this.unitData.otherRelated)
          }
        ]
      },
      set (v) {
      }
    },
    tableData: {
      get () {
        return [...Object.values(this.unitData.siu), ...Object.values(this.unitData.metricUnit),
          ...Object.values(this.unitData.municipalUnit), ...Object.values(this.unitData.imperialUnit),
          ...Object.values(this.unitData.otherRelated)
        ]
      },
      set (v) {
      }
    }
  },
  data () {
    return {
      unit1: 'm',
      unit2: 'Cun',
      input: null,
      enableShowAllDialog: false
    }
  },
  methods: {
    onInput () {
      this.result = this.calUnitResult()
      if (this.enableShowAllDialog) {
        this.showAll()
      }
    },

    onUnitChange () {
      this.result = this.calUnitResult()
      if (this.enableShowAllDialog) {
        this.showAll()
      }
    },

    showAll () {
      this.allUnitResult = this.calAllUnit()
      this.enableShowAllDialog = true
    },
    exchangeUnit () {
      const tempUnit = this.unit1
      this.unit1 = this.unit2
      this.unit2 = tempUnit
      this.onUnitChange()
    },
    calAllUnit () {
      const allUnitResult = []
      for (let i = 0; i < this.unitOptions.length; i++) {
        if (i !== 0) {
          const unitOptions = this.unitOptions.at(i).units
          for (const unitOption of unitOptions) {
            allUnitResult.push(this.calResult(unitOption))
          }
        }
      }
      return allUnitResult
    },
    calUnitResult () {
      if (this.input && this.unit1 && this.unit2) {
        const inputNum = new BigNumber(math.evaluate(this.input))
        if (inputNum.isFinite()) {
          const unit1Data = this.getUnitDataByUnitKey(this.unit1)
          const unit2Data = this.getUnitDataByUnitKey(this.unit2)
          if (unit1Data && unit2Data) {
            const to = math.evaluate(unit1Data.toSiuExp.replaceAll('y', this.input))
            const from = math.evaluate(unit2Data.fromSiuExp.replaceAll('x', to))
            return this.input + ' ' + this.$refs.unit1OptionRef.selected.label + '  =  ' + from + ' ' + this.$refs.unit2OptionRef.selected.label
          } else {
            return null
          }
        }
        return null
      } else {
        return null
      }
    },
    calResult (resultUnit) {
      if (this.input && this.unit1 && resultUnit) {
        const inputNum = new BigNumber(math.evaluate(this.input))
        if (inputNum.isFinite()) {
          const unit1Data = this.getUnitDataByUnitKey(this.unit1)
          const unit2Data = resultUnit
          if (unit1Data && unit2Data) {
            const to = math.evaluate(unit1Data.toSiuExp.replaceAll('y', this.input))
            const from = math.evaluate(unit2Data.fromSiuExp.replaceAll('x', to))
            return this.input + ' ' + this.$refs.unit1OptionRef.selected.label + '  =  ' + from + ' ' + resultUnit.unitName
          } else {
            return null
          }
        }
        return null
      } else {
        return null
      }
    },
    getUnitDataByUnitKey (unitKey) {
      for (const unit of Object.values(this.unitData)) {
        if (unit && unit[unitKey]) {
          return unit[unitKey]
        }
      }
      return null
    },
    objectSpanMethod ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 1,
            colspan: 1
          }
        }
        if (rowIndex === 1) {
          return {
            rowspan: 7,
            colspan: 1
          }
        }
        if (rowIndex === 8) {
          return {
            rowspan: 7,
            colspan: 1
          }
        }
        if (rowIndex === 15) {
          return {
            rowspan: 9,
            colspan: 1
          }
        }
        if (rowIndex === 24) {
          return {
            rowspan: 2,
            colspan: 1
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
