<template>
  <div>
    <div class="code-container">
      <pre>{{ $t('hash.hashDefine') }}</pre>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="hashAlg"
        :label="$t('hash.hashAlg')"
        sortable
        width="110px">
      </el-table-column>
      <el-table-column
        prop="outputResult"
        :label="$t('hash.outputResult')"
        width="110px">
      </el-table-column>
      <el-table-column
        prop="performance"
        :label="$t('hash.performance')"
        sortable
        width="110px">
      </el-table-column>
      <el-table-column
        prop="collisionProbability"
        :label="$t('hash.collisionProbability')"
        sortable
        width="110px">
      </el-table-column>
      <el-table-column
        prop="usageScenario"
        :label="$t('hash.usageScenario')"
        width="170px"
      >
      </el-table-column>
      <el-table-column
        prop="principle"
        :label="$t('hash.principle')"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: 'HashIntro',
  watch: {
    '$i18n.locale': {
      handler: function (newVal, oldVal) {
        this.changeTableData(newVal)
      }
    },
    immediate: true
  },
  data () {
    return {
      tableData: [],
      tableDataZh: [
        {
          hashAlg: 'MD5',
          outputResult: '128位(16字节)',
          performance: 'P4 - 较快',
          collisionProbability: 'C4 - 较低',
          usageScenario: '数据完整性校验，如文件校验、密码存储等',
          principle: 'MD5是消息摘要算法(Message Digest Algorithm)的一种(其他如MD2,MD4)，其原理为首先将输入分割成512位的数据块，然后通过一系列的位操作和逻辑函数对数据块进行处理，最后得到128位的散列值'
        },
        {
          hashAlg: 'SHA1',
          outputResult: '160位(20字节)',
          performance: 'P3 - 较快',
          collisionProbability: 'C3 - 较低',
          usageScenario: '数字签名、证书颁发等安全相关的场景',
          principle: 'SHA1是安全散列算法(Secure Hash Algorithm)的一种(其他如SHA256, SHA512)。首先将输入分割成512位的数据块，然后通过一系列的位操作和逻辑函数对数据块进行处理，最后得到160位的散列值'
        },
        {
          hashAlg: 'SHA256',
          outputResult: '256位(32字节)',
          performance: 'P2 - 较快',
          collisionProbability: 'C2 - 较低',
          usageScenario: '密码学和数据完整性校验等安全相关的场景',
          principle: '首先将输入分割成512位的数据块，然后通过一系列的位操作和逻辑函数对数据块进行处理，最后得到256位的散列值'
        },
        {
          hashAlg: 'SHA512',
          outputResult: '512位(64字节)',
          performance: 'P1 - 较快',
          collisionProbability: 'C1 - 较低',
          usageScenario: '密码学和数据完整性校验等安全相关的场景',
          principle: '首先将输入分割成1024位的数据块，然后通过一系列的位操作和逻辑函数对数据块进行处理，最后得到512位的散列值'
        },
        {
          hashAlg: 'CRC32',
          outputResult: '32位(4字节)',
          performance: 'P5 - 很快',
          collisionProbability: 'C5 - 较高',
          usageScenario: '数据传输的错误检测等场景，如网络通信',
          principle: 'CRC32是循环冗余校验(Cyclic Redundancy Check)算法的一种(其他如CRC8,CRC16等)，核心原理是通过对数据进行多项式运算(模2运算)。首先定义一个32位的多项式(如0x04C11DB7)，将输入数据与该多项式进行异或运算，然后将生成的数据左移一位，再与该多项式进行异或运算，如此重复直到最后一位，即得到CRC32值。'
        }
      ],
      tableDataEn: [
        {
          hashAlg: 'MD5',
          outputResult: '128位(16字节)',
          performance: 'P4 - 较快',
          collisionProbability: 'C4 - 较低',
          usageScenario: '数据完整性校验，如文件校验、密码存储等',
          principle: 'MD5是消息摘要算法(Message Digest Algorithm)的一种(其他如MD2,MD4)，其原理为首先将输入分割成512位的数据块，然后通过一系列的位操作和逻辑函数对数据块进行处理，最后得到128位的散列值'
        },
        {
          hashAlg: 'SHA1',
          outputResult: '160位(20字节)',
          performance: 'P3 - 较快',
          collisionProbability: 'C3 - 较低',
          usageScenario: '数字签名、证书颁发等安全相关的场景',
          principle: 'SHA1是安全散列算法(Secure Hash Algorithm)的一种(其他如SHA256, SHA512)。首先将输入分割成512位的数据块，然后通过一系列的位操作和逻辑函数对数据块进行处理，最后得到160位的散列值'
        },
        {
          hashAlg: 'SHA256',
          outputResult: '256位(32字节)',
          performance: 'P2 - 较快',
          collisionProbability: 'C2 - 较低',
          usageScenario: '密码学和数据完整性校验等安全相关的场景',
          principle: '首先将输入分割成512位的数据块，然后通过一系列的位操作和逻辑函数对数据块进行处理，最后得到256位的散列值'
        },
        {
          hashAlg: 'SHA512',
          outputResult: '512位(64字节)',
          performance: 'P1 - 较快',
          collisionProbability: 'C1 - 较低',
          usageScenario: '密码学和数据完整性校验等安全相关的场景',
          principle: '首先将输入分割成1024位的数据块，然后通过一系列的位操作和逻辑函数对数据块进行处理，最后得到512位的散列值'
        },
        {
          hashAlg: 'CRC32',
          outputResult: '32位(4字节)',
          performance: 'P5 - 很快',
          collisionProbability: 'C5 - 较高',
          usageScenario: '数据传输的错误检测等场景，如网络通信',
          principle: 'CRC32是循环冗余校验(Cyclic Redundancy Check)算法的一种(其他如CRC8,CRC16等)，核心原理是通过对数据进行多项式运算(模2运算)。首先定义一个32位的多项式(如0x04C11DB7)，将输入数据与该多项式进行异或运算，然后将生成的数据左移一位，再与该多项式进行异或运算，如此重复直到最后一位，即得到CRC32值。'
        }
      ]
    }
  },
  created () {
    this.changeTableData(this.$i18n.locale)
  },
  methods: {
    changeTableData (lang) {
      if (lang === 'en') {
        this.tableData = this.tableDataEn
      } else {
        this.tableData = this.tableDataZh
      }
    }
  }
}
</script>

<style lang="less" scoped>
.code-container {
  position: relative;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  padding-left: 10px;
  margin-bottom: 10px;
}
</style>
