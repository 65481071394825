<template>
  <!--  <el-container>-->
  <!--    <el-aside width="160px">-->
  <!--      <h2>{{ $t('userCenter.personalInfo') }}</h2>-->
  <!--      <el-menu default-active="/userInfo" router>-->
  <!--        <el-menu-item index="/userInfo">-->
  <!--          <i class="el-icon-menu"></i>-->
  <!--          <span slot="title">{{ $t('userCenter.userInfo') }}</span>-->
  <!--        </el-menu-item>-->
  <!--        <el-menu-item index="/accountInfo">-->
  <!--          <i class="el-icon-setting"></i>-->
  <!--          <span slot="title">{{ $t('userCenter.accountInfo') }}</span>-->
  <!--        </el-menu-item>-->
  <!--      </el-menu>-->
  <!--    </el-aside>-->
  <!--    <el-main>-->
  <!--      <router-view></router-view>-->
  <!--    </el-main>-->
  <!--  </el-container>-->
  <el-container>
    <el-aside width="150px">
      <el-menu :default-active="this.$route.path" class="el-menu-vertical-demo"
               @open="handleOpen" @close="handleClose" @select="handleSelect">
        <menu-tree :menu-list="menuList"></menu-tree>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import MenuTree from '@/components/MenuTree'

export default {
  components: { MenuTree },
  name: 'PerCenter',
  data () {
    return {
      defaultActiveIndex: this.$route.path,
      menuList: [
        {
          index: '/perCenter/myInfo',
          icon: 'iconfont vj-icon-my_info',
          name: '我的信息',
          nameKey: 'userCenter.myInfo',
          children: [
            {
              index: '/perCenter/usrPerInfo',
              icon: 'iconfont vj-icon-yonghuxinxi',
              name: '用户信息',
              nameKey: 'userCenter.usrPerInfo',
              children: []
            },
            {
              index: '/perCenter/usrAcctInfo',
              icon: 'iconfont vj-icon-account-information-query-block',
              name: '账号信息',
              nameKey: 'userCenter.usrAcctInfo',
              children: []
            }
          ]
        },
        {
          index: '/perCenter/vjbJar',
          icon: 'iconfont vj-icon-G-act',
          name: '乌及豆罐',
          nameKey: 'trade.vjbJar',
          children: []
        },
        {
          index: '/perCenter/vojistarCard',
          icon: 'iconfont vj-icon-xingxingqia',
          name: '乌及星卡',
          nameKey: 'trade.vojistarCard',
          children: []
        },
        {
          index: '/perCenter/myOrder',
          icon: 'iconfont vj-icon-my_order',
          name: '我的订单',
          nameKey: 'trade.myOrder',
          children: []
        },
        {
          index: '/perCenter/myBill',
          icon: 'iconfont vj-icon-wodezhangdan',
          name: '我的账单',
          nameKey: 'trade.myBill'
        }
      ]
    }
  },
  methods: {
    handleOpen (index, indexPath) {
      console.log(this.$route.path)
      if (this.$route.path === index) {
        return
      }
      this.$router.push({
        path: '/perCenter/usrPerInfo'
      })
    },
    handleSelect (index, indexPath) {
      if (this.$route.path === index) {
        return
      }
      if (!indexPath.includes('/perCenter/usrPerInfo')) {
        this.$router.push(index)
        return
      }
      if (indexPath.length > 1) {
        this.$router.push({
          path: '/perCenter/usrPerInfo'
        })
      }
    },
    handleClose (index, indexPath) {
      if (this.$route.path === index) {
        return
      }
      this.$router.push({
        path: '/perCenter/usrPerInfo',
        query: {
          usrPerId: null
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
