<template>
  <div>
    <el-row :gutter="8">
      <el-col :span="10">
        <h3>{{ this.$t('toolCmn.inputTextArea') }}</h3>
        <el-input v-model="inputTextArea" type="textarea" rows="10"
                  @input="onInput">
        </el-input>
      </el-col>
      <el-col :span="4">
        <div class="execute-mode-div">
          <div>
            <el-radio v-model="executeMode" label="HASH" @input="onChangeExecuteMode">
              <span v-html="this.$t('hash.spaceHash')"></span>
            </el-radio>
          </div>
          <div>
            <el-radio v-model="executeMode" label="CRACK_HASH" @input="onChangeExecuteMode">
              {{ this.$t('hash.crackHash') }}
            </el-radio>
          </div>
          <div>
            <el-button round size="medium" @click="execute" v-html="this.$t('hash.spaceConfirm')"></el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="10">
        <h3 v-if="executeMode==='HASH'">{{ hashAlg }} {{ this.$t('toolCmn.resultTextArea') }}</h3>
        <h3 v-else>{{ this.$t('hash.crack') }} {{ hashAlg }} {{ this.$t('toolCmn.resultTextArea') }}</h3>
        <el-input v-model="resultTextArea" type="textarea" rows="10" disabled>
        </el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HashUtil from '@/util/HashUtil'
import HashApi from '@/api/vojivosvc/toolsubject/HashApi'

export default {
  name: 'HashCmpt',
  props: {
    hashAlg: String
  },
  data () {
    return {
      inputTextArea: null,
      resultTextArea: null,
      executeMode: 'HASH',
      delayExecuteTimeoutId: null
    }
  },
  methods: {
    onChangeExecuteMode (selectedValue) {
      if (selectedValue === 'HASH') {
        this.delayExecute()
      } else {
        this.clearDelayExecuteTimeout()
        this.resultTextArea = null
      }
    },
    onInput () {
      if (this.executeMode === 'HASH') {
        this.delayExecute()
      }
    },
    delayExecute () {
      this.clearDelayExecuteTimeout()
      this.delayExecuteTimeoutId = setTimeout(() => {
        this.execute()
      }, 500)
    },
    clearDelayExecuteTimeout () {
      if (this.delayExecuteTimeoutId) {
        clearTimeout(this.delayExecuteTimeoutId)
      }
    },
    execute () {
      this.clearDelayExecuteTimeout()
      if (this.inputTextArea === '' || this.inputTextArea === null || this.inputTextArea === undefined) {
        this.resultTextArea = null
        return
      }
      if (this.executeMode === 'HASH') {
        this.resultTextArea = HashUtil.hash(this.inputTextArea, this.hashAlg)
      } else {
        HashApi.crack(this.inputTextArea, this.hashAlg)
          .then(resultData => {
            if (resultData) {
              this.resultTextArea = resultData
            }
          })
      }
    }
  }
}
</script>
