<template>
  <div>
    <el-row :gutter="20">
      <!--  左侧标签栏    -->
      <el-col v-if="isExtended" :xs="9" :sm="6" :md="5" :lg="4" :xl="4">
        <div class="head-container">
          <el-input
            v-model="tagBarSrchKw"
            clearable
            size="small"
            prefix-icon="el-icon-search"
            class="filter-item"
            @input="srchResTag"
          />
        </div>
        <el-tree
          node-key="id"
          :data="tagBarResTagTrees"
          :load="loadTagBarResTagTrees"
          :props="{label: 'name', children: 'children', hasChildren: 'name'}"
          :expand-on-click-node="false"
          show-checkbox
          check-strictly
          lazy
          @node-click="handleTagBarTreeNodeClicked"
          @check="handleTagTreeNodeCheck"
        />
      </el-col>

      <!--   右侧资源栏   -->
      <el-col :xs="15" :sm="18" :md="19" :lg="20" :xl="20">
        <!-- 内容页面上部  -->
        <div>
          <div class="menu-src-div">
            <el-button v-if="isExtended" icon="el-icon-s-fold" @click="inExtend"></el-button>
            <el-button v-else icon="el-icon-s-unfold" @click="inExtend"></el-button>
            <el-input
              v-model="findByPageIp.srchIp.srchKw"
              @input="delaySrch">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-dropdown @command="handleCmd">
              <el-button icon="el-icon-s-operation"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="checkCurPage">{{ $t('cmn.checkCurPage') }}</el-dropdown-item>
                <el-dropdown-item v-if="checkedResPkgMap&&checkedResPkgMap.size>0" command="cancelCheckAll">
                  {{ $t('cmn.cancelCheckAll') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="checkedResPkgMap&&checkedResPkgMap.size>0" command="batchFav">
                  {{ $t('cmn.batchFav') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="checkedResPkgMap&&checkedResPkgMap.size>0" command="batchPur">批量购买
                </el-dropdown-item>
                <el-dropdown-item v-if="checkedResPkgMap&&checkedResPkgMap.size>0" command="batchCancelFav">
                  {{ $t('cmn.cancelFav') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <!-- 内容页面下部  -->
        <div class="res-pkg-shower">
          <div v-infinite-scroll="loadMoreResPkg"
               :infinite-scroll-disabled="isLoadingMoreResPkg"
               class="scroll-div"
          >
            <!--资源Card渲染-->
            <div class="res-pkg-card-container">
              <el-card
                v-for="studyLabResPkg in studyLabResPkgList"
                :key="studyLabResPkg.id"
                class="res-pkg-card"
              >
                <div class="img-area">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <img :src="studyLabResPkg.firstImgUrl" class="left-img">
                    </el-col>
                    <el-col :span="12">
                      <div>
                        <img :src="studyLabResPkg.secondImgUrl" class="right-img">
                      </div>
                      <div>
                        <img :src="studyLabResPkg.thirdImgUrl" class="right-img">
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="tag-area">
                  <div class="base-tag">
                    <el-tag
                      v-for="growthStage in studyLabResPkg.growthStages"
                      :key="growthStage"
                      effect="dark"
                      size="mini"
                    >
                      {{ $t('studyLab.' + growthStage) }}
                    </el-tag>
                    <el-badge
                      v-for="carrierInfo in studyLabResPkg.carrierInfos"
                      :key="carrierInfo.carrierType"
                      :value="carrierInfo.count"
                      class="item"
                    >
                      <el-tag
                        type="info"
                        effect="dark"
                        size="mini"
                      >
                        {{ $t('studyLab.' + carrierInfo.carrierType) }}
                      </el-tag>
                    </el-badge>

                  </div>
                  <div class="categ-tag">
                    <el-tag
                      v-for="mainCategTag in studyLabResPkg.mainCategTags"
                      :key="mainCategTag.id"
                      type="warning"
                      effect="dark"
                      size="mini"
                    >
                      {{ mainCategTag.name }}
                    </el-tag>
                  </div>
                  <div class="highlight-tag">
                    <el-tag
                      v-for="mainHltTag in studyLabResPkg.mainHltTags"
                      :key="mainHltTag.id"
                      type="danger"
                      effect="dark"
                      size="mini"
                    >
                      {{ mainHltTag.name }}
                    </el-tag>
                  </div>
                </div>

                <div class="name-area">
                  <h2>{{ studyLabResPkg.name }}</h2>
                </div>
                <div class="profile-area">
                  {{ studyLabResPkg.profile }}
                </div>
                <div class="price-opn-area">
                  <el-row :gutter="20">
                    <el-col :span="10">
                      <div v-if="studyLabResPkg.bePur">
                        <el-tag type="info" size="mini">{{ $t('trade.bePur') }}</el-tag>
                      </div>
                      <div v-else class="price-area">
                        <div v-if="studyLabResPkg.vojistarCardPricing">
                          <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                          <span class="final-price-span">{{ studyLabResPkg.finalPrice }}</span>
                          <div>
                            <el-tag
                              type="info" size="mini">
                              {{ $t('trade.' + studyLabResPkg.vojistarCardPricing.unid) }}
                            </el-tag>
                          </div>
                          <div>
                            <span class="final-cur-price-span">{{ studyLabResPkg.curPrice }}</span>
                            <span class="orig-price-span">{{ studyLabResPkg.origPrice }}</span>
                          </div>
                        </div>
                        <div v-else>
                          <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                          <span class="cur-price-span">{{ studyLabResPkg.curPrice }}</span>
                          <span class="orig-price-span">{{ studyLabResPkg.origPrice }}</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="14">
                      <div class="opn-area">
                        <el-button
                          icon="el-icon-info"
                          circle
                          size="small"
                          @click="goToResPkgDetail(studyLabResPkg.id)"
                        />
                        <el-button icon="el-icon-download" circle size="small" @click="downloadOrPur(studyLabResPkg)"/>
                        <el-popover
                          v-if="studyLabResPkg.beFav"
                          :ref="'CancelFavPop.' + studyLabResPkg.id"
                          placement="top"
                          width="200"
                        >
                          <p>{{ $t('cmn.cancelFavConfirm') }}</p>
                          <div style="text-align: right;">
                            <el-button size="mini" type="text"
                                       @click="$refs['CancelFavPop.' + studyLabResPkg.id].at(0).doClose()">
                              {{ $t('cmn.cancel') }}
                            </el-button>
                            <el-button type="primary" size="mini" @click="doCancelFav(studyLabResPkg)">
                              {{ $t('cmn.confirm') }}
                            </el-button>
                          </div>
                          <el-button v-if="studyLabResPkg.beFav" slot="reference" icon="el-icon-star-on" circle
                                     size="small"/>
                        </el-popover>
                        <el-button v-if="!studyLabResPkg.beFav" icon="el-icon-star-off" circle size="small"
                                   @click="toFav(studyLabResPkg)"/>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <el-checkbox
                  :key="studyLabResPkg.id"
                  v-model="studyLabResPkg.checked"
                  class="res-pkg-check-box"
                  @change="handleResChecked(studyLabResPkg,$event)"
                ></el-checkbox>
              </el-card>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!--    Dialog    -->
    <div>
      <!-- Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableFavForm"
        :title="$t('cmn.fav')"
        append-to-body
        width="600px"
      >
        <el-form
          ref="favFormRef"
          :inline="true"
          :model="favForm"
          :rules="favFormRules"
          size="small"
          label-width="120px"
        >
          <el-form-item :label="$t('studyLab.resPkgName')" prop="resPkgName">
            <el-input v-model="favForm.resPkgName" disabled style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cmn.customName')" prop="customName">
            <el-input v-model="favForm.customName" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.customProfile')" prop="customProfile">
            <el-input v-model="favForm.customProfile" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.group')" prop="resPkgGroupIds">
            <el-tree
              v-model="favForm.resPkgGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              show-checkbox
              check-strictly
              lazy
              @check="handleGroupTreeNodeCheck"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchFavForm"
        title="批量收藏"
        append-to-body
        width="600px"
      >
        <el-form
          ref="batchFavFormRef"
          :inline="true"
          :model="batchFavForm"
          :rules="batchFavFormRules"
          size="small"
          label-width="120px"
        >
          <el-form-item :label="$t('toolHall.toolList')" prop="resPkgIds">
            <el-select
              v-model="batchFavForm.resPkgIds"
              :multiple="true"
              clearable
              style="width: 400px;"
            >
              <el-option
                v-for="resPkg in checkedResPkgMap.values()"
                :key="resPkg.id"
                :label="resPkg.name"
                :value="resPkg.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('cmn.group')" prop="resPkgGroupIds">
            <el-tree
              v-model="batchFavForm.resPkgGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              show-checkbox
              check-strictly
              lazy
              @check="handleGroupTreeNodeCheckForBatchFav"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Cancel Fav Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchCancelFavForm"
        :title="$t('cmn.cancelFav')"
        append-to-body
        width="600px"
      >
        <el-form
          ref="batchCancelFavFormRef"
          :inline="true"
          :model="batchCancelFavForm"
          :rules="batchCancelFavFormRules"
          size="small"
          label-width="120px"
        >
          <el-form-item :label="$t('toolHall.toolList')" prop="resPkgIds">
            <el-select
              v-model="batchCancelFavForm.resPkgIds"
              :multiple="true"
              clearable
              style="width: 400px;"
            >
              <el-option
                v-for="tool in checkedResPkgMap.values()"
                :key="tool.id"
                :label="tool.name"
                :value="tool.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableBatchCancelFavForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="doBatchCancelFav">{{ $t('cmn.confirm') }}</el-button>
        </div>
      </el-dialog>

      <!-- Pur Order Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enablePurOrderForm"
        :title="$t('trade.purOrder')"
        append-to-body
        width="600px"
      >
        <el-form
          ref="purOrderFormRef"
          :inline="true"
          :model="purOrderForm"
          :rules="purOrderFormRules"
          size="small"
          label-width="120px"
        >
          <el-form-item :label="$t('studyLab.resPkgName')" prop="resPkgName">
            <el-input v-model="purOrderForm.resPkgName" disabled style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cmn.customName')" prop="customName">
            <el-input v-model="purOrderForm.customName" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.customProfile')" prop="customProfile">
            <el-input v-model="purOrderForm.customProfile" style="width: 300px;"/>
          </el-form-item>
          <el-form-item :label="$t('cmn.group')" prop="resPkgGroupIds">
            <el-tree
              v-model="purOrderForm.resPkgGroupIds"
              :data="groupTrees"
              node-key="id"
              :load="loadGroupTrees"
              :props="{label: 'name', children: 'children', hasChildren: 'name'}"
              :expand-on-click-node="false"
              show-checkbox
              check-strictly
              lazy
              @check="handleGroupTreeNodeCheckForPurOrder"
            />
          </el-form-item>
          <div class="price-area">
            <span class="cur-price-span">￥{{ purOrderForm.curPrice }}</span>
            <span class="orig-price-span">￥{{ purOrderForm.origPrice }}</span>
          </div>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
          <span class="vjb-amt-span">{{ purOrderForm.orderPrice }}</span>
          <el-button type="danger" @click="doPurOrder">{{ $t('trade.purOrder') }}</el-button>
        </div>
      </el-dialog>

      <!-- Batch Pur Order Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableBatchPurForm"
        :title="$t('trade.batchPur')"
        append-to-body
        width="600px"
      >
        <el-form
          ref="batchPurFormRef"
          :model="batchPurForm"
          :rules="batchPurFormRules"
          size="small"
          label-width="50px"
        >
          <el-form-item prop="resPkgs">
            <el-card v-for="resPkg in batchPurForm.resPkgs" :key="resPkg.id" style="width: 400px">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="pur-res-pkg-img-area">
                    <el-row :gutter="5">
                      <el-col :span="12">
                        <img :src="resPkg.firstImgUrl" class="left-img">
                      </el-col>
                      <el-col :span="12">
                        <div>
                          <img :src="resPkg.secondImgUrl" class="right-img">
                        </div>
                        <div>
                          <img :src="resPkg.thirdImgUrl" class="right-img">
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <h2>{{ resPkg.name }}</h2>
                </el-col>
                <el-col :span="16">
                  <div class="pur-res-pkg-price-area">
                    <div v-if="resPkg.vojistarCardPricing">
                      <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                      <span class="final-price-span">{{ resPkg.finalPrice }}</span>
                      <el-tag
                        type="info" size="mini">
                        {{ $t('trade.' + resPkg.vojistarCardPricing.unid) }}
                      </el-tag>
                      <div>
                        <span class="final-cur-price-span">{{ resPkg.curPrice }}</span>
                        <span class="orig-price-span">{{ resPkg.origPrice }}</span>
                      </div>
                    </div>
                    <div v-else>
                      <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
                      <span class="cur-price-span">{{ resPkg.curPrice }}</span>
                      <span class="orig-price-span">{{ resPkg.origPrice }}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-form-item>
          <!--          <el-form-item :label="$t('cmn.group')" prop="resPkgGroupIds">-->
          <!--            <el-tree-->
          <!--              v-model="batchPurForm.resPkgGroupIds"-->
          <!--              :data="groupTrees"-->
          <!--              node-key="id"-->
          <!--              :load="loadGroupTrees"-->
          <!--              :props="{label: 'name', children: 'children', hasChildren: 'name'}"-->
          <!--              :expand-on-click-node="false"-->
          <!--              :check-strictly="true"-->
          <!--              show-checkbox-->
          <!--              lazy-->
          <!--              @check="handleGroupTreeNodeCheck"-->
          <!--            />-->
          <!--          </el-form-item>-->
          <!--          <el-form-item :label="$t('trade.orderPrice')" prop="price">{{-->
          <!--              batchPurForm.price-->
          <!--            }}-->
          <!--          </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <div v-if="batchPurForm.price > this.walletVjb">
            <span>{{ $t('trade.walletVjb') }}</span>{{ this.walletVjb }}
          </div>
          <i class="iconfont vj-icon-menu-soybean vjb-icon"></i>
          <span class="vjb-amt-span">{{ batchPurForm.price }}</span>
          <el-button v-if="batchPurForm.price > this.walletVjb" type="primary" @click="toTopUp">
            {{ $t('trade.toTopUp') }}
          </el-button>
          <el-button v-else type="danger" @click="doBatchPurOrder">{{ $t('trade.orderPay') }}</el-button>
        </div>
      </el-dialog>

      <!--  Pur Pay Dialog-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enablePurPayDialog"
        :title="$t('trade.purPay')"
        append-to-body
        width="800px"
      >
        <el-form
          ref="purPayFormRef"
          :inline="true"
          :model="purPayForm"
          :rules="purPayFormRules"
          size="small"
          label-width="100px"
        >
          <el-form-item :label="$t('trade.walletVjb')" prop="walletVjb">
            {{
              purPayForm.walletVjb
            }}
          </el-form-item>

          <el-form-item :label="$t('trade.origPrice')" prop="origPrice">
            {{
              purPayForm.origPrice
            }}
          </el-form-item>
          <el-form-item :label="$t('trade.curPrice')" prop="curPrice">
            {{
              purPayForm.curPrice
            }}
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enablePurPayDialog=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" @click="purPay">{{ $t('trade.confirmPay') }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { DEFAULT_FIND_BY_PAGE_PO, DEFAULT_ORDER_BY } from '@/constant/CmnBizConst'

import ResTagApi from '@/api/vojivosvc/studylab/ResTagApi'
import ResPkgApi from '@/api/vojivosvc/studylab/ResPkgApi'
import UsrzGroupApi from '@/api/vojivosvc/studylab/UsrzResPkgGroupApi'
import UsrzFavResPkgApi from '@/api/vojivosvc/studylab/UsrzFavResPkgApi'
import UsrzPurResPkgApi from '@/api/vojivosvc/studylab/UsrzPurResPkgApi'
import lodash from 'lodash'
import ProtRule from '@/constant/ProtRule'
import VjbWalletApi from '@/api/tradecenter/VjbWalletApi'
import BizConfig from '@/config/BizConfig'

export default {
  name: 'StudyLab',
  data () {
    return {
      isExtended: true,
      defaultPage: {
        total: 0,
        current: 1,
        size: 10
      },
      pageInfo: {},
      delaySrchTimeoutId: null,
      srchResTagTreeRootIds: [],
      findByPageIp: {
        ...lodash.cloneDeep(DEFAULT_FIND_BY_PAGE_PO),
        parentTagIds: [],
        growthStage: null,
        carrierType: null
      },
      resTagTrees: [
        {
          id: 0,
          label: 'NULL PARENT',
          children: null
        }
      ],
      tagBarResTagTrees: [],
      tagBarSrchKw: null,
      isLoadingMoreResPkg: true,
      studyLabResPkgList: [],
      checkedResPkgMap: new Map(),
      enableFavForm: false,
      favForm: {
        resPkgName: null,
        resPkgId: null,
        resPkgGroupIds: [],
        customName: null,
        customProfile: null
      },
      favFormRules: {},
      enablePurOrderForm: false,
      purOrderForm: {
        resPkgName: null,
        resPkgId: null,
        resPkgGroupIds: [],
        customName: null,
        customProfile: null,
        origPrice: null,
        curPrice: null,
        finalPrice: null,
        orderPrice: null,
        vojistarCardPricing: null
      },
      purOrderFormRules: {},
      enableBatchFavForm: false,
      batchFavForm: {
        resPkgNames: [],
        resPkgIds: [],
        resPkgGroupIds: []
      },
      batchFavFormRules: {},
      enableBatchCancelFavForm: false,
      batchCancelFavForm: {
        resPkgNames: [],
        resPkgIds: []
      },
      batchCancelFavFormRules: {},
      enableBatchPurForm: false,
      batchPurForm: {
        resPkgs: [],
        price: 0,
        resPkgGroupIds: []
      },
      batchPurFormRules: {},
      groupTrees: [],
      checkedGroupIds: [],
      enablePurPayDialog: false,
      purPayForm: {
        orderId: null,
        txnId: null,
        origPrice: 0,
        curPrice: 0,
        walletVjb: 0
      },
      purPayFormRules: {},
      walletVjb: 0
    }
  },
  created () {
    this.doSrch()
    this.initFormRules()
  },
  methods: {
    initFormRules () {
      this.favFormRules = {
        resPkgGroupIds: [
          ProtRule.cmnRule().cmnBatchSize
        ],
        customName: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().length_64
        ],
        customProfile: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().cmnProfileLength
        ]
      }
      this.batchFavFormRules = {
        resPkgIds: [
          ProtRule.cmnRule().cmnBatchSize
        ],
        resPkgGroupIds: [
          ProtRule.cmnRule().mBatchSize
        ]
      }
      this.batchCancelFavFormRules = {
        resPkgIds: [
          ProtRule.cmnRule().cmnBatchSize
        ]
      }
      this.purOrderFormRules = {
        resPkgGroupIds: [
          ProtRule.cmnRule().mBatchSize
        ],
        customName: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().length_64
        ],
        customProfile: [
          ProtRule.cmnRule().notWhite,
          ProtRule.cmnRule().cmnProfileLength
        ],
        origPrice: [
          ProtRule.cmnRule().priceRule
        ],
        curPrice: [
          ProtRule.cmnRule().priceRule
        ]
      }
      this.batchPurFormRules = {
        resPkgs: [
          ProtRule.cmnRule().mBatchSize
        ],
        resPkgGroupIds: [
          ProtRule.cmnRule().mBatchSize
        ]
      }
    },
    inExtend () {
      this.isExtended = !this.isExtended
    },
    /** *************** Srch ******************** **/
    loadMoreResPkg () {
      this.isLoadingMoreResPkg = true
      if (this.pageInfo.current >= this.pageInfo.total / this.pageInfo.size) {
        return
      }
      this.pageInfo.current += 1
      this.doSrch(true)
      this.doSrch(true)
    },
    delaySrch () {
      this.clearDelaySrchTimeout()
      this.delaySrchTimeoutId = setTimeout(() => {
        this.doSrch()
      }, BizConfig.cmnDelayMsec)
    },
    clearDelaySrchTimeout () {
      if (this.delaySrchTimeoutId) {
        clearTimeout(this.delaySrchTimeoutId)
        this.delaySrchTimeoutId = null
      }
    },
    doSrch (isLoadingMore) {
      if (isLoadingMore) {
        this.findByPageIp.pageIp.current = this.pageInfo.current
        this.findByPageIp.pageIp.size = this.pageInfo.size
      } else {
        this.findByPageIp.pageIp = { ...this.defaultPage }
      }
      this.findByPageIp.resTagTreeRootIds = this.srchResTagTreeRootIds
      ResPkgApi.findByPage(this.findByPageIp).then(pageOp => {
        this.isLoadingMoreResPkg = false
        this.refreshPageInfo(pageOp)
        const data = pageOp.data
        data.map(item => {
          if (this.checkedResPkgMap.has(item.id)) {
            this.$set(item, 'checked', true)
          } else {
            this.$set(item, 'checked', false)
          }
          return item
        })
        if (isLoadingMore) {
          this.studyLabResPkgList.push(...data)
        } else {
          this.studyLabResPkgList = data
        }
      })
    },

    refreshPageInfo (pageOp) {
      this.pageInfo.size = pageOp.pageIp.size
      this.pageInfo.current = pageOp.pageIp.current
      this.pageInfo.total = pageOp.total
    },
    doResetSrch () {
      this.findByPageIp = { ...DEFAULT_FIND_BY_PAGE_PO }
      this.doSrch()
    },
    doRefresh () {
      this.doSrch()
    },
    handleSortChange (data) {
      const orderBy = { ...DEFAULT_ORDER_BY }
      orderBy.key = data.prop
      orderBy.asc = data.order === 'ascending'
      this.orderBys = [
        { ...orderBy }
      ]
      this.doSrch()
    },
    loadTagBarResTagTrees (node, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        ResTagApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.tagBarResTagTrees = resultData
            }
          })
      }, 100)
    },
    async srchResTag () {
      if (this.tagBarSrchKw === null || this.tagBarSrchKw.trim() === '') {
        const findTreesIp = {
          srchIp: null,
          timeIp: null,
          orderBys: [
            {
              key: 'rankValue',
              asc: true
            }
          ],
          parentIds: [],
          treeHeight: 1
        }
        this.tagBarResTagTrees = await ResTagApi.findTrees(findTreesIp)
        return
      }
      const ip = {
        srchIp: {
          srchKey: null,
          srchKw: this.tagBarSrchKw
        },
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        pageIp: {
          current: 1,
          size: 100
        }
      }
      this.tagBarResTagTrees = (await ResTagApi.findByPage(ip)).data
    },
    handleTagBarTreeNodeClicked (data) {
      if (this.srchResTagTreeRootIds.includes(data.id)) {
        this.srchResTagTreeRootIds.remove(data.id)
      } else {
        this.srchResTagTreeRootIds.push(data.id)
      }
      this.doSrch()
    },
    handleTagTreeNodeCheck (data, checkedData) {
      this.srchResTagTreeRootIds = []
      this.srchResTagTreeRootIds.push(...checkedData.checkedKeys)
      this.doSrch()
    },
    handleResChecked (studyLabRes, isChecked) {
      if (isChecked) {
        this.checkedResPkgMap.set(studyLabRes.id, studyLabRes)
      } else {
        this.checkedResPkgMap.delete(studyLabRes.id)
      }
    },
    checkCurPage () {
      this.studyLabResPkgList.map(item => {
        // 这里，给对象添加属性，用$set方法。
        this.$set(item, 'checked', true)
        this.checkedResPkgMap.set(item.id, item)
        return item
      })
    },
    cancelCheckAll () {
      this.studyLabResPkgList.map(item => {
        // 这里，给对象添加属性，用$set方法。
        this.$set(item, 'checked', false)
        this.checkedResPkgMap.delete(item.id)
        return item
      })
    },
    /** *************** Fav ******************** **/

    toFav (resPkg) {
      this.enableFavForm = true
      this.favForm.resPkgId = resPkg.id
      this.favForm.resPkgName = resPkg.name
      this.favForm.resPkgGroupIds = []
    },
    doFav () {
      this.$refs.favFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          ...this.favForm
        }
        UsrzFavResPkgApi.fav(ip).then(rd => {
          if (rd) {
            this.enableFavForm = false
            for (const resPkg of this.studyLabResPkgList) {
              if (resPkg.id === this.favForm.resPkgId) {
                resPkg.beFav = true
              }
            }
          }
        })
      })
    },
    handleGroupTreeNodeCheck (data, checkedData) {
      this.checkedGroupIds = []
      this.checkedGroupIds.push(...checkedData.checkedKeys)
      this.favForm.resPkgGroupIds = []
      this.favForm.resPkgGroupIds.push(...checkedData.checkedKeys)
    },
    /** *************** Cancel Fav ******************** **/
    doCancelFav (resPkg) {
      const ip = {
        resPkgIds: []
      }
      ip.resPkgIds.push(resPkg.id)
      UsrzFavResPkgApi.batchCancelFav(ip)
        .then(resultData => {
          if (resultData) {
            resPkg.beFav = false
            this.$refs['CancelFavPop.' + resPkg.id].at(0).doClose()
          }
        })
    },

    /** *************** Batch Fav ******************** **/
    toBatchFav () {
      this.enableBatchFavForm = true
      this.batchFavForm.resPkgIds = []
      this.batchFavForm.resPkgIds.push(...this.checkedResPkgMap.keys())
      this.batchFavForm.resPkgGroupIds = null
    },
    doBatchFav () {
      this.$refs.batchFavFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          resPkgIds: this.batchFavForm.resPkgIds,
          resPkgGroupIds: this.batchFavForm.resPkgGroupIds
        }
        UsrzFavResPkgApi.batchFav(ip)
          .then((rd) => {
            if (rd) {
              this.enableBatchFavForm = false
            }
          })
      })
    },
    handleGroupTreeNodeCheckForBatchFav (data, checkedData) {
      this.batchFavForm.resPkgGroupIds = []
      this.batchFavForm.resPkgGroupIds.push(...checkedData.checkedKeys)
    },

    /** *************** Batch Cancel Fav ******************** **/

    toBatchCancelFav () {
      this.enableBatchCancelFavForm = true
      this.batchCancelFavForm.resPkgIds.push(...this.checkedResPkgMap.keys())
    },
    doBatchCancelFav () {
      this.$refs.batchCancelFavFormRef.validate(isValid => {
        if (!isValid) {
          return
        }
        const ip = {
          resPkgIds: this.batchCancelFavForm.resPkgIds
        }
        UsrzFavResPkgApi.batchCancelFav(ip)
          .then(() => {
            this.enableBatchCancelFavForm = false
          })
      })
    },

    /** *************** Pur ******************** **/
    downloadOrPur (studyLabResPkg) {
      if (studyLabResPkg.bePur) {
        this.toDownloadResPkg(studyLabResPkg)
      } else {
        this.toPurOrderNew(studyLabResPkg)
      }
    },
    toDownloadResPkg (resPkg) {
      this.goToResPkgDetail(resPkg.id)
    },
    toPurOrder (resPkg) {
      this.enablePurOrderForm = true
      this.purOrderForm.resPkgId = resPkg.id
      this.purOrderForm.resPkgName = resPkg.name
      this.purOrderForm.curPrice = resPkg.curPrice
      this.purOrderForm.origPrice = resPkg.origPrice
      this.purOrderForm.finalPrice = resPkg.finalPrice
      this.purOrderForm.orderPrice = resPkg.finalPrice
      this.purOrderForm.vojistarCardPricing = resPkg.vojistarCardPricing
      this.purOrderForm.resPkgGroupIds = []
    },
    toPurOrderNew (resPkg) {
      this.enableBatchPurForm = true
      this.batchPurForm.resPkgs = []
      this.batchPurForm.price = 0
      this.batchPurForm.resPkgs.push({
        id: resPkg.id,
        name: resPkg.name,
        firstImgUrl: resPkg.firstImgUrl,
        secondImgUrl: resPkg.secondImgUrl,
        thirdImgUrl: resPkg.thirdImgUrl,
        origPrice: resPkg.origPrice,
        curPrice: resPkg.curPrice,
        finalPrice: resPkg.finalPrice,
        vojistarCardPricing: resPkg.vojistarCardPricing
      })
      this.batchPurForm.price += resPkg.finalPrice
      VjbWalletApi.findOrInit().then(op => {
        if (op) {
          this.walletVjb = op.walletVjb
        }
      })
    },
    handleGroupTreeNodeCheckForPurOrder (data, checkedData) {
      this.purOrderForm.resPkgGroupIds = []
      this.purOrderForm.resPkgGroupIds.push(...checkedData.checkedKeys)
    },
    doPurOrder () {
      const ip = {
        ...this.purOrderForm
      }
      UsrzPurResPkgApi.purOrder(ip)
        .then(op => {
          if (op && op.resPkgOrderOp) {
            const order = op.resPkgOrderOp
            this.enablePurOrderForm = false
            this.purPayForm = {
              orderId: order.id,
              txnId: order.txnId,
              price: order.price
            }
            VjbWalletApi.findOrInit().then(rd => {
              if (rd) {
                this.purPayForm.walletVjb = rd.walletVjb
                this.enablePurPayDialog = true
              }
            })
          }
        })
    },
    purPay (resPkgOrder) {
      const ip = {
        orderId: resPkgOrder.id,
        orderPrice: resPkgOrder.price,
        txnId: resPkgOrder.txnId
      }
      UsrzPurResPkgApi.purPay(ip)
        .then(op => {
          if (op) {
            this.enableBatchPurForm = false
            this.doSrch()
          }
        })
    },

    /** *************** Batch Pur ******************** **/
    toBatchPur () {
      this.batchPurForm.resPkgs = []
      this.batchPurForm.price = 0
      for (const resPkg of this.checkedResPkgMap.values()) {
        if (resPkg.bePur) {
          continue
        }
        this.batchPurForm.resPkgs.push({
          id: resPkg.id,
          name: resPkg.name,
          firstImgUrl: resPkg.firstImgUrl,
          secondImgUrl: resPkg.secondImgUrl,
          thirdImgUrl: resPkg.thirdImgUrl,
          origPrice: resPkg.origPrice,
          curPrice: resPkg.curPrice,
          finalPrice: resPkg.finalPrice,
          vojistarCardPricing: resPkg.vojistarCardPricing
        })
        this.batchPurForm.price += resPkg.finalPrice
      }
      if (this.batchPurForm.resPkgs.length < 1) {
        this.$message.info(this.$t('trade.allCheckedBePur'))
        return
      }
      this.enableBatchPurForm = true
      VjbWalletApi.findOrInit().then(op => {
        if (op) {
          this.walletVjb = op.walletVjb
        }
      })
    },
    doBatchPurOrder () {
      const ip = {
        ...this.batchPurForm
      }
      UsrzPurResPkgApi.batchPurOrder(ip)
        .then(purOrderOp => {
          this.purPay(purOrderOp.resPkgOrderOp)
        })
    },

    toTopUp () {
      this.$router.push('/perCenter/vjbJar')
    },

    loadGroupTrees (node, resolve) {
      const findTreesIp = {
        srchIp: null,
        timeIp: null,
        orderBys: [
          {
            key: 'rankValue',
            asc: true
          }
        ],
        parentIds: [],
        treeHeight: 1
      }
      if (node && node.data && node.data.id) {
        findTreesIp.parentIds = [node.data.id]
      }
      setTimeout(() => {
        UsrzGroupApi.findTrees(findTreesIp)
          .then(resultData => {
            if (resolve) {
              resolve(resultData)
            } else {
              this.groupTrees = resultData
            }
          })
      }, 100)
    },
    goToResPkgDetail (resPkgId) {
      const routeData = this.$router.resolve({
        path: '/resPkgDetail',
        query: {
          resPkgId: resPkgId
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleCmd (cmd) {
      switch (cmd) {
        case 'checkCurPage':
          this.checkCurPage()
          break
        case 'cancelCheckAll':
          this.cancelCheckAll()
          break
        case 'batchFav':
          this.toBatchFav()
          break
        case 'batchPur':
          this.toBatchPur()
          break
        case 'batchCancelFav':
          this.toBatchCancelFav()
          break
      }
    }
  }
}
</script>
