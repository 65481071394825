<template>
  <div>
    <div class="unit-input-div">
      <el-input class="item unit-converter-input-number-area" v-model="input" @input="onInput"></el-input>
      <el-select class="item" v-model="unit1" filterable @change="onUnitChange" ref="unit1OptionRef">
        <el-option-group
          v-for="unitOption in unitOptions"
          :key="unitOption.categ"
          :label="unitOption.categ">
          <el-option
            v-for="(item, idx) in unitOption.units"
            :key="unitOption.categ + '.' + idx"
            :label="item.unitName"
            :value="item.unitKey">
          </el-option>
        </el-option-group>
      </el-select>
      <el-button class="item" icon="el-icon-refresh" circle @click="exchangeUnit"></el-button>
      <el-select class="item" v-model="unit2" filterable @change="onUnitChange" ref="unit2OptionRef">
        <el-option-group
          v-for="unitOption in unitOptions"
          :key="unitOption.categ"
          :label="unitOption.categ">
          <el-option
            v-for="(item, idx) in unitOption.units"
            :key="unitOption.categ + '.' + idx"
            :label="item.unitName"
            :value="item.unitKey">
          </el-option>
        </el-option-group>
      </el-select>
      <el-button class="item" icon="el-icon-more" circle @click="showAll"></el-button>
    </div>
    <div v-if="this.result">
      <el-divider></el-divider>
      <h3 v-if="this.result">{{ this.result }}</h3>
    </div>
    <el-divider></el-divider>
    <div>
      <el-table
        :data="tableData"
        height="500px"
        border
        :span-method="objectSpanMethod"
        style="width: 100%">
        <el-table-column
          prop="categ"
          :label="$t('unitConverter.categ')"
          width="150">
        </el-table-column>
        <el-table-column
          prop="unitName"
          :label="$t('unitConverter.unit')"
          width="180">
        </el-table-column>
        <el-table-column
          prop="fromSiuFormula"
          label="FROM Mem(B)">
        </el-table-column>
        <el-table-column
          prop="toSiuFormula"
          label="TO Mem(B)">
        </el-table-column>
        <el-table-column
          prop="remark"
          :label="$t('cmn.remark')">
        </el-table-column>
      </el-table>
    </div>

    <div>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableShowAllDialog"
        append-to-body
      >
        <div style="display: flex">
          <el-input class="unit-converter-input-number-area" v-model="input" @input="onInput"></el-input>
          <el-select v-model="unit1" @change="onUnitChange" ref="unit1OptionRef">
            <el-option-group
              v-for="unitOption in unitOptions"
              :key="unitOption.categ"
              :label="unitOption.categ">
              <el-option
                v-for="(item, idx) in unitOption.units"
                :key="unitOption.categ + '.' + idx"
                :label="item.unitName"
                :value="item.unitKey">
              </el-option>
            </el-option-group>
          </el-select>
        </div>
        <div>
          <div v-for="(item, idx) in allUnitResult" :key="idx">
            {{ item }}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js'

const math = require('mathjs')

export default {
  name: 'StorageUnit',
  computed: {
    result: {
      get () {
        return this.calUnitResult()
      },
      set (v) {
      }
    },
    allUnitResult: {
      get () {
        return this.calAllUnit()
      },
      set (v) {
      }
    },
    unitData: {
      get () {
        return {
          siu: {
            byte: {
              categ: this.$t('unitConverter.siu'),
              unitName: this.$t('storageUnit.byte'),
              unitKey: 'byte',
              fromSiuFormula: 'Mem(B) = Mem(B)',
              toSiuFormula: 'Mem(B) = Mem(B)',
              fromSiuExp: 'x * 1',
              toSiuExp: 'y * 1',
              remark: this.$t('storageUnit.remark.byte')
            }
          },
          regularUnit: {
            bit: {
              categ: this.$t('unitConverter.regularUnit'),
              unitName: this.$t('storageUnit.bit'),
              unitKey: 'bit',
              fromSiuFormula: 'Mem(bit) = Mem(B) * 8',
              toSiuFormula: 'Mem(B) = Mem(bit) / 8',
              fromSiuExp: 'x * 8',
              toSiuExp: 'y / 8',
              remark: this.$t('storageUnit.remark.bit')
            },
            byte: {
              categ: this.$t('unitConverter.regularUnit'),
              unitName: this.$t('storageUnit.byte'),
              unitKey: 'byte',
              fromSiuFormula: 'Mem(B) = Mem(B)',
              toSiuFormula: 'Mem(B) = Mem(B)',
              fromSiuExp: 'x * 1',
              toSiuExp: 'y * 1',
              remark: this.$t('storageUnit.remark.byte')
            },
            kb: {
              categ: this.$t('unitConverter.regularUnit'),
              unitName: this.$t('storageUnit.kb'),
              unitKey: 'kb',
              fromSiuFormula: 'Mem(KB) = Mem(B) / 1024',
              toSiuFormula: 'Mem(B) = Mem(KB) * 2^10 = Mem(KB) * 1024',
              fromSiuExp: 'x / 1024',
              toSiuExp: 'y * 1024',
              remark: this.$t('storageUnit.remark.kb')
            },
            mb: {
              categ: this.$t('unitConverter.regularUnit'),
              unitName: this.$t('storageUnit.mb'),
              unitKey: 'mb',
              fromSiuFormula: 'Mem(MB) = Mem(B) / 1024^2',
              toSiuFormula: 'Mem(B) = Mem(MB) * 2^20 = Mem(MB) * 1024^2',
              fromSiuExp: 'x / (1024^2)',
              toSiuExp: 'y * 1024^2',
              remark: this.$t('storageUnit.remark.mb')
            },
            gb: {
              categ: this.$t('unitConverter.regularUnit'),
              unitName: this.$t('storageUnit.gb'),
              unitKey: 'gb',
              fromSiuFormula: 'Mem(GB) = Mem(B) / 1024^3',
              toSiuFormula: 'Mem(B) = Mem(GB) * 2^30 = Mem(GB) * 1024^3',
              fromSiuExp: 'x / (1024^3)',
              toSiuExp: 'y * 1024^3',
              remark: this.$t('storageUnit.remark.gb')
            },
            tb: {
              categ: this.$t('unitConverter.regularUnit'),
              unitName: this.$t('storageUnit.tb'),
              unitKey: 'tb',
              fromSiuFormula: 'Mem(TB) = Mem(B) / 1024^4',
              toSiuFormula: 'Mem(B) = Mem(TB) * 2^40 = Mem(TB) * 1024^4',
              fromSiuExp: 'x / (1024^4)',
              toSiuExp: 'y * 1024^4',
              remark: this.$t('storageUnit.remark.tb')
            },
            pb: {
              categ: this.$t('unitConverter.regularUnit'),
              unitName: this.$t('storageUnit.pb'),
              unitKey: 'pb',
              fromSiuFormula: 'Mem(PB) = Mem(B) / 1024^5',
              toSiuFormula: 'Mem(B) = Mem(PB) * 2^50 = Mem(PB) * 1024^5',
              fromSiuExp: 'x / (1024^5)',
              toSiuExp: 'y * 1024^5',
              remark: this.$t('storageUnit.remark.pb')
            },
            eb: {
              categ: this.$t('unitConverter.regularUnit'),
              unitName: this.$t('storageUnit.eb'),
              unitKey: 'eb',
              fromSiuFormula: 'Mem(PB) = Mem(B) / 1024^6',
              toSiuFormula: 'Mem(B) = Mem(PB) * 2^60 = Mem(PB) * 1024^6',
              fromSiuExp: 'x / (1024^6)',
              toSiuExp: 'y * 1024^6',
              remark: this.$t('storageUnit.remark.eb')
            }
          }
        }
      },
      set (v) {
      }
    },
    unitOptions: {
      get () {
        return [
          {
            categ: this.$t('unitConverter.siu'),
            units: Object.values(this.unitData.siu)
          },
          {
            categ: this.$t('unitConverter.regularUnit'),
            units: Object.values(this.unitData.regularUnit)
          }
        ]
      },
      set (v) {
      }
    },
    tableData: {
      get () {
        return [...Object.values(this.unitData.siu), ...Object.values(this.unitData.regularUnit)
        ]
      },
      set (v) {
      }
    }
  },
  data () {
    return {
      unit1: 'gb',
      unit2: 'byte',
      input: null,
      enableShowAllDialog: false
    }
  },
  methods: {
    onInput () {
      this.result = this.calUnitResult()
      if (this.enableShowAllDialog) {
        this.showAll()
      }
    },

    onUnitChange () {
      this.result = this.calUnitResult()
      if (this.enableShowAllDialog) {
        this.showAll()
      }
    },

    showAll () {
      this.allUnitResult = this.calAllUnit()
      this.enableShowAllDialog = true
    },
    exchangeUnit () {
      const tempUnit = this.unit1
      this.unit1 = this.unit2
      this.unit2 = tempUnit
      this.onUnitChange()
    },
    calAllUnit () {
      const allUnitResult = []
      for (let i = 0; i < this.unitOptions.length; i++) {
        if (i !== 0) {
          const unitOptions = this.unitOptions.at(i).units
          for (const unitOption of unitOptions) {
            allUnitResult.push(this.calResult(unitOption))
          }
        }
      }
      return allUnitResult
    },
    calUnitResult () {
      if (this.input && this.unit1 && this.unit2) {
        const inputNum = new BigNumber(math.evaluate(this.input))
        if (inputNum.isFinite()) {
          const unit1Data = this.getUnitDataByUnitKey(this.unit1)
          const unit2Data = this.getUnitDataByUnitKey(this.unit2)
          if (unit1Data && unit2Data) {
            const to = math.evaluate(unit1Data.toSiuExp.replaceAll('y', this.input))
            const from = math.evaluate(unit2Data.fromSiuExp.replaceAll('x', to))
            return this.input + ' ' + this.$refs.unit1OptionRef.selected.label + '  =  ' + from + ' ' + this.$refs.unit2OptionRef.selected.label
          } else {
            return null
          }
        }
        return null
      } else {
        return null
      }
    },
    calResult (resultUnit) {
      if (this.input && this.unit1 && resultUnit) {
        const inputNum = new BigNumber(math.evaluate(this.input))
        if (inputNum.isFinite()) {
          const unit1Data = this.getUnitDataByUnitKey(this.unit1)
          const unit2Data = resultUnit
          if (unit1Data && unit2Data) {
            const to = math.evaluate(unit1Data.toSiuExp.replaceAll('y', this.input))
            const from = math.evaluate(unit2Data.fromSiuExp.replaceAll('x', to))
            return this.input + ' ' + this.$refs.unit1OptionRef.selected.label + '  =  ' + from + ' ' + resultUnit.unitName
          } else {
            return null
          }
        }
        return null
      } else {
        return null
      }
    },
    getUnitDataByUnitKey (unitKey) {
      for (const unit of Object.values(this.unitData)) {
        if (unit && unit[unitKey]) {
          return unit[unitKey]
        }
      }
      return null
    },
    objectSpanMethod ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 1,
            colspan: 1
          }
        }
        if (rowIndex === 1) {
          return {
            rowspan: 8,
            colspan: 1
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
