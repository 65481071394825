<template>
  <el-container>
    <el-aside width="150px">
      <div>
        <tool-shower tool-unid="Hash"></tool-shower>
      </div>
      <el-menu
        router
        :default-active="this.$route.path"
      >
        <menu-tree :menu-list="menuList"></menu-tree>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import MenuTree from '@/components/MenuTree.vue'
import ToolShower from '@/components/ToolShower.vue'

export default {
  name: 'Hash',
  components: {
    ToolShower,
    MenuTree
  },
  data () {
    return {
      menuList: [
        {
          index: '/tool/hash/hashIntro',
          icon: 'iconfont vj-icon-jisuanwenbenhaxizhi',
          nameKey: 'hash.hashIntro',
          name: '哈希介绍',
          children: []
        },
        {
          index: '/tool/hash/md5',
          icon: 'iconfont vj-icon-MD5',
          name: 'MD5',
          children: []
        },
        {
          index: '/tool/hash/sha256',
          icon: 'iconfont vj-icon-SHA-256',
          name: 'SHA256',
          children: []
        },
        {
          index: '/tool/hash/sha1',
          icon: 'iconfont vj-icon-haxijisuan',
          name: 'SHA1',
          children: []
        },
        {
          index: '/tool/hash/sha512',
          icon: 'iconfont vj-icon-haxijisuan',
          name: 'SHA512',
          children: []
        },
        {
          index: '/tool/hash/crc32',
          icon: 'iconfont vj-icon-iconjieshu',
          name: 'CRC32',
          children: []
        }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
</style>
