<template>
  <div>
    <div class="code-container">
      <p>{{ $t('enDecoder.enDecodeDefine') }}</p>
    </div>
    <el-divider></el-divider>
    <el-table
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="coding"
        :label="$t('enDecoder.coding')"
        sortable
        width="110px">
      </el-table-column>
      <el-table-column
        prop="define"
        :label="$t('enDecoder.define')"
      >
      </el-table-column>
      <el-table-column
        prop="principle"
        :label="$t('enDecoder.principle')"
      >
      </el-table-column>
      <el-table-column
        prop="usageScenario"
        :label="$t('enDecoder.usageScenario')"
      >
      </el-table-column>
    </el-table>
    <el-divider></el-divider>
    <el-table
      :data="charCodingTableData"
      border
      :span-method="objectSpanMethod"
      style="width: 100%">
      <el-table-column
        prop="charset"
        :label="$t('enDecoder.charset')"
        width="110px"
      >
      </el-table-column>
      <el-table-column
        prop="charCoding"
        :label="$t('enDecoder.charCoding')"
        width="110px"
      >
      </el-table-column>
      <el-table-column
        prop="intro"
        :label="$t('enDecoder.intro')"
      >
      </el-table-column>
    </el-table>
    <el-divider></el-divider>
    <div class="code-container">
      <p>{{ $t('enDecoder.beLe') }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EnDecodeOverview',
  watch: {
    '$i18n.locale': {
      handler: function (newVal, oldVal) {
        this.changeTableData(newVal)
      }
    },
    immediate: true
  },
  data () {
    return {
      tableData: [],
      tableDataZh: [
        {
          coding: 'BASE64',
          define: 'Base64编码是基于64个ASCII可打印字符来表示任意二进制数据的解码方式。',
          principle: '将三个字节的二进制数据，按6位一组，转换为4个可打印字符。如果数据长度不是3的倍数，会使用特殊字符进行填充。',
          usageScenario: '一般用于在HTTP协议(文本协议)下传输二进制数据'
        },
        {
          coding: 'URL',
          define: 'URL编码是一种将非ASCII字符和特殊字符转换为%加上两位十六进制数的编码方式，用于在URL中传输数据',
          principle: '将非ASCII字符和特殊字符转换为%加上两位十六进制数，ASCII字符保持不变',
          usageScenario: '主要用于在URL中传输数据，因为URL不支持非ASCII字符和特殊字符。'
        },
        {
          coding: 'HEX',
          define: 'Hex编码是一种将二进制数据转换为十六进制字符串的编码方式。',
          principle: '将每个字节的二进制数据，转换为两个十六进制字符。',
          usageScenario: '主要用于数据的可视化，因为二进制数据不易于阅读和处理，而十六进制数据可以直观地表示二进制数据。'
        },
        {
          coding: '字符',
          define: '字符编码是一种将字符转换为二进制数据的编码方式。',
          principle: '为每个字符分配一个唯一的二进制编码',
          usageScenario: '主要用于计算机内部处理文本数据，因为计算机只能处理二进制数据。'
        }
      ],
      tableDataEn: [
        {
          coding: 'BASE64',
          define: 'Base64编码是基于64个ASCII可打印字符来表示任意二进制数据的解码方式。',
          principle: '将三个字节的二进制数据，按6位一组，转换为4个可打印字符。如果数据长度不是3的倍数，会使用特殊字符进行填充。',
          usageScenario: '一般用于在HTTP协议(文本协议)下传输二进制数据'
        },
        {
          coding: 'URL',
          define: 'URL编码是一种将非ASCII字符和特殊字符转换为%加上两位十六进制数的编码方式，用于在URL中传输数据',
          principle: '将非ASCII字符和特殊字符转换为%加上两位十六进制数，ASCII字符保持不变',
          usageScenario: '主要用于在URL中传输数据，因为URL不支持非ASCII字符和特殊字符。'
        },
        {
          coding: 'HEX',
          define: 'Hex编码是一种将二进制数据转换为十六进制字符串的编码方式。',
          principle: '将每个字节的二进制数据，转换为两个十六进制字符。',
          usageScenario: '主要用于数据的可视化，因为二进制数据不易于阅读和处理，而十六进制数据可以直观地表示二进制数据。'
        },
        {
          coding: '字符',
          define: '字符编码是一种将字符转换为二进制数据的编码方式。',
          principle: '为每个字符分配一个唯一的二进制编码',
          usageScenario: '主要用于计算机内部处理文本数据，因为计算机只能处理二进制数据。'
        }
      ],
      charCodingTableData: [],
      charCodingTableDataZh: [
        {
          charset: 'ASCII',
          charCoding: 'ASCII',
          intro: '共256个字符。ASCII基本字符集使用7位（bits）表示一个字符，共128字符；ASCII扩展字符集使用8位（bits）表示一个字符，共256字符。'
        },
        {
          charset: '汉字',
          charCoding: 'GB2312',
          intro: '共收录6763个汉字'
        },
        {
          charset: '汉字',
          charCoding: 'GBK',
          intro: '共收录21886个字符，完全兼容GB2312'
        },
        {
          charset: '汉字',
          charCoding: 'GB18030',
          intro: '共收录七万多个字符，基本兼容GBK，完全兼容GB2312'
        },
        {
          charset: 'UNICODE',
          charCoding: 'UTF-8',
          intro: '变长编码，每个字符使用1到4个字节来存储'
        },
        {
          charset: 'Unicode',
          charCoding: 'UTF-16',
          intro: '变长编码，BMP(U+0000-U+FFFF)内的字符均用2个字节存储，其他字符用4个字节存储'
        },
        {
          charset: 'Unicode',
          charCoding: 'UTF-32',
          intro: '定长编码，规定每个字符使用四个字节存储'
        }
      ],
      charCodingTableDataEn: []
    }
  },
  created () {
    this.changeTableData(this.$i18n.locale)
  },
  methods: {
    changeTableData (lang) {
      if (lang === 'en') {
        this.tableData = this.tableDataEn
        this.charCodingTableData = this.charCodingTableDataEn
      } else {
        this.tableData = this.tableDataZh
        this.charCodingTableData = this.charCodingTableDataZh
      }
    },
    objectSpanMethod ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 1,
            colspan: 1
          }
        }
        if (rowIndex === 1) {
          return {
            rowspan: 3,
            colspan: 1
          }
        }
        if (rowIndex === 4) {
          return {
            rowspan: 3,
            colspan: 1
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.code-container {
  white-space: pre-wrap;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  padding-left: 10px;
  margin-bottom: 10px;
}
</style>
