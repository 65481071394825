<template>
  <el-container>
    <el-aside width="150px">
      <div>
        <tool-shower tool-unid="UnitConverter"></tool-shower>
      </div>
      <el-menu :default-active="this.$route.path" router>
        <menu-tree :menu-list="this.menuList"></menu-tree>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>

</template>

<script>

import MenuTree from '@/components/MenuTree'
import ToolShower from '@/components/ToolShower.vue'

export default {
  name: 'UnitConverter',
  components: {
    ToolShower,
    MenuTree
  },
  data () {
    return {
      menuList: [
        {
          index: '/tool/unitConverter/timeUnit',
          icon: 'iconfont vj-icon-shijianzengjiarishifenmiaoshijianduan',
          name: '时间单位',
          nameKey: 'unitConverter.timeUnit',
          children: []
        },
        {
          index: '/tool/unitConverter/lengthUnit',
          icon: 'iconfont vj-icon-changdudanwei',
          name: '长度单位',
          nameKey: 'unitConverter.lengthUnit',
          children: []
        },
        {
          index: '/tool/unitConverter/areaUnit',
          icon: 'iconfont vj-icon-mianjidanwei',
          name: '面积单位',
          nameKey: 'unitConverter.areaUnit',
          children: []
        },
        {
          index: '/tool/unitConverter/volumeUnit',
          icon: 'iconfont vj-icon-chongzhilifangmi',
          name: '体积单位',
          nameKey: 'unitConverter.volumeUnit',
          children: []
        },
        {
          index: '/tool/unitConverter/massUnit',
          icon: 'iconfont vj-icon-kg',
          name: '质量单位',
          nameKey: 'unitConverter.massUnit',
          children: []
        },
        {
          index: '/tool/unitConverter/storageUnit',
          icon: 'iconfont vj-icon-zifuzhuanzijie',
          name: '存储单位',
          nameKey: 'unitConverter.storageUnit',
          children: []
        },
        {
          index: '/tool/unitConverter/tempUnit',
          icon: 'iconfont vj-icon-wendu',
          name: '温度单位',
          nameKey: 'unitConverter.tempUnit',
          children: []
        },
        {
          index: '/tool/unitConverter/speedUnit',
          icon: 'iconfont vj-icon-Speed-Meter',
          name: '速度单位',
          nameKey: 'unitConverter.speedUnit',
          children: []
        },
        {
          index: '/tool/unitConverter/angularUnit',
          icon: 'iconfont vj-icon-jiaodu',
          name: '角度单位',
          nameKey: 'unitConverter.angularUnit',
          children: []
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>
.el-container {
  height: 100%;
}

/*.el-menu-vertical-demo:not(.el-menu--collapse) {*/
/*  width: 200px;*/
/*  min-height: 400px;*/
/*}*/

.el-aside {
  height: 100%;
  background-color: #ffffff;
}
</style>
