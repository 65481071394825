<template>
  <div>
    <h2>【{{ $t('ieSpace.gameRoom') }}】 {{ $t('cmn.plsWaitingFor') }}</h2>
    <p>{{ $t('cmn.plsJoinUs') }}</p>
  </div></template>

<script>
export default {
  name: 'GameRoom',
  created () {
    console.log(this.$route.query)
  }
}
</script>

<style scoped>

</style>
