<template>
  <el-container>
    <el-header>
      <el-button circle @click="download" class="iconfont vj-icon-cloud-download"></el-button>
      <el-button circle @click="toSave" :disabled="isSaveDisabled" class="iconfont vj-icon-icon-baocunxinzeng">
      </el-button>
      <!--      <el-button @click="toGenCode">{{ $t('coCoder.newProject') }}</el-button>-->
      <!--      <el-button @click="toMyProject">{{ $t('coCoder.myProject') }}</el-button>-->
      <!--      <el-button @click="showExampleProject">{{ $t('coCoder.exampleProject') }}</el-button>-->
    </el-header>
    <el-container>
      <el-aside>
        <el-tree :data="codeGenTreeData" ref="projectTreeRef"
                 :props="defaultProps"
                 @node-click="onTreeNodeClick"></el-tree>
      </el-aside>
      <el-main>
        <div class="java-viewer-main">
          <div class="java-viewer" ref="javaViewer"></div>
        </div>
      </el-main>
    </el-container>
    <div>
      <!-- Save Form-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="enableSaveForm"
        :title="$t('cmn.save')"
        width="500px"
      >
        <el-form
          ref="saveFormRef"
          :model="saveForm"
          :rules="saveFormRules"
        >
          <el-form-item :label="$t('coCoder.projectName')" prop="projectData.projectName">
            <el-input v-model="saveForm.projectData.projectName"/>
          </el-form-item>

          <div v-if="saveForm.projectCountOverLimit">
              <span>{{
                  $t('trade.maxCountTip')
                }}: {{ saveForm.projectMaxCount }}，{{ $t('trade.vojistarCardNoLimitTip') }}</span>
            <el-button type="text" @click="goToPurVojistarCard">
              {{ $t('trade.click2VojistarCard') }}
            </el-button>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="enableSaveForm=false">{{ $t('cmn.cancel') }}</el-button>
          <el-button type="primary" :disabled="saveForm.projectCountOverLimit"
                     @click="doSave">
            {{
              $t('cmn.confirm')
            }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </el-container>
</template>

<script>

import CoCoderApi from '@/api/vojivosvc/toolsubject/CoCoderApi'
import UsrzCoCoderProjectApi from '@/api/vojivosvc/toolsubject/UsrzCoCoderProjectApi'
import UsrzBizConfigApi from '@/api/vojivosvc/UsrzBizConfigApi'

export default {
  name: 'CoCoderView',
  created () {
    this.init()
  },
  data () {
    return {
      projectId: null,
      genCodeData: {
        genCodeIp: null,
        genCodeOp: {
          codeGenTree: null,
          projectOp: null
        }
      },
      codeGenTreeData: [],
      projectData: null,
      defaultProps: {
        label: 'name',
        children: 'children'
      },
      editor: null,
      isSaveDisabled: true,
      enableSaveForm: false,
      saveForm: {
        projectCount: 0,
        projectMaxCount: 0,
        projectCountOverLimit: false,
        codeGenTree: null,
        projectData: {
          projectName: null
        }
      },
      saveFormRules: {}
    }
  },
  methods: {
    init () {
      if (this.genCodeData.genCodeOp && this.genCodeData.genCodeOp.codeGenTree) {
        this.codeGenTreeData = []
        this.codeGenTreeData.push(this.genCodeData.genCodeOp.codeGenTree)
        this.projectData = this.genCodeData.genCodeOp.projectOp
        this.updateCanSaveStatus()
        return
      }
      if (this.$route.params) {
        const genCodeData = this.$route.params.genCodeData
        if (genCodeData && genCodeData.genCodeOp && genCodeData.genCodeOp.codeGenTree) {
          this.codeGenTreeData = []
          this.codeGenTreeData.push(genCodeData.genCodeOp.codeGenTree)
          this.projectData = genCodeData.genCodeOp.projectOp
          this.updateCanSaveStatus()
          return
        }
      }
      if (this.$route.query) {
        const projectId = this.$route.query.projectId
        if (projectId !== undefined && projectId !== null && projectId !== '') {
          this.projectId = projectId
        }
        if (this.projectId) {
          UsrzCoCoderProjectApi.findDetail(this.projectId)
            .then(rt => {
              this.codeGenTreeData = []
              this.codeGenTreeData.push(rt.codeGenTree)
              this.projectData = rt.projectOp
              this.updateCanSaveStatus()
            })
        }
      }
    },
    onTreeNodeClick (nodeData) {
      if (nodeData.treeNodeFormatType === 'FILE') {
        this.editor.setValue(nodeData.content)
      }
    },
    async download () {
      await CoCoderApi.download(this.codeGenTreeData[0], this.projectData.projectName)
    },
    toSave () {
      this.enableSaveForm = true
      this.saveForm.codeGenTree = this.codeGenTreeData[0]
      this.saveForm.projectData = this.projectData
      UsrzBizConfigApi.findUsrzBizConfig().then(configOp => {
        if (configOp) {
          this.saveForm.projectMaxCount = configOp.coCoderProjectMaxCount
          UsrzCoCoderProjectApi.countTotal().then(op => {
            if (op) {
              this.saveForm.projectCount = op
              if (this.saveForm.projectCount >= this.saveForm.projectMaxCount) {
                this.saveForm.projectCountOverLimit = true
              }
            }
          })
        }
      })
    },
    doSave () {
      const ip = {
        codeGenTree: this.saveForm.codeGenTree,
        projectIp: this.saveForm.projectData
      }
      UsrzCoCoderProjectApi.add(ip)
        .then(rt => {
          this.projectData = rt
          this.updateCanSaveStatus()
          this.enableSaveForm = false
        })
    },

    goToPurVojistarCard () {
      this.$router.push('/perCenter/vojistarCard')
    },

    updateCanSaveStatus () {
      if (this.projectData && this.projectData.id === null) {
        this.isSaveDisabled = false
      } else {
        this.isSaveDisabled = true
      }
    },
    toGenCode () {
      this.$router.push('/tool/coCoder/genCode')
    },
    toMyProject () {
      this.$router.push('/tool/coCoder/coCoderProject')
    },
    showExampleProject () {
    }
  },
  mounted () {
    var ace = require('ace-builds')
    require('ace-builds/webpack-resolver')
    require('ace-builds/src-noconflict/mode-java')
    require('ace-builds/src-noconflict/ext-language_tools')
    var editor = ace.edit(this.$refs.javaViewer)
    editor.setTheme('ace/theme/monokai')
    editor.session.setMode('ace/mode/java')
    // editor.getSession().on('change', this.onChange)
    editor.getSession().setValue('public class HelloWorld {\n' +
      '\n' +
      '    public static void main(String[] args) {\n' +
      '\n' +
      '        System.out.println("Hello World")\n' +
      '    }\n' +
      '\n' +
      '}')
    editor.setOptions({})
    this.editor = editor
  }
}
</script>

<style lang="less" scoped>
.el-container {
  height: 100%;
}

.el-aside {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  padding: 0 0 0 0;
  height: 100%;
}

.java-viewer-main {
  height: 500px;
  width: 100%;

  .java-viewer {
    height: 100%;
  }
}
</style>
