/**
 * 天干地支之天干速查表
 * @Array Of Property trans['甲','乙','丙','丁','戊','己','庚','辛','壬','癸']
 * @return Cn string
 */
export default [
  '\u7532',
  '\u4e59',
  '\u4e19',
  '\u4e01',
  '\u620a',
  '\u5df1',
  '\u5e9a',
  '\u8f9b',
  '\u58ec',
  '\u7678'
]
