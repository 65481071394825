import resultHttpTool from '@/http/ResultHttpTool'
import ApiMsgBox from '@/api/ApiMsgBox'

const API_PREFIX = '/api/usr/usrAcct'

export function activate (usrAcctId, activationCode) {
  return resultHttpTool.get(API_PREFIX + '/activate', {
    params: {
      usrAcctId: usrAcctId,
      activationCode: activationCode
    }
  })
}

export function findCurrent () {
  return resultHttpTool.get(API_PREFIX + '/findCurrent')
}

export function detectUnidAvl (unid) {
  return resultHttpTool.get(API_PREFIX + '/detectUnidAvl', { params: { unid: unid } })
    .catch(() => {
      return false
    })
}

export function detectEmailAvl (email) {
  return resultHttpTool.get(API_PREFIX + '/detectEmailAvl', { params: { email: email } })
    .catch(() => {
      return false
    })
}

export function changeUnid (ip) {
  return resultHttpTool.post(API_PREFIX + '/changeUnid', ip)
}

export function setAvatar (ip) {
  return resultHttpTool.post(API_PREFIX + '/setAvatar', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function changePwd (ip) {
  return resultHttpTool.post(API_PREFIX + '/changePwd', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function changeEmail (ip) {
  return resultHttpTool.post(API_PREFIX + '/changeEmail', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export function updateBasicInfo (ip) {
  return resultHttpTool.post(API_PREFIX + '/updateBasicInfo', ip)
    .then(op => {
      if (op) {
        ApiMsgBox.opSuccess()
      } else {
        ApiMsgBox.opFailed()
      }
      return op
    })
}

export default {
  activate,
  findCurrent,
  detectUnidAvl,
  detectEmailAvl,
  changeUnid,
  changePwd,
  changeEmail,
  updateBasicInfo,
  setAvatar
}
