<template>
  <div>
    <I18nBtn></I18nBtn>
    <el-button @click="login">Login</el-button>
    <el-button @click="register">Register</el-button>
    <el-button @click="jumpToHome">Home</el-button>
    <el-button @click="toResPkg">StudyLabResPkg</el-button>
    <el-button @click="toTestMain">TestMain</el-button>

    <div class="css-box-1">
      <div class="content-area"></div>
    </div>
    <div class="css-box-2">
      <div class="content-area"></div>
    </div>
  </div>
</template>

<script>
import I18nBtn from '../../components/I18nBtn'

export default {
  name: 'Welcome',
  components: { I18nBtn },
  methods: {
    login () {
      this.$router.push({ path: '/login' })
    },
    register () {
      this.$router.push({ path: '/register' })
    },
    jumpToHome () {
      this.$router.push({ path: '/home' })
    },
    toResPkg () {
      this.$router.push({ path: '/studyLabResPkg' })
    },
    toTestMain () {
      this.$router.push({ path: '/testMain' })
    }
  }
}
</script>

<style scoped>
.css-box-1 {
  width: 300px;
  height: 200px;
  border: 20px red solid;
  padding: 20px;
  margin: 20px;
}

.css-box-2 {
  width: 300px;
  height: 200px;
  border: 20px green solid;
  padding: 20px;
  margin: 20px;
}

.content-area {
  width: 300px;
  height: 200px;
  background-color: blue;
}
</style>
