import axios from 'axios'
import uuid from '../util/UuidUtil'
import i18n from '../i18n/i18n'
import Vue from 'vue'

import SysConfig from '@/SysConfig'
import TimeUtil from '@/util/TimeUtil'
import LoginManager from '@/sys/LoginManager'

const generalHttpTool = axios.create({
  baseURL: '/vojivoUsrApi',
  timeout: SysConfig.reqTimeoutMsec
})

generalHttpTool.interceptors.request.use(config => {
  config.headers.token = LoginManager.token()
  const cmnParam = {
    traceId: uuid(),
    fromAppId: 'vojivo-usr-web',
    langId: i18n.locale,
    regionId: 'CHINA',
    timeZoneId: TimeUtil.guessCurrentTimezoneId()
  }
  config.headers['Cmn-Param'] = JSON.stringify(cmnParam)
    .replace('{', 'CmnParam(')
    .replace('}', ')CmnParam')
  return config
}, error => {
  console.log(error)
  return Promise.reject(error)
})

Vue.prototype.$http = generalHttpTool
export default generalHttpTool
