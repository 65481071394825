<template>
  <div>
    <el-row :gutter="8">
      <el-col :span="10">
        <h3>{{ this.$t('toolCmn.inputTextArea') }}</h3>
        <el-input v-if="executeMode==='TO_UNICODE'" v-model="inputTextArea" type="textarea" rows="10"
                  @input="onInput">
        </el-input>
        <el-input v-else v-model="inputTextArea" type="textarea" rows="10"
                  :placeholder="this.$t('enDecoder.inputUnicode')"
                  @input="onInput">
        </el-input>
      </el-col>
      <el-col :span="4">
        <div class="execute-mode-div">
          <div>
            <el-radio v-model="executeMode" label="TO_UNICODE" @input="onChangeExecuteMode">
              <span v-html="this.$t('enDecoder.toUnicode')"></span>
            </el-radio>
          </div>
          <div>
            <el-radio v-model="executeMode" label="FROM_UNICODE" @input="onChangeExecuteMode">
              {{ this.$t('enDecoder.fromUnicode') }}
            </el-radio>
          </div>
        </div>
      </el-col>
      <el-col :span="10">
        <h3 v-if="executeMode==='TO_UNICODE'">
          UNICODE
        </h3>
        <h3 v-else>
          {{ this.$t('toolCmn.resultTextArea') }}
        </h3>
        <el-input v-model="resultTextArea" type="textarea" rows="10" disabled>
        </el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EnDecoderApi from '@/api/vojivosvc/toolsubject/EnDecoderApi'

export default {
  name: 'UnicodeCharset',
  props: {
    encodeAlg: String
  },
  data () {
    return {
      inputTextArea: null,
      resultTextArea: null,
      executeMode: 'TO_UNICODE',
      delayExecuteTimeoutId: null
    }
  },
  methods: {
    onChangeExecuteMode (selectedValue) {
      this.delayExecute()
    },
    onInput () {
      this.delayExecute()
    },
    delayExecute () {
      this.clearDelayExecuteTimeout()
      this.delayExecuteTimeoutId = setTimeout(() => {
        this.execute()
      }, 500)
    },
    clearDelayExecuteTimeout () {
      if (this.delayExecuteTimeoutId) {
        clearTimeout(this.delayExecuteTimeoutId)
      }
    },
    execute () {
      this.clearDelayExecuteTimeout()
      if (this.inputTextArea === '' || this.inputTextArea === null || this.inputTextArea === undefined) {
        this.resultTextArea = null
        return
      }
      if (this.executeMode === 'TO_UNICODE') {
        EnDecoderApi.toUnicode(this.inputTextArea).then(rd => {
          this.resultTextArea = rd
        })
      } else {
        EnDecoderApi.fromUnicode(this.inputTextArea).then(rd => {
          this.resultTextArea = rd
        })
      }
    }
  }
}
</script>
